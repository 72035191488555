import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import TooltipWrapper from "../common/TooltipWrapper";
import styled from "styled-components";


const TagsWrapp = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 4px;
  z-index: 999;
`;

const TagWrapper = styled.div`
  padding-left: 4px;
`;

const Tag = styled.span`
  text-align: center;
  display: flex;
  font-size: 11px;
  color: ${props => {
    let color = '#fff';
    if(props.textColor) color = props.textColor;
    return color;
  }};
  white-space: nowrap;
  background-color: ${props => {
    let color = 'transparent';
    if (props.bgColor) color = props.bgColor;
    if (props.bgColor === 'red') color = '#e37e74';
    if (props.bgColor === 'green') color = '#15b95d';
    return color;
  }};

  border: ${props => {
    let border;
    if (props.borderColor) border = `1px solid ${props.borderColor}`;
    if (props.bgColor === 'red') border = '1px solid #a85c54';
    if (props.bgColor === 'green') border = '1px solid #2a9454';
    return border;
  }};
  padding: 2px 3px;
  border-radius: 3px;
`;

const SummonerTags = ({data, ranks}) => {
    const {t} = useTranslation();


    const RenderSpan = useCallback(({color,borderColor,textColor = "#fff", contentText, explainText, disabled}) => {
        return (
            <TagWrapper>
                <TooltipWrapper
                    disabled={disabled}
                    tooltipText={explainText}>
                    <Tag bgColor={color} textColor={textColor} borderColor={borderColor}>{contentText}</Tag>
                </TooltipWrapper>
            </TagWrapper>
        )
    }, [data]);

    const Carry = useCallback(() => {
        if (ranks === 1 || ranks === 2) {
            if (Number(data.summoner_info_dict.wins) + Number(data.summoner_info_dict.losses) > 4) {
                if (data.summoner_info_dict.ai_score_avg > 56) {
                    return (
                        <>
                            <RenderSpan
                                color={'green'}
                                contentText={t('tags.carry')}
                                explainText={t('tags.carryExplain')}
                            />
                        </>
                    )
                }
            }
        }
        return <></>

    }, [data, ranks]);


    const EarlyCarry = useCallback(() => {
        const matchCount = data.summoner_info_dict.wins + data.summoner_info_dict.wins;
        if (matchCount > 4) {
            if (data.summoner_info_dict.ai_score_avg_15 > 56) {
                return (
                    <RenderSpan
                        color={'green'}
                        contentText={t('tags.earlyCarry')}
                        explainText={t('tags.earlyCarryExplain')}
                    />
                )
            } else if (data.summoner_info_dict.ai_score_avg_15 < 44) {
                return (
                    <RenderSpan
                        color={'red'}
                        contentText={t('tags.earlyWeakness')}
                        explainText={t('tags.earlyWeaknessExplain')}
                    />
                )
            }
        }


        return (
            <></>
        )
    }, [data]);

    const SolidMental = useCallback(() => {
        if (data.summoner_info_dict.losses > 3) {
            if (data.summoner_info_dict.ai_score_avg_loss > 47) {
                return (<RenderSpan
                    color={'green'}
                    contentText={t('tags.strongMental')}
                    explainText={t('tags.strongMentalExplain')}
                />)
            } else if (data.summoner_info_dict.ai_score_avg_loss < 39) {
                return (
                    <RenderSpan
                        color={'red'}
                        contentText={t('tags.weakMental')}
                        explainText={t('tags.weakMentalExplain')}
                    />)
            }
        }

        return (
            <></>
        )
    }, [data]);

    const CheckAway = useCallback(() => {
        if (data.summoner_info_dict.tag.runaway_count > 0) {
            return (
                <RenderSpan
                    color={'red'}
                    contentText={t('tags.escape')}
                    explainText={t('tags.escapeExplain')}
                />
            )
        }

        return (
            <></>
        )
    }, [data]);

    const LastMatch = useCallback(() => {
        // const timeClac = Math.trunc(Date.now() / 1000) - new Date(data.last_match_time).getTime();
        // const conditionalDate = 86400 * 14;
        if (data.summoner_info_dict.tag.comeback_user) {
            return (
                <RenderSpan
                    color={'red'}
                    contentText={t('tags.comeback')}
                    explainText={t('tags.comebackExplain')}
                />
            )
        }
        return (
            <></>
        )
    }, [data]);

    const Strikes = useCallback(() => {
        if (data.summoner_info_dict.tag.wining_streak > 1) {
            return (
                <RenderSpan
                    color={'green'}
                    contentText={`${data.summoner_info_dict.tag.wining_streak} ${t('tags.win')}`}
                    disabled={true}
                />
            )
        } else if (data.summoner_info_dict.tag.losing_streak > 1) {
            return (
                <RenderSpan
                    color={'red'}
                    contentText={`${data.summoner_info_dict.tag.losing_streak} ${t('tags.lose')}`}
                    disabled={true}
                />
            )
        }
        return <></>

    }, [data])

    // const Duo = useCallback(() => {
    //     console.log("ISDUO",isDuo);
    //     if (Array.isArray(isDuo) && isDuo.length > 0) {
    //       return  isDuo.slice(0,2).map((duo) => {
    //             return <RenderSpan
    //                 color={duo.background}
    //                 contentText={`DUO`}
    //                 textColor={duo.textColor}
    //                 borderColor={duo.borderColor}
    //                 disabled={true}
    //             />
    //         })
    //
    //     }
    //
    //     return null;
    // }, [isDuo]);

    return (
        <>
            <TagsWrapp>
                <Strikes/>
                <Carry/>
                {/*<Duo/>*/}
                <EarlyCarry/>
                <SolidMental/>
                <CheckAway/>
                <LastMatch/>

                {/*{data.tags.solo_kill_tag === 1 && <span className="win w4">{t('tags.killer')}</span>}*/}
                {/*{data.tags.solo_death_tag === 1 && <span className="lose w4">{t('tags.victim')}</span>}*/}
                {/*{data.tags.roaming_kill_tag === 1 && <span className="win w4">{t('tags.roamer')}</span>}*/}
                {/*{data.tags.roaming_death_tag === 1 && (<span className="lose w4">{t('tags.roam2')}</span>)}*/}
                {/*{data.tags.ganking_kill_tag === 1 && <span className="win w4">{t('tags.ganker')}</span>}*/}
                {/*{data.tags.ganking_death_tag === 1 && (<span className="lose w4">{t('tags.gank2')}</span>)}*/}
            </TagsWrapp>
        </>
    );
};

export default SummonerTags;
