import React, {useState} from 'react';
import styled from "styled-components";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SharedMessage from "./SharedMessage";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  position: relative;
`;


const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0px 2px 2px #00000040;
  border: 1px solid #5C628D;
  width: 124px;
  height: 30px;
`;

const ButtonText = styled.span`
  margin: 0 0 0 2px;
  color: #5F6695;
  font-size: 14px;
  font-weight: bold;
`;

const ShareImage = styled.img`
  width: 15px;
  height: 15px;
`;

const DetailShareTag = ({match,summoner,region}) => {
    const {t} = useTranslation();

    const url = `${window.location.origin}/summoner/${region}/${encodeURIComponent(summoner.summoner_basic_info_dict.summoner_name)}/matches/${match.match_basic_dict.match_id}`


    const [copy, setCopy] = useState({
        copied: false,
    });

    const handleCopy = () => {
        setCopy({copied: true});
        closeCopied();
    };

    // 공유하기 툴팁
    const closeCopied = () => {
        setTimeout(() => {
            setCopy({copied: false});
        }, 2000);
    };

    return (
        <Wrapper>
            {copy.copied && <SharedMessage/>}
            <CopyToClipboard onCopy={handleCopy} text={url}>
                <ButtonWrapper>
                    <ShareImage
                        src={'images/ico_list_copy_link.svg'}
                    />
                    <ButtonText>
                        {t('detailTab.share')}
                    </ButtonText>
                </ButtonWrapper>
            </CopyToClipboard>
        </Wrapper>
    );
};

export default DetailShareTag;
