import React, {useCallback, useEffect, useMemo} from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import AutoCompleteContent from "./AutoCompleteContent";
import {useHistory} from "react-router-dom";
import {GET_AUTO_COMPLETE_REQUEST} from "../../../../store/reducer/summoner";
import {useTranslation} from "react-i18next";
import {cho_convertor} from "../../../../function";

const Wrapper = styled.div`
  display: ${props => {
    let visible = 'none';
    if (props?.summonerName) visible = 'block';
    return visible;
  }};
  width: 100%;
`;


const SumWrapper = styled.div`
  width: 100%;
  border: 1px solid #6B78B5;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 6px;
  box-shadow: 0 1px 6px #00000029;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #EFF3FF;
  padding: 12px;
`;

const ContentText = styled.span`
  font-size: 14px;
  margin-left: 4px;
`;
const HeaderText = styled.span`
  color: #fff;
  font-size: 12px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #979EC2;
  padding: 5px 9px;
`;

const RotateImg = styled.img`
  animation: rotate_image 1.2s linear infinite;
  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2px;
`;

const ChampionWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;


const AutoComplete = ({summonerName, region,focus}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const championData = useSelector((state) => state?.imageStore.champions);
    const version = useSelector((state) => state?.gameVersion.version);
    const {autoComplete, getAutoCompleteLoading, getAutoCompleteDone} = useSelector((state) => state.summoner);
    const history = useHistory();
    const championFiltered = useMemo(() => {
        if (championData.length > 0 && summonerName) {
            const regex = new RegExp(`^${summonerName.toLowerCase()}+`);
            return championData.filter((data) => regex.test(data.champion_name_en.toLowerCase()) || regex.test(data.champion_name_kr) || regex.test(data.cho_name));
        }
        return [];
    }, [summonerName]);

    const onClickChampion = useCallback((data) => {
            const lowerName = data.champion_name_en.replace(/\s|&|willump|!|/gi, "").toLowerCase();
            history.push(`/champions/${lowerName}/build`);
        }, []
    );

    const onClickSummoner = useCallback((data) => {
        if(data.summoner_basic_info_dict) {
            history.push(`/summoner/${region}/${encodeURIComponent(data.summoner_basic_info_dict.summoner_name)}`)
        }
    },[region]);

    useEffect(() => {
        if (summonerName.length > 0) {
            dispatch({
                type: GET_AUTO_COMPLETE_REQUEST,
                data: {
                    username: summonerName,
                    region: region,
                }
            })
        }
    }, [summonerName, region])
    //loading
    if(!focus) return null;

    if (getAutoCompleteLoading) {
        return (
            <Wrapper summonerName={summonerName}>
                <SumWrapper>
                    <ContentWrapper>
                        <EmptyWrapper>
                            <RotateImg
                                width={20}
                                height={20}
                                src={'/images/ico_input_search_loading.svg'}
                            />
                            <ContentText>{t("autoComplete.search")}</ContentText>
                        </EmptyWrapper>
                    </ContentWrapper>
                </SumWrapper>
            </Wrapper>
        )
    }

    return (
        <Wrapper summonerName={summonerName}>
            <SumWrapper>
                <HeaderWrapper>
                    <HeaderText>
                        {t('header.navSummoner')}
                    </HeaderText>
                </HeaderWrapper>
                <ContentWrapper>
                    {autoComplete?.summoner_basic_info_dict ?
                        <AutoCompleteContent
                            tier={autoComplete?.season_tier_info_dict.ranked_solo_5x5?.tier}
                            division={autoComplete?.season_tier_info_dict.ranked_solo_5x5?.division}
                            onClick={onClickSummoner}
                            data={autoComplete}
                            key={autoComplete?.summoner_basic_info_dict?.summoner_id}
                            img={`${process.env.REACT_APP_IMG}${version}/img/profileicon/${autoComplete?.summoner_basic_info_dict?.profile_id}.png`}
                            name={autoComplete?.summoner_basic_info_dict?.summoner_name}
                        />
                        :
                        <EmptyWrapper>
                            <img
                                width={20}
                                height={20}
                                src={'/images/ico_input_search_empty_box.svg'}
                                alt={""}
                            />
                            <ContentText>{t("autoComplete.noData")}</ContentText>
                        </EmptyWrapper>}
                </ContentWrapper>
            </SumWrapper>
            {championFiltered.length > 0 &&
            <SumWrapper>
                <HeaderWrapper>
                    <HeaderText>
                        {t('header.navChampions')}
                    </HeaderText>
                </HeaderWrapper>
                <ContentWrapper>
                    <ChampionWrapper>
                        {championFiltered.map((data) => {
                            return (
                                <AutoCompleteContent
                                    onClick={onClickChampion}
                                    data={data}
                                    key={data.championId}
                                    img={`${process.env.REACT_APP_IMG}${version}/img/champion/${data.image_name}`}
                                    name={data.champion_name_kr}
                                />
                            )
                        })}
                    </ChampionWrapper>
                </ContentWrapper>
            </SumWrapper>}
        </Wrapper>
    );
};

export default AutoComplete;
