import {all, call, delay, fork, put, select, takeLatest, take} from "redux-saga/effects";
import {
    GET_AUTO_COMPLETE_FAILURE,
    GET_AUTO_COMPLETE_REQUEST,
    GET_AUTO_COMPLETE_SUCCESS,
    GET_CHAMP_INFO_FAILURE,
    GET_CHAMP_INFO_REQUEST,
    GET_CHAMP_INFO_SUCCESS,
    GET_CHAMPIONS_BY_SEASON_REQUEST,
    GET_CHAMPIONS_BY_SEASON_SUCCESS,
    GET_GAME_ANALYTICS_PATCH_REQUEST,
    GET_GAME_ANALYTICS_PATCH_SUCCESS,
    GET_INGAME_CHECK_FAILURE,
    GET_INGAME_CHECK_REQUEST,
    GET_INGAME_CHECK_SUCCESS,
    GET_MATCH_BY_QUEUE_FAILURE,
    GET_MATCH_BY_QUEUE_REQUEST,
    GET_MATCH_BY_QUEUE_SUCCESS,
    GET_MATCH_FAILURE,
    GET_MATCH_REQUEST,
    GET_MATCH_SUCCESS,
    GET_MORE_MATCHES_FAILURE,
    GET_MORE_MATCHES_REQUEST,
    GET_MORE_MATCHES_SUCCESS,
    GET_MULTI_LIST_REQUEST,
    GET_MULTI_LIST_SUCCESS,
    GET_MULTI_LIST_UPDATE_REQUEST,
    GET_MULTI_LIST_UPDATE_SUCCESS,
    GET_MULTI_SUMMONER_LP_CHART_REQUEST,
    GET_MULTI_SUMMONER_LP_CHART_SUCCESS,
    GET_SHORT_LINK_REQUEST,
    GET_SHORT_LINK_SUCCESS,
    GET_SUMMONER_FAILURE,
    GET_SUMMONER_INFO_FAILURE,
    GET_SUMMONER_INFO_REQUEST,
    GET_SUMMONER_INFO_SUCCESS,
    GET_SUMMONER_LP_CHART_REQUEST,
    GET_SUMMONER_LP_CHART_SUCCESS,
    GET_SUMMONER_REQUEST,
    GET_SUMMONER_SUCCESS,
    SET_SHORT_LINK_REQUEST,
    SET_SHORT_LINK_SUCCESS,
    UPDATE_SUMMONER_INFO_FAILURE,
    UPDATE_SUMMONER_INFO_REQUEST,
    UPDATE_SUMMONER_INFO_SUCCESS
} from "../reducer/summoner";
import axios from "axios";
import {getRegion, setSummonerName} from "../../function";
import {END, eventChannel} from "redux-saga";
import {SSEFix} from "../../common/moduleFork/sseFix";
import {_NOTICE} from "../modules/notice";


const createSSEChannel = (action) => {

    return eventChannel(emit => {
        let retry = 1;
        const eventStream = new SSEFix(`${process.env.REACT_APP_NEW_API}match/matches`, {
            payload: JSON.stringify(action),
        });

        eventStream.addEventListener("error", (e) => {
            const data = e?.detail?.data;
            if (data) {
                if (JSON.parse(data).status === 1) {
                    emit({
                        error: JSON.parse(data).status
                    })
                }
                if (JSON.parse(data).status === 2 && retry) {
                    setTimeout(() => {
                        eventStream.stream();
                    }, 2000);
                    retry--;
                } else if (!retry) {
                    emit({
                        error: JSON.parse(data).status,
                    })
                }
            } else {
                eventStream.close();
            }
        });

        eventStream.addEventListener("message", (e) => {
            // Fallback handler for undefined event types

            const percent = e.data;

            if (Number(percent) === 100) {
                emit(percent);
                emit(END);
            }
        });
        eventStream.stream();
        return () => eventStream.removeEventListener();
    })
}

const mutliListAPI = (action) => {
    const url = `${process.env.REACT_APP_CACHE_API}multisearch/multisearch?puu_id=${action.puu_id}&season=${action.season}`;
    return axios.get(url).then(res => {
        if (res.data && typeof res.data === 'object') {
            return {...res.data, summoner: {...action.summoner}}
        }
    });
}

const getShortLinkAPI = (action) => {
    const currentLang = getRegion(action.region);
    const url = 'https://scrimapi.deeplol.gg/api/getshortlink/' + encodeURIComponent(action.short);
    return axios.get(url).then(res => ({...res.data, region: currentLang}));
}

const setShortLinkAPI = (action) => {
    if (action) {
        const url = 'https://scrimapi.deeplol.gg/api/getsummoners/' + action.summoner;
        return axios.get(url).then(res => res.data);
    } else {
        throw "unexpected name";
    }

}


const getChampInfoAPI = (action) => {
    let url;
    const changeRegion = getRegion(action.region);
    if (action.category === 0) {
        url = `${process.env.REACT_APP_API}summoner_stat_tap?summonerId=${action.summoner_id}&season=${action.season}&region=${changeRegion}`
    } else if (action.category === 1) {
        url = `${process.env.REACT_APP_API}summoner_stat_tap_enemy?summonerId=${action.summoner_id}&season=${action.season}&region=${changeRegion}`
    } else {
        throw 'unexpected category';
    }

    return axios.get(url).then((res) => res.data.result);
}

const getSummonerName = (action) => {
    if (action?.puu_id) {
        const searchName = setSummonerName(action?.puu_id);
        const changeRegion = getRegion(action.region);
        const url = `${process.env.REACT_APP_CACHE_API}summoner/summoner-name?puu_id=${encodeURI(searchName)}&platform_id=${changeRegion}`;

        return axios.get(url).then((result) => {
            if (!result.data.error) {
                return result.data.summoner_name;
            } else {
                throw result.data.error
            }
        }).catch((e) => {
            return action.username;
        });
    } else {
        return action.username;
    }

}

const getSummonerAPI = (action) => {
    const searchName = setSummonerName(action.username);
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}summoner/summoner?summoner_name=${encodeURI(searchName)}&platform_id=${changeRegion}`;

    return axios.get(url).then((result) => {
        if (!result.data.error) {
            return result.data;
        } else {
            throw result.data.error
        }
    }).catch((e) => {
        const statusCode = e.response.status;
        if (statusCode === 404 || statusCode === 501) {
            return 404;
        }
    });
}

const getSummonerStatus = (action) => {
    // const url = `${process.env.REACT_APP_API}summoner_stat?summonerId=${action.summoner_id}&region=${action.region}&season=13`
    const url = `${process.env.REACT_APP_CACHE_API}summoner/champion-stat?puu_id=${action.puu_id}&season=${action.season}`
    // 소환사 stat 정보
    return axios.get(url)
        .then((res) => {
            return res.data;
        }).catch(((e) => {
            return e.response.status;
        }));
}


const getIngameDataAPI = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}ingame_basic?summonerId=${action.summoner_id}&region=${changeRegion}&season=13`;
    return axios.get(url).then((response) => {
        const result = response.data.result;
        if (result !== 'Not playing') {
            if (result !== 'Not supported') {
                if (result !== 'server error') {
                    return result;
                }
                throw 'server error';
            }
            throw 'Not supported';
        }
        throw "Not playing";
    })
}

const getSummonerIngameStatusAPI = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}ingame/check?summoner_id=${action.summoner_id}&platform_id=${changeRegion}`

    return axios.get(url)
        .then((res) => {
            const data = res.data;
            return Boolean(data?.playing);
        })
        .catch((e) => {
            return false;
        });

}


const getUpdateStatus = (action) => {
    const url = `${process.env.REACT_APP_CACHE_API}summoner/updated-time?puu_id=${action?.puu_id}`;
    return axios.get(url)
        .then((res) => res.data);
}

const getMatchInfoAPI = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}match/matches?puu_id=${action.puu_id}&platform_id=${changeRegion}&offset=${action.offset}&count=${action.matchLength}&queue_type=${action.queueNum}&champion_id=${action.champId}&only_list=1`
    return axios.get(url).then((res) => res.data.match_id_list).catch((() => false));
}
const getSingleMatch = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}match/match-cached?match_id=${action.match_id}&platform_id=${changeRegion}`;
    return axios.get(url).then((res) => res.data);
}

const getMatchDataAPI = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}match/match-cached?match_id=${action.matchId}&platform_id=${changeRegion}`
    return axios.get(url).then((res) => res.data);
}

const getTierChartAPI = (action) => {
    const changeRegion = getRegion(action.region);
    return axios.get(`${process.env.REACT_APP_CACHE_API}summoner/tier-chart?summoner_id=${action.summoner_id}&platform_id=${changeRegion}`)
        .then((res) => res.data).catch((() => false));
}

const gameAnalyticsPatch = (action) => {
    const url = `${process.env.REACT_APP_CACHE_API}match/patch?version=${action.version}`;
    return axios.get(url).then((res) => res.data);
}


function* getMoreMatches(action) {
    try {


        const summoner = yield select((state) => state.summoner.summoner);
        const match = yield select((state) => state.summoner.match);

        const matchInfoArgs = {
            puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            region: getRegion(action.data.region),
            offset: match.length,
            matchLength: 20,
            queueNum: action.data.queueNum,
            champId: action.data.champId,
        }

        const matchArr = yield call(getMatchInfoAPI, matchInfoArgs)
        const getMatches = yield all(matchArr.map((data) => {
            return call(getSingleMatch, {
                match_id: data,
                region: matchInfoArgs.region,
                puu_id: matchInfoArgs.puu_id,
            })
        }))

        yield put({
            type: GET_MORE_MATCHES_SUCCESS,
            data: {
                match: getMatches || [],
            }
        })


    } catch (e) {
        console.log(e);
        yield put({
            type: GET_MORE_MATCHES_FAILURE,
            error: e,
        })
    }
}


function* getSummoner(action) {
    try {
        const nameCheck = yield call(getSummonerName, action.data);

        const getSummonerObj = {
            ...action?.data,
            username: nameCheck
        }
        const getSummoner = yield call(getSummonerAPI, getSummonerObj);

        if (typeof getSummoner === "number") throw getSummoner;


        if (action?.data?.matchId) {
            yield put({
                type: GET_MATCH_REQUEST,
                data: {
                    match: {
                        region: action.data.region,
                        matchId: action.data.matchId,
                        puu_id: getSummoner.summoner_basic_info_dict.puu_id,
                    }
                }
            });
        }


        yield all([put({
            type: GET_SUMMONER_SUCCESS,
            data: {
                summoner: getSummoner,
            }
        }),
            put({
                type: GET_SUMMONER_INFO_REQUEST,
                data: {
                    summoner: getSummoner,
                    ...action.data,
                }
            })
        ])

    } catch (e) {
        yield put({
            type: GET_SUMMONER_FAILURE,
            error: e,
        })
    }

}


//Deprecated,to do not usable on new api
function* getChampInfo(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);
        const champData = yield select((state) => state.imageStore.champions);
        const lang = yield select((state) => state.translation.lang);
        const currentSeason = yield select((state) => state.imageStore.currentSeason);
        const champInfo = yield call(getChampInfoAPI, {
            summoner_id: summoner.summoner_id,
            region: action.data.region,
            category: action.data.category,
            season: currentSeason,
        });

        if (champInfo) {
            //챔피언 이름 붙이기
            for (let attr in champInfo) {
                for (let position in champInfo[attr]) {
                    if (champInfo[attr].hasOwnProperty(position)) {
                        champInfo[attr][position] = champInfo[attr][position].map((data, index) => {
                            const getChampionData = champData.find((champ, index) => Number(champ.championId) === Number(data.championId));
                            let detail = [];
                            if (data.detail.length > 0) {
                                detail = data.detail.map((detail, index) => {
                                    const detailData = champData.find((champ, index) => Number(champ.championId) === Number(detail.enemyId));
                                    return {
                                        ...detail,
                                        enemy_name_kr: detailData.champion_name_kr,
                                        enemy_name_en: detailData.champion_name_en,
                                    }
                                })
                            }
                            return {
                                ...data,
                                detail: [...detail],
                                champion_name_kr: getChampionData.champion_name_kr,
                                champion_name_en: getChampionData.champion_name_en,
                            }
                        })
                    }
                }
            }


            const sortFilter = ['games', 'winrate'];
            sortFilter.push(lang === 'KOR' ? 'champion_name_kr' : 'champion_name_en');

            const withOrigin = champInfo['with_position'];
            const withOutOrigin = champInfo['without_position'];

            yield put({
                type: GET_CHAMP_INFO_SUCCESS,
                data: {
                    withOrigin: {...withOrigin},
                    withOutOrigin: {...withOutOrigin},
                }
            })
        }


    } catch (e) {
        console.error(e);
        yield put({
            type: GET_CHAMP_INFO_FAILURE,
            error: e,
        })
    }
}

function* getIngameCheck(action) {
    try {

        const obj = {
            summoner_id: action.data.summonerId,
            region: action.data.region,
        }


        const getIngameStatus = yield call(getSummonerIngameStatusAPI, obj);

        yield put({
            type: GET_INGAME_CHECK_SUCCESS,
            data: {
                ingameStatus: getIngameStatus
            }
        })

    } catch (e) {
        yield put({
            type: GET_INGAME_CHECK_FAILURE,
            error: e,
        })
    }
}

function* getSummonerInfo(action) {
    try {
        const regionAndSummonerId = {
            summoner_id: action.data.summoner?.summoner_basic_info_dict?.summoner_id,
            puu_id: action.data.summoner?.summoner_basic_info_dict?.puu_id,
            region: action.data.region,
            season: action.data.currentSeason,
        }


        const matchInfoArgs = {
            puu_id: action.data.summoner?.summoner_basic_info_dict?.puu_id,
            region: action.data.region,
            matchLength: 20,
            queueNum: "ALL",
            offset: 0,
            champId: 0,
        }

        console.log("GET SUMMONER",action.data);

        const [
            getUpateStatus,
            getSummonerStat,
        ] = yield all([
            call(getUpdateStatus, regionAndSummonerId),
            call(getSummonerStatus, regionAndSummonerId),
            put({
                type: GET_INGAME_CHECK_REQUEST,
                data: {
                    summonerId: regionAndSummonerId.summoner_id,
                    region: regionAndSummonerId.region,
                }
            }),
            put({
                type: GET_MATCH_BY_QUEUE_REQUEST,
                data: {
                    queueNum: action?.data?.queue_id || 'ALL',
                    champId: 0,
                    region: action.data.region,
                }
            }),
            put({
                type: GET_SUMMONER_LP_CHART_REQUEST,
                data: {
                    ...regionAndSummonerId
                }
            }),
        ]);


        yield put({
            type: GET_SUMMONER_INFO_SUCCESS,
            data: {
                updateStatus: getUpateStatus || null,
                summonerStatus: getSummonerStat || null,
            }
        })


        yield put({
            type: GET_CHAMP_INFO_SUCCESS,
            data: {
                champions: getSummonerStat,
            }
        })


    } catch (error) {
        yield put({
            type: GET_SUMMONER_INFO_FAILURE,
            data: error,
        })
    }
}


function* getLPChart(action) {
    try {

        const regionAndSummonerId = {
            summoner_id: action.data.summoner_id,
            puu_id: action.data.puu_id,
            region: action.data.region,
        }

        const getTierChart = yield call(getTierChartAPI, regionAndSummonerId);

        yield put({
            type: GET_SUMMONER_LP_CHART_SUCCESS,
            data: {
                tierChart: getTierChart || null,
            }
        })
    } catch (e) {
        console.log(e);
    }
}

function* updateSummonerInfo(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);

        const [upateStatus, summonerInfo] = yield all([call(getUpdateStatus, {
            puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            region: action.data.region,
        }), call(getSummonerAPI, {
            username: summoner.summoner_basic_info_dict?.summoner_name,
            region: action.data.region
        })]);


        yield all([put({
            type: GET_SUMMONER_INFO_REQUEST,
            data: {
                summoner: summoner,
                ...action.data,
            }
        }),
            put({
                type: UPDATE_SUMMONER_INFO_SUCCESS,
                data: {
                    summoner: summonerInfo,
                    updateStatus: upateStatus
                }
            })

        ])

    } catch (error) {
        console.error(error);
        yield put({
            type: UPDATE_SUMMONER_INFO_FAILURE,
            error: error,
        })
    }
}

function* getMatchByQueue(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);

        const matchInfoArgs = {
            puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            region: action.data.region,
            matchLength: 20,
            offset: 0,
            queueNum: action.data.queueNum,
            champId: action.data.champId,
        }
        const getMatchInfo = yield call(getMatchInfoAPI, matchInfoArgs)
        const getMatches = yield all(getMatchInfo.map((data) => {
            return call(getSingleMatch, {
                match_id: data,
                region: matchInfoArgs.region,
                puu_id: matchInfoArgs.puu_id,
            })
        }))

        yield put({
            type: GET_MATCH_BY_QUEUE_SUCCESS,
            data: {
                match: getMatches || [],
            }
        })

    } catch (e) {
        console.error(e);
        yield put({
            type: GET_MATCH_BY_QUEUE_FAILURE,
            error: e,
        })
    }
}

function* getMatch(action) {
    try {

        const getMatchData = yield call(getMatchDataAPI, {
            ...action.data.match,

        });

        yield put({
            type: GET_MATCH_SUCCESS,
            data: getMatchData,
        })

    } catch (e) {
        yield put({
            type: GET_MATCH_FAILURE,
            error: e,
        })
    }
}

function* liveSummonerIngame(action) {
    try {
        // const liveData = yield call(getIngameDataAPI, action.data);

    } catch (e) {
    }
}

function* getShortLink(action) {
    try {
        const getShortLinkData = yield call(getShortLinkAPI, action.data);
        yield put({
            type: GET_SHORT_LINK_SUCCESS,
            data: getShortLinkData,
        })
    } catch (e) {

    }
}

function* setShortLink(action) {
    try {
        const getShortLink = yield call(setShortLinkAPI, action.data)
        yield put({
            type: SET_SHORT_LINK_SUCCESS,
            data: getShortLink,
        })
    } catch (e) {

    }
}

function* getAutoComplete(action) {
    try {
        yield delay(500);
        const getSummoner = yield call(getSummonerAPI, action.data);

        yield put({
            type: GET_AUTO_COMPLETE_SUCCESS,
            data: {
                autoComplete: getSummoner,
            }
        })
    } catch (e) {
        yield put({
            type: GET_AUTO_COMPLETE_FAILURE,
            error: e,
        })
    }
}

function* multiListFetch(summoners) {
    const multiList = yield all(summoners.map((summoner) => call(mutliListAPI, {
        summoner: summoner,
        puu_id: summoner?.summoner_basic_info_dict?.puu_id,
        season: summoner?.currentSeason
    })));
    return multiList;
}

function* getMultiList(action) {
    try {

        const summonersArray = action?.data?.summoners?.split(',');
        if (Array.isArray(summonersArray) && summonersArray.length > 0) {
            const summoners = yield all(summonersArray.map((name) => call(getSummonerAPI, {
                username: name,
                region: action.data.region
            })));

            const reduceArray = summoners.reduce((sum, cur) => {
                if (cur !== 404 && typeof cur === 'object') {
                    sum.push({...cur, currentSeason: action.data.currentSeason});
                }
                return sum;
            }, []);


            const nameList = reduceArray.map((data) => data.summoner_basic_info_dict.summoner_name);

            yield put({
                type: SET_SHORT_LINK_REQUEST,
                data: {
                    summoner: encodeURIComponent(nameList.toString()),
                }
            })
            //             type: SET_SHORT_LINK_REQUEST,
            // data: {
            //     summoner: encodeURIComponent(splitedName.toString()),


            const multiList = yield multiListFetch(reduceArray);

            yield put({
                type: GET_MULTI_SUMMONER_LP_CHART_REQUEST,
                data: {
                    list: [...multiList],
                    region: action.data.region,
                }
            })

            yield put({
                type: GET_MULTI_LIST_SUCCESS,
                data: {
                    list: [...multiList],
                }
            });
            const sseObj = {
                "season": action.data.currentSeason,
                "queue_type": "RANKED_SOLO_5x5",
                "start_idx": 0,
                "count": 10
            }


            yield put({
                type: GET_MULTI_LIST_UPDATE_REQUEST,
            })

            const sseUpdate = yield all(multiList.map((list) => call(createSSEChannel, {
                ...sseObj,
                platform_id: getRegion(action.data.region),
                puu_id: list.summoner.summoner_basic_info_dict.puu_id,
            })))


            while (true) {
                const event = yield all(sseUpdate.map((update) => take(update)));

                const notClear = event.find((data) => Number(data) !== 100);
                const hasError = event.find((data) => data.error > 0);
                if (hasError) {
                    break;
                }
                if (!notClear) {
                    break;
                }
            }

            const updatedList = yield multiListFetch(reduceArray);
            yield put({
                type: GET_MULTI_LIST_UPDATE_SUCCESS,
                data: {
                    list: [...updatedList],
                }
            })


        }

    } catch (e) {
        console.log(e);
    }
}

function* getMultiLPChart(action) {
    try {

        const summonerList = action.data.list;

        const tierList = yield all(summonerList.map((data) => {

            const regionAndSummonerId = {
                summoner_id: data?.summoner?.summoner_basic_info_dict?.summoner_id,
                puu_id: data?.summoner?.summoner_basic_info_dict?.puu_id,
                region: action?.data?.region,
            }
            return call(getTierChartAPI, regionAndSummonerId);
        }));


        yield put({
            type: GET_MULTI_SUMMONER_LP_CHART_SUCCESS,
            data: {
                list: [...tierList],
            }
        })
    } catch (e) {

    }
}

function* championsBySeason(action) {
    try {
        const obj = {
            puu_id: action.data.puu_id,
            season: action.data.season,
        }
        const result = yield call(getSummonerStatus, obj);

        yield put({
            type: GET_CHAMPIONS_BY_SEASON_SUCCESS,
            data: {
                summonerStatus: result,
            }

        })

    } catch (e) {

        console.log(e);
    }
}

function* getGameAnalyticsPatch(action) {
    try {
        const obj = {
            version: action.data.version,
        }
        const result = yield call(gameAnalyticsPatch,obj);

        yield put({
            type :GET_GAME_ANALYTICS_PATCH_SUCCESS,
            data: {
                version: obj.version,
                patch_correction_list : result.patch_correction_list,
            }
        })
    } catch (e) {
        console.log(e);
    }
}

function* watchGetMoreMatches() {
    yield takeLatest(GET_MORE_MATCHES_REQUEST, getMoreMatches)
}

function* watchGetMatches() {
    yield takeLatest(GET_SUMMONER_REQUEST, getSummoner)
}

function* watchGetChampInfo() {
    yield takeLatest(GET_CHAMP_INFO_REQUEST, getChampInfo)
}

function* watchGetSummonerInfo() {
    yield takeLatest(GET_SUMMONER_INFO_REQUEST, getSummonerInfo);
}


function* watchUpdateSummonerInfo() {
    yield takeLatest(UPDATE_SUMMONER_INFO_REQUEST, updateSummonerInfo);
}


function* watchGetMatchByQueue() {
    yield takeLatest(GET_MATCH_BY_QUEUE_REQUEST, getMatchByQueue);
}

function* watchLiveSummonerIngame() {
    yield takeLatest("??????", liveSummonerIngame);
}

function* watchGetMatch() {
    yield takeLatest(GET_MATCH_REQUEST, getMatch);
}

function* watchShortLink() {
    yield takeLatest(GET_SHORT_LINK_REQUEST, getShortLink);
}

function* watchSetShortLink() {
    yield takeLatest(SET_SHORT_LINK_REQUEST, setShortLink);
}

function* watchAutoComplete() {
    yield takeLatest(GET_AUTO_COMPLETE_REQUEST, getAutoComplete);
}

function* watchGetMultiList() {
    yield takeLatest(GET_MULTI_LIST_REQUEST, getMultiList);
}

function* watchLPchart() {
    yield takeLatest(GET_SUMMONER_LP_CHART_REQUEST, getLPChart);
}

function* watchGetIngameCheck() {
    yield takeLatest(GET_INGAME_CHECK_REQUEST, getIngameCheck)
}

function* watchGetMultiLPChart() {
    yield takeLatest(GET_MULTI_SUMMONER_LP_CHART_REQUEST, getMultiLPChart);
}

function* watchGetChampionsBySeason() {
    yield takeLatest(GET_CHAMPIONS_BY_SEASON_REQUEST, championsBySeason);
}

function* watchGetGameAnalyticsPatch() {
    yield takeLatest(GET_GAME_ANALYTICS_PATCH_REQUEST,getGameAnalyticsPatch);
}

export default function* summonerSaga() {

    yield all([
        fork(watchGetMoreMatches),
        fork(watchGetMatches),
        fork(watchGetChampInfo),
        fork(watchGetSummonerInfo),
        fork(watchUpdateSummonerInfo),
        fork(watchGetMatchByQueue),
        fork(watchLiveSummonerIngame),
        fork(watchGetMatch),
        fork(watchShortLink),
        fork(watchSetShortLink),
        fork(watchAutoComplete),
        fork(watchGetMultiList),
        fork(watchLPchart),
        fork(watchGetIngameCheck),
        fork(watchGetMultiLPChart),
        fork(watchGetChampionsBySeason),
        fork(watchGetGameAnalyticsPatch),
    ])
}
