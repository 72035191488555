import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import {changeRegion} from "../../../store/modules/region";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import SearchList from "../../common/SearchList";
import BookmarkList from "../../common/BookmarkList";
import AutoComplete from "../../common/NewSearch/AutoComplete";
import RegionTag from "../../common/NewSearch/RegionTag";
import regionList from "../../../store/static_json/regionList";


const SearchAndIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const ButtonText = styled.span`
  font-size: 12px;
  min-width: 5ch;
  text-align: center;
  color: #313131;
`;

const SearchInput = styled.input`
  width: 225px;
  height: 100%;
  font-size: 14px;
  border: 0;
  padding: 0 6px;

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus:-moz-placeholder {
    color: transparent;
  }

  &:focus::-moz-placeholder {
    color: transparent;
  }

  &:focus:-ms-input-placeholder {
    color: transparent;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  height: 100%;
  border-radius: 3px;
  z-index: 99;
`;

const AlignBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 10px;
  cursor: pointer;
`;

const LangBox = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 3px 0 0 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;


const SearchImage = styled.img`
`;

const BackDrop = styled.div`
  display: ${props => {
    let display = 'none';
    if (props.backdrop) display = 'flex';
    return display;
  }};
  position: fixed;
  //background-color: rgba(0,0,0,0.4);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  //opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: 80;
`;

const AbsoluteBox = styled.div`
  width: 100%;
  box-shadow: 0 1px 6px #00000026;
  display: ${props => {
    let display = 'none';
    if (props.backdrop) display = 'flex';
    return display;
  }};
  top: 40px;
  left: 0;
  flex-direction: column;
  position: absolute;
  border-radius: 3px;
  ${props => {
    if (props.backdrop) {
      return "animation: absolutefade-in 250ms;"
    } else {
      return "animation: absolutefade-out 250ms;"
    }
  }};
  @keyframes absolutefade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes absolutefade-out {
    from {
      opacity: 1;

    }
    to {
      opacity: 0;
    }
  }
`;

const AbsoluteContentWrapper = styled.div`
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .no_list {
    padding: 20px;
    text-align: center;
    line-height: 14px;
  }

  .searchListContainer ul {
    padding: 12px;
  }

  .searchListContainer li {
    height: 36px;
    padding: 0 12px;
    margin: 3px 0;

    a {
      font-size: 14px;
    }

    .bookmark {
      width: 20px;
      height: 20px;
      background-size: 20px;
    }

    .remove {
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }

  .searchListContainer li:nth-child(n+13) {
    display: none;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;

  div:first-child {
    border-right: 1px solid #9CA1B2;
  }
`;

const FlexWrapper = styled.div`
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex: 1;
  font-size: 14px;
  color: #3362E5;
  border-bottom: 1px solid ${props => {
    let color = '#9CA1B2';
    if (props.selected) color = 'transparent';
    return color;
  }};
  background-color: ${props => {
    let color = '#D7DAE5';
    if (props.selected) color = '#fff';
    return color;
  }};
  border-radius: ${props => {
    let radius = 0;
    if (props.radius) radius = props.radius;
    return radius;
  }};
`;

const AutoCompleteWrapper = styled.div`
  background-color: transparent;
`;


const ServerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 61px;
  padding: 0;
`;

const Abosluteul = styled.ul`
  left: 0;
  width: 46px;
  max-height: 300px;
  background-color: white;
  top: calc(100% + 13px);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  border: 1.5px solid #6B78B5;
  overflow-y: auto;
  box-shadow: 0 3px 6px #00000026;
  padding-block: 13px;
  ${props => {
    if (props.isOpen) {
      return "animation: absolutefade-in 250ms;"
    } else {
      return "animation: absolutefade-out 250ms;"
    }
  }};

  .regionTag:not(:last-of-type) {
    margin-bottom: 6px;
  }
`;

const AbsoluteLi = styled.li`
  padding: 8px 0 8px 9px;
  cursor: pointer;
  font-size: 12px;
  font-weight: ${props => {
    let weight = 'normal';
    if (props.selected) weight = 'bold';
    return weight;
  }};
  color: ${props => {
    let color = '#504B4B';
    if (props.selected) color = '#313131';
    return color;
  }};
  opacity: ${props => {
    let opacity = '0.8';
    if (props.selected) opacity = '1';
    return opacity
  }};

  &:hover {
    background-color: #EFF0FF;
    color: #3362E5;
    opacity: 1;
  }
`;

const Arrow = styled.div`
  margin-left: 8px;
  margin-top: 1px;
  border-top: #313131 5px solid;
  border-left: transparent 3px solid;
  border-right: transparent 3px solid;
  border-bottom: transparent 0px solid;
  width: 0;
  height: 0;
`;

const HeaderSearch = ({initLoadingNotFound}) => {
    const {t} = useTranslation();
    const inputRef = useRef(null);
    const history = useHistory();
    const currentLang = useSelector((state) => state.translation.lang);
    const params = useParams();
    const dispatch = useDispatch();
    const [summonerName, setSummonerName] = useState('');
    const [isToggled, setIsToggled] = useState(false);
    const [autocomplete, setAutocomplete] = useState([]);
    const [isOpend, setIsOpend] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const [openInputContent, setOpenInputContent] = useState(false);
    const [selectFavRecent, setSelectFavRecent] = useState(0);
    const region = useSelector((state) => state.region.result);


    useEffect(() => {
        setSummonerName("");
        setIsOpend(false);
        setBackdrop(false);
        setIsToggled(false);
        setOpenInputContent(false);
        if (params.region && params.region !== region) {
            dispatch(changeRegion(params.region.toUpperCase()))
        }

        if (params.userName && params.userName) {
            // setSummonerName(params.userName);
        }

    }, [params]);


    // debounce 된 query로 자동완성
    const sendQuery = async (query) => {
        if (query.length === 0) return;
        try {
            // 추후 수정해야함  ---- parameter에 지역 추가 (지금은 지역상관없이 다나옴)
            // eslint-disable-next-line no-useless-escape
            const regExp = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
            if (!regExp.test(query)) {
                // 특수문자 제거
                const result = query.replace(regExp, '');
                await axios.get(`${process.env.REACT_APP_SEARCH}?term=${result}`).then((res) => {
                    const data = res?.data.hits.hit;
                    if (data.length !== 0) {
                        setAutocomplete(data);
                        setIsOpend(true);
                    } else {
                        setIsOpend(false);
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    // 자동완성 debounce
    const delayedQueryCall = useRef(_.debounce((q) => sendQuery(q), 500)).current;

    // search input에 값 입력시
    const handleSummonerName = (e) => {
        const {value} = e.target;
        setSummonerName(value);
        delayedQueryCall(value);

        if (value.length < 1) {
            setAutocomplete([]);
            setIsOpend(false);
        }
    };

    // autocomplete 클릭시
    const handleSummonerSelect = (item) => {
        setSummonerName(item.fields.summonername);
        setIsOpend(false);
        inputRef.current.focus();
    };

    // search button 클릭시
    const handleSummonerSearch = () => {
        setSummonerName("");
        setIsOpend(false);
        setBackdrop(false);
        setOpenInputContent(false);
        inputRef.current.blur();
        if (initLoadingNotFound) {
            initLoadingNotFound();
        }

        // eslint-disable-next-line no-useless-escape
        const regExp = /(joined the room.)|(joined the lobby.)|로비에|,|(,\s),/gi;
        if (regExp.test(summonerName) === false) {
            // 전적검색으로
            if (summonerName !== "") {
                history.push({
                    pathname: `/summoner/${region}/${summonerName}`,
                    state: {name: summonerName},
                });
            }
            // history.go(0);
        } else {
            // 멀티서치로
            let arr_str = summonerName.replace(/(joined the room.)|(joined the lobby.)|(님이 로비에 참가했습니다.)|(님이 로비에 참가하셨습니다.)|(がロビーに参加しました)|(,\s)|,|\n|\r/gi, '/');
            arr_str = arr_str.replace(/(\s\/)+|(\s\/)/gi, '/');
            arr_str = arr_str.replace(/(\/\s)|(\/)+/gi, '/');
            arr_str = arr_str.replace(/\/$/gi, '');
            arr_str = arr_str.split('/');

            // 중복, 빈값 제거
            const set = new Set(arr_str);
            const uniqueArr = [...set];
            const modyArr = uniqueArr.filter((item) => item !== '').slice(0, 5);

            history.replace({
                pathname: `/multi/${region}/${modyArr}`,
                state: {name: modyArr},
            });

        }
    };

    // toggle language 선택시
    const handleLangSelect = (item) => {
        dispatch(changeRegion(item));
        setIsToggled(false);
    };

    // enter 입력시
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSummonerSearch();
    };

    // 자동완성 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (isOpend && (!e.current || !e.current.contains(e.target))) setIsOpend(false);
            if (isToggled && (!e.current || !e.current.contains(e.target))) setIsToggled(false);
        },
        [isOpend, isToggled]
    );

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    const setBackdropOn = useCallback(() => {
        setBackdrop(true);
    }, [backdrop]);

    const setBackDropClose = useCallback(() => {
        setIsToggled(false);
        setOpenInputContent(false);
        setBackdrop(false);
    }, [backdrop])


    const ServerSelectorChange = useCallback(() => {
        setIsToggled(!isToggled)
        setOpenInputContent(false);
        setBackdrop(false);
    }, [isToggled]);

    const onOpenInput = useCallback(() => {
        setOpenInputContent(true);
        setBackdropOn();
    }, []);


    return (
        <Wrapper>
            <ContentWrapper>
                <LangBox>
                    <ServerWrapper onClick={ServerSelectorChange}>
                         <RegionTag
                            fontSize={'10px'}
                            borderRadius={"3px"}
                            width={'34px'}
                            height={'20px'}
                            customWeight={true}
                            title={region}
                            selected={true}
                        />
                        {/*<ButtonText>{region}</ButtonText>*/}
                        <Arrow/>
                    </ServerWrapper>
                    {isToggled && (
                        <Abosluteul isOpen={isToggled}>
                            {regionList.map((item, index) => (
                                <RegionTag
                                    isDisabled={item.disabled}
                                    fontSize={"10px"}
                                    borderRadius={"3px"}
                                    width={"34px"}
                                    height={"20px"}
                                    customWeight={true}
                                    selected={item.disabled ? false : true}
                                    key={index + " absolute" + item.title}
                                    onClick={() => handleLangSelect(item.title)}
                                    title={item.title}
                                />
                            ))}
                        </Abosluteul>
                    )}
                </LangBox>
                <SearchAndIconWrapper onClick={!openInputContent ? onOpenInput : null}>
                    <div>
                        <SearchInput
                            type="text"
                            value={summonerName}
                            onChange={handleSummonerName}
                            onKeyPress={handleKeyPress}
                            ref={inputRef}
                            placeholder={t('search.placeholder')}
                        />
                    </div>
                    <AlignBox onClick={handleSummonerSearch}>
                        <SearchImage
                            src={'/images/ico_search_w.svg'}
                            width={18}
                            height={18}
                            alt={'?'}
                        />
                    </AlignBox>
                    <AbsoluteBox backdrop={openInputContent}>
                        {summonerName.length > 0 ?
                            <AutoCompleteWrapper>
                                <AutoComplete
                                    focus={true}
                                    region={region}
                                    summonerName={summonerName}
                                />
                            </AutoCompleteWrapper>
                            :
                            <>
                                <HeaderWrapper>
                                    <FlexWrapper selected={selectFavRecent === 0} onClick={() => setSelectFavRecent(0)}
                                                 radius={'3px 0 0 0'}>
                                        {t('buttons.recentSearch')}
                                    </FlexWrapper>
                                    <FlexWrapper selected={selectFavRecent === 1} onClick={() => setSelectFavRecent(1)}
                                                 radius={'0 3px 0 0'}>
                                        {t('buttons.favorite')}
                                    </FlexWrapper>
                                </HeaderWrapper>
                                <AbsoluteContentWrapper>
                                    {selectFavRecent === 0 ?
                                        <SearchList
                                            onSelect={setBackDropClose}
                                            fullWidth={true}
                                        />
                                        :
                                        <BookmarkList
                                            onSelect={setBackDropClose}
                                            fullWidth={true}
                                        />
                                    }
                                </AbsoluteContentWrapper>
                            </>
                        }
                    </AbsoluteBox>
                </SearchAndIconWrapper>
            </ContentWrapper>
            <BackDrop onClick={setBackDropClose} backdrop={backdrop}/>
        </Wrapper>

    );
};

export default HeaderSearch;
