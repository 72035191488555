import React, {useCallback, useMemo} from 'react';
import {getAiPrediction, getTierScore, tierBadge} from "../../../../function";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import SimpleTooltip from "../../../common/SimpleTooltip";
import {useSelector} from "react-redux";


const Wrapper = styled.div`
  padding: 15px 5px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #C3D1DC;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 12ch;
`;

const TooltipWrapper = styled.div`
  margin-left: 2px;
`;

const TitleText = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`;

const Predict = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px;
`;

const ArrowBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
`;

const ArrowImg = styled.img`
  width: 10px;
  height: 8px;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BadgeText = styled.span`
  min-height: 2ch;
  font-size: 12px;
  margin-top: 5px;
  color: #313131;
`;


const InfoAiPrediction = ({queueNum, summoner, summary, matchInfo, region}) => {
    const {t} = useTranslation();
    const aiData = useSelector((state) => state.imageStore.aiData)[region];
    const all = 'ALL';
    const allQueue = 0;
    const rankedSolo = "RANKED_SOLO_5x5";
    const rankedSoloQueue = 420;
    const rankedFlex = "RANKED_FLEX_SR";
    const rankedFlexQueue = 440

    const RenderWarning = useCallback(() => {
        let count;
        if (queueNum === all || queueNum === rankedSolo || queueNum === rankedFlex) {
            if (queueNum === all || queueNum === rankedSolo) {
                count = matchInfo.reduce((sum, data, index) => {
                    if (data.match_basic_dict.queue_id === rankedSoloQueue) {
                        sum = sum + 1
                    }
                    return sum;
                }, 0);
            }


            if (queueNum === rankedFlex) {
                count = matchInfo.reduce((sum, data, index) => {
                    if (data.match_basic_dict.queue_id === rankedFlexQueue) {
                        sum = sum + 1
                    }
                    return sum;
                }, 0);
            }


            if (count < 8) {
                return (
                    <TooltipWrapper>
                        <SimpleTooltip
                            tooltipText={t('summoner.aiTierPredictionExplain')}
                            imgSrc={"/images/ico_warning.svg"}
                        />
                    </TooltipWrapper>
                )
            }
        }
        return (<></>)
    }, [queueNum, summoner, matchInfo]);

    const rankSolo = useMemo(() => summoner?.season_tier_info_dict?.ranked_solo_5x5, [summoner]);
    const rankFlex = useMemo(() => summoner?.season_tier_info_dict?.ranked_flex_sr, [summoner]);


    return (
        <Wrapper>
            <TextWrapper>
                <TitleText>{t('summoner.aiTierPreciction')}</TitleText>
                <RenderWarning/>
            </TextWrapper>
            <Predict>
                <ColumnBox>
                    {((queueNum === all || queueNum === rankedSolo) && rankSolo !== undefined) && tierBadge(getTierScore(`${rankSolo.tier} ${rankSolo.division}`).txt)}
                    {queueNum === rankedFlex && rankFlex !== undefined && tierBadge(getTierScore(`${rankFlex.tier} ${rankFlex.division}`).txt)}
                    {((queueNum === all || queueNum === rankedSolo) && rankSolo !== undefined) &&
                        <BadgeText>{t('summoner.current')}</BadgeText>}
                    {queueNum === rankedFlex && rankFlex !== undefined &&
                        <BadgeText>{t('summoner.current')}</BadgeText>}
                </ColumnBox>
                <ArrowBox>
                    {((queueNum === all || queueNum === rankedSolo) && rankSolo !== undefined) &&
                        <ArrowImg src={'/images/ico_cell_right_arrow_w.svg'}/>}
                    {queueNum === rankedFlex && rankFlex !== undefined &&
                        <ArrowImg src={'/images/ico_cell_right_arrow_w.svg'}/>}
                </ArrowBox>
                <ColumnBox>
                    {((queueNum === all || queueNum === rankedSolo) && rankSolo !== undefined) && tierBadge(getAiPrediction(rankSolo, summary.ai_score, aiData).txt)}
                    {queueNum === rankedFlex && rankFlex !== undefined && tierBadge(getAiPrediction(rankFlex, summary.ai_score, aiData).txt)}
                    {((queueNum === all || queueNum === rankedSolo) && rankSolo !== undefined) &&
                        <BadgeText>{t('summoner.forecast')}</BadgeText>}
                    {queueNum === rankedFlex && rankFlex !== undefined &&
                        <BadgeText>{t('summoner.forecast')}</BadgeText>}
                </ColumnBox>
            </Predict>
        </Wrapper>
    );
};

export default InfoAiPrediction;