import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation, useParams, useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {useTranslation} from 'react-i18next';
import {Header, Loading, Modal, Notice} from '../../components';
import {useTitle} from '../../function';
import Profile from './Profile';
import Champ from './Champ';
import Ingame from './Ingame';
import {IngameContainer, SummonerContainer} from '../../styled/SummonerStyle';
import {GET_MATCH_REQUEST, GET_SUMMONER_REQUEST} from "../../store/reducer/summoner";
import SummonerNotFound from "./SummonerNotFound";
import Info from "./Info";
import MatchList from "./MatchList";
import Float from "./Float";
import {searchList} from "../../store/modules/searchList";
import {noticeChange} from "../../store/modules/notice";
import useQuery from "../../common/hooks/useQuery";
import SummonerMatches from "./Matches";
import styled from "styled-components";
import ServerStatusErrorbox from "../common/Error/StatusError";
import regionList from "../../store/static_json/regionList";


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;
  background-color: #fff;
  margin-top: 12px;
  border-radius: 6px;
`;

const FloatWrapper = styled.div`
  position: relative;
`;

const Summoner = () => {
    const {userName, region, matchId} = useParams();

    const {t} = useTranslation();
    const history = useHistory();
    const query = useQuery();
    const [updatePer, setUpdatePer] = useState(100);
    const page = useRouteMatch();
    const dispatch = useDispatch();
    const modalOpen = useSelector((state) => state?.modal.open);
    const modalData = useSelector((state) => state?.modal.modalData);
    const currentLang = useSelector((state) => state?.translation.lang);
    const notice = useSelector((state) => state.notice.notice);
    const match = useSelector((state) => state?.modal.match);
    const [tempPuuId, setTempPuuId] = useState(JSON.parse(localStorage.getItem("tempPuuId")));
    const [backToNormal, setbackToNormal] = useState(false);
    const [tabIndex, setTabIndex] = useState(query.get('tabs') > 0 ? query.get('tabs') - 1 : 0);
    const [queueNum, setQueueNum] = useState("ALL");
    const {
        singleMatch,
        summoner,
        ingameStatus,
        getSummonerDone,
        getSummonerInfoDone,
        getSummonerError,
        updateSummonerInfoDone
    } = useSelector((state) => state?.summoner);
    const currentSeason = useSelector((state) => state.imageStore.currentSeason);
    const [mounted, setMounted] = useState(false);
    const initRef = useRef(false);
    const currentUserName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.summoner_name;
        } else {
            return userName;
        }
    }, [summoner, userName])

    useEffect(() => {
        window.scroll(0, 0);
    }, [userName]);

    useEffect(() => {
        if (getSummonerError) {
            if (getSummonerError !== 'No source') {
                noticeChange('alertError');
            }
        }
    }, [getSummonerError])

    useEffect(() => {
        if (updateSummonerInfoDone && getSummonerInfoDone) {
            setUpdatePer(100);
        }
    }, [getSummonerInfoDone, updateSummonerInfoDone]);


    useEffect(() => {
        if (getSummonerDone && initRef.current === true) {
            dispatch(searchList({...summoner, region: region}, 'add'));
            setTimeout(() => titleUpdate(`${currentUserName} - ${t('header.title')}`), 500);
            window.history.replaceState(null, null, `/summoner/${region}/${currentUserName}`);
            localStorage.removeItem("tempPuuId");
            setTempPuuId('');
        }
        initRef.current = true;
    }, [getSummonerDone])


    // 검색된 소환사명 수정
    //region 렌더링 문제
    //주소 예외처리
    useEffect(() => {
        let summonerName = userName.replace(/\s/gi, '');
        if (summonerName.length === 2) {
            summonerName = userName.split('').join('%20');
        }

        if (region) {
            const regionMatch = regionList.find((data) => data.title === region);
            if (regionMatch.disabled === false) {
                setMounted(true);
            } else {
                history.replace({
                    pathname: `/summoner/KR/${userName}`,
                    state: {name: summonerName},
                })
            }
        } else {
            history.replace({
                pathname: `/summoner/KR/${userName}`,
                state: {name: summonerName},
            })
        }
    }, [region]);


    const titleUpdate = useTitle('Loading...');


    // 소환사 api 호출
    const getData = useCallback(() => {
        if (currentSeason) {
            setQueueNum('ALL');
            dispatch({
                type: GET_SUMMONER_REQUEST,
                data: {
                    username: userName,
                    region: region,
                    matchId: matchId,
                    puu_id: tempPuuId,
                    currentSeason: currentSeason,
                }
            })
        }
    }, [userName, region, matchId, tempPuuId, currentSeason]);


    // 업데이트 버튼


    // 탭버튼 클릭시 ingame 체크
    const handleTab = (index) => {
        setTabIndex(index);
        setbackToNormal(true);
    };

    useEffect(() => {
        if (modalOpen) {
            document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;
      `;
            return () => {
                const scrollY = document.body.style.top;
                document.body.style.cssText = '';
                window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
            };
        }
    }, [modalOpen]);

    useEffect(() => {
        if (mounted) {
            getData();
            if (!query.get('tabs')) {
                setTabIndex(0);
            }
        }
    }, [userName, matchId, region, page, mounted, currentSeason]);


    const initLoadingNotFound = useCallback(() => {
        setTabIndex(0);
    }, []);


    return (
        <>
            {mounted
                &&
                <Header
                    noticeOff={true}
                    initLoadingNotFound={initLoadingNotFound}
                />}
            <SummonerContainer>
                <Notice fixed={false}/>
                <ServerStatusErrorbox/>
                {
                    getSummonerError === 404 ? (
                        <SummonerNotFound
                            userName={userName}
                        />
                    ) : getSummonerDone ? (
                        <ContentWrapper>
                            <ContentBox>
                                <Profile
                                    queueNum={queueNum}
                                    handleTab={handleTab}
                                    region={region}
                                    updatePer={updatePer}
                                    setUpdatePer={setUpdatePer}
                                    userName={currentUserName}
                                />
                                <Tabs
                                    className="main_tab"
                                    selectedIndex={tabIndex}
                                    onSelect={(index) => handleTab(index)}>
                                    <TabList className="main_tab_list">
                                        <Tab>{t('tabs.summary')}</Tab>
                                        <Tab>{t('tabs.champions')}</Tab>
                                        <Tab>
                                            <p style={ingameStatus && currentLang !== 'KOR' ? {fontSize: 14} : {}}>
                                                {t('tabs.liveGame')}
                                            </p>
                                            {ingameStatus ? (<span className="live"><img src="/images/ico_live.svg"
                                                                                         alt="LIVE"/></span>) : <></>}
                                        </Tab>
                                    </TabList>
                                    <FloatWrapper>
                                        <Float/>
                                    </FloatWrapper>
                                    <TabPanel className="main_tab_panel">
                                        {/* 종합 Panel */}

                                        {matchId && !backToNormal ? <SummonerMatches
                                                region={region}
                                                summoner={summoner}
                                                currentLang={currentLang}
                                                match={singleMatch}
                                            /> :
                                            <>
                                                <Info
                                                    handleTab={handleTab}
                                                />
                                                <MatchList
                                                    queueNum={queueNum}
                                                    setQueueNum={setQueueNum}
                                                    region={region}
                                                    updatePer={updatePer}
                                                />
                                            </>
                                        }

                                    </TabPanel>
                                    <TabPanel className="main_tab_panel">

                                        <Champ
                                            region={region}
                                        />
                                    </TabPanel>
                                    <TabPanel className="main_tab_panel">
                                        {
                                            notice === 'maintance' ?
                                                <IngameContainer>
                                                    <div className="not_playing">
                                                        <div>
                                                            <p style={{
                                                                whiteSpace: 'break-spaces',
                                                                lineHeight: 2,
                                                                textAlign: 'start'
                                                            }}>
                                                                {t('ingame.maintance')}
                                                                {` 10:00 ~ 16:00${currentLang === 'KOR' ? '' : " (KST)"}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </IngameContainer>
                                                :
                                                <Ingame
                                                    region={region}
                                                />
                                        }

                                    </TabPanel>
                                </Tabs>
                            </ContentBox>
                        </ContentWrapper>
                    ) : (
                        <>

                            <Loading/>
                        </>
                    )}
            </SummonerContainer>
            {modalOpen && <Modal modalData={modalData} open={modalOpen} match={match}/>}
        </>
    );
};

export default React.memo(Summoner);
