import React, {useCallback} from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  padding: ${props => props.padding || 0};
  border-radius: 5px;
  width: 24px;
  height: 14px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => {
    //iron
    let color = '#717171';
    if (props.tier === 'challenger') {
      //챌린저
      color = "#FD8A12";
    } else if (props.tier === 'grandmaster') {
      //그랜드마스터
      color = "#B9354A";
    } else if (props.tier === 'master') {
      color = "#8D41A9";
    } else if (props.tier === 'diamond') {
      color = "#3785A9";
    } else if (props.tier === 'platinum') {
      color = '#30988A';
    } else if (props.tier === 'gold') {
      color = '#B89033';
    } else if (props.tier === 'silver') {
      color = '#707997';
    } else if (props.tier === 'bronze') {
      color = '#866D30';
    }
    return color;
  }};
`;

const TagSpan = styled.span`
  font-size: ${props => props.fontSize || "14px"};
  text-align: center;
  line-height: 10px;
  color: white;
`;


const TierTag = ({tier, division, fontSize, padding, testId}) => {


    const getTierAndDivision = useCallback(() => {
        if (tier.toLowerCase() === 'challenger') {
            return 'C';
        } else if (tier.toLowerCase() === 'grandmaster') {
            return 'GM';
        } else if (tier.toLowerCase() === 'master') {
            return "M";
        } else {
            if(tier.length > 0) {
                return tier.toUpperCase()[0] + division;
            }

        }
    }, [tier, division])

    if (typeof tier !== 'string' && typeof division !== 'number') return null;

    return (
        <Wrapper tier={tier.toLowerCase()} data-testid={testId} padding={padding}>
            <TagSpan fontSize={fontSize}>{getTierAndDivision()}</TagSpan>
        </Wrapper>
    );
};

export default TierTag;
