import React from 'react';
import styled from "styled-components";
import {ImageChamp} from "../../../common";
import {useTranslation} from "react-i18next";
import SimpleTooltip from "../../../common/SimpleTooltip";
import MostContent from "../MostContent";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const TooltipWrapper = styled.div`
  margin-left: 5px;
`;


const MostPickChamp = ({summary, summoner, queueNum, region}) => {
    const {t} = useTranslation();

    return (
        <>
            <Wrapper>
                <HeaderText>{t('summoner.championTierEvalution')}</HeaderText>
                <TooltipWrapper>
                    <SimpleTooltip
                        imgSrc={'/images/list_btn_tip_s_w.svg'}
                        tooltipText={t('summoner.championTierEvalutionExplain')}
                    />
                </TooltipWrapper>
            </Wrapper>
            <ContentWrapper>
                {summary.mostChamp1.games !== 0 && (
                    <MostContent
                        region={region}
                        t={t}
                        lost={summary.mostChamp1.lose}
                        win={summary.mostChamp1.win}
                        aiScore={summary.mostChamp1.ai_score}
                        queueNum={queueNum}
                        summoner={summoner}
                        ImageComponent={() => <ChampionImageWithLane
                            champId={summary.mostChamp1.championId}
                        />}
                    />
                )}
                {summary.mostChamp2.games !== 0 && (
                    <MostContent
                        region={region}
                        t={t}
                        lost={summary.mostChamp2.lose}
                        win={summary.mostChamp2.win}
                        aiScore={summary.mostChamp2.ai_score}
                        queueNum={queueNum}
                        summoner={summoner}
                        ImageComponent={() => <ChampionImageWithLane
                            champId={summary.mostChamp2.championId}
                        />}
                    />
                )}
            </ContentWrapper>
        </>
    );
};

export default MostPickChamp;