import React, {useCallback, useMemo} from 'react';
import UpArrow from "../../../common/TagWithArrow/UpArrow";
import styled from "styled-components";
import {getGameTime, patchHistoryTime} from "../../../../function";
import {useSelector} from "react-redux";
import DownArrow from "../../../common/TagWithArrow/DownArrow";
import {useTranslation} from "react-i18next";

const VersionAndStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const VersionWrapper = styled.div`
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const VersionText = styled.span`
  font-size: 28px;
  font-weight: bold;
`;

const HeightBar = styled.div`
  position: absolute;
  left: -14px;
  bottom: 6px;
  width: 4px;
  height: 40px;
  background: #6A5AAC;
`;

const DateText = styled.span`
  width: 20ch;
  margin-top: 6px;
  font-size: 10px;
  opacity: 0.8;
`;

const BuffAndNerfWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
`;

const BuffNerfText = styled.span`
  margin-left: 4px;
  font-size: 12px;
`;

const InfoWrapper = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HistoryContentDayInfo = ({version, date = Date.now(), correction, hotfix}) => {
    const {t} = useTranslation();
    const currentLang = useSelector((state) => state.translation.lang);
    const dateRender = useMemo(() => {
        const newDate = new Date(Number(date * 1000));
        const leftDate = patchHistoryTime(Number(date), currentLang)
        return `${newDate.getFullYear()}.${newDate.getMonth() + 1 > 9 ? newDate.getMonth() + 1 : '0' + (newDate.getMonth() + 1)}.${newDate.getDate() > 9 ? newDate.getDate() : "0" + (newDate.getDate())} (${leftDate})`
    }, [date,currentLang]);

    const BuffOrNerf = useCallback((status) => {
        switch (status) {
            case 'buff' : {
                return (
                    <>
                        <UpArrow size={6}/>
                        <BuffNerfText>{t('championsHistory.buff')}</BuffNerfText>
                    </>
                )
            }
            case 'nerf' : {
                return (
                    <>
                        <DownArrow size={6}/>
                        <BuffNerfText>{t('championsHistory.nerf')}</BuffNerfText>
                    </>
                )
            }
            case 'fix': {
                return (
                    <>
                        <img src={'./images/icon_adj.svg'} width={12} height={12} alt={''}/>
                        <BuffNerfText>{t('championsHistory.adj')}</BuffNerfText>
                    </>
                )
            }
        }
    }, [])

    return (
        <VersionWrapper>
            <VersionAndStatusWrapper>
                <VersionWrapper>
                    <VersionText>{version}</VersionText>
                    <DateText>{dateRender}</DateText>
                    <HeightBar/>
                </VersionWrapper>
                <BuffAndNerfWrapper>
                    {BuffOrNerf(correction)}
                </BuffAndNerfWrapper>
                {hotfix ?
                <InfoWrapper>
                    <img src={'./images/icon_hotfix.svg'} width={12} height={12} alt={''}/>
                    <BuffNerfText>{t('championsHistory.hotfix')}</BuffNerfText>
                </InfoWrapper> : null}
            </VersionAndStatusWrapper>
        </VersionWrapper>
    );
};

export default HistoryContentDayInfo;
