import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

import {getLPScore, kdaColor, scoreColor, sortChampData, tierText,} from '../../function';
import {ChartLp, ImageChamp, Loading} from '../../components';
import {InfoContainer} from '../../styled/SummonerStyle';
import styled from "styled-components";
import ChampionImageWithLane from "../common/ChampionImageWithLane";

const HideElementTbody = styled.tbody`
  tr:nth-child(n+6) {
    display: none;
  }
`;

const MoreTab = styled.div`
  position: absolute;
  width: 58px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 78px;
  padding-bottom: 7px;
`;


const MoreWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  padding: 4px 3px 4px 6px;
  font-size: 11px;
  font-weight: 500;
  background-color: #F8FAFF;
  border: 1px solid #5F6695;
`;
const ArrowRight = styled.div`
  margin-left: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const Info = ({handleTab}) => {
    const {t} = useTranslation();
    const rankDict = useSelector((state) => state.summoner.summoner)?.season_tier_info_dict;
    const summonerStat = useSelector((state) => state.summoner.summonerStatus);
    const currentLang = useSelector((state) => state.translation.lang);
    const aiData = useSelector((state) => state.imageStore.aiData)
    const tierChart = useSelector((state) => state.summoner.tierChart);





    const {
        getMatchByQueueDone,
        getSummonerInfoLoading,
        getSummonerInfoDone,
        getSummonerInfoError
    } = useSelector((state) => state.summoner);
    const matchInfo = useSelector((state) => state.summoner.match);
    const [soloTierChart, setSoloTierChart] = useState(false);
    const [flexTierChart, setFlexTierChart] = useState(false);


    const isToday = useCallback((timestamp) => {
        if (typeof timestamp === 'number') {
            const multiplyTime = timestamp * 1000;
            const time = new Date(multiplyTime).getTime();
            //1660070056000
            //1660094662488
            const now = Date.now();
            const zeroHour = new Date(now);
            zeroHour.setHours(0, 0, 0, 0);
            if (time > zeroHour.getTime() && time < now) {
                return true
            } else {
                return false;
            }
        }
    }, [])

    function setRankData(key, queue_id, setter) {
        const array = [...tierChart[key]];
        if (array.length > 0) {
            if (array[array.length - 1]?.timestamp) {
                if (isToday(array[array.length - 1].timestamp)) {
                    array[array.length - 1] = {
                        timestamp: Date.now() / 1000,
                        tier: rankDict[key].tier,
                        division: rankDict[key].division,
                        league_points: rankDict[key].league_points,
                    }
                    setter(getChartOptions([...array]));
                } else {
                    setter(getChartOptions(tierChart[key]));
                }
            }
        } else {
            setter(true);
        }
    }

    useEffect(() => {
        if (getSummonerInfoDone) {
            if (tierChart && tierChart !== {}) {
                for (const key in tierChart) {
                    switch (key) {
                        case 'ranked_solo_5x5': {
                            setRankData(key, 420, setSoloTierChart);
                        }
                            break;
                        case 'ranked_flex_sr': {
                            setRankData(key, 440, setFlexTierChart);
                        }
                            break;
                        default:
                            break;
                    }
                }
            } else {
                setSoloTierChart(true);
                setFlexTierChart(true);
            }
        }
    }, [tierChart, getSummonerInfoDone]);

    // 차트옵션 만들기
    const getChartOptions = useCallback(
        (data) => {
            let originalArr = []; // 원래 data값을 배열로
            let calculatedArr = []; // 계산된 lp 값
            let dateArr = []; // xAxis categories 값
            let tierArr = []; // tier, order 배열
            let grandMasterValue = 2950;
            let challengerValue = 4000;
            let grandMaxValue = 4000;
            let challengerMaxvalue = 10000;


            for (const obj in data) {

                if (data[obj].tier === "GRANDMASTER") {
                    grandMasterValue = Math.min(grandMasterValue, data[obj].league_points + 2800);
                    grandMaxValue = Math.max(grandMaxValue, data[obj].league_points + 2800);
                }
                if (data[obj].tier === "CHALLENGER") {
                    challengerValue = Math.min(challengerValue, data[obj].league_points + 3100);
                    challengerMaxvalue = Math.max(challengerMaxvalue, data[obj].league_points + 2800);
                }
            }


            for (const i in data) {
                originalArr.push(data[i]);
                // 티어별 order값
                let tierOrder = 0;
                if (data[i].tier === 'IRON') tierOrder = 1;
                else if (data[i].tier === 'BRONZE') tierOrder = 2;
                else if (data[i].tier === 'SILVER') tierOrder = 3;
                else if (data[i].tier === 'GOLD') tierOrder = 4;
                else if (data[i].tier === 'PLATINUM') tierOrder = 5;
                else if (data[i].tier === 'DIAMOND') tierOrder = 6;
                else if (data[i].tier === 'MASTER') tierOrder = 7;
                else if (data[i].tier === 'GRANDMASTER') tierOrder = 8;
                else if (data[i].tier === 'CHALLENGER') tierOrder = 9;


                tierArr.push({
                    tier: data[i].tier,
                    order: tierOrder,
                });

                // 계산된 lp로 입력
                calculatedArr.push(getLPScore(data[i].tier, data[i].division, data[i].league_points, grandMasterValue, challengerValue));

                // xAxis categories (날짜데이터)
                // let idxDate = new Date(i * 1000);
                // let idxDate = getGameTime(i, currentLang);
                dateArr.push(data[i].timestamp);
            }

            // calculatedArr로 티어별 최소,최대값 구하기
            let minVal =
                Math.min.apply(null, calculatedArr) < 50 ? 0 : Math.min.apply(null, calculatedArr) - 50;
            let maxVal = Math.max.apply(null, calculatedArr) + 50;
            // let yTickPositions = [minVal, maxVal]; // yAxis tickPositions 값

            // 객체배열 중복제거
            tierArr = _.uniqBy(tierArr, 'tier');
            tierArr.sort(function (a, b) {
                // order 순으로 정렬
                return a.order - b.order;
            });


            let yTickPositions = {
                GRANDMASTER: grandMasterValue,
                CHALLENGER: challengerValue,
            }

            // 티어별 yAxis tickPositions 값 넣기


            // yTickPositions 앞뒤로 400 추가 (왜 하는거지?)
            // let minTP = yTickPositions[0];
            // let maxTP = yTickPositions[yTickPositions.length - 1];
            // if (minTP !== 0) yTickPositions.unshift(minTP - 400);
            // if (maxTP < 2400) yTickPositions.push(maxTP + 400);
            // else yTickPositions.push(maxTP + 1000);

            // 내림차순 정렬


            return {originalArr, calculatedArr, dateArr, yTickPositions};
        },
        [currentLang]
    );


    const onClickMore = useCallback(() => {
        handleTab(1);
    }, []);

    return (
        <InfoContainer>
            <div className="p__box">
                <Tabs className="tier">
                    <TabList>
                        {rankDict?.ranked_solo_5x5?.tier !== "" ?
                            <CustomTab
                                info={rankDict?.ranked_solo_5x5 && rankDict?.ranked_solo_5x5}
                                summoner={rankDict}>{t('summoner.rankedSolo')}</CustomTab>
                            :
                            <CustomTab info="Unranked">{t('summoner.rankedFlex')}</CustomTab>
                        }
                        {rankDict?.ranked_flex_sr?.tier !== "" ?
                            <CustomTab info={rankDict?.ranked_flex_sr} summoner={rankDict}
                                       flag={'flex'}>{t('summoner.rankedFlex')}</CustomTab>
                            :
                            <CustomTab info="Unranked">{t('summoner.rankedFlex')}</CustomTab>
                        }
                    </TabList>
                    <TabPanel>
                        <div className="title">
                            LP {t('summoner.graph')}{' '}
                            <span>
                                ({t('summoner.recent')} 60{t('summoner.day')})
                              </span>
                        </div>
                        {!soloTierChart ? <Loading/> :
                            <ChartLp tierChart={soloTierChart} reverse={true}/>
                        }
                    </TabPanel>
                    <TabPanel>
                        <div className="title">
                            LP {t('summoner.graph')}{' '}
                            <span>
                ({t('summoner.recent')} 60{t('summoner.day')})
              </span>
                        </div>
                        {!flexTierChart ? <Loading/> :
                            <ChartLp tierChart={flexTierChart} reverse={true}/>
                        }
                    </TabPanel>
                </Tabs>
                {getSummonerInfoLoading ? (
                    <div className="champ">
                        <Loading/>
                    </div>
                ) : (
                    <Tabs className="champ" >
                        <TabList>
                            <Tab>{t("tabs.total")}</Tab>
                            <Tab>{t("tabs.rankedSolo")}</Tab>
                            <Tab>{t("tabs.rankedFlex")}</Tab>
                            <MoreTab>
                                <MoreWrapper onClick={onClickMore}>
                                    {t('summoner.moreChamp')}
                                    <ArrowRight>
                                        <img
                                            src={'./images/ico_more_more.svg'}
                                            alt={""}
                                            width={8}
                                            height={8}
                                        />
                                    </ArrowRight>
                                </MoreWrapper>
                            </MoreTab>
                        </TabList>
                        {summonerStat?.counter_champion_stats ? Object.keys(summonerStat.counter_champion_stats).map((item, index) => {
                                const result = sortChampData(summonerStat.counter_champion_stats[item].enemy_champion_stats.All, {
                                    value: "games",
                                    sortType: false
                                });
                                return (
                                    <CustomTabPanel key={index} stat={result}>
                                        {item}
                                    </CustomTabPanel>
                                );
                            })
                            :
                            [1, 2, 3].map((data) => {
                                return <CustomTabPanel stat={[]} key={data}/>
                            })

                        }
                    </Tabs>
                )}
            </div>
        </InfoContainer>
    );
};

// 솔로랭크, 자유랭크 탭 버튼
const CustomTab = ({children, info, summoner, flag, ...otherProps}) => {
    const {t} = useTranslation();

    return (
        <Tab {...otherProps}>
            {info === 'Unranked' ? (
                <>
                    <div className="title">
                        <h5>{children}</h5>
                        <p></p>
                    </div>
                    <div className="info">
                        <img
                            src={`/images/Emblem_${info.toUpperCase()}.png`}
                            className="imgEmblem"
                            alt={info}
                        />
                        <div className="t_group">
                            <p className="t1">
                                <span>{info}</span>
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="title">
                        <h5>{children}</h5>
                        {flag !== 'flex' &&
                            <dl>
                                {info.ranking === -1 ? null : (
                                    <dt>
                                        {t('summoner.ranking')}
                                        <span>{info.ranking.toLocaleString('ko-KR')}</span>
                                    </dt>
                                )}
                                {info.ranking_rate === -1 ? null : <dd>({info.ranking_rate}%)</dd>}
                            </dl>
                        }
                    </div>
                    <div className="info">
                        <img src={`/images/Emblem_${info.tier}.png`} className="imgEmblem" alt={info.tier}/>
                        <div className="t_group">
                            <p className="t1">{tierText(info.tier, info.division)}</p>
                            <p className="t2">
                <span className="lp">
                  <i>{info.league_points}</i> LP
                </span>
                                <span className="game">
                  <i>{((info.wins / (info.wins + info.losses)) * 100).toFixed(1)}</i> %
                </span>
                            </p>
                            <p className="t3">
                <span className="game">
                  <i>{info.wins}</i> {t('summoner.contractionWin')}
                </span>
                                <span className="game">
                  <i>{info.losses}</i> {t('summoner.contractionLose')}
                </span>

                            </p>
                            {info?.mini_series_dict?.progress && (
                                <div className="promos">
                                    {info?.mini_series_dict?.progress.split('').map((item, index) => (
                                        <span key={index} className={item}>
                      {item}
                    </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Tab>
    );
};
CustomTab.tabsRole = 'Tab';


const ChampionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

const ChampionSpan = styled.span`
  margin-left: 12px;
  text-align: left;
  font-size: 14px;
  color: #313131;
  width: 10ch;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal !important;
  text-overflow: ellipsis;
`;

// 챔피언 전적정보 탭 패널
const CustomTabPanel = ({children, stat, ...otherProps}) => {
    const {t, i18n} = useTranslation();
    const champions = useSelector((state) => state.imageStore.champions);
    const isKorean = useMemo(() => ["ko", "ko-KR"].includes(i18n.language), [i18n.language]);
    return (
        <TabPanel {...otherProps}>
            {/* <h1>{children}</h1> */}
            <table>
                <colgroup>
                    <col width="20%"/>
                    <col width="20%"/>
                    <col width="20%"/>
                    <col width="20%"/>
                    <col width="20%"/>
                </colgroup>
                <thead>
                <tr>
                    <th rowSpan="2">{t('tabs.champ')}</th>
                    <th rowSpan="2">{t('tabs.games')}</th>
                    <th rowSpan="2">{t('tabs.winrate')}</th>
                    <th rowSpan="2">{t('tabs.kda')}</th>
                    <th rowSpan="2">{t('tabs.csm')}</th>
                </tr>
                </thead>
                <HideElementTbody>
                    {Array.isArray(stat) && stat.length !== 0 ? (
                        stat.map((game, index) => {
                            const champion = champions.find((data) => Number(data?.championId) === Number(game?.champion_id))
                            if (index <= 6 && game.champion_id !== 0) {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <ChampionBox>
                                                <ChampionImageWithLane champion_id={game.champion_id}/>
                                                <ChampionSpan>{isKorean ? champion?.champion_name_kr : champion?.champion_name_en}</ChampionSpan>
                                            </ChampionBox>
                                        </td>
                                        <td>
                                            <span>{game.games}</span>
                                        </td>
                                        <td>{scoreColor(game.win_rate.toFixed(1))}%</td>
                                        <td>
                                            {kdaColor((game.kda)?.toFixed(2))}
                                            <p>
                                                {game?.kills?.toFixed(1)} /
                                                <i className="death"> {game?.deaths?.toFixed(1)}</i>/ {game?.assists?.toFixed(1)}
                                            </p>
                                        </td>
                                        <td>{game?.cs_per_min?.toFixed(1)}</td>
                                    </tr>

                                )
                            }
                        })
                    ) : (
                        <tr>
                            <td colSpan="8" className="no_data">
                                {t('notice.noData')}
                            </td>
                        </tr>
                    )}
                </HideElementTbody>
            </table>
        </TabPanel>
    );
};
CustomTabPanel.tabsRole = 'TabPanel';

export default Info;
