import React from 'react';
import styled from "styled-components";
import dompurify from "dompurify";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;


  &:hover {
    .absoluteBox {
      display: ${(props) => {
        let display = 'flex';
        if (props.disabled) display = 'none';
        return display;
      }};
`;


const AbsoluteBox = styled.div`
  display: none;
  position: absolute;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  ${props => {
    let position = 'bottom: 100%;'
    if (props.reverse) position = 'top: 100%;';
    return position;
  }}
  animation: tooltip-show 0.5s;


  @keyframes tooltip-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

`;

const TextBox = styled.div`
  position: relative;
  width: max-content;
  background-color: #333540;
  max-width: 300px;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px #00000029;
  opacity: 0.95;
  padding: 6px 16px;
  border-radius: 6px;
`;

const TooltipText = styled.span`
  text-align: center;
  flex-shrink: 0;
  white-space: pre-wrap;
  font-weight: normal;
  font-size: 10px;
  color: #ECECEC;
  line-height: 1.4;
`;

const Arrow = styled.div`
  opacity: 0.9;
  border-top: #333540 10px solid;
  border-left: transparent 7px solid;
  border-right: transparent 7px solid;
  border-bottom: transparent 2px solid;
  width: 0;
  height: 0;
`;


const ArrowUp = styled.div`
  opacity: 0.9;
  border-top: transparent 2px solid;
  border-left: transparent 7px solid;
  border-right: transparent 7px solid;
  border-bottom: #333540 10px solid;
  width: 0;
  height: 0;
`;


const BigTooltip = ({children, tooltipText, htmlInput = false, maxWidth = 300, reverse = false, disabled}) => {
    const sanitizer = dompurify.sanitize;

    return (
        <Wrapper disabled={disabled}>
            {children}
            <AbsoluteBox className={'absoluteBox'} reverse={reverse}>
                {reverse && <ArrowUp/>}
                <TextBox>
                    <TooltipText
                        dangerouslySetInnerHTML={{__html: sanitizer(tooltipText)}}
                    >
                    </TooltipText>
                </TextBox>
                {!reverse && <Arrow/>}
            </AbsoluteBox>
        </Wrapper>
    );
};


export default BigTooltip;