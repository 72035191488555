import React, {useCallback} from 'react';
import styled from "styled-components";

const ListLI = styled.li`
  margin-inline: 2px;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  cursor: pointer;

  &::after {
    content: '';
    width: ${props => {
      if (props.selected) {
        return '100%';
      } else {
        return 0;
      }
    }};
    display: block;
    height: 5px;
    background: transparent linear-gradient(93deg, #1eb8a0 0%, #3c68b2 100%);
    transition: ${props => {
      if (props.selected) {
        return 'width 200ms linear;';
      }
      return '';
    }};
    overflow: hidden;
  }
`;

const ListText = styled.span`
  flex: 1;
  margin-left: 4px;
  font-size: 0.85rem;
  color: ${props => {
    let color = '#828282';
    if (props.selected) color = '#313131';
    return color;
  }};
  font-weight: ${props => {
    let color = '';
    if (props.selected) color = 'bold';
    return color;
  }};;
  line-height: 22px;
`;

const LaneImage = styled.img`
  opacity: ${props => {
    let opacity = '0.5';
    if (props.selected) opacity = '1';
    return opacity
  }};
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline: 10px;
`;

const SearchListLI = ({data, index, selected, onClick, lane = 'Top'}) => {
    const onClickList = useCallback(() => {
        if (onClick !== undefined && index !== undefined) {
            onClick(index,lane);
        }
    }, []);

    return (
        <ListLI
            onClick={onClickList}
            selected={selected}
        >
            <DataWrapper>
                <LaneImage
                    selected={selected}
                    width={16} height={16} src={`/images/icon_s_position_${lane.toLowerCase()}.svg`}/>
                <ListText selected={selected}>{data}</ListText>
            </DataWrapper>
        </ListLI>
    );
};


export default React.memo(SearchListLI);