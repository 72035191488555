import React, {useEffect, usepart} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IngameSummoner from './IngameSummoner';
import {ImageChamp, ImageLane, ImageRune, ImageSpell, Loading} from '../../components';
import {getGameTime, kdaColor, scoreColor} from '../../function';
import SummonerProfile from "./Ingame/SummonerProfile";
import styled from "styled-components";
import ChampionImageWithLane from "../common/ChampionImageWithLane";

const CounterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  flex: 1;
`;

const IngameItem = ({
                        part,
    blueBasic,
    redBasic,
                        queue,
                        blueDuoList,
                        redDuoList,
                        tierInfo,
                        handleMoreInfo,
                        openLane,
                        ranks,
                        blueSummoner,
                        redSummoner,
                        updateStatus,
                    }) => {
    const {t} = useTranslation();
    const currentLang = useSelector((part) => part.translation.lang);
    const blueGameInfo = blueSummoner;
    const redGameInfo = redSummoner;
    const blueLane = blueSummoner?.position;
    const redLane = redSummoner?.position;




    const getSummonerMatchAvg = (list) => {
        let winSum = 0;
        let loseSum = 0;
        list.forEach((item) => {
            item.is_win ? winSum++ : loseSum++;
        });

        return {
            win: winSum,
            lose: loseSum,
        };
    };


    try {
        return (
            <li
                className={`ingame_item ${updateStatus ? "complete" : "incomplete"} ${
                    openLane === blueLane ? 'open' : 'close'
                }`}
            >
                {!updateStatus && <Loading/>}
                <div className="row">
                    <div className="team blue">
                        <IngameSummoner
                            summoner={blueSummoner}
                            ranks={ranks.B}
                            gameInfo={part.B}
                            teamInfo={blueGameInfo}
                            lane={blueLane}
                            duoList={blueDuoList}
                            tierInfo={tierInfo}
                            side="blue"
                            feature={part.feature}
                            queue={queue}
                        />
                        <div className="game_info">
                            <div className="champ_data">
                                <ChampionImageWithLane champion_id={blueGameInfo.champion_id}/>
                                <div className="spell">
                                    <ImageSpell spellId={blueBasic.spell_id_dict.spell_1}/>
                                    <ImageSpell spellId={blueBasic.spell_id_dict.spell_2}/>
                                </div>
                                <SummonerProfile
                                    gameInfo={blueGameInfo}
                                    part={part.B}
                                />
                            </div>
                            <div className="rune_data">
                                <div className="runes">
                                    {Object.values(blueBasic.rune_detail_dict).map(
                                        (id, index) => index < 4 && <ImageRune key={index} runeId={id}/>
                                    )}
                                </div>
                                <div className="runes">
                                    {Object.values(blueBasic.rune_detail_dict).map(
                                        (id, index) => 3 < index && index < 6 && <ImageRune key={index} runeId={id}/>
                                    )}
                                </div>
                                <div className="runes">
                                    {Object.values(blueBasic.rune_detail_dict).map((id, index) => {
                                        if (5 < index) {
                                            if (id === 5001) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsHealthScalingIcon.png"
                                                        alt="HealthScaling"
                                                    />
                                                );
                                            } else if (id === 5002) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsArmorIcon.png"
                                                        alt="Armor"
                                                    />
                                                );
                                            } else if (id === 5003) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsMagicResIcon.png"
                                                        alt="MagicRes"
                                                    />
                                                );
                                            } else if (id === 5005) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAttackSpeedIcon.png"
                                                        alt="AttackSpeed"
                                                    />
                                                );
                                            } else if (id === 5007) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsCDRScalingIcon.png"
                                                        alt="CDRScaling"
                                                    />
                                                );
                                            } else if (id === 5008) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAdaptiveForceIcon.png"
                                                        alt="Adaptive"
                                                    />
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="center"
                         style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <CounterWrapper>
                            <div className="score_info">
                                <div className="score">
                                    {scoreColor(part.B.summoner_info_dict.ai_score_avg)}
                                </div>
                                <div className="graph">
                                    <div className="number">
                                        <p className="blue">
                  <span>
                    {isNaN(
                        part.B.summoner_info_dict.ai_score_avg / (part.B.summoner_info_dict.ai_score_avg + part.R.summoner_info_dict.ai_score_avg)
                    )
                        ? 0
                        : (
                            (part.B.summoner_info_dict.ai_score_avg /
                                (part.B.summoner_info_dict.ai_score_avg + part.R.summoner_info_dict.ai_score_avg)) *
                            100
                        ).toFixed(0)}
                  </span>
                                            %
                                        </p><p className="red">
                  <span>
                    {isNaN(
                        part.R.summoner_info_dict.ai_score_avg / (part.B.summoner_info_dict.ai_score_avg + part.R.summoner_info_dict.ai_score_avg)
                    )
                        ? 0
                        : (
                            (part.R.summoner_info_dict.ai_score_avg /
                                (part.B.summoner_info_dict.ai_score_avg + part.R.summoner_info_dict.ai_score_avg)) *
                            100
                        ).toFixed(0)}
                  </span>
                                            %
                                        </p>
                                    </div>
                                    <div className="bar">
                                        <p
                                            className="blue"
                                            style={{
                                                width: `${(
                                                    (part.B.summoner_info_dict.ai_score_avg / (part.B.summoner_info_dict.ai_score_avg + part.R.summoner_info_dict.ai_score_avg)) *
                                                    100
                                                ).toFixed(0)}%`,
                                            }}
                                        ></p>
                                        <p
                                            className="red"
                                            style={{
                                                width: `${(
                                                    (part.R.summoner_info_dict.ai_score_avg / (part.B.summoner_info_dict.ai_score_avg + part.R.summoner_info_dict.ai_score_avg)) *
                                                    100
                                                ).toFixed(0)}%`,
                                            }}
                                        ></p>
                                    </div>
                                </div>
                                <div className="score">
                                    {scoreColor(part.R.summoner_info_dict.ai_score_avg)}
                                </div>
                            </div>
                        </CounterWrapper>
                        <div className="counter_info">
                            <div className="counter blue">
                                <ChampionImageWithLane champion_id={blueGameInfo.champion_id}/>
                                <p>{scoreColor(part.champion_counter_stats.blue_win_rate)} %</p>
                            </div>
                            <dl className="vs">
                                <dt>VS</dt>
                                <dd>
                                    <span>{part.champion_counter_stats.games.toLocaleString()}</span> games
                                </dd>
                            </dl>
                            <div className="counter red">
                                <ChampionImageWithLane champion_id={redGameInfo.champion_id}/>
                                <p>{scoreColor(part.champion_counter_stats.red_win_rate)} %</p>
                            </div>
                        </div>
                    </div>
                    <div className="team red">
                        <IngameSummoner
                            summoner={redSummoner}
                            ranks={ranks.R}
                            gameInfo={part.R}
                            teamInfo={redGameInfo}
                            lane={redLane}
                            duoList={redDuoList}
                            tierInfo={tierInfo}
                            side="red"
                            feature={part.feature}
                            queue={queue}
                        />
                        <div className="game_info">
                            <div className="champ_data">
                                <ChampionImageWithLane champion_id={redGameInfo.champion_id}/>
                                <div className="spell">

                                    <ImageSpell spellId={redBasic.spell_id_dict.spell_1}/>
                                    <ImageSpell spellId={redBasic.spell_id_dict.spell_2}/>
                                </div>
                                <SummonerProfile
                                    reverse={true}
                                    gameInfo={redGameInfo}
                                    part={part.R}
                                />
                                {/*<div className="txt">*/}
                                {/*  {champNames.map(*/}
                                {/*    (champ, idx) =>*/}
                                {/*      Number(champ.champion_id) === redGameInfo.champion_id && (*/}
                                {/*        <p className="name" key={idx}>*/}
                                {/*          <strong>{currentLang === 'KOR' ? champ.champion_name_kr : champ.champion_name_en}</strong>*/}
                                {/*        </p>*/}
                                {/*      )*/}
                                {/*  )}*/}
                                {/*  <p>*/}
                                {/*    <em>{scoreColor(part.R.this_champion_stat.season_winrate.toFixed(1))}%</em>*/}
                                {/*    <em>*/}
                                {/*      <strong>{part.R.this_champion_stat.season_games}</strong> {t('summoner.games')}*/}
                                {/*    </em>*/}
                                {/*  </p>*/}
                                {/*  <p>*/}
                                {/*    <em>{kdaColor(part.R.this_champion_stat.season_kda.toFixed(2))}</em>*/}
                                {/*    <em>{scoreColor(part.R.this_champion_stat.season_ai_score)}</em>*/}
                                {/*  </p>*/}
                                {/*</div>*/}
                            </div>
                            <div className="rune_data">
                                <div className="runes">

                                    {Object.values(redBasic.rune_detail_dict).map(
                                        (id, index) => index < 4 && <ImageRune key={index} runeId={id}/>
                                    )}
                                </div>
                                <div className="runes">
                                    {Object.values(redBasic.rune_detail_dict).map(
                                        (id, index) => 3 < index && index < 6 && <ImageRune key={index} runeId={id}/>
                                    )}
                                </div>
                                <div className="runes">
                                    {Object.values(redBasic.rune_detail_dict).map((id, index) => {
                                        if (5 < index) {
                                            if (id === 5001) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsHealthScalingIcon.png"
                                                        alt="HealthScaling"
                                                    />
                                                );
                                            } else if (id === 5002) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsArmorIcon.png"
                                                        alt="Armor"
                                                    />
                                                );
                                            } else if (id === 5003) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsMagicResIcon.png"
                                                        alt="MagicRes"
                                                    />
                                                );
                                            } else if (id === 5005) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAttackSpeedIcon.png"
                                                        alt="AttackSpeed"
                                                    />
                                                );
                                            } else if (id === 5007) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsCDRScalingIcon.png"
                                                        alt="CDRScaling"
                                                    />
                                                );
                                            } else if (id === 5008) {
                                                return (
                                                    <img
                                                        key={index}
                                                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAdaptiveForceIcon.png"
                                                        alt="Adaptive"
                                                    />
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button">
                    <button onClick={() => handleMoreInfo(blueLane)}>
                        <img src="/images/icon_arrow_b.svg" alt={t('summoner.more')}/>
                    </button>
                </div>
                {
                    openLane === blueLane && (
                        <div className="ingame_detail">
                            <h4>
                                {t('ingame.detailTitle')} <span>(AI-Score, KDA, {t('summoner.rank')})</span>
                            </h4>
                            <div className="detail_info">
                                <div className="team blue">
                                    <ul className="match_list">
                                        {part.B.summoner_info_dict.last_match_list.map((item) => (
                                            <li key={item.end_timestamp} className="match_item">
                                                <div className={`box ${item.is_win ? 'win' : 'lose'}`}>
                                                    <div className="champ">
                                                        <ChampionImageWithLane champion_id={item.champion_id} position={item.position}/>
                                                    </div>
                                                    <div className="score">

                                                        {item.is_runaway ? scoreColor(0) : scoreColor(item.ai_score.toFixed(0))}
                                                    </div>
                                                    <div className="kda">
                                                        {item.is_runaway ? (
                                                            '-'
                                                        ) : item.death === 0 ? (
                                                            <span className="kda_color perfect">Perfect</span>
                                                        ) : (
                                                            kdaColor(item.kda.toFixed(2))
                                                        )}
                                                    </div>
                                                    <div className="rating">
                                                        {item.mvp === 1 &&
                                                            <img src="/images/ico_crown_gold_w.svg" alt="mvp"/>}
                                                        {item.ace === 1 &&
                                                            <img src="/images/ico_crown_silver_w.svg" alt="mvp"/>}
                                                        {item.is_runaway ? (
                                                            <span className="away">{t('summoner.escape')}</span>
                                                        ) : item.ai_score_rank === 1 ? (
                                                            item.ai_score_rank + t('summoner.firstGrade')
                                                        ) : item.ai_score_rank === 2 ? (
                                                            item.ai_score_rank + t('summoner.secondGrade')
                                                        ) : item.ai_score_rank === 3 ? (
                                                            item.ai_score_rank + t('summoner.thirdGrade')
                                                        ) : (
                                                            item.ai_score_rank + t('summoner.grade')
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="time">{getGameTime(item.end_timestamp, currentLang)}</div>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="match_num">

                                        <h5>{scoreColor(part.B.summoner_info_dict.ai_score_avg)}</h5>
                                        <div className="winlose">
                                            <dl>
                                                <dt>
                                                    {getSummonerMatchAvg(part.B.summoner_info_dict.last_match_list).win}
                                                    {t('summoner.contractionWin')}
                                                </dt>
                                                <dd>{scoreColor(part.B.summoner_info_dict.ai_score_avg_win)}</dd>
                                            </dl>
                                            <dl>
                                                <dt>

                                                    {getSummonerMatchAvg(part.B.summoner_info_dict.last_match_list).lose}
                                                    {t('summoner.contractionLose')}
                                                </dt>

                                                <dd>{scoreColor(part.B.summoner_info_dict.ai_score_avg_loss)}</dd>
                                            </dl>
                                        </div>
                                        <p>#{part.B.summoner_info_dict.ai_score_rank_avg.toFixed(1)}</p>
                                    </div>
                                </div>
                                <div className="team red">
                                    <ul className="match_list">

                                        {part.R.summoner_info_dict.last_match_list.map((item) => (
                                            <li key={item.end_timestamp} className="match_item">
                                                <div className={`box ${item.is_win ? 'win' : 'lose'}`}>
                                                    <div className="champ">
                                                        <ChampionImageWithLane champion_id={item.champion_id} position={item.position}/>
                                                    </div>
                                                    <div className="score">
                                                        {item.is_runaway ? scoreColor(0) : scoreColor(item.ai_score.toFixed(0))}
                                                    </div>
                                                    <div className="kda">
                                                        {item.is_runaway ? (
                                                            '-'
                                                        ) : item.death === 0 ? (
                                                            <span className="kda_color perfect">Perfect</span>
                                                        ) : (
                                                            kdaColor(item.kda.toFixed(2))
                                                        )}
                                                    </div>
                                                    <div className="rating">
                                                        {item.mvp === 1 &&
                                                            <img src="/images/ico_crown_gold_w.svg" alt="mvp"/>}
                                                        {item.ace === 1 &&
                                                            <img src="/images/ico_crown_silver_w.svg" alt="mvp"/>}
                                                        {item.is_runaway ? (
                                                            <span className="away">{t('summoner.escape')}</span>
                                                        ) : item.ai_score_rank === 1 ? (
                                                            item.ai_score_rank + t('summoner.firstGrade')
                                                        ) : item.ai_score_rank === 2 ? (
                                                            item.ai_score_rank + t('summoner.secondGrade')
                                                        ) : item.ai_score_rank === 3 ? (
                                                            item.ai_score_rank + t('summoner.thirdGrade')
                                                        ) : (
                                                            item.ai_score_rank + t('summoner.grade')
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="time">{getGameTime(item.end_timestamp, currentLang)}</div>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="match_num">
                                        <h5>{scoreColor(part.R.summoner_info_dict.ai_score_avg)}</h5>
                                        <div className="winlose">
                                            <dl>
                                                <dt>
                                                    {getSummonerMatchAvg(part.R.summoner_info_dict.last_match_list).win}
                                                    {t('summoner.contractionWin')}
                                                </dt>
                                                <dd>{scoreColor(part.R.summoner_info_dict.ai_score_avg_win)}</dd>
                                            </dl>
                                            <dl>
                                                <dt>
                                                    {getSummonerMatchAvg(part.R.summoner_info_dict.last_match_list).lose}
                                                    {t('summoner.contractionLose')}
                                                </dt>
                                                <dd>{scoreColor(part.R.summoner_info_dict.ai_score_avg_loss)}</dd>
                                            </dl>
                                        </div>
                                        <p>#{part.R.summoner_info_dict.ai_score_rank_avg.toFixed(1)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </li>
        );
    } catch (e) {
        console.log(e);
        return (
            <></>
        )

    }

};

export default React.memo(IngameItem);
