import React, {useCallback} from 'react';
import {ImageChamp} from "../../../../../../common";
import {threedigits} from "../../../../../../../function";
import styled from "styled-components";
import WinRateGraph from "../../../../../../common/WinRateGraph";
import MatchChampImg from "../../../../../MathItem/ChampImg";
import ChampionImageWithLane from "../../../../../../common/ChampionImageWithLane";

const ContentTD = styled.td`
  padding: 8px;
`;

const TableContentWrapper = styled.div`
  display: flex;
  align-items: center;

  .imgChamp {
    width: 34px;
    aspect-ratio: 1;
  }
`;


const GamesCountText = styled.span`
  font-size: 12px;
  flex: 1;
  text-align: center;
  color: #7473AB;
`;


const ChampSynergyTableItem = ({games, win_rate, champion_id}) => {
    const renderWinRatePercent = useCallback((value) => {
        if (value === 100) {
            return Number(value);
        } else {
            return value.toFixed(1)
        }
    }, []);

    return (
        <tr>
            <ContentTD>
                <TableContentWrapper>
                    <ChampionImageWithLane
                        champion_id={champion_id}
                    />
                    <GamesCountText>{threedigits(games)}</GamesCountText>
                </TableContentWrapper>
            </ContentTD>
            <ContentTD>
                <WinRateGraph
                    addPercent={true}
                    dmg={renderWinRatePercent(Number(win_rate))}
                    total={100}
                    color={'#7473AB'}
                    barColor={'#EF4D8A'}
                    bgColor={'#D9DDE1'}
                />
            </ContentTD>
        </tr>
    );
};

export default React.memo(ChampSynergyTableItem);