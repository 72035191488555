import React, {useCallback, useEffect, useMemo} from 'react';
import styled from "styled-components";
import useInput from "../../../../../common/hooks/useInput";
import WinRateText from "../../../../common/WinrateText";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WinRateWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 8px;
`;

const WinRateSpan = styled.span`
  font-size: 24px;
  font-weight: bold;
`;


const WinLoseSpan = styled.span`
  color: #313131;
  font-size: 12px;
  margin-right: 3px;
`;

const ExtraSpan = styled.span`
  font-size: 12px;
  opacity: 0.6;
`;


const WinLoseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${ExtraSpan}:not(:last-of-type) {
    margin-right: 6px;
  }

`;

const initObj = {
    win: 0,
    lose: 0,
}

const OverviewWinRate = ({data}) => {
    const {t} = useTranslation();
    const winLoseObj = useMemo(() => {
        if (Array.isArray(data) && data.length > 0) {
            return data.reduce((sum, cur) => {
                if (cur) {
                    sum.win += 1;
                } else {
                    sum.lose += 1;
                }
                return sum;
            }, {...initObj})
        } else {
            return {...initObj};
        }
    }, [data]);

    const calcPercent = (top, bottom) => {
        return ((top / bottom) * 100).toFixed(1);
    }

    const renderPercent = useCallback(() => {
        if (winLoseObj.win + winLoseObj.lose > 0) {
            return calcPercent(winLoseObj.win, winLoseObj.win + winLoseObj.lose)
        } else {
            return "0.0"
        }

    }, [winLoseObj])


    if (!Array.isArray(data) || data.length === 0) return (
        <Wrapper>
            There are no data
        </Wrapper>
    );


    return (
        <Wrapper>
            <WinRateWrapper>
                <WinRateText fontWeight={'bold'} fontSize={'24px'}>{renderPercent()}</WinRateText>
                <ExtraSpan>%</ExtraSpan>
            </WinRateWrapper>
            <WinLoseWrapper>
                <WinLoseSpan>
                    {winLoseObj.win}
                </WinLoseSpan>
                <ExtraSpan>{t('gameAnalytics.win')}</ExtraSpan>
                <WinLoseSpan>
                    {winLoseObj.lose}
                </WinLoseSpan>
                <ExtraSpan>{t('gameAnalytics.lose')}</ExtraSpan>
            </WinLoseWrapper>
        </Wrapper>
    );
};

export default OverviewWinRate;
