import React, {useCallback, useMemo, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _, {range} from 'lodash';
import {useSelector} from "react-redux";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {getDividedGameTime, getLPTime} from "../../../function";
import ChartMultiLegend from "./ChartMultiLegend";


const LegendWrapper = styled.div`
  border: 1px solid #A5BED2;
  border-radius: 6px;
  padding: 12px 8px;
  margin-left: 14px;
  height: fit-content;
`;


const ChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 22px 0;
background-color: #fff;

  .highcharts-tick {
    fill: rgba(255, 168, 80, 1);
    fill-opacity: 1;
    stroke-width: 0;
  }
  
  .pro_tag {
    display: flex;
    border-radius: 4px;
    margin-right: 4px;
    background: transparent linear-gradient(180deg, #5E59F1 0%, #7C41A8 100%);
    padding: 1px 4px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
  }
  
  .streamer_tag {
    display: flex;
    border-radius: 4px;
    margin-right: 4px;
    background: transparent linear-gradient(180deg, #F1597D 0%, #A87141 100%);
    padding: 1px 4px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
  }
`;


const _barColor = ['#4270EE', '#FF9E48', '#4FC54F', '#E377C2', '#67A4FF', '#FE4C4D', '#C5D20E', '#9B68F6', '#48C9D8', '#C56A57']

const ChartMulti = ({data, reverse = false}) => {
    const {t} = useTranslation();
    const currentLang = useSelector((state) => state.translation.lang);
    const [legendIndex, setLegendIndex] = useState(99);
    // originalArr    : 60개의 원래 티어 정보
    // calculatedArr  : 계산된 티어 포인트
    // dateArr        : 날짜 정보
    // yTickPositions : y축 값 (이제 안씀)






    const onChangeLegend = useCallback((index) => {
        if (typeof index === 'number') {
            setLegendIndex(index);
        }
    }, []);

    const lineVisible = useCallback((index) => {
        if (legendIndex === 99) {
            return true;
        } else {
            return legendIndex === index;
        }
    }, [legendIndex]);


    const minValue = Math.floor(Math.min.apply(null, data.map((item) => item && item.calculatedArr).reduce((sum, cur) => sum.concat(cur))) / 100) * 100;
    const maxValue = (Math.floor(Math.max.apply(null, data.map((item) => item && item.calculatedArr).reduce((sum, cur) => sum.concat(cur))) / 100) + 1.2
    ) * 100;
    const yTickPositions = {
        GRANDMASTER: Math.min.apply(null, data.map((item) => item?.yTickPositions ? item.yTickPositions.GRANDMASTER : 2950)),
        CHALLENGER: Math.min.apply(null, data.map((item) => item?.yTickPositions ? item.yTickPositions.CHALLENGER : 9999)),
    }


    const rangeData = range(minValue, maxValue + 100, 100);
    let newRange = rangeData.filter(
        (item, index) => {
            return index === 0 || index === rangeData?.length - 1 || item % 100 === 0 || item >= yTickPositions?.GRANDMASTER
        }
    );

    const maxCategoryLength = useMemo(() => {
        if(Array.isArray(data)) {
            return Math.max.apply(null,data.map((data) => data && data.calculatedArr.length));
        }
        return 0;
    },[data]);

    const changeTier = (tier,division) => {
        const removeDivision = tier.replaceAll(/\d/g,"");
        const upperCase = removeDivision.toUpperCase();
        if(upperCase === 'CHALLENGER' || upperCase === 'GRANDMASTER' || upperCase === 'MASTER') {
            return removeDivision
        } else {
            return `${tier} ${division}`;
        }
    }

    const tierPosition = [...new Array(25).fill("").map((data, index) => index * 100), yTickPositions?.GRANDMASTER, yTickPositions?.CHALLENGER];
    const options = {
        // 하이차트 옵션
        chart: {
            marginLeft: 40,
            marginBottom: 50,
            marginRight: 0,
            marginTop: 0,
            type: 'area',
            plotBackgroundColor: 'none',
            backgroundColor: 'none',
            plotBorderWidth: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height: newRange.length * 40,
            width: 870,
            events: {
                // load: function () {
                //   var point = this.series[0].points;
                //   point.forEach((p) => {
                //     if (Math.max.apply(null, calculatedArr) === p.y) {
                //       p.update({
                //         marker: {
                //           enabled: true,
                //           fillColor: '#46BCED',
                //           radius: 5,
                //           width: 5,
                //           lineColor: '#ffffff',
                //           lineWidth: 2,
                //         },
                //         dataLabels: {
                //           enabled: false,
                //         },
                //       });
                //     }
                //   });
                // },
            },
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        xAxis: {
            margin: 0,
            labels: {
                style: {
                    textOverflow: "ellipsis",
                    fontFamily: 'Noto Sans KR',
                    fontSize: '11px',
                    fontWeight: 400,
                    color: '#313131',
                },
                useHTML: true,
                formatter: function () {
                    const calvalue = getLPTime(this.value, currentLang);
                    return calvalue;
                },
            },
            tickWidth: 1,
            tickColor: '#FFA850',
            tickInterval: 20,
            categories: _.uniq((data.map((item) => item?.dateArr).reduce((sum, cur) => sum.concat(cur))).sort((a,b) => a-b)),
            lineWidth: 0,
            // lineColor: 'rgba(0,0,0,0.5)',
            gridLineWidth: 1,
            gridLineColor: 'rgba(0,0,0,0.2)',
            gridLineDashStyle: 'ShortDash',
        },
        yAxis: {
            title: {
                enabled: false,
            },
            labels: {
                x: -10,
                style: {
                    fontFamily: 'Noto Sans KR',
                    fontSize: '12px',
                    fontWeight: 500,
                },
                useHTML: true,
                formatter: function () {
                    let thisValue = this.value;
                    if (thisValue === 0) return "<span style='color: #5A5959'>I4</span>";
                    else if (thisValue === 100) return "<span style='color: #5A5959; text-align: right'>I3</span>";
                    else if (thisValue === 200) return "<span style='color: #5A5959;text-align: right'>I2</span>";
                    else if (thisValue === 300) return "<span style='color: #5A5959;text-align: right'>I1</span>";
                    else if (thisValue === 400) return "<span style='color: #876B26;text-align: right'>B4</span>";
                    else if (thisValue === 500) return "<span style='color: #876B26;text-align: right'>B3</span>";
                    else if (thisValue === 600) return "<span style='color: #876B26;text-align: right'>B2</span>";
                    else if (thisValue === 700) return "<span style='color: #876B26;text-align: right'>B1</span>";
                    else if (thisValue === 800) return "<span style='color: #6A79AB;text-align: right'>S4</span>";
                    else if (thisValue === 900) return "<span style='color: #6A79AB;text-align: right'>S3</span>";
                    else if (thisValue === 1000) return "<span style='color: #6A79AB;text-align: right'>S2</span>";
                    else if (thisValue === 1100) return "<span style='color: #6A79AB;text-align: right'>S1</span>";
                    else if (thisValue === 1200) return "<span style='color: #BA8D26;text-align: right'>G4</span>";
                    else if (thisValue === 1300) return "<span style='color: #BA8D26;text-align: right'>G3</span>";
                    else if (thisValue === 1400) return "<span style='color: #BA8D26;text-align: right'>G2</span>";
                    else if (thisValue === 1500) return "<span style='color: #BA8D26;text-align: right'>G1</span>";
                    else if (thisValue === 1600) return "<span style='color: #1DA794;text-align: right'>P4</span>";
                    else if (thisValue === 1700) return "<span style='color: #1DA794;text-align: right'>P3</span>";
                    else if (thisValue === 1800) return "<span style='color: #1DA794;text-align: right'>P2</span>";
                    else if (thisValue === 1900) return "<span style='color: #1DA794;text-align: right'>P1</span>";
                    else if (thisValue === 2000) return "<span style='color: #2898C9;text-align: right'>D4</span>";
                    else if (thisValue === 2100) return "<span style='color: #2898C9;text-align: right'>D3</span>";
                    else if (thisValue === 2200) return "<span style='color: #2898C9;text-align: right'>D2</span>";
                    else if (thisValue === 2300) return "<span style='color: #2898C9;text-align: right'>D1</span>";
                    else if (thisValue === 2400) return "<span style='color: #863DA1;text-align: right'>M</span>";
                    else if (thisValue === yTickPositions?.GRANDMASTER) return "<span style='color: #C23449'>GM</span>";
                    else if (thisValue === yTickPositions?.CHALLENGER) return "<span style='color: #EE7A00'>C</span>";
                    else return '';
                },
            },
            min: minValue,
            max: maxValue,
            startOnTick: false,
            endOnTick: false,
            tickPositions: [...newRange, yTickPositions?.GRANDMASTER, yTickPositions?.CHALLENGER],
            tickColor: 'transparent',
            plotLines: tierPosition.map((data) => {

                if(data % 400 === 0 || data === yTickPositions?.GRANDMASTER || data === yTickPositions?.CHALLENGER ) {
                    return {
                        color: 'rgba(0,0,0,0.4)',
                        width: 1,
                        value: data,
                        dashStyle: 'solid',
                    }
                } else {
                    return {
                        color: 'rgba(0,0,0,0.2)',
                        width: 1,
                        value: data,
                        dashStyle: 'ShortDash',
                    }
                }

            }),
            minorGridLineWidth: 0,
            minorGridLineColor: 'transparent',
            lineWidth: 0,
            // lineColor: 'rgba(0,0,0,0.5)',
            gridLineWidth: 0,
            // gridLineColor: 'rgba(0,0,0,0.2)',
            // gridLineDashStyle: 'ShortDash',
            plotBands: [
                {
                    color: 'rgba(90, 89, 89, 0.08)',
                    from: 0,
                    to: 400,
                },
                {
                    color: 'rgba(135, 107, 38, 0.08)',
                    from: 400,
                    to: 800,
                },
                {
                    color: 'rgba(106, 121, 171, 0.08)',
                    from: 800,
                    to: 1200,
                },
                {
                    color: 'rgba(186, 141, 38, 0.08)',
                    from: 1200,
                    to: 1600,
                },
                {
                    color: 'rgba(29, 167, 148, 0.08)',
                    from: 1600,
                    to: 2000,
                },
                {
                    color: 'rgba(40, 152, 201, 0.08)',
                    from: 2000,
                    to: 2400,
                },
                {
                    color: 'rgba(134, 61, 161, 0.08)',
                    from: 2400,
                    to: yTickPositions?.GRANDMASTER - 1,
                },
                {
                    color: 'rgba(194, 52, 73, 0.08)',
                    from: yTickPositions?.GRANDMASTER,
                    to: yTickPositions?.CHALLENGER,
                },
                {
                    color: 'rgba(238, 122, 0, 0.08)',
                    from: yTickPositions?.CHALLENGER,
                    to: yTickPositions?.CHALLENGER + 10000,
                },
            ],
        },
        series: data.map((line, index) => {
            return {
                type: 'line',
                name: 'lp graph',
                data: line.multiCalArr,
                opacity: lineVisible(index) ? 1 : 0.3,
                enableMouseTracking: lineVisible(index),
                lineWidth: 2,
                lineColor: _barColor[index],
                marker: {
                    symbol: 'circle',
                    lineColor: "#fff",
                    lineWidth: 2,
                    enabled: false,
                    fillColor: _barColor[index],
                    radius: 4,
                },
                showInLegend: false
            }
        }),
        // series: [
        //     {
        //         type: 'line',
        //         name: 'lp graph',
        //         data: calculatedArr,
        //         opacity: lineVisible(0) ? 1 : 0.2,
        //         enableMouseTracking: lineVisible(0),
        //         lineWidth: 2,
        //         lineColor: '#4270EE',
        //         color: '#4270EE',
        //         marker: {
        //             enabled: false,
        //         },
        //         showInLegend: false
        //     },
        //     {
        //         type: 'line',
        //         name: 'lp graph',
        //         opacity: lineVisible(1) ? 1 : 0.2,
        //         enableMouseTracking: lineVisible(1),
        //         data: data[1].calculatedArr,
        //         lineWidth: 2,
        //         lineColor: '#FF9E48',
        //         color: '#FF9E48',
        //
        //         marker: {
        //             enabled: false,
        //         },
        //         showInLegend: false
        //     },
        // ],
        plotOptions: {
            series: {
              pointPlacement:true,
            },
            showInLegend: false
        },
        tooltip: {
            backgroundColor: '#ffffff',
            borderColor: '#4C506D',
            borderRadius: 4,
            className: 'tooltip',
            useHTML: true,
            formatter: function () {
                const pointIndex = data[this.colorIndex].multiOriginalArr[this.point.index];

                const [time, text] = getDividedGameTime(this.x, currentLang);


                const date = new Date(this.x * 1000);
                const influData = data[this.colorIndex].summoner?.summoner_basic_info_dict?.pro_streamer_info_dict;
                const summonerName = data[this.colorIndex]?.summoner?.summoner_basic_info_dict?.summoner_name;

                if (pointIndex) {
                    return `
      <p style="z-index: 300">
        <span style="font-weight: bold; font-size: 11px">${text !== '방금 전' ? time : ''}</span>
        <span style="color: #313131; opacity: 0.6; font-weight: 500; font-size: 11px">${text}</span>
      </p>
      <div style="display: flex; flex-direction: row; align-items: center">
      <div class="${influData.status === "pro" && 'pro_tag'}">${influData.status === "pro" ? 'Pro' : ""}</div>
      <div class="${influData.status === "Streamer" && 'streamer_tag'}">${influData.status === "Streamer" ? 'Streamer' : ""}</div>
      <span style="width: 7ch; overflow: hidden; text-overflow: ellipsis; font-weight: bold">${influData.name}</span>
      </div>
      <p>
      <span style="font-size: 14px; font-weight: bold">${summonerName}</span></p>
          <p>
          <span style="color: #313131; opacity: 0.6; font-weight: 500; font-size: 11px">${t('chart.rank')} </span>
         
          <span class="tier_color ${pointIndex.tier.toLowerCase()}" style="font-size: 11px; font-weight: bold; margin-right: 5px">${changeTier(pointIndex.tier,pointIndex.division)}</span>
          <span style="color: #313131; opacity: 0.6; font-weight: 500; font-size: 11px">LP </span> <span style="font-size: 11px; font-weight: bold">${pointIndex.league_points}</span>
      </p>
          <p style="font-size: 11px;font-weight: bold"><span>${date.getFullYear()}.${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}.${date.getDate() > 9 ? date.getDate() : "0" + (date.getDate())}</span></p>
        `;
                }
                return '';

            },
        },
    };


    Highcharts.wrap(Highcharts.Tick.prototype, 'getMarkPath', function (prev, x, y, tickLength, tickWidth, horiz, renderer) {
        if (tickWidth > 0) {
            const xPath = Math.round(x) - 2.5;
            const elevateValue = 2;
            const yPath = Math.round(y) + 0.4;

            return ["M", xPath, yPath,
                "L",
                xPath + elevateValue, yPath - elevateValue,
                xPath + elevateValue * 2, yPath,
                xPath + elevateValue, yPath + elevateValue,
                xPath, yPath
            ]
        }
    });


    return (
        <>
            {(data.length !== 0 && data) ? (
                <ChartWrapper>
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={options}/>
                    </div>
                    <LegendWrapper>
                        {data.map((item, index) => {
                            const tier = `${item?.summoner?.season_tier_info_dict?.ranked_solo_5x5?.tier} ${item?.summoner?.season_tier_info_dict?.ranked_solo_5x5?.division}`;
                            const summonerName = item?.summoner?.summoner_basic_info_dict?.summoner_name;
                            const influData = item?.summoner?.summoner_basic_info_dict?.pro_streamer_info_dict;
                            if (tier && summonerName && influData) {
                                return <ChartMultiLegend
                                    key={index}
                                    influData={influData}
                                    barColor={_barColor[index]}
                                    selected={legendIndex === index}
                                    index={index}
                                    onClick={onChangeLegend}
                                    summonerName={summonerName}
                                    tier={tier}
                                />
                            } else {
                                return <React.Fragment key={index}></React.Fragment>
                            }

                        })}

                    </LegendWrapper>
                </ChartWrapper>
            ) : (
                <div className="chart_lp">No Data</div>
            )}
        </>
    );
};

export default ChartMulti;
