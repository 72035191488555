const regionList = [{
    title: 'NA',
    disabled : false,
    serverName : 'NA1',
}, {
    title: "EUW",
    disabled : false,
    serverName : 'EUW1'
}, {
    title: "EUN",
    disabled : false,
    serverName : 'EUN1'
}, {
    title: "KR",
    disabled : false,
    serverName : 'KR'
}, {
    title: "JP",
    disabled : false,
    serverName : 'JP1'
}, {
    title: "BR",
    disabled : true,
    serverName : 'BR1'
}, {
    title: "LAN",
    disabled : false,
    serverName : 'LA1'
}, {
    title: "LAS",
    disabled : false,
    serverName : 'LA2'
}, {
    title: "OCE",
    disabled : false,
    serverName : 'OC1'
}, {
    title: "TR",
    disabled : true,
    serverName : 'TR1'
}, {
    title: "RU",
    disabled : true,
    serverName : 'RU1'
}];


export default regionList
