import React, {lazy, Suspense, useCallback, useMemo} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {Redirect, Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";

import ChampionsDetailCategory from "./Category";
import NotFound from "../../NotFound";
import {GoogleAdvertise, Loading} from "../../common";
import ChampionsHistory from "../../ChampionsHistory";

const ChampionsDetailHeader = lazy(() => import("./ChampionsDetailHeader"));
const ChampionsDetailBuild = lazy(() => import("./ChampionsDetailBuild"));


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: hidden;
  background-color: #fff;
`;

const CenterDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 1162px;
  background-color: #fff;
  padding: 12px 16px;
  margin-bottom: 10vh;
  border-radius: 6px;
`;

const AbsoluteBackground = styled.div`
  opacity: .99;
  position: absolute;
  width: 100%;
  min-width: 1300px;
  height: 480px;
  top: 0;
  left: 0;
  background: transparent linear-gradient(90deg, #8FA5C2 0%, #6277A1 100%) 0 0;
  z-index: 0;
`;

const ContentWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const LoadingWrapper = styled.div`
  height: 50vh;
  width: 100%;
`;
const AdTopAdWrapper = styled.div`
  z-index: 1;
  margin-top: 12px;
  width: 1130px;
  height: 128px;


  .adsbygoogle {
    min-width: 400px;
    max-width: 1130px;
    max-height: 128px;
    width: 100%;
    height: 100%;
  }

  margin-bottom: 21px;
`;

const SideAdWrapper = styled.div`
  position: sticky;
  top: 70px;
  width: 300px;
  height: 600px;
  margin-top: 166px;
  margin-left: 16px;
  margin-right: 16px;
  z-index: 1;

  .adsbygoogle {
    width: 300px;
    height: 600px;
  }

`;

const ChampionsDetailContent = () => {
    const {path, url} = useRouteMatch();
    const {champName} = useParams();
    const history = useHistory();
    const championStore = useSelector((state) => state?.imageStore.champions);
    const version = useSelector((state) => state?.gameVersion.version);
    const champData = useMemo(() => {
        if (championStore.length > 0) {
            return championStore.find(
                (data) => data.champion_name_en.replace(/\s|&|willump|!|/gi, "").toLowerCase() === champName);
        }
    }, [championStore, champName]);


    const RedirectToBuild = useCallback(() => {
        const copyUrl = url;
        const lastword = copyUrl.charAt(copyUrl.length - 1);
        let finalUrl = url;
        if (lastword === '/') {
            finalUrl = finalUrl.substr(0, finalUrl.length - 1);
        }

        history.replace(`${finalUrl}/build`)
        return null;
    }, []);


    if (championStore.length === 0) return (
        <LoadingWrapper>
            <Loading/>
        </LoadingWrapper>
    );
    if (!champData) return (
        <NotFound/>
    )


    return (
        <Wrapper>
            <ContentWrapper>
                <SideAdWrapper marginRight={'16px'}>

                </SideAdWrapper>
                <CenterDiv>
                    <Suspense fallback={<Loading/>}>
                        <ChampionsDetailHeader
                            champion={champData}
                            version={version}
                        />
                    </Suspense>
                    <ChampionsDetailCategory/>
                    <Switch>
                        <Route path={`${path}/build`}>
                            <Suspense fallback={<Loading/>}>
                                <ChampionsDetailBuild
                                    champion={champData}
                                />
                            </Suspense>
                        </Route>
                        {/*<Route path={`${path}/counter`}>*/}

                        {/*</Route>*/}
                        <Route path={`${path}/history`}>
                            <Suspense fallback={<Loading/>}>
                                <ChampionsHistory
                                    champion={champData}
                                />
                            </Suspense>
                        </Route>
                        <Route>
                            <Redirect
                                to={`/champions/${champName}/build`}
                            />
                        </Route>
                    </Switch>
                </CenterDiv>
                <SideAdWrapper marginRight={'16px'}>

                </SideAdWrapper>
            </ContentWrapper>

        </Wrapper>
    );
};

export default ChampionsDetailContent;
