import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {prevTierAndDivision, sortChampData} from "../../../function";
import {useSelector} from "react-redux";


const DictWrapper = styled.div`
  background: #38466D;
  padding: 0 3px;
  height: 17px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid transparent;

  &.challenger {
    background-color: #DB7F48;
    border-color: #9E5D36;
  }

  &.grandmaster {
    background-color: #CF475B;
    border-color: #953543;
  }

  &.master {
    background-color: #A67ACB;
    border-color: #75568F;
  }

  &.diamond {
    background-color: #2CABE2;
    border-color: #20789E;
  }

  &.platinum {
    background-color: #20B19D;
    border-color: #177B6D;
  }

  &.gold {
    background-color: #BA8D26;
    border-color: #81621A;
  }

  &.silver {
    background-color: #8A9BD5;
    border-color: #616D94;
  }

  &.bronze {
    background-color: #BBA05D;
    border-color: #847141;
  }

  &.iron {
    background-color: #ACACAC;
    border-color: #6B6B6B;
  }

  &.unrank {
    background-color: #313131;
`;

const DictText = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 10px;
  text-transform: capitalize;
`;

const Year = styled.i`
  font-size: 10px;
  display: inline-block;
  color: #fff;
`;

const Wrapper = styled.div`

  display: flex;
  flex-direction: ${props => {
    let direction = 'row';
    if (props.reverse) direction = 'row-reverse';
    return direction;
  }};
  justify-content: flex-end;

  ${DictWrapper}:not(:last-of-type) {
    ${props => {
      let margin = 'margin-right: 6px;';
      if (props.reverse) margin = 'margin-left: 6px;';
      return margin;
    }}

  }
`;


const SummonerDict = ({dict, reverse, onlyLatest}) => {
    const currentSeason = useSelector((state) => state.imageStore.currentSeason);

    const seasonYearList = [
        {
            key: 11,
            value: 2021
        },
        {
            key: 12,
            value: 2022
        },
        {
            key: 13,
            value: 2022
        },
        {
            key: 14,
            value: 2023
        },
        {
            key: 15,
            value: 2023
        },
    ]

    const getYear = useCallback((season) => {
        if (season) {
            const result = seasonYearList.find((data) => Number(data.key) === Number(season));
            if (result) {
                return result.value;
            }
            return season;
        }
    }, []);

    const sortedDict = useMemo(() => {
        if (Array.isArray(dict) && dict.length > 0) {
            if (!onlyLatest) {
                const sorted = sortChampData(dict, [{sortType: false, value: 'season'}]);
                if (sorted) {
                    return sorted;
                }
            } else {
                const filtered = dict.filter((data) => Number(data.season) === Number(currentSeason - 1));
                if (filtered) {
                    return filtered
                }
            }
        }

        return [];
    }, [dict,currentSeason]);

    return (
        <Wrapper reverse={reverse}>
            {sortedDict.map((item, index) => {
                return (
                    <DictWrapper key={index + 'dict'} className={`${item.tier ? (item.tier).toLowerCase() : ''}  `}>
                        <Year>{getYear(item.season)} &nbsp;
                            <DictText
                                className={`tier_color`}>{prevTierAndDivision(item.tier, item.division)}</DictText>
                        </Year>
                    </DictWrapper>
                );
            })}
        </Wrapper>
    );
};

export default SummonerDict;
