import React, {useCallback} from 'react';
import styled from "styled-components";
import QueueFilter from "./QueueFilter";
import useInput from "../../../common/hooks/useInput";
import OverviewContent from "./OverviewContent";
import {GET_MATCH_BY_QUEUE_REQUEST} from "../../../store/reducer/summoner";
import {useDispatch} from "react-redux";

const Wrapper = styled.div`
  width: 1130px;
  background-color: #F4F4F5;
  border: 1px solid #C3D1DC;
  border-radius: 6px;
`;

const NewOverview = ({region,selectedQueue,onChangeSelectedQueue}) => {
    const dispatch = useDispatch();
    const [selectedChampId, onChangeSelectedChampId] = useInput(0);

    const handleQueueFilter = useCallback((queueNum) => {
        onChangeSelectedQueue(queueNum);

        dispatch({
            type: GET_MATCH_BY_QUEUE_REQUEST,
            data: {
                champId: selectedChampId,
                queueNum: queueNum,
                region: region,
            }
        })
    }, [selectedChampId,region]);



    return (
        <Wrapper>
            <QueueFilter
                onChangeSelected={handleQueueFilter}
                selected={selectedQueue}
            />
            <OverviewContent
                queue={selectedQueue}
            />
        </Wrapper>
    );
};

export default NewOverview;
