import React, {useCallback, useEffect} from 'react';
import styled from "styled-components";
import DownArrow from "../../../common/TagWithArrow/DownArrow";
import {useDispatch, useSelector} from "react-redux";
import {GET_CHAMPIONS_VERSIONS_REQUEST} from "../../../../store/reducer/champions";
import useQuery from "../../../../common/hooks/useQuery";

const TierSelector = styled.select`
  padding: 6px 14px;
  border-radius: 6px;
  width: ${props => props.width}px;
  height: 32px;
  font-weight: 500;
  line-height: 1.3;
  border-color: ${props => {
    let color = '#313131';
    if (props.borderColor) color = props.borderColor;
    return color;
  }};
  -webkit-appearance: none;
`;


const SelectWrapper = styled.div`
  position: relative;
`;
const ArrowBox = styled.div`
  position: absolute;
  right: 12px;
  top: 14px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${SelectWrapper}:not(:last-of-type) {
    margin-right: 8px;
  }
`;

const ChampionFilter = ({borderColor, selectVersion, currentVersion, selectTier, currentTier}) => {
    const dispatch = useDispatch();
    const versions = useSelector((state) => state.champions.championVersions);
    const query = useQuery();
    const tierList = ['Platinum+',
        "Diamond+",
        "Master+",
        "Grandmaster+",
        "Challenger+"]
    const legionList = ['KR'];


    useEffect(() => {
        // if (getChampionsRankDone && versions.length === 0) {
        //     dispatch({
        //         type: GET_CHAMPIONS_VERSIONS_REQUEST, data: {
        //             cnt: 3,
        //         }
        //     })
        // }
    }, []);

    const onSelectTier = useCallback((e) => {
        if (e?.target?.value && selectTier) {
            selectTier(e.target.value)
        }
    }, [selectTier]);

    const onSelectVersion = useCallback((e) => {
        if (e?.target?.value && selectVersion) {
            selectVersion(e.target.value)
        }
    }, [selectVersion]);



    return (<Wrapper>
        <SelectWrapper>
            <TierSelector width={144} borderColor={borderColor} onChange={onSelectTier} value={currentTier}>
                {tierList.map((data, index) => (<option value={data} key={index}>{data}</option>))}
            </TierSelector>
            <ArrowBox>
                <DownArrow color={"#313131"}/>
            </ArrowBox>
        </SelectWrapper>
        <SelectWrapper>
            <TierSelector width={80} borderColor={borderColor}>
                {legionList.map((data, index) => (<option value={data} key={index}>{data}</option>))}
            </TierSelector>
            <ArrowBox>
                <DownArrow color={"#313131"}/>
            </ArrowBox>
        </SelectWrapper>
        <SelectWrapper>
            <TierSelector width={80} borderColor={borderColor} onChange={onSelectVersion} value={currentVersion}>
                {versions.map((data, index) => (<option value={data.toString()} key={index}>{data}</option>))}
            </TierSelector>
            <ArrowBox>
                <DownArrow color={"#313131"}/>
            </ArrowBox>
        </SelectWrapper>
    </Wrapper>);
};

export default ChampionFilter;