import styled from 'styled-components';

export const MultiContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  background-color: #fff;
  overflow: hidden;

  .center {
    display: flex;
    width: 1130px;
  }

  .contentCenter {
    display: flex;
    width: 1130px;
  }

  .multi_top {
    padding: 30px 0 0;
    margin-bottom: 21px;


    .info {
      margin-left: 16px;

      .box {
        width: 176px;
        background-color: #fff;
        border: 1px solid #A5BED2;
      }

      h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        background: transparent linear-gradient(98deg, #34b8a5 0%, #4174c8 100%);
        font-size: 14px;
        color: #fff;
      }

      .bg {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        text-align: center;

        p {
          font-size: 38px;
          font-weight: 700;
          color: #008e6c;

          span {
            display: inline-block;
            margin-left: 8px;
            font-size: 24px;
          }
        }
      }
    }

    .buttons {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-left: 16px;

      button {
        width: 140px;
        height: 44px;
        background-color: #F8FAFF;
        border: 1px solid #5F6695;

        border-radius: 6px;
        font-size: 16px;
        font-weight: 700;
        color: #5f6695;

        &::before {
          display: inline-block;
          margin-right: 8px;
        }

        &.ingame {
          &::before {
            content: 'LIVE';
            padding: 2px 2px 3px;
            font-size: 13px;
            font-weight: 400;
            color: #fff;
            letter-spacing: 0.08em;
            background: #eb5b5b;
            border: 1px solid #8f3535;
            border-radius: 2px;
          }
        }

        &.share {
          &::before {
            content: 'copy';
            width: 21px;
            height: 21px;
            background: url('/images/ico_copy_link.svg') no-repeat center;
            text-indent: -9999px;
          }
        }
      }

      .share_tooltip {
        position: absolute;
        top: -15px;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.85);
        border-radius: 8px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        color: #65cbff;
        transform: translateX(-50%);
      }
    }
  }

  .multi_bottom {
    display: flex;
    justify-content: flex-start;
    //max-width: 1700px;
    margin: 0px auto;
  }

  .how_to_use {
    width: 100%;
    height: fit-content;
    background-color: #fff;
    border: 1px solid #a5bed2;

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      background: transparent linear-gradient(92deg, #34b8a5 0%, #4174c8 100%);
      font-size: 18px;
      font-weight: 700;
      color: #fff;
    }

    .box {

      padding: 26px 22px;

      p {
        font-size: 16px;
        color: #45496f;
        text-align: center;

        span {
          font-weight: 700;
        }
      }

      .imgs {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        max-width: 100%;

        .picks,
        .browser {
          /* height: 260px; */
        }
      }
    }
  }

  .imgEmblem {
    width: 55px;
  }

  .imgChamp {
    width: 42px;
  }
`;

export const MultiSearchContainer = styled.div`
  position: relative;
  flex: 1;
  border: 1px solid #A5BED2;

  textarea {
    width: 100%;
    height: 100%;
    padding: 4px 13px;
    resize: none;
    border: 0;
    overflow-y: auto;
    font: inherit;
    font-size: 12px;
    line-height: 1.4;

    :focus::-webkit-input-placeholder {
      color: transparent;
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #b8cddc;
    }

    ::-webkit-scrollbar-track {
      background-color: #dbe8ff;
    }
  }

  button {
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -40px;
    width: 32px;
    height: 80px;
    line-height: 28px;
    background: transparent linear-gradient(180deg, #58cbaa 0%, #37aaaa 100%);
    border: 1px solid #1B9CA5;
    border-radius: 6px;

    i {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url('/images/ico_input_serach_big_w.svg') no-repeat center;
      font-size: 0;
      text-indent: -9999px;
    }
  }
`;

export const MultiListContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border: 1px solid #a5bed2;
  font-size: 12px;
  height: fit-content;

  .row {
    position: relative;
    display: flex;
  }

  .col {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex_column1 {
    display: flex;
    flex-direction: column;
    width: 240px;
  }

  .flex_column2 {
    width: 173px;
  }

  .flex_column3 {
    display: flex;
    flex-direction: row;
    width: 485px;
  }

  .flex_column4 {
    width: 230px;
  }

  .flex_full {
    width: 100%;
    font-size: 18px;
  }

  .t_head {

    .col {
      height: 35px;
      font-weight: 500;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 1px;
        height: 8px;
        background-color: #a5bed2;
      }

      &:first-of-type {
        &::after {
          display: none;
        }
      }

      span {
        display: inline-block;
        margin-left: 6px;
        font-weight: 400;
        color: rgba(49, 49, 49, 0.8);
      }
    }
  }

  .t_body {

    .row {
      border-top: 1px solid #a5bed2;

      &:nth-of-type(even) {
        background-color: #f1f3f9;
      }

      &.complete {
        &::after {
          display: none;
        }
      }

      &.incomplete {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9999;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }

      .loading {
        position: absolute;
        z-index: 30;

        .dank-ass-loader {
          margin-top: 0;
        }
      }
    }

    .col {
      height: 130px;
    }

    .flex_column1 {
      padding-left: 14px;
      justify-content: flex-start;

      .tags {
        display: flex;
        align-content: center;

        & > span {
          display: inline-block;
          text-align: center;
          width: 38px;
          height: 18px;
          font-size: 11px;
          color: #fff;
          border-radius: 3px;
          line-height: 16px;

          & + span {
            margin-left: 5px;
          }

          &.win {
            background-color: #15b95d;
            border: 1px solid #2a9454;
          }

          &.lose {
            background-color: #e37e74;
            border: 1px solid #a85c54;
          }

          &.w4 {
            width: 46px;
          }
        }
      }

      .promos {
        max-width: 85px;

        span {
          width: 13px;
          height: 13px;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
          font-size: 0;
          text-indent: -9999px;
        }
      }

      .icons {
        width: 60px;
        text-align: center;

        p {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          padding: 2px 5px;
          background-color: #dde1ec;
          border-radius: 2px;

          span {
            display: inline-block;
            margin-left: 3px;
            font-size: 11px;
            /* text-transform: uppercase; */
          }
        }

        .duo {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 37px;
          height: 17px;
          background-color: #90d489;
          border: 1px solid #779f73;
          border-radius: 3px;
          font-size: 11px;
          font-weight: 700;
        }
      }

      .texts {
        position: relative;
        margin-left: 14px;
        line-height: 20px;

        &.tag_on {
        }

        &.tag_off {
        }

        a {
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: break-word;
          font-size: 14px;
          font-weight: bold;

          &:hover {
            text-decoration: underline;
          }
        }

        dl {
          display: flex;
          align-items: center;
        }

        .t1 {
          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: rgba(49, 49, 49, 0.4);

            span {
              display: inline-block;
              margin-right: 4px;
              font-size: 14px;
              font-weight: 700;
            }
          }

          dd {
            margin-left: 3px;
          }
        }

        .t2 {
          font-size: 10px;
          color: rgba(49, 49, 49, 1);

          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: rgba(49, 49, 49, 0.6);
            margin-right: 5px;

            span {
              display: inline-block;
              margin-right: 2px;
              font-size: 14px;
              font-weight: 700;
              color: #259a7e;
            }
          }

          dd {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;
            font-size: 12px;
            color: rgba(49, 49, 49, 0.6);

            span {
              display: inline-block;
              margin-right: 2px;
              color: rgba(49, 49, 49, 1);
            }
          }
        }
      }
    }

    .flex_column2 {
      padding: 0 14px;
      text-align: center;

      .aiscore {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;

        p {
          display: flex;
          flex-direction: column;
          margin: 0 5px;
          font-size: 14px;

          span {
            display: block;
            margin-top: 6px;
            font-size: 20px;
            font-weight: 700;
          }
        }

        strong {
          display: block;
          margin-left: 8px;

          span {
            font-size: 32px;
            font-weight: 700;
          }
        }
      }
    }

    .flex_column3 {
      .match_list {
        display: flex;
        width: 100%;
      }

      .match_item {
        position: relative;
        margin-left: 2px;
      }

      .box {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 46px;
        padding: 4px;
        text-align: center;
        border: 1px solid #a5bed2;

        &:hover {
          padding: 3px;
          border-width: 2px;
        }

        &.win {
          background-color: #d7e9fe;

          &:hover {
            border-color: #5D9CEB;
            background-color: #D2E6F5;
          }
        }

        &.lose {
          background-color: #fee7e7;

          &:hover {
            border-color: #E37E74;
            background-color: #FEE7E7;
          }
        }

        .champ {
          position: relative;

          .imgLane {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: #313131;
          }
        }

        .score {
          margin: 4px 0;
          font-size: 18px;
          font-weight: 700;
        }

        .kda {
          margin-bottom: 3px;

          .perfect {
            font-size: 11px;
          }
        }

        .rating {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 12px;
            height: 9px;
            margin-right: 3px;
            margin-top: 1px;
          }

          .away {
            color: #c24a4a;
          }
        }
      }

      .time {
        margin-top: 5px;
        font-size: 10px;
        color: rgba(49, 49, 49, 0.6);
        letter-spacing: -0.01em;
        text-align: center;
      }
    }

    .flex_column4 {
      border-left: 1px solid #a5bed2;

      .float {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;

        &.close {
          .champ_list {
            li:nth-child(n+6) {
              display: none;
            }

            max-height: 130px;
          }
        }

        &.open {
          z-index: 20;
          background-color: #d2e6f5;

          .champ_list {
            max-height: 100%;
            z-index: 22;
          }

          .more button img {
            transform: rotate(180deg);
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: calc(100% + 2px);
            height: 100%;
            border: 3px solid #5d9ceb;
            box-sizing: border-box;
          }
        }
      }

      .champ_list {
        min-width: 195px;
        max-width: 195px;
        width: 100%;
        min-height: 130px;
        height: 100%;
        padding: 6px;
      }

      .champ_item {
        display: flex;
        align-items: center;
        text-align: right;

        & + .champ_item {
          margin-top: 5px;
        }
      }

      .games {
        width: 30px;
        margin-left: 8px;
      }

      .winrate {
        text-align: center;
        width: 58px;
        margin-left: 5px;
      }

      .kda {
        margin-left: 5px;
      }

      .score {
        //width: 30px;
        //margin-left: 4px;
        //font-weight: 700;
      }

      .more {
        position: relative;
        z-index: 20;
        width: 34px;

        button {
          position: relative;
          width: 100%;
          height: 100%;

          img {
            position: absolute;
            bottom: 14px;
            left: 50%;
            margin-left: -7px;
            width: 14px;
            opacity: 0.4;
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.08);
        }
      }

      .imgChamp {
        width: 26px;
      }
    }
  }
`;
