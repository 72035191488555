import React from 'react';
import ChampRenderTable from "../ChampRenderTable";
import ChampSynergy from "../ChampSynergy";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {useTranslation} from "react-i18next";




const ChampRenderBy = ({currentCategory, champCategory,onChangeSelectedSeason,selectedSeason}) => {
    const {t} = useTranslation();
    const {summonerStatus} = useSelector((state) => state.summoner);





    if (currentCategory === 0 || currentCategory === 1) {
        return (
            <ChampRenderTable
                onChangeSelectedSeason={onChangeSelectedSeason}
                selectedSeason={selectedSeason}
                currentCategory={currentCategory}
                champCategory={champCategory}
            />
        );
    } else if (currentCategory === 2 || currentCategory === 3) {
        return <ChampSynergy
            onChangeSelectedSeason={onChangeSelectedSeason}
            selectedSeason={selectedSeason}
            currentCategory={currentCategory}
            champCategory={champCategory}
        />;
    }

    return null;

};

export default ChampRenderBy;
