import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import ChampFilter from "../ChampFilter";
import ChampTable from "../../ChampTable";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import useInput from "../../../../common/hooks/useInput";
import {getNestedObject, sortChampData} from "../../../../function";

const Wrapper = styled.div`
    background: #fff;
`;

const InfoWrapper = styled.div`
  margin: 10px 10px 0 20px;
`;
const EmptyDiv = styled.div`
  width: 100%;
  height: 492px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const RenderTable = ({champCategory,currentCategory,onChangeSelectedSeason,selectedSeason}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [currentList, setCurrentList] = useInput([]);
    const [openId, setOpenId] = useState(false);
    const champions = useSelector((state) => state.imageStore.champions);
    const season = useSelector((state) => state.imageStore.season);
    const [currentRank, setCurrentRank] = useInput('total');
    const [position, onChangePostion] = useInput('All');
    const [champName, onChangeChampName] = useInput('');
    // const [currentCategory, setcurrentCategory] = useState(0);
    const currentLang = useSelector((state) => state.translation.lang);
    const [mostDamage, onChangeMoseDamage] = useInput(0);
    const [leastDamage, onChangeLeastDamage] = useInput(0);
    const {summonerStatus} = useSelector((state) => state.summoner);
    const initialSort = [
        {
            value: 'games',
            sortType: false,
        },
        {
            value: 'win_rate',
            sortType: false,
        },
        {
            value: currentLang === 'KOR' ? 'champion_name_kr' : 'champion_name_en',
            sortType: true,
        },
    ];
    const [sort, setSort] = useState({
        ...initialSort,
    });

    const [sortOrder, setSortOrder] = useState({
        games: {
            value: 'games',
            sortType: false,
        },
        win_rate: {
            value: 'win_rate',
            sortType: true,
        },
        champions: {
            value: currentLang === 'KOR' ? 'champion_name_kr' : 'champion_name_en',
            sortType: true,
        },
        kda: {
            value: 'kda',
            sortType: true,
        },
        cs_per_min: {
            value: 'cs_per_min',
            sortType: true,
        },
        gold_diff_15: {
            value: 'gold_diff_15',
            sortType: true,
        },
        damage_dealt_per_min: {
            value: 'damage_dealt_per_min',
            sortType: true,
        },
        damage_taken_per_min: {
            value: 'damage_taken_per_min',
            sortType: true,
        },
        damage_per_team: {
            value: "damage_per_team",
            sortType: true,
        },
        gold_per_team: {
            value: "gold_per_team",
            sortType: true,
        }
    });


    // const initialSortOrder = useCallback(() => {
    //     setSortOrder(sortOrder)
    // }, [])
    //
    // const initialStates = useCallback(() => {
    //     setData([]);
    //     setChampName('');
    //     setOpenId(false);
    //     // setposition('All');
    //     // setGameType({value: 'total'});
    //     // setPosition('without_position');
    //     setSortOrder(sortOrder);
    // }, [])
    //




    // const [mostDamage, onChangeMoseDamage] = useInput(0);
    function getMaxRank(rank) {
        if (typeof rank === 'string') {
            const list = ['All', "Top", "Jungle", "Middle", "Bot", "Supporter"];
            const result = list.reduce((sum, cur) => {
                const flexSRArray = getNestedObject(`counter_champion_stats.${rank}.enemy_champion_stats.${cur}`, summonerStatus);
                const result = getMinMaxValue(flexSRArray);
                sum.most = Math.max(sum.most, result.most);
                sum.least = Math.max(sum.least, result.most);
                return sum;
            }, {
                most: 0,
                least: 0,
            });
            return result;
        } else {
            return {
                most: 0,
                least: 0,
            }
        }

    }

// const [leastDamage, onChangeLeastDamage] = useInput(0);
    useEffect(() => {
        if (summonerStatus?.counter_champion_stats) {
            const value = getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.enemy_champion_stats.${position}`, summonerStatus);
            setCurrentList(value);

            if (Array.isArray(value) && value.length > 0) {


                const flexSR = getMaxRank('ranked_flex_sr');
                const rankSolo = getMaxRank('ranked_solo_5x5');

                const maxMost = Math.max(flexSR.most, rankSolo.most);
                const maxLeast = Math.max(flexSR.most, rankSolo.most);
                onChangeMoseDamage(maxMost);
                onChangeLeastDamage(maxLeast);
            }

        }
    }, [summonerStatus]);


    useEffect(() => {
        // setcurrentCategory(currentCategory);
        if(typeof currentCategory === 'number') {
            const value = getNestedObject(`${champCategory[currentCategory].value}.total.enemy_champion_stats.All`, summonerStatus);
            setCurrentList(value);
            resetState();
            // initialStates()
        }

    }, [currentCategory]);

    const handleInput = useCallback((e) => {
        const value = e?.target?.value;
        if (typeof value === 'string') {
            onChangeChampName(value);

            const result = getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.enemy_champion_stats.${position}`, summonerStatus);
            const regexp = new RegExp(`${value}`);

            if (value.length > 0) {

                onChangeCurrentList(result.filter((data) => {
                    const findChamp = champions.find((champ) => Number(champ.championId) === Number(data.champion_id));
                    if (findChamp) {
                        return (regexp.test(findChamp.champion_name_kr) || regexp.test(findChamp.champion_name_en))
                    } else {
                        return false;
                    }

                }));

            } else {
                onChangeCurrentList(result);
            }

        }
    }, [currentCategory, currentRank, position, summonerStatus, champCategory])

    const handleGameType = useCallback((value) => {

        if (typeof value === 'string') {
            setCurrentRank(value);
            const result = getNestedObject(`${champCategory[currentCategory].value}.${value}.enemy_champion_stats.${position}`, summonerStatus);
            onChangeCurrentList(result);
            resetSort();
        }
    }, [currentCategory, position, currentCategory, currentRank]);

    // const [positionState, setpositionState] = useState('without_position');
    //
    //     const [currentRank, setCurrentRank] = useInput('total');
    //     const [position, onChangePostion] = useInput('All');
    //     const [champName, onChangeChampName] = useInput('');


    const resetSort = useCallback(() => {
        const copySortData = {...sortOrder}
        const allOff = allOffObject(copySortData);
        allOff.games.sortType = false;
        setSortOrder(allOff);
        setSort({...initialSort})
    }, [sortOrder]);

    const resetState = useCallback(() => {
        onChangePostion("All");
        onChangeChampName("");
        setCurrentRank('total');
        setOpenId(false);
        resetSort();
    }, [])

    const handleReset = useCallback(() => {
        onChangePostion("All");
        onChangeChampName("");
        setOpenId(false);
        const value = getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.enemy_champion_stats.All`, summonerStatus);
        onChangeCurrentList(value)
    }, [currentCategory, currentRank]);

    const _withPositionAll = useCallback(() => {
        const object = getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.enemy_champion_stats.All`, summonerStatus);
        return object;
    }, [champCategory, currentCategory, currentRank])

    const onChangeCurrentList = useCallback((value) => {
        if (Array.isArray(value)) {
            const soredChampData = sortChampData(value, sort);
            const addChampionName = soredChampData.map((data) => {

                return data
            }) ;
            setCurrentList(sortChampData(value, sort))
        }
    }, [sort])

    //with_position
    //without_position
    const handlePosition = useCallback((value) => {
        const result = _withPositionAll();
        onChangeCurrentList(result);
    }, [currentCategory, currentRank]);


    const handeLane = useCallback((value) => {

        if (typeof value === 'string') {
            onChangePostion(value);

            if (value === 'All') {
                const result = _withPositionAll();
                onChangeCurrentList(result);
            } else {
                const result = getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.enemy_champion_stats.${value}`, summonerStatus);
                onChangeCurrentList(result.map((data) => ({...data, lane: value})));
            }
        }
        resetSort();
    }, [currentCategory, currentRank]);


    const allOffObject = useCallback((copyData) => {
        if (typeof copyData === 'object') {
            const copy = {...copyData};
            Object.keys(copy).forEach((key) => {
                copy[key].sortType = true;
            })
            return copy;
        }
        return copyData;
    }, [])

    const handleSort = useCallback((data) => {

        if (typeof data === 'object') {
            const copyData = {...data};
            const copySortData = {...sortOrder};
            if (copyData) {
                if (copySortData[copyData.value]) {
                    const allOff = allOffObject(copySortData);
                    copyData.sortType = !copyData.sortType;
                    allOff[copyData.value].sortType = copyData.sortType;
                    setSort([{...copyData}]);
                    setSortOrder(copySortData);
                }
            }
        }
    }, [sortOrder]);

    useEffect(() => {
        setCurrentList((prev) => onChangeCurrentList(prev, sortOrder));
    }, [sortOrder])


    function getMinMaxValue(array) {
        if (Array.isArray(array) && array.length > 0) {
            const result = array.reduce((sum, cur) => {
                const versus = cur.stats_by_enemy.reduce((versusSum, versusCur) => {
                    versusSum.most = Math.max(versusSum.most, Number(versusCur.damage_dealt_per_min))
                    versusSum.least = Math.max(versusSum.least, Number(versusCur.damage_taken_per_min))
                    return versusSum;
                }, {
                    most: 0,
                    least: 0,
                });
                const mostValue = Math.max(Number(cur.damage_dealt_per_min), versus.most);
                const leastValue = Math.max(Number(cur.damage_dealt_per_min), versus.least)
                sum.most = Math.max(sum.most, Number(mostValue))
                sum.least = Math.max(sum.least, Number(leastValue))
                return sum;
            }, {
                most: 0,
                least: 0
            });
            return result;
        } else {
            return {most: 0, least: 0}

        }

    }


    return (
        <Wrapper>
            <InfoWrapper>
                <ChampFilter
                    onChangeSelectedSeason={onChangeSelectedSeason}
                    selectedSeason={selectedSeason}
                    summonerStautsLength={Object.values(summonerStatus).length}
                    season={season}
                    champName={champName}
                    handleInput={handleInput}
                    laneInfo={getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.position_rate`, summonerStatus)}
                    onChangePosition={handeLane}
                    selectedLane={position}
                    selectedRank={currentRank}
                    handleGameType={handleGameType}
                    handleReset={handleReset}
                />
            </InfoWrapper>
            {Object.values(summonerStatus).length === 0 ?
                <EmptyDiv>{t('notice.noData')}</EmptyDiv>
                :
                <ChampTable
                    sort={sort}
                    mostDamage={mostDamage}
                    leastDamage={leastDamage}
                    initialSort={initialSort}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    setOpenId={setOpenId}
                    openId={openId}
                    data={currentList}
                    handleSort={handleSort}/>}
        </Wrapper>
    );
};

export default RenderTable;
