import React, {useEffect, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {useTranslation} from 'react-i18next';

import {Footer, Header} from '../../components';
import BookmarkList from '../common/BookmarkList';
import SearchList from '../common/SearchList';
import {useTitle} from '../../function';
import {HomeContainer} from '../../styled/HomeStyle';
import {GoogleAdvertise, Notice} from '../common';
import {useDispatch, useSelector} from "react-redux";
import useQuery from "../../common/hooks/useQuery";
import {translation} from "../../store/modules/translation";
import styled from "styled-components";
import HomeBanner from "./HomeBanner";
import ServerStatusErrorbox from "../common/Error/StatusError";
import NewSearch from "../common/NewSearch";


const AdSection = styled.div`
  margin-top: 3rem;
  width: 620px;
  height: 90px;
`;

const BannerWrapper = styled.div`
  margin: 5rem 0 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const Home = () => {
    const {t, i18n} = useTranslation();
    const titleUpdate = useTitle('Loading...');
    const query = useQuery();
    const dispatch = useDispatch();
    const [openFeedback, setOpenFeedback] = useState(false);
    setTimeout(() => titleUpdate(t('header.title')), 500);


    useEffect(() => {
        const lang = query.get('hl');
        if (lang) {
            i18n.changeLanguage(lang);
            dispatch(translation(lang));
        }
    }, []);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);


    return (
        <>

            <Header
                isHome={true}
                setOpenView={setOpenFeedback}
                openView={openFeedback}
            />
            <Notice fixed={true}/>
            <ServerStatusErrorbox/>
            <HomeContainer>
                <div className="home">
                    <h2>
                        <img src="/images/ico_logo_w.svg" alt="DEEPLOL"/>
                    </h2>
                    <BannerWrapper>
                        <HomeBanner/>
                    </BannerWrapper>
                    <NewSearch home/>
                    <Tabs>
                        <TabList>
                            <Tab>{t('buttons.recentSearch')}</Tab>
                            <Tab>{t('buttons.favorite')}</Tab>
                        </TabList>
                        <TabPanel>
                            <SearchList/>
                        </TabPanel>
                        <TabPanel>
                            <BookmarkList/>
                        </TabPanel>
                    </Tabs>
                    <AdSection>

                    </AdSection>
                </div>
            </HomeContainer>
            <Footer
                setOpenFeedback={setOpenFeedback}
            />

        </>
    );

};

export default Home;
