import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {ChartAnalysis, ImageChamp, ImageMonster} from '../../components';
import {getFormatTime, getNeGa, getWinrateCalc, scoreColor} from '../../function';
import {ChartModalContainer} from '../../styled/SummonerStyle';
import ChampionImageWithLane from "../common/ChampionImageWithLane";

const ChartModal = ({
                        modalData,
                        modalOpen,
                        handleModalClose,
                        handleModalIndex,
                        info,
                        originArr,
                        myMatch,
                        inferenceData,
                    }) => {
    const {t} = useTranslation();
    const currentLang = useSelector((state) => state.translation.lang);
    const [chartModalInfo,setChartModalInfo] = useState(modalData);
    // {modalIndex, eventArr, detailEvent, concatEvent} = modalData;
    const handleModalFunction = (data) => {
        setChartModalInfo(data)
    }
    const [data, setData] = useState({});
    const [mapLocation] = useState([
        {BLUE_BOT_LANE_NEXUS_TURRET: {x: 42.29, y: 240.78}},
        {BLUE_TOP_LANE_NEXUS_TURRET: {x: 34.39, y: 232.32}},
        {BLUE_MID_LANE_BASE_TURRET: {x: 69.43, y: 206.25}},
        {BLUE_TOP_LANE_BASE_TURRET: {x: 23.73, y: 195.45}},
        {BLUE_BOT_LANE_BASE_TURRET: {x: 81.03, y: 250.9}},
        {BLUE_MID_LANE_INNER_TURRET: {x: 95.15, y: 185.85}},
        {BLUE_TOP_LANE_INNER_TURRET: {x: 30.05, y: 151.36}},
        {BLUE_BOT_LANE_INNER_TURRET: {x: 129.6, y: 246.7}},
        {BLUE_MID_LANE_OUTER_TURRET: {x: 109.85, y: 156.9}},
        {BLUE_TOP_LANE_OUTER_TURRET: {x: 20.27, y: 82.96}},
        {BLUE_BOT_LANE_OUTER_TURRET: {x: 195.61, y: 255.0}},
        {BLUE_MID_LANE_INHIBITOR: {x: 61.18, y: 215.17}},
        {BLUE_TOP_LANE_INHIBITOR: {x: 23.77, y: 208.54}},
        {BLUE_BOT_LANE_INHIBITOR: {x: 65.77, y: 251.21}},
        {RED_TOP_LANE_NEXUS_TURRET: {x: 234.41, y: 34.66}},
        {RED_BOT_LANE_NEXUS_TURRET: {x: 242.53, y: 43.28}},
        {RED_MID_LANE_BASE_TURRET: {x: 207.21, y: 68.96}},
        {RED_TOP_LANE_BASE_TURRET: {x: 195.19, y: 24.31}},
        {RED_BOT_LANE_BASE_TURRET: {x: 253.06, y: 80.57}},
        {RED_MID_LANE_INNER_TURRET: {x: 182.04, y: 88.96}},
        {RED_TOP_LANE_INNER_TURRET: {x: 148.46, y: 28.68}},
        {RED_BOT_LANE_INNER_TURRET: {x: 247.59, y: 123.45}},
        {RED_MID_LANE_OUTER_TURRET: {x: 167.09, y: 118.26}},
        {RED_TOP_LANE_OUTER_TURRET: {x: 81.71, y: 20.2}},
        {RED_BOT_LANE_OUTER_TURRET: {x: 257.51, y: 191.46}},
        {RED_MID_LANE_INHIBITOR: {x: 215.76, y: 60.56}},
        {RED_TOP_LANE_INHIBITOR: {x: 209.55, y: 23.83}},
        {RED_BOT_LANE_INHIBITOR: {x: 252.69, y: 66.97}},
    ]); //타워,억제기 좌표
    const modalRef = useRef();

    const initialFunc = useCallback(() => {
        chartModalInfo.eventArr.forEach((item, index) => {
            if (chartModalInfo.modalIndex === index) {
                // 이벤트 배열 만들기
                let eventArr = [];
                for (let i = 0; i < item.length; i++) {
                    eventArr.push(chartModalInfo.detailEvent[item[i]]);
                }

                // 이벤트 객체 만들기
                let eventObj = {};
                Object.values(chartModalInfo.concatEvent).forEach((itm) => {
                    if (JSON.stringify(item) === JSON.stringify(itm.detail_index_set)) {
                        eventObj = itm;
                    }
                });

                // 최소,최대 시간
                let time = [];
                eventArr.forEach((itm) => {
                    time.push(itm.time);
                });

                // 데이터
                let dataBinding = {
                    event: eventArr,
                    team: {...eventObj},
                    minTime: time[0],
                    maxTime: time[time.length - 1],
                    total: {
                        B_Bot: 0,
                        B_Jun: 0,
                        B_Mid: 0,
                        B_Sup: 0,
                        B_Top: 0,
                        R_Bot: 0,
                        R_Jun: 0,
                        R_Mid: 0,
                        R_Sup: 0,
                        R_Top: 0,
                    },
                };

                // 교전 정보 데이터 가공
                dataBinding.event.forEach((itm) => {
                    // 이벤트 점수 합계
                    dataBinding.total.B_Bot += itm.B_Bot;
                    dataBinding.total.B_Jun += itm.B_Jun;
                    dataBinding.total.B_Mid += itm.B_Mid;
                    dataBinding.total.B_Sup += itm.B_Sup;
                    dataBinding.total.B_Top += itm.B_Top;
                    dataBinding.total.R_Bot += itm.R_Bot;
                    dataBinding.total.R_Jun += itm.R_Jun;
                    dataBinding.total.R_Mid += itm.R_Mid;
                    dataBinding.total.R_Sup += itm.R_Sup;
                    dataBinding.total.R_Top += itm.R_Top;
                });

                // 승패 데이터 가공
                originArr.forEach((itm, idx) => {
                    // 블루팀
                    if (idx === 0) dataBinding.blueWin = itm.win;
                    //레드팀
                    if (idx === 5) dataBinding.redWin = itm.win;

                    // 팀,라인
                    let sideLane = '';
                    if (itm.side === 'blue') {
                        sideLane = 'B_';
                    } else if (itm.side === 'red') {
                        sideLane = 'R_';
                    }
                    if (itm.lane === 'Top') {
                        sideLane += 'Top';
                    } else if (itm.lane === 'Jungle') {
                        sideLane += 'Jun';
                    } else if (itm.lane === 'Middle') {
                        sideLane += 'Mid';
                    } else if (itm.lane === 'Bot') {
                        sideLane += 'Bot';
                    } else if (itm.lane === 'Supporter') {
                        sideLane += 'Sup';
                    }
                    itm.sideLane = sideLane;

                    // 이벤트 마지막 시점의 챔피언레벨
                    itm.skill_seq.forEach((fitm, fidx) => {
                        if (fitm.skillTime <= dataBinding.maxTime) {
                            itm.eventLevel = fidx + 1;
                        }
                    });
                });

                setData(dataBinding);
            }
        });
        modalRef.current.focus();
    }, [chartModalInfo, originArr]);

    const handleModalPrev = () => {
        chartModalInfo.modalIndex > 1
        ? setChartModalInfo((prev) => ({...prev, modalIndex : prev.modalIndex - 1})) : alert(t('modal.alertFirst'));
    };

    const handleModalNext = () => {
        chartModalInfo.modalIndex < chartModalInfo.eventArr.length - 1
            ? setChartModalInfo((prev) => ({...prev, modalIndex : prev.modalIndex  + 1}))
            : alert(t('modal.alertLast'));
    };

    const handleKeyboardArrow = (e) => {
        e.keyCode === 37 && handleModalPrev();
        e.keyCode === 39 && handleModalNext();
    };

    useEffect(() => {
        initialFunc();
    }, [initialFunc]);


    return (
        <ChartModalContainer className={modalOpen ? 'openModal' : 'closeModal'}>
            <div className="dimm" onClick={() => handleModalClose()}></div>
            <div className="buttons" tabIndex={0} ref={modalRef} onKeyUp={handleKeyboardArrow}>
                <button className="prev" onClick={handleModalPrev}></button>
                <button className="next" onClick={handleModalNext}></button>
            </div>
            {Object.values(data).length !== 0 && (
                <div className="modal" onFocus={() => modalRef.current.focus()}>
                    <div className="description">
                        <p>{t('modal.keyboard')}</p>
                    </div>
                    <div className="title">
                        <h3>
                            {t('modal.whoWillWin')}
                            <span>
                ({getFormatTime(data.minTime)} ~ {getFormatTime(data.maxTime)})
              </span>
                        </h3>
                        <div className="winrate">
                            {/* 블루팀 */}
                            <div className={`team ${data.blueWin ? 'win' : 'lose'}`}>
                                <div
                                    className={`updown ${
                                        getNeGa(data.team.win_rate_diff) === 'positive' ? 'positive' : 'negative'
                                    }`}
                                >
                                    (<i></i>
                                    {Math.abs(data.team.win_rate_diff.toFixed(1))}%)
                                </div>
                                <h4>
                                    {getWinrateCalc(data.team.win_rate, 'blue').toFixed(0)}
                                    <span>%</span>
                                </h4>
                            </div>
                            {/* 우세도 */}
                            <div className="woose_bar">
                                <div
                                    className={`bar ${data.blueWin ? 'win' : 'lose'}`}
                                    style={{width: `${getWinrateCalc(data.team.win_rate, 'blue').toFixed(1)}%`}}
                                ></div>
                                <div className="center"></div>
                                <div
                                    className={`bar ${data.redWin ? 'win' : 'lose'}`}
                                    style={{width: `${getWinrateCalc(data.team.win_rate, 'red').toFixed(1)}%`}}
                                ></div>
                                <div className="graduation">
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                </div>
                            </div>
                            {/* 레드팀 */}
                            <div className={`team ${data.redWin ? 'win' : 'lose'}`}>
                                <h4>
                                    {getWinrateCalc(data.team.win_rate, 'red').toFixed(0)}
                                    <span>%</span>
                                </h4>
                                <div
                                    className={`updown ${
                                        getNeGa(data.team.win_rate_diff) === 'positive' ? 'negative' : 'positive'
                                    }`}
                                >
                                    (<i></i>
                                    {Math.abs(data.team.win_rate_diff.toFixed(1))}%)
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="result">
                        {/* 블루팀 */}
                        <div className={`team blue ${data.blueWin ? 'win' : 'lose'}`}>
                            <h4>
                                {data.blueWin ? t('summoner.win') : t('summoner.lose')}
                                {currentLang === 'KOR' && t('summoner.team')}
                            </h4>
                            <ul>
                                {originArr.map(
                                    (item) =>
                                        item.side === 'blue' && (
                                            <li key={item.frameId}>
                        <span
                            className={`champ ${
                                item.summonerName === info.summonerName ? 'is_mine' : 'not_mine'
                            }`}
                        >
                          <ChampionImageWithLane champion_id={item.championId}/>
                          <i>{item.eventLevel}</i>
                        </span>
                                                <span className="score">
                          {scoreColor(data.team[item.sideLane].toFixed(0))}
                        </span>
                                                <span className={`updown ${getNeGa(data.total[item.sideLane])}`}>
                          (<i></i>
                                                    {Math.abs(data.total[item.sideLane]).toFixed(1)})
                        </span>
                                            </li>
                                        )
                                )}
                            </ul>
                        </div>
                        {/* 레드팀 */}
                        <div className={`team red ${data.redWin ? 'win' : 'lose'}`}>
                            <h4>
                                {data.redWin ? t('summoner.win') : t('summoner.lose')}
                                {currentLang === 'KOR' && t('summoner.team')}
                            </h4>
                            <ul>
                                {originArr.map(
                                    (item) =>
                                        item.side === 'red' && (
                                            <li key={item.frameId}>
                        <span
                            className={`champ ${
                                item.summonerName === info.summonerName ? 'is_mine' : 'not_mine'
                            }`}
                        >
                          <ChampionImageWithLane champion_id={item.championId}/>
                          <i>{item.eventLevel}</i>
                        </span>
                                                <span className="score">
                          {scoreColor(data.team[item.sideLane].toFixed(0))}
                        </span>
                                                <span className={`updown ${getNeGa(data.total[item.sideLane])}`}>
                          (<i></i>
                                                    {Math.abs(data.total[item.sideLane]).toFixed(1)})
                        </span>
                                            </li>
                                        )
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="view">
                        <ul className="event_box">
                            {data.event.map((item, index) => {
                                return (
                                    <li
                                        className={`event_item ${
                                            item.event.side === 'B'
                                                ? data.blueWin
                                                    ? 'win'
                                                    : 'lose'
                                                : data.redWin
                                                    ? 'win'
                                                    : 'lose'
                                        }`}
                                        key={index}
                                    >
                                        {/* 이벤트 타입 종류 : Champion, Tower, Monster, Item */}
                                        <div className="event_column1">
                                            <span className="num">{index + 1}</span>
                                            <span className="time">{getFormatTime(item.time)}</span>
                                        </div>
                                        <div className="event_column2">
                                            <ul className="list">
                                                {originArr.map((user) => {
                                                    if (item.event.type === 'Champion' || item.event.type === 'Tower') {
                                                        // 이벤트 타입이 챔피언, 타워 일때
                                                        return item.event.assists.map(
                                                            (assist) =>
                                                                user.sideLane === assist && (
                                                                    <li key={user.frameId}>
                                                                        <div
                                                                            className={`champ ${
                                                                                user.summonerName === info.summonerName
                                                                                    ? 'is_mine'
                                                                                    : 'not_mine'
                                                                            }`}
                                                                        >
                                                                            <ChampionImageWithLane champion_id={user.championId}/>
                                                                            <i>{user.eventLevel}</i>
                                                                        </div>
                                                                        <span
                                                                            className={`updown ${getNeGa(item[user.sideLane])}`}>
                                      (<i></i>
                                                                            {Math.abs(item[user.sideLane]).toFixed(1)})
                                    </span>
                                                                    </li>
                                                                )
                                                        );
                                                    } else {
                                                        // 이벤트 타입이 몬스터, 아이템 일때
                                                        return false;
                                                    }
                                                })}
                                            </ul>
                                            {item.event.killer !== '' ? (
                                                // 이벤트 킬러가 챔피언, 타워 일때
                                                originArr.map(
                                                    (user) =>
                                                        user.sideLane === item.event.killer && (
                                                            <div className="killer" key={user.frameId}>
                                                                <div
                                                                    className={`champ ${
                                                                        user.summonerName === info.summonerName ? 'is_mine' : 'not_mine'
                                                                    }`}
                                                                >
                                                                    <ChampionImageWithLane champion_id={user.championId}/>
                                                                    <i>{user.eventLevel}</i>
                                                                </div>
                                                                <span className={`updown ${getNeGa(item[user.sideLane])}`}>
                                  (<i></i>
                                                                    {Math.abs(item[user.sideLane]).toFixed(1)})
                                </span>
                                                            </div>
                                                        )
                                                )
                                            ) : (
                                                <div className="killer">
                                                    <div className="champ">
                                                        {'B' === item.event.side ? (
                                                            <img
                                                                src="/images/icon_popup_turret_blue_square.png"
                                                                className="imgChamp"
                                                                alt="블루 타워"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/images/icon_popup_turret_red_square.png"
                                                                className="imgChamp"
                                                                alt="레드 타워"
                                                            />
                                                        )}
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                            {item.event.killer === 'None' && (
                                                // 이벤트 킬러가 미니언 일때
                                                <div className="killer">
                                                    <div className="champ">
                                                        {'B' === item.event.side ? (
                                                            <img
                                                                src="/images/icon_popup_bluemelee_square.png"
                                                                className="imgChamp"
                                                                alt="블루 미니언"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/images/icon_popup_redmelee_square.png"
                                                                className="imgChamp"
                                                                alt="레드 미니언"
                                                            />
                                                        )}
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                            {item.event.type === 'Item' &&
                                            // 이벤트 타입이 아이템 일때
                                            originArr.map(
                                                (user) =>
                                                    user.sideLane === item.event.user && (
                                                        <div className="killer" key={user.frameId}>
                                                            <div
                                                                className={`champ ${
                                                                    user.summonerName === info.summonerName ? 'is_mine' : 'not_mine'
                                                                }`}
                                                            >
                                                                <ChampionImageWithLane champion_id={user.championId}/>
                                                                <i>{user.eventLevel}</i>
                                                            </div>
                                                            <span className={`updown ${getNeGa(item[user.sideLane])}`}>
                                  (<i></i>
                                                                {Math.abs(item[user.sideLane]).toFixed(1)})
                                </span>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                        <div className="event_column3">
                                            <div className="vs"></div>
                                        </div>
                                        <div className="event_column4">
                                            {item.event.type === 'Champion' &&
                                            originArr.map(
                                                (user) =>
                                                    user.sideLane === item.event.victim && (
                                                        <div className="target" key={user.frameId}>
                                                            <div
                                                                className={`champ ${
                                                                    user.summonerName === info.summonerName ? 'is_mine' : 'not_mine'
                                                                }`}
                                                            >
                                                                <ChampionImageWithLane champion_id={user.championId}/>
                                                                <i>{user.eventLevel}</i>
                                                            </div>
                                                            <span className={`updown ${getNeGa(item[user.sideLane])}`}>
                                  (<i></i>
                                                                {Math.abs(item[user.sideLane]).toFixed(1)})
                                </span>
                                                        </div>
                                                    )
                                            )}
                                            {item.event.type === 'Tower' && (
                                                <div className="target">
                                                    <div className="champ">
                                                        {'B' !== item.event.side ? (
                                                            <>
                                                                {item.event.target.indexOf('Turret') !== -1 ? (
                                                                    <img
                                                                        src="/images/icon_popup_turret_blue_square.png"
                                                                        className="imgChamp"
                                                                        alt="블루 타워"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="/images/icon_popup_inhibitor_blue_square.png"
                                                                        className="imgChamp"
                                                                        alt="블루 억제기"
                                                                    />
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {item.event.target.indexOf('Turret') !== -1 ? (
                                                                    <img
                                                                        src="/images/icon_popup_turret_red_square.png"
                                                                        className="imgChamp"
                                                                        alt="레드 타워"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="/images/icon_popup_inhibitor_red_square.png"
                                                                        className="imgChamp"
                                                                        alt="레드 억제기"
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                            {item.event.type === 'Monster' && (
                                                <div className="target">
                                                    <div className="champ">
                                                        <ImageMonster obj={item.event.target} noIcon={true}/>
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                            {item.event.type === 'Item' && (
                                                <div className="target">
                                                    <div className="champ">
                                                        <ImageMonster obj={item.event.item} noIcon={true}/>
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="map_box">
                            {/* 블루팀 win */}
                            {data.blueWin && (
                                <>
                  <span className="mini icon" style={{left: '28px', top: '250px'}}>
                    <img src="/images/icon_blue_nexus.svg" alt="blue_nexus"/>
                  </span>
                                    <span className="mini icon" style={{left: '250px', top: '28px'}}>
                    <img src="/images/icon_red_nexus.svg" alt="red_nexus"/>
                  </span>
                                    {mapLocation.map((item, index) => {
                                        const targetName = Object.keys(item)[0];
                                        const coordinate = Object.values(item)[0];
                                        return (
                                            <span
                                                className={`mini icon`}
                                                key={index}
                                                style={{
                                                    left: `${coordinate.x}px`,
                                                    top: `${coordinate.y}px`,
                                                }}
                                            >
                        {data.event[0].building_list[index] === 1 && (
                            <Structure targetName={targetName} team="blue"/>
                        )}
                      </span>
                                        );
                                    })}
                                </>
                            )}
                            {/* 레드팀 win */}
                            {data.redWin && (
                                <>
                  <span className="mini icon" style={{left: '28px', top: '250px'}}>
                    <img src="/images/icon_red_nexus.svg" alt="red_nexus"/>
                  </span>
                                    <span className="mini icon" style={{left: '250px', top: '28px'}}>
                    <img src="/images/icon_blue_nexus.svg" alt="blue_nexus"/>
                  </span>
                                    {mapLocation.map((item, index) => {
                                        const targetName = Object.keys(item)[0];
                                        const coordinate = Object.values(item)[0];
                                        return (
                                            <span
                                                className={`mini icon`}
                                                key={index}
                                                style={{
                                                    left: `${coordinate.x}px`,
                                                    top: `${coordinate.y}px`,
                                                }}
                                            >
                        {data.event[0].building_list[index] === 1 && (
                            <Structure targetName={targetName} team="red"/>
                        )}
                      </span>
                                        );
                                    })}
                                </>
                            )}
                            {data.event.map((item, index) => {
                                if (item.event.type !== 'Tower') {
                                    return (
                                        <span
                                            className={`mini num ${
                                                item.event.side === 'B'
                                                    ? data.blueWin
                                                        ? 'win'
                                                        : 'lose'
                                                    : data.redWin
                                                        ? 'win'
                                                        : 'lose'
                                            }`}
                                            key={index}
                                            style={{left: `${item.position.x}px`, top: `${item.position.y}px`}}
                                        >
                      {index + 1}
                    </span>
                                    );
                                } else {
                                    return (
                                        <span
                                            className="mini destruction"
                                            key={index}
                                            style={{left: `${item.position.x}px`, top: `${item.position.y}px`}}
                                        >
                      <img src="/images/icon_destruction.svg" alt="destruction"/>
                    </span>
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div className="chart">
                        <ChartAnalysis
                            handleModalFunction={handleModalFunction}
                            inferenceData={inferenceData}
                            info={info}
                            myMatch={myMatch}
                            originArr={originArr}
                            inModal={true}
                            inModalTime={data.team.time}
                        />
                    </div>
                </div>
            )}
        </ChartModalContainer>
    );
};

const Structure = ({targetName, team}) => {
    if (targetName.toLowerCase().indexOf(team) !== -1) {
        if (targetName.toLowerCase().indexOf('turret') !== -1) {
            return <img src="/images/icon_blue_turret.svg" alt="blue_turret"/>;
        } else {
            return <img src="/images/icon_blue_inhibitor.svg" alt="blue_inhibitor"/>;
        }
    } else {
        if (targetName.toLowerCase().indexOf('turret') !== -1) {
            return <img src="/images/icon_red_turret.svg" alt="red_turret"/>;
        } else {
            return <img src="/images/icon_red_inhibitor.svg" alt="red_inhibitor"/>;
        }
    }
};

export default ChartModal;
