import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #A5BED2;
  background-color: #fff;
  min-height: 20vh;
`;

const ChampionsWhiteWrapper = ({children}) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default ChampionsWhiteWrapper;