import React from 'react';
import SummaryContent from "../index";
import styled from "styled-components";
import {scoreColor} from "../../../../../function";
import WinLoseText from "../WinloseText";

const WinWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RatioText = styled.span`
  font-size: 22px;
  font-weight: bold;
`;

const SmallPercent = styled.span`
  font-size: 12px;
  color: #313131;
  opacity: 0.6;
`;

const TextWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
`;
const SummaryContentWinrate = ({title, rate = 0,win,lost}) => {
    return (
        <SummaryContent
            title={title}>
            <WinWrapper>
                <RatioText>
                    {scoreColor(rate.toFixed(1))}<SmallPercent> %</SmallPercent>
                </RatioText>
                <TextWrapper>
                    <WinLoseText
                        win={win}
                        lost={lost}
                    />
                </TextWrapper>
            </WinWrapper>
        </SummaryContent>
    );
};

export default SummaryContentWinrate;