import React, {useCallback} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0px 2px 2px #00000040;
  border: 1px solid #5C628D;
  height: 30px;
  margin-right: 8px;
`;

const ButtonText = styled.span`
  margin: 0 0 0 2px;
  color: #5F6695;
  font-size: 14px;
  font-weight: bold;
  width: 16ch;
`;

const DetailHideSummonerName = ({onChangeHide,hide}) => {
    const {t} = useTranslation();

    const onClick = useCallback(() => {
        onChangeHide();
    },[]);

    return (
        <ButtonWrapper onClick={onClick}>
            <ButtonText>{hide ? t('detailTab.show') :t('detailTab.hide')  }</ButtonText>
        </ButtonWrapper>
    );
};

export default DetailHideSummonerName;
