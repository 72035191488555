import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

import {translation} from '../../store/modules/translation';
import {_NOTICE} from "../../store/modules/notice";
import styled from "styled-components";
import {Link, NavLink} from "react-router-dom";
import HeaderSearch from "../Header/HeaderSearch";
import {getRegion} from "../../function";


const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;
const LogoImg = styled.img`
  margin-top: 6px;
  width: 125px;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContentWrapper = styled.div`
  .h__contactus {
    position: fixed;
    bottom: 47px;
    right: 47px;
    z-index: 50;

    img {
      cursor: pointer;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 15px;
      width: 30px;
    }
  }
`;


const LangWrapper = styled.div`
  width: 165px;

  .h__util {
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    justify-content: flex-end;

    .lang {
      position: relative;

      button {
        display: flex;
        align-items: center;
        height: 18px;
        font-size: 12px;
        color: #dfdfdf;

        &::before {
          content: '';
          display: inline-block;
          width: 17px;
          height: 17px;
          margin-top: 3px;
          margin-right: 9px;
          background: url('/images/ico_lang_w.svg') no-repeat center;
          background-size: cover;
        }

        &::after {
          content: '';
          overflow: hidden;
          display: inline-block;
          border-color: #dfdfdf transparent transparent transparent;
          border-style: solid;
          border-width: 4px;
          width: 0;
          height: 0;
          transition: all 0.3s linear;
          margin-top: 3px;
          margin-left: 9px;
        }
      }

      ul {
        position: absolute;
        z-index: 1;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        max-width: 536px;
        max-height: 140px;
        margin-top: 10px;
        padding: 10px 0;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        border: 1px solid #313131;
      }

      li {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 26px;
  z-index: 1000;
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  height: 48px;
  background: #3C466B;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
`;

const FlexWrapper = styled.div`
  flex: 1;
`;

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const NavText = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 22px;
  margin-right: 2px;
  color: #fff;
  border-bottom: 3px solid transparent;
  font-size: 14px;
  opacity: 0.85;

  &:hover {
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(96, 96, 96, 0.3) 100%);
    border-bottom: 3px solid rgba(255, 255, 255, 0.7);
    opacity: 1;
  }

  &.active {
    color: #41DFE0;
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(96, 96, 96, 0.3) 100%);
    border-bottom: 3px solid #41DFE0;
    opacity: 1;
  }`;

const TextWrapper = styled.div`
  &.active {
    color: #41DFE0;
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(96, 96, 96, 0.3) 100%);
  }
;
`;

const NavLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  
  ${NavText} {
    padding: 0 36px;
  }
`;

const NewTag = styled.span`
  position: absolute;
  top: 10px;
  right: 4px;
  background: transparent linear-gradient(112deg, #27E2C5 0%, #4B7CCE 100%) 0 0 no-repeat padding-box;
  padding: 1px 4px;
  border-radius: 3px;
  font-size: 9px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-family: Arial;
`;


const MaintainTag = styled.div`
  position: absolute;
  top: 2px;
  right: 10px;
  background: transparent linear-gradient(112deg, #EC7C51 0%, #C1436D 100%) 0 0 no-repeat padding-box;
  padding: 1px 4px;
  border-radius: 3px;
  font-size: 9px;
  color: #fff;
  font-weight: bold;
  font-family: Arial;
`;

const ScaleFont = styled.div`
  font-size: 12px;
`;


const MaxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 1130px;
`;

const BetaText = styled.span`
  text-align: center;
  margin-top: 6px;
  width: 40px;
  padding-bottom: 1px;
  background: transparent linear-gradient(90deg, #2e79bb 0%, #561dab 100%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
`;


const Header = ({initLoadingNotFound, noticeOff = false, openView, setOpenView, isHome}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [view, setView] = useState(false);
    const region = useSelector((state) => state.region.result);
    const [openLang, setOpenLang] = useState(false);
    const scroll = useRef();
    const notice = useSelector((state) => state.notice.notice);
    const currentLang = useSelector((state) => state.translation.lang);
    const [isTop, setIsTop] = useState(true);
    const [temp, setTemp] = useState(false);

    useEffect(() => {
        if (openView === true) {
            setView(openView);
            setOpenView(false);
        }
    }, [openView])


    const disaptchNotice = useCallback((data) => {
        dispatch({
            type: _NOTICE,
            data: data,
        })
    }, [])


    useEffect(() => {
        setTemp(false);
        const fetchData = () => {
            axios.get(`${process.env.REACT_APP_CACHE_API}common/riot-status?platform_id=${getRegion(region)}`).then((res) => {
                if (!res.data.status) {
                    setTemp(true);
                    disaptchNotice('alertError');
                } else if (res.data.msg !== 'server ok') {
                    setTemp(true);
                } else {
                    setTemp(false);
                }
            }).catch((e) => {
                const statusCode = e?.response?.status;
                console.log(e);
                if (statusCode === 404) {

                } else if (statusCode === 500) {

                }
            });
        };
        fetchData();
    }, [region]);

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const onChangeLang = (lang) => {
        if (lang === 'KOR') {
            i18n.changeLanguage('ko-KR');
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, 'ko-KR');
        } else if (lang === 'ENG') {
            i18n.changeLanguage('en-US');
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, 'en-US');
        }
        dispatch(translation(lang));
        setOpenLang(false);
    };

    // 언어설정 토글
    const toggleLang = () => {
        setOpenLang((prev) => !prev);
    };

    // 문의하기 열기
    const handleContactOpen = () => {
        setView(true);
    };
    // 문의하기 닫기
    const handleContactClose = () => {
        setView(false);
    };

    // 언어설정 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (openLang && (!e.current || !e.current.contains(e.target))) setOpenLang(false);
        },
        [openLang]
    );

    const scrollEvent = useCallback((e) => {
        if (document.scrollingElement.scrollTop === 0) {
            setIsTop(true);
        } else {
            if (isTop) {
                setIsTop(false);
            }
        }
    }, [isTop])

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent)
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', scrollEvent)
        };
    }, [handleClickOutside]);


    return (
        <>
            <Wrapper scroll={isTop ? 100 : 101}>
                <LogoWrapper>
                    <Link to="/">
                        <LogoImg src={'/images/deeplol_logo.svg'} width={125} height={30}/>
                    </Link>
                    <BetaText>Beta</BetaText>
                </LogoWrapper>

                <CenterWrapper>
                    <MaxWrapper>
                        <FlexWrapper>
                            <NavWrapper>
                                <NavText exact to="/">{t('header.navHome')}</NavText>
                                {/*<NavText to="/summoner">{t('header.navSummoner')}</NavText>*/}
                                    <NavText to="/champions">{t('header.navChampions')}</NavText>
                                    {/*<MaintainTag><ScaleFont>점검중</ScaleFont></MaintainTag>*/}
                                <NavLinkWrapper>
                                    <NavText to={`/ranking/${region}/all`}>{t('header.navRanking')}</NavText>
                                    <NewTag>NEW</NewTag>
                                </NavLinkWrapper>
                                <NavText to="/multi">{t('header.navMulti')}</NavText>
                            </NavWrapper>
                        </FlexWrapper>
                        <SearchWrapper>
                            {!isHome && <HeaderSearch initLoadingNotFound={initLoadingNotFound}/>}
                        </SearchWrapper>
                    </MaxWrapper>
                </CenterWrapper>
                <LangWrapper>
                    <div className="h__util">
                        <div className="lang">
                            <button onClick={toggleLang}>{currentLang}</button>
                            {openLang && (
                                <ul>
                                    <li
                                        className={`${currentLang === 'KOR' && 'selected'}`}
                                        onClick={() => onChangeLang('KOR')}
                                    >
                                        KOR
                                    </li>
                                    <li
                                        className={`${currentLang === 'ENG' && 'selected'}`}
                                        onClick={() => onChangeLang('ENG')}
                                    >
                                        ENG
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </LangWrapper>
            </Wrapper>
            <ContentWrapper>
                <div className="h__contactus">
                    {!view && (
                        <img
                            src="/images/ico_write_qa_w.svg"
                            alt="contact us open"
                            className="open"
                            onClick={handleContactOpen}
                        />
                    )}
                    {view && (
                        <img
                            src="/images/ico_search_tag_del.svg"
                            alt="contact us close"
                            className="close"
                            onClick={handleContactClose}
                        />
                    )}
                    {view && (
                        <iframe
                            title="contact-us"
                            src="https://2021.deeplol.gg/test__2.html"
                            frameBorder="0"
                            width={350}
                            height={510}
                        ></iframe>
                    )}
                </div>
            </ContentWrapper>
            {/*</HeaderContainer>*/}
        </>
    );
};

export default Header;
