import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {MultiSearchContainer} from '../../styled/MultiStyle';

const MultiSearch = ({getSummonersName, inpText}) => {
    const {t} = useTranslation();
    const [text, setText] = useState('');


    // 찾기버튼 클릭
    const handleSummonerSearch = () => {

        if (text !== "") {
            getSummonersName(text);
        }
    };

    // 소환사명 입력
    const handleSummonersName = (e) => {
        setText(e.target.value);
    };

    useEffect(() => {
        if (inpText !== "") {
            setText(inpText);
        }
    }, [inpText]);


    return (
        <MultiSearchContainer>
      <textarea
          onChange={handleSummonersName}
          placeholder={` KDF Kiin ${t('multi.placeholder')}.\n KDF Ellim ${t(
              'multi.placeholder'
          )}.\n KDF Fate ${t('multi.placeholder')}.\n KDF Teddy ${t(
              'multi.placeholder'
          )}.\n KDF Hoit ${t('multi.placeholder')}.`}
          spellCheck="false"
          value={text}
      ></textarea>
            <button onClick={handleSummonerSearch}>
                <i>{t('multi.search')}</i>
            </button>
        </MultiSearchContainer>
    );
};

export default MultiSearch;
