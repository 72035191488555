import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import ChampLane from "../ChampLane";
import RankingDropBox from "../../../common/RankingDropBox";
import {useDispatch, useSelector} from "react-redux";
import {GET_CHAMPIONS_BY_SEASON_REQUEST} from "../../../../store/reducer/summoner";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


const RankTag = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 28px;
  font-size: 12px;
  background: ${props => {
    let background = '#F0EFF5';
    if (props.selectedRank) background = '#5F57A5';
    return background;
  }};
  color: ${props => {
    let color = 'rgba(49, 49, 49, 0.6)';
    if (props.selectedRank) color = '#fff';
    return color;
  }};


  outline: ${props => {
    let px = '0px';
    if (props.selectedRank) px = '1px';
    return px;
  }} solid #353063;
  outline-offset: -1px;
  border: 1px solid #A5BED2;
  margin-right: -1px;
  z-index: ${props => {
    let zIndex = '0';
    if (props.selectedRank) zIndex = '1';
    return zIndex;
  }};
`;

const RankWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LaneTag = styled.div`
  cursor: pointer;
  min-width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${props => {
    let background = 'transparent';
    if (props.selected) background = '#6A5AAC';
    return background;
  }};
  color: ${props => {
    let color = '#6A5AAC';
    if (props.selected) color = '#fff';
    return color;
  }};
`;


const AllLangSpan = styled.span`
  font-size: 12px;
  transform: scale(0.9);
  font-weight: bold;
`;

const LaneWrapper = styled.div`
  margin-left: 10px;
  height: 28px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid #C3D1DC;
  padding: 3px;
`;


const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChampSearchInput = styled.input`
  width: 200px;
  height: 32px;
  padding-right: 22px;
  border: 1px solid #C3D1DC;
  border-radius: 3px;
  padding-left: 6px;
  background-image: url('/images/ico_search_w.svg');
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: center;
  background-size: 18px 18px;
`;

const ResetButton = styled.button`
  margin-left: 10px;
  width: 32px;
  height: 32px;
  background: #5c628d url('/images/ico_reset.svg') no-repeat center;
  border: 1px solid #474c72;
  border-radius: 3px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 0;
  text-indent: -9999px;

`;

const SeasonWrapper = styled.div`
  height: 28px;
`;


//ChampContainer 종속관계
// 12: 2022년도 프리시즌
// 13: 2022년도 시즌
// 14: 2023년도 프리시즌
// 15: 2023년도 시즌

const ChampFilter = ({
    season,
                         selectedSeason,
                         onChangeSelectedSeason,
                         summonerStautsLength,
                         handleGameType,
                         selectedRank,
                         selectedLane,
                         onChangePosition,
                         laneInfo,
                         champName,
                         handleInput,
                         handleReset
                     }) => {
    const dispatch = useDispatch();
    const summoner = useSelector((state) => state.summoner.summoner);
    const {t} = useTranslation();
    const rankList = [{
        title: t('champions.filter.total'),
        value: 'total'
    }, {
        title: t('champions.filter.r_solo'),
        value: "ranked_solo_5x5"
    }, {
        title: t('champions.filter.r_flex'),
        value: "ranked_flex_sr"
    }];


    const seasonList = [
        {
            title: t('season.preseason2022'),
            value: 12,
        },
        {
            title: t('season.season2022'),
            value: 13,
        },
        {
            title: t('season.preseason2023'),
            value: 14,
        },
        {
            title: t('season.season2023'),
            value: 15,
        }
    ]



    const onChangeSeason = useCallback((e) => {
        const value = e.target.value;
        if (value && summoner) {
            onChangeSelectedSeason(value);
            dispatch({
                type: GET_CHAMPIONS_BY_SEASON_REQUEST,
                data: {
                    puu_id: summoner.summoner_basic_info_dict.puu_id,
                    season: value,
                }
            });
        }

    }, [summoner]);


    if (summonerStautsLength === 0) return (
        <Wrapper>
            <FilterWrapper>
                <SeasonWrapper>
                    <RankingDropBox
                        onChange={onChangeSeason}
                        selected={(data) => Number(data) === Number(selectedSeason)}
                        value={(data) => seasonList.find((item) => data === item.value).value}
                        title={(data) => seasonList.find((item) => data === item.value).title}
                        list={season}
                    />
                </SeasonWrapper>
            </FilterWrapper>
        </Wrapper>
    )


    return (
        <Wrapper>
            <FilterWrapper>
                <SeasonWrapper>
                    <RankingDropBox
                        onChange={onChangeSeason}
                        selected={(data) => Number(data) === Number(selectedSeason)}
                        value={(data) => seasonList.find((item) => data === item.value).value}
                        title={(data) => seasonList.find((item) => data === item.value).title}
                        list={season}
                    />
                </SeasonWrapper>
                <RankWrapper>
                    {rankList.map((data, index) => {
                        return (
                            <RankTag
                                onClick={() => handleGameType(data.value)}
                                selectedRank={selectedRank === data.value}
                                key={index}
                            >
                                {data.title}
                            </RankTag>
                        )
                    })}
                </RankWrapper>
                <LaneWrapper>
                    <ChampLane
                        mapValue={(lane) => laneInfo[lane]?.rate?.toFixed(0)}
                        selectedLane={selectedLane}
                        laneInfo={laneInfo}
                        onChangePosition={onChangePosition}
                    />
                </LaneWrapper>
            </FilterWrapper>
            <SearchWrapper>
                <ChampSearchInput
                    type="text"
                    value={champName}
                    onChange={handleInput}
                    placeholder={t('champions.searchChampion')}
                />
                <ResetButton onClick={handleReset}>{t('champions.reset')}</ResetButton>
            </SearchWrapper>
        </Wrapper>
    );
};

export default ChampFilter;
