import {all, call, delay, fork, put, select, takeLatest} from "redux-saga/effects";
import {
    GET_CHAMPIONS_BUILD_FAILURE,
    GET_CHAMPIONS_BUILD_REQUEST,
    GET_CHAMPIONS_BUILD_SUCCESS,
    GET_CHAMPIONS_BUILD_VERSION_FAILURE,
    GET_CHAMPIONS_BUILD_VERSION_REQUEST,
    GET_CHAMPIONS_BUILD_VERSION_SUCCESS,
    GET_CHAMPIONS_PATCH_HISTORY_FAILURE,
    GET_CHAMPIONS_PATCH_HISTORY_REQUEST,
    GET_CHAMPIONS_PATCH_HISTORY_SUCCESS,
    GET_CHAMPIONS_RANK_FAILURE,
    GET_CHAMPIONS_RANK_REQUEST,
    GET_CHAMPIONS_RANK_SUCCESS,
    GET_CHAMPIONS_VERSIONS_REQUEST,
    GET_CHAMPIONS_VERSIONS_SUCCESS
} from "../reducer/champions";
import axios from "axios";

//+ == queryString에서 스페이스바로 인식됨.

const getPatchHistroy = (data) => {
    if (typeof data === 'object') {
        const getLang = data.currentLang === 'KOR' ? 'kr' : 'en';
        const url = `${process.env.REACT_APP_CACHE_API}champion/history?champion_id=${data.championId}&language=${getLang}`;
        return axios.get(url).then((result) => result.data);
    }
}

const getRankData = (action) => {
    if (typeof action === 'object') {
        const url = `${process.env.REACT_APP_CACHE_API}champion/main?platform_id=KR&tier=${encodeURIComponent(action.tier)}&game_version=${action.version}`;
        return axios.get(url).then((result) => result.data);
    }
}

const getBuildData = (data) => {
    const url = `${process.env.REACT_APP_CACHE_API}champion/build?platform_id=KR&champion_id=${data.championId}&game_version=${data.game_version}&tier=${encodeURIComponent(data.tier)}`;
    return axios.get(url).then((result) => {
        return result.data
    });
    // return testApi;
}

const getVersions = () => {
    const url = `${process.env.REACT_APP_CACHE_API}champion/version?cnt=3`;
    return axios.get(url).then((result) => result.data.game_version_list);
}


function* getStateVersion() {
    let version = yield select((state) => state.champions.championVersions);

    if (version.length === 0) {
        version = yield yield call(getVersions);
        yield put({
            type: GET_CHAMPIONS_VERSIONS_SUCCESS,
            data: {
                versions: version,
            }
        })
    }
    return version;
}


function* getChampionRank(action) {
    try {
        const version = yield getStateVersion();

        const currentVersion = action?.data?.version !== 'null' && action?.data?.version ? action?.data?.version : version[0];
        const currentTier = action?.data?.tier !== 'null' && action?.data?.tier ? action?.data?.tier : "Platinum+";


        const dataObj = {
            version: currentVersion,
            tier: currentTier,
        }

        const getData = yield call(getRankData, dataObj);

        yield put({
            type: GET_CHAMPIONS_RANK_SUCCESS,
            data: getData,
        })
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_CHAMPIONS_RANK_FAILURE,
            error: e,
        })
    }
}


function* getChampionsBuild(action) {
    try {
        const version = yield getStateVersion();

        const currentVersion = action?.data?.version !== 'null' && action?.data?.version ? action?.data?.version : version[0];
        const currentTier = action?.data?.tier !== 'null' && action?.data?.tier ? action?.data?.tier : "Platinum+";


        const obj = {
            championId: action?.data?.championId,
            game_version: currentVersion,
            tier: currentTier,
        }
        const getData = yield call(getBuildData, obj);


        yield put({
            type: GET_CHAMPIONS_BUILD_SUCCESS,
            data: {
                build: getData.position_build_list,
                championId: action.data.championId,
            },
        })

    } catch (e) {
        console.log(e)
        yield put({
            type: GET_CHAMPIONS_BUILD_FAILURE,
            error: e,
        })
    }
}

function* getChampionsVersions(action) {
    try {
        const version = yield select((state) => state.champions.championVersions);
        if (version.length === 0) {
            const getVersion = yield call(getVersions);
            yield put({
                type: GET_CHAMPIONS_VERSIONS_SUCCESS,
                data: {
                    versions: getVersion,
                }
            })
        } else {
            yield put({
                type: GET_CHAMPIONS_VERSIONS_SUCCESS,
                data: {
                    versions: version,
                }
            })
        }


    } catch (e) {

    }
}

function* getChampionsBuildVersion(action) {
    try {

        const getData = yield call(getBuildData, action.data);

        yield put({
            type: GET_CHAMPIONS_BUILD_VERSION_SUCCESS,
            data: {
                build: getData.position_build_list,
                championId: action.data.championId,
            },
        })

    } catch (e) {
        console.log(e)
        yield put({
            type: GET_CHAMPIONS_BUILD_VERSION_FAILURE,
            error: e,
        })
    }
}

function* getChampionsPatchHistory(action) {
    try {

        const getData = yield call(getPatchHistroy, action.data);
        yield put({
            type: GET_CHAMPIONS_PATCH_HISTORY_SUCCESS,
            data: {
                patchHistory: {
                    aram_patch_history : getData.aram_patch_history,
                    sr_patch_history: getData.sr_patch_history,
                },
                championId: action.data.championId,
            },
        })

    } catch (e) {
        console.log(e)
        yield put({
            type: GET_CHAMPIONS_PATCH_HISTORY_FAILURE,
            error: e,
        })
    }
}

function* watchGetChampionsRank() {
    yield takeLatest(GET_CHAMPIONS_RANK_REQUEST, getChampionRank)
}

function* watchGetChampionsBuild() {
    yield takeLatest(GET_CHAMPIONS_BUILD_REQUEST, getChampionsBuild)
}

function* watchGetChampionsBuildVersion() {
    yield takeLatest(GET_CHAMPIONS_BUILD_VERSION_REQUEST, getChampionsBuildVersion)
}

function* watchGetChampionsVersions() {
    yield takeLatest(GET_CHAMPIONS_VERSIONS_REQUEST, getChampionsVersions)
}

function* watchGetChampionsPatchHistory() {
    yield takeLatest(GET_CHAMPIONS_PATCH_HISTORY_REQUEST, getChampionsPatchHistory)
}

export default function* championsSaga() {
    yield all([
        fork(watchGetChampionsRank),
        fork(watchGetChampionsBuild),
        fork(watchGetChampionsBuildVersion),
        fork(watchGetChampionsVersions),
        fork(watchGetChampionsPatchHistory)
    ])
}
