// 액션 생성
import {
    GET_AUTO_COMPLETE_FAILURE,
    GET_AUTO_COMPLETE_REQUEST,
    GET_AUTO_COMPLETE_SUCCESS,
    GET_CHAMP_INFO_FAILURE,
    GET_CHAMP_INFO_REQUEST,
    GET_CHAMP_INFO_SUCCESS,
    GET_CHAMPIONS_BY_SEASON_FAILURE,
    GET_CHAMPIONS_BY_SEASON_REQUEST,
    GET_CHAMPIONS_BY_SEASON_SUCCESS,
    GET_GAME_ANALYTICS_PATCH_FAILURE,
    GET_GAME_ANALYTICS_PATCH_REQUEST,
    GET_GAME_ANALYTICS_PATCH_SUCCESS,
    GET_INGAME_CHECK_FAILURE,
    GET_INGAME_CHECK_REQUEST,
    GET_INGAME_CHECK_SUCCESS,
    GET_MATCH_BY_QUEUE_FAILURE,
    GET_MATCH_BY_QUEUE_REQUEST,
    GET_MATCH_BY_QUEUE_SUCCESS,
    GET_MATCH_FAILURE,
    GET_MATCH_REQUEST,
    GET_MATCH_SUCCESS,
    GET_MORE_MATCHES_FAILURE,
    GET_MORE_MATCHES_REQUEST,
    GET_MORE_MATCHES_SUCCESS,
    GET_MULTI_LIST_FAILURE,
    GET_MULTI_LIST_REQUEST,
    GET_MULTI_LIST_SUCCESS,
    GET_MULTI_LIST_UPDATE_REQUEST,
    GET_MULTI_LIST_UPDATE_SUCCESS,
    GET_MULTI_SUMMONER_LP_CHART_FAILURE,
    GET_MULTI_SUMMONER_LP_CHART_REQUEST,
    GET_MULTI_SUMMONER_LP_CHART_SUCCESS,
    GET_SHORT_LINK_FAILURE,
    GET_SHORT_LINK_REQUEST,
    GET_SHORT_LINK_SUCCESS,
    GET_SUMMONER_FAILURE,
    GET_SUMMONER_INFO_FAILURE,
    GET_SUMMONER_INFO_REQUEST,
    GET_SUMMONER_INFO_SUCCESS,
    GET_SUMMONER_LP_CHART_REQUEST,
    GET_SUMMONER_LP_CHART_SUCCESS,
    GET_SUMMONER_REQUEST,
    GET_SUMMONER_SUCCESS,
    INIT_SHORT_LINK,
    SET_SHORT_LINK_FAILURE,
    SET_SHORT_LINK_REQUEST,
    SET_SHORT_LINK_SUCCESS,
    UPDATE_SUMMONER_INFO_FAILURE,
    UPDATE_SUMMONER_INFO_REQUEST,
    UPDATE_SUMMONER_INFO_SUCCESS,
} from "../reducer/summoner";

const SUMMONER = 'summoner/SUMMONER';

// 액션 함수 내보내기
export const changeSummoner = (info) => ({type: SUMMONER, info});

// 초기 상태 값
const initialState = {
    info: {},
    summoner: {},
    summonerStatus: {},
    ingameStatus: false,
    updateStatus: {},
    tierChart: {},
    match: [],
    singleMatch: {},
    withOrigin: {},
    withOutOrigin: {},
    setShortLink: {},
    shortLink: {},
    autoComplete: {},
    multiList: [],
    multiLPChart: [],
    gameSummaryPatch: [],


    getAutoCompleteLoading: false,
    getAutoCompleteDone: false,
    getAutoCompleteError: null,

    getShortLinkLoading: false,
    getShortLinkDone: false,
    getShortLinkError: null,

    setShortLinkLoading: false,
    setShortLinkDone: false,
    setShortLinkError: null,

    getSummonerLoading: false,
    getSummonerDone: false,
    getSummonerError: null,

    getSummonerInfoLoading: false,
    getSummonerInfoDone: false,
    getSummonerInfoError: null,

    getMoreMatchesLoading: false,
    getMoreMatchesDone: false,
    getMoreMatchesError: null,

    getUpdateStatusLoading: false,
    getUpdateStatusDone: false,
    getUpdateStatusError: null,

    getChampInfoLoading: false,
    getChampInfoDone: false,
    getChampInfoError: null,

    getMatchByQueueLoading: false,
    getMatchByQueueDone: false,
    getMatchByQueueError: null,

    updateSummonerInfoLoading: false,
    updateSummonerInfoDone: false,
    updateSummonerInfoError: null,

    getMatchLoading: false,
    getMatchDone: false,
    getMatchError: null,

    getMultiListLoading: false,
    getMultiListDone: false,
    getMultiListError: null,

    getMultiListUpdateLoading: false,
    getMultiListUpdateDone: false,
    getMultiListUpdateError: null,

    getLPChartLoading : false,
    getLPChartDone : false,
    getLPChartError : false,

    getIngameCheckLoading : false,
    getIngameCheckDone : false,
    getIngameCheckError : false,

    getMultiLPChartLoading : false,
    getMultiLPChartDone : false,
    getMultiLPChartError : false,

    getChampionsBySeasonLoading : false,
    getChampionsBySeasonDone : false,
    getChampionsBySeasonError : null,

    getGameAnalyticsPatchLoading : false,
    getGameAnalyticsPatchDone : false,
    getGameAnalyticsPatchError : null,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_MATCH_REQUEST : {
            return {
                ...state,
                getMatchLoading: true,
                getMatchDone: false,
                getMatchError: null,
            }
        }
        case GET_MATCH_SUCCESS: {
            return {
                ...state,
                singleMatch: action.data,
                getMatchLoading: false,
                getMatchDone: true,
            }
        }
        case GET_MATCH_FAILURE: {
            return {
                ...state,
                getMatchLoading: false,
                getMatchError: action.error,
            }
        }
        case GET_MATCH_BY_QUEUE_REQUEST: {
            return {
                ...state,
                getMatchByQueueLoading: true,
                getMatchByQueueDone: false,
                getMatchByQueueError: null,
            }
        }
        case GET_MATCH_BY_QUEUE_SUCCESS: {

            return {
                ...state,
                match: [...action.data.match],
                getMatchByQueueLoading: false,
                getMatchByQueueDone: true,
            }
        }
        case GET_MATCH_BY_QUEUE_FAILURE: {
            return {
                ...state,
                getMatchByQueueLoading: false,
                getMatchByQueueError: action.error,
            }
        }
        case UPDATE_SUMMONER_INFO_REQUEST: {
            return {
                ...state,
                updateSummonerInfoLoading: true,
                updateSummonerInfoDone: false,
                updateSummonerInfoError: null,
            }
        }
        case UPDATE_SUMMONER_INFO_SUCCESS: {

            return {
                ...state,
                summoner: {...action.data.summoner},
                updateStatus: {...action.data.updateStatus},
                updateSummonerInfoLoading: false,
                updateSummonerInfoDone: true,
            }
        }
        case UPDATE_SUMMONER_INFO_FAILURE: {
            return {
                ...state,
                updateSummonerInfoLoading: false,
                updateSummonerInfoError: action.error,
            }
        }
        case GET_SUMMONER_INFO_REQUEST: {

            return {
                ...state,
                getSummonerInfoLoading: true,
                getSummonerInfoDone: false,
                getSummonerInfoError: null,
            }
        }
        case GET_SUMMONER_INFO_SUCCESS: {

            return {
                ...state,
                updateStatus: {...action.data.updateStatus},
                summonerStatus: {...action.data.summonerStatus},
                getSummonerInfoLoading: false,
                getSummonerInfoDone: true,
            }
        }
        case GET_SUMMONER_INFO_FAILURE: {
            return {
                ...state,
                getSummonerInfoLoading: false,
                getSummonerInfoError: action.error,
            }
        }
        case GET_SUMMONER_REQUEST: {
            return {
                ...initialState,
                getSummonerLoading: true,
                getSummonerDone: false,
                getSummonerError: null,
            }
        }

        case GET_SUMMONER_SUCCESS: {
            return {
                ...state,
                summoner: {...action.data.summoner},

                getSummonerLoading: false,
                getSummonerDone: true,
            }
        }
        case GET_SUMMONER_FAILURE: {
            return {
                ...state,
                getSummonerLoading: false,
                getSummonerError: action.error,
            }
        }
        case GET_CHAMP_INFO_REQUEST: {
            return {
                ...state,
                getChampInfoLoading: true,
                getChampInfoDone: false,
                getChampInfoError: null,
            }
        }
        case GET_CHAMP_INFO_SUCCESS: {
            return {
                ...state,
                withOrigin: {...action.data.withOrigin},
                withOutOrigin: {...action.data.withOutOrigin},
                getChampInfoLoading: false,
                getChampInfoDone: true,
            }
        }
        case GET_CHAMP_INFO_FAILURE: {
            return {
                ...state,
                getChampInfoLoading: false,
                getChampInfoError: action.error,
            }
        }
        case GET_MORE_MATCHES_REQUEST: {
            return {
                ...state,
                getMoreMatchesLoading: true,
                getMoreMatchesDone: false,
                getMoreMatchesError: null,
            }
        }
            ;
        case GET_MORE_MATCHES_SUCCESS: {
            return {
                ...state,
                match: [...state.match, ...action.data.match],
                getMoreMatchesLoading: false,
                getMoreMatchesDone: true,
            }
        }
        case GET_MORE_MATCHES_FAILURE: {
            return {
                ...state,
                getMoreMatchesError: action.error,
            }
        }
        case GET_SHORT_LINK_REQUEST: {

            return {
                ...state,
                getShortLinkLoading: true,
                getShortLinkDone: false,
                getShortLinkError: null,
            }
        }
        case GET_SHORT_LINK_SUCCESS: {

            return {
                ...state,
                getShortLinkLoading: false,
                getShortLinkDone: true,
                shortLink: {...action.data},
            }
        }
        case GET_SHORT_LINK_FAILURE: {
            return {
                ...state,
                getShortLinkLoading: false,
                getShortLinkError: action.error,
            }
        }
        case SET_SHORT_LINK_REQUEST: {

            return {
                ...state,
                getShortLinkDone: false,
                setShortLinkLoading: true,
                setShortLinkDone: false,
                setShortLinkError: null,
            }
        }
        case SET_SHORT_LINK_SUCCESS: {

            return {
                ...state,
                setShortLinkLoading: false,
                setShortLinkDone: true,
                setShortLink: {...action.data},
            }
        }
        case SET_SHORT_LINK_FAILURE: {
            return {
                ...state,
                setShortLinkLoading: false,
                setShortLinkError: action.error,
            }
        }
        case INIT_SHORT_LINK : {
            return {
                ...state,
                setShortLinkLoading: false,
                setShortLinkDone: false,
                setShortLinkError: null,
                getMoreMatchesLoading: false,
                getMoreMatchesDone: false,
                getMoreMatchesError: null,
                shortLink: "",
            }
        }
        case GET_AUTO_COMPLETE_REQUEST : {
            return {
                ...state,
                autoComplete: {},
                getAutoCompleteLoading: true,
                getAutoCompleteDone: false,
                getAutoCompleteError: null,
            }
        }
        case GET_AUTO_COMPLETE_SUCCESS: {
            return {
                ...state,
                autoComplete: {...action.data.autoComplete},
                getAutoCompleteLoading: false,
                getAutoCompleteDone: true,
            }
        }
        case GET_AUTO_COMPLETE_FAILURE: {
            return {
                ...state,
                getAutoCompleteLoading: false,
                getAutoCompleteError: action.error,
            }
        }
        case GET_MULTI_LIST_REQUEST: {
            return {
                ...state,
                multiList: [],
                getMultiListLoading: true,
                getMultiListDone: false,
                getMultiListError: null,
            }
        }
        case GET_MULTI_LIST_SUCCESS: {

            return {
                ...state,
                multiList: [...action.data.list],
                getMultiListLoading: false,
                getMultiListDone: true,
            }
        }
        case GET_MULTI_LIST_FAILURE: {
            return {
                ...state,
                getMultiListLoading: false,
                getMultiListError: action.error,
            }
        }
        case GET_MULTI_LIST_UPDATE_REQUEST: {
            return {
                ...state,
                getMultiListUpdateLoading: true,
                getMultiListUpdateDone: false,
                getMultiListUpdateError: null,
            }
        }
        case GET_MULTI_LIST_UPDATE_SUCCESS: {
            return {
                ...state,
                multiList: [...action.data.list],
                getMultiListUpdateLoading: false,
                getMultiListUpdateDone: true,
            }
        }
        case GET_SUMMONER_LP_CHART_REQUEST: {
            return {
                ...state,
                getLPChartLoading: true,
                getLPChartDone: false,
                getLPChartError: null,
            }
        }
        case GET_SUMMONER_LP_CHART_SUCCESS: {

            return {
                ...state,
                getLPChartLoading: false,
                getLPChartDone: true,
                tierChart: action.data.tierChart || null,
            }
        }
        case GET_INGAME_CHECK_REQUEST: {
            return {
                ...state,
                getIngameCheckLoading : true,
                getIngameCheckDone : false,
                getIngameCheckError : null,
            }
        }
        case GET_INGAME_CHECK_SUCCESS: {
            return {
                ...state,
                getIngameCheckLoading : false,
                getIngameCheckDone : true,
                ingameStatus: action.data.ingameStatus,
            }
        }
        case GET_INGAME_CHECK_FAILURE: {
            return {
                ...state,
                getIngameCheckLoading : false,
                getIngameCheckError : action.error,
            }
        }
        case GET_MULTI_SUMMONER_LP_CHART_REQUEST: {
            return {
                ...state,
                getMultiLPChartLoading : true,
                getMultiLPChartDone : false,
                getMultiLPChartError : false,
            }
        }
        case GET_MULTI_SUMMONER_LP_CHART_SUCCESS: {

            return {
                ...state,
                multiLPChart: [...action.data.list],
                getMultiLPChartLoading : false,
                getMultiLPChartDone : true,
            }
        }
        case GET_MULTI_SUMMONER_LP_CHART_FAILURE: {
            return {
                ...state,
                getMultiLPChartLoading : false,
                getMultiLPChartError : action.error,
            }
        }
        case GET_CHAMPIONS_BY_SEASON_REQUEST: {
            return {
                ...state,
                getChampionsBySeasonLoading : true,
                getChampionsBySeasonDone : false,
                getChampionsBySeasonError : null,
            }
        }
        case GET_CHAMPIONS_BY_SEASON_SUCCESS: {
            return {
                ...state,
                summonerStatus: {...action.data.summonerStatus},
                getChampionsBySeasonLoading : false,
                getChampionsBySeasonDone : true,
            }
        }
        case GET_CHAMPIONS_BY_SEASON_FAILURE: {
            return {
                ...state,

                getChampionsBySeasonLoading : false,
                getChampionsBySeasonError : action.error,
            }
        }
        case GET_GAME_ANALYTICS_PATCH_REQUEST: {
            return {
                ...state,
                getGameAnalyticsPatchLoading : true,
                getGameAnalyticsPatchDone : false,
                getGameAnalyticsPatchError : null,
            }
        }
        case GET_GAME_ANALYTICS_PATCH_SUCCESS: {
            const copyArray = [...state.gameSummaryPatch];
            copyArray.push(action.data);
            return {
                ...state,
                gameSummaryPatch: [...copyArray],
                getGameAnalyticsPatchLoading : false,
                getGameAnalyticsPatchDone : true,
            }
        }
        case GET_GAME_ANALYTICS_PATCH_FAILURE: {

            return {
                ...state,

                getGameAnalyticsPatchLoading : false,
                getGameAnalyticsPatchError : action.error,
            }
        }
        case SUMMONER:
            return {
                info: action.info,
            };
        default:
            return state;
    }
};
export default reducer;
