import _ from 'lodash';

// 액션 생성
const BOOKMARKLIST = 'bookmarkList/BOOKMARKLIST';

// 액션 함수 내보내기
export const bookmarkList = (bookmarks, flag) => ({ type: BOOKMARKLIST, bookmarks, flag });

// 초기 상태 값
const initialState = {
  bookmarks: [],
  flag: 'add',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKMARKLIST:
        let result = [];
        const arr = state.bookmarks.slice(-12);
        if(action.flag === 'add') {
            result = _.uniqBy([...arr, action.bookmarks], (e) => e?.summoner_basic_info_dict?.summoner_id)
        } else {
            result = arr.filter((e) => e?.summoner_basic_info_dict?.summoner_id !== action.bookmarks.summoner_basic_info_dict.summoner_id)
        }
      return {
        bookmarks: [...result]
      };
    default:

        if(state.bookmarks.length > 12) {
            return {
                ...state,
                bookmarks: state.bookmarks.slice(-12),
            }
        } else {
            return state;
        }
  }
};

export default reducer;
