export const GET_MORE_MATCHES_REQUEST = 'summoner/GET_MORE_MATCHES_REQUEST';
export const GET_MORE_MATCHES_SUCCESS = 'summoner/GET_MORE_MATCHES_SUCCESS';
export const GET_MORE_MATCHES_FAILURE = 'summoner/GET_MORE_MATCHES_FAILURE';

export const GET_SUMMONER_REQUEST = 'summoner/GET_SUMMONER_REQUEST';
export const GET_SUMMONER_SUCCESS = 'summoner/GET_SUMMONER_SUCCESS';
export const GET_SUMMONER_FAILURE = 'summoner/GET_SUMMONER_FAILURE';

export const GET_SUMMONER_INFO_REQUEST = 'summoner/GET_SUMMONER_INFO_REQUEST';
export const GET_SUMMONER_INFO_SUCCESS = 'summoner/GET_SUMMONER_INFO_SUCCESS';
export const GET_SUMMONER_INFO_FAILURE = 'summoner/GET_SUMMONER_INFO_FAILURE';

export const UPDATE_SUMMONER_INFO_REQUEST = 'summoner/UPDATE_SUMMONER_INFO_REQUEST'
export const UPDATE_SUMMONER_INFO_SUCCESS = 'summoner/UPDATE_SUMMONER_INFO_SUCCESS'
export const UPDATE_SUMMONER_INFO_FAILURE = 'summoner/UPDATE_SUMMONER_INFO_FAILURE'

export const GET_UPDATE_SATUS_REQUEST = 'summoner/GET_UPDATE_SATUS_REQUEST';
export const GET_UPDATE_SATUS_SUCCESS = 'summoner/GET_UPDATE_SATUS_SUCCESS';
export const GET_UPDATE_SATUS_FAILURE = 'summoner/GET_UPDATE_SATUS_FAILURE';

export const GET_MATCH_BY_QUEUE_SUCCESS = 'summoner/GET_MATCH_BY_QUEUE_SUCCESS';
export const GET_MATCH_BY_QUEUE_REQUEST = 'summoner/GET_MATCH_BY_QUEUE_REQUEST';
export const GET_MATCH_BY_QUEUE_FAILURE = 'summoner/GET_MATCH_BY_QUEUE_FAILURE';

export const GET_CHAMP_INFO_REQUEST = 'summoner/GET_CHAMP_INFO_REQUEST';
export const GET_CHAMP_INFO_SUCCESS = 'summoner/GET_CHAMP_INFO_SUCCESS';
export const GET_CHAMP_INFO_FAILURE = 'summoner/GET_CHAMP_INFO_FAILURE';

export const GET_MATCH_REQUEST = 'summoner/GET_MATCH_REQUEST';
export const GET_MATCH_SUCCESS = 'summoner/GET_MATCH_SUCCESS';
export const GET_MATCH_FAILURE = 'summoner/GET_MATCH_FAILURE';


export const GET_SUMMONER_LP_CHART_REQUEST = 'summoner/GET_SUMMONER_LP_CHART_REQUEST';
export const GET_SUMMONER_LP_CHART_SUCCESS = 'summoner/GET_SUMMONER_LP_CHART_SUCCESS';
export const GET_SUMMONER_LP_CHART_FAILURE = 'summoner/GET_SUMMONER_LP_CHART_FAILURE';

export const GET_MULTI_SUMMONER_LP_CHART_REQUEST = 'summoner/GET_MULTI_SUMMONER_LP_CHART_REQUEST';
export const GET_MULTI_SUMMONER_LP_CHART_SUCCESS = 'summoner/GET_MULTI_SUMMONER_LP_CHART_SUCCESS';
export const GET_MULTI_SUMMONER_LP_CHART_FAILURE = 'summoner/GET_MULTI_SUMMONER_LP_CHART_FAILURE';


export const INIT_SHORT_LINK = 'summoner/INIT_SHORT_LINK';

export const GET_SHORT_LINK_REQUEST = 'summoner/GET_SHORT_LINK_REQUEST';
export const GET_SHORT_LINK_SUCCESS = 'summoner/GET_SHORT_LINK_SUCCESS';
export const GET_SHORT_LINK_FAILURE = 'summoner/GET_SHORT_LINK_FAILURE';


export const SET_SHORT_LINK_REQUEST = 'summoner/SET_SHORT_LINK_REQUEST';
export const SET_SHORT_LINK_SUCCESS = 'summoner/SET_SHORT_LINK_SUCCESS';
export const SET_SHORT_LINK_FAILURE = 'summoner/SET_SHORT_LINK_FAILURE';

export const GET_AUTO_COMPLETE_REQUEST = 'summoner/GET_AUTO_COMPLETE_REQUEST';
export const GET_AUTO_COMPLETE_SUCCESS = 'summoner/GET_AUTO_COMPLETE_SUCCESS';
export const GET_AUTO_COMPLETE_FAILURE = 'summoner/GET_AUTO_COMPLETE_FAILURE';

export const GET_MULTI_LIST_REQUEST = 'summoner/GET_MULTI_LIST_REQUEST';
export const GET_MULTI_LIST_SUCCESS = 'summoner/GET_MULTI_LIST_SUCCESS';
export const GET_MULTI_LIST_FAILURE = 'summoner/GET_MULTI_LIST_FAILURE';


export const GET_MULTI_LIST_UPDATE_REQUEST = 'summoner/GET_MULTI_LIST_UPDATE_REQUEST';
export const GET_MULTI_LIST_UPDATE_SUCCESS = 'summoner/GET_MULTI_LIST_UPDATE_SUCCESS';
export const GET_MULTI_LIST_UPDATE_FAILURE = 'summoner/GET_MULTI_LIST_UPDATE_FAILURE';

export const GET_INGAME_CHECK_REQUEST = 'summoner/GET_INGAME_CHECK_REQUEST'
export const GET_INGAME_CHECK_SUCCESS = 'summoner/GET_INGAME_CHECK_SUCCESS'
export const GET_INGAME_CHECK_FAILURE = 'summoner/GET_INGAME_CHECK_FAILURE'


export const GET_CHAMPIONS_BY_SEASON_REQUEST = 'summoner/GET_CHAMPIONS_BY_SEASON_REQUEST'
export const GET_CHAMPIONS_BY_SEASON_SUCCESS = 'summoner/GET_CHAMPIONS_BY_SEASON_SUCCESS'
export const GET_CHAMPIONS_BY_SEASON_FAILURE = 'summoner/GET_CHAMPIONS_BY_SEASON_FAILURE'


export const GET_GAME_ANALYTICS_PATCH_REQUEST = 'summoner/GET_GAME_ANALYTICS_PATCH_REQUEST'
export const GET_GAME_ANALYTICS_PATCH_SUCCESS = 'summoner/GET_GAME_ANALYTICS_PATCH_SUCCESS'
export const GET_GAME_ANALYTICS_PATCH_FAILURE = 'summoner/GET_GAME_ANALYTICS_PATCH_FAILURE'
