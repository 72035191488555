import React, {useCallback} from 'react';
import styled from "styled-components";
import SvgArrow from "../../../../../common/SVGArrow";
import RankingTableNavigatorArrow from "./RankingTableNavigatorArrow";
import DownArrow from "../../../../../common/TagWithArrow/DownArrow";

const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


const ArrowWrapper = styled.div`
  margin-inline: 3px;
`;

const PagingWrapper = styled.div`
  padding-inline: 16px;
`;

const PagingNumber = styled.span`
  font-weight: 500;
  margin-right: 6px;
`;

const LastIndexNumber = styled.span`
  font-weight: 500;
  color: #313131;
  opacity: 0.5;
`;


const RankingTableNavigator = ({currentPage, lastPage,onClickNext,onClickPrev,onClickToEnd,onClickToFist}) => {



    return (
        <Wrapper>
            <ArrowWrapper>
                <RankingTableNavigatorArrow
                    onClick={onClickToFist}
                    Arrow={DownArrow}
                    matrix={'0, 1, -1, 0, 0, 0'}
                    disabled={currentPage === 1}
                />
            </ArrowWrapper>
            <ArrowWrapper>
                <RankingTableNavigatorArrow
                    onClick={onClickPrev}
                    Arrow={SvgArrow}
                    matrix={'0, 1, -1, 0, 0, 0'}
                    disabled={currentPage === 1}
                />
            </ArrowWrapper>
            <PagingWrapper>
                <PagingNumber>{currentPage}</PagingNumber>
                <LastIndexNumber>/{lastPage}</LastIndexNumber>
            </PagingWrapper>
            <ArrowWrapper>
                <RankingTableNavigatorArrow
                    onClick={onClickNext}
                    Arrow={SvgArrow}
                    matrix={'0, -1, 1, 0, 0, 0'}
                    disabled={Number(currentPage) >= Number(lastPage)}
                />
            </ArrowWrapper>
            <ArrowWrapper>
                <RankingTableNavigatorArrow
                    onClick={onClickToEnd}
                    Arrow={DownArrow}
                    matrix={'0, -1, 1, 0, 0, 0'}
                    disabled={Number(currentPage) >= Number(lastPage)}
                />
            </ArrowWrapper>
        </Wrapper>
    );
};

export default RankingTableNavigator;
