import React from 'react';
import styled from "styled-components";

const BannerWrapper = styled.div`
  border-radius: 6px;
  display: flex;
  flex: 1;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: #CED4E3;
  background: linear-gradient(180deg, #FFFFFF 0%, #F1F4F9 100%);
  box-shadow: 0px 4px 6px #0000001A;
  height: auto;
  margin-bottom: 5px;
`;

const ContentWrapper = styled.div`
  padding: 13px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

`;

const Bar = styled.div`
  width: 3px;
  background-color: ${props => props.backgroundColor};
  margin-right: 10px;
`;

const TextWrapper = styled.div`
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TextHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;


`;
const ImageWrapper = styled.div`
  display: flex;
`;

const HeaderText = styled.span`
  font-size: 20px;
  font-family: "Noto Sans KR";
  font-weight: bold;
  padding: 2px 0;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }

`;

const TextContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  line-height: 20px;
  @media only screen and (max-width: 768px) {
    margin-top: 13px;
    margin-bottom: 13px;
  }
`;

const ContentText = styled.span`
  white-space: pre-wrap;
  font-size: ${props => props.fontSize + 'px'};
  font-family: "Noto Sans KR";
`;

const HomeContent = ({img, contentText, headerText, barColor, fontSize}) => {
    return (
        <>
            <BannerWrapper>
                <ContentWrapper>
                    <TextWrapper>
                        <TextHeader>
                            <Bar
                                backgroundColor={barColor}
                            />
                            <HeaderText>{headerText}</HeaderText>
                        </TextHeader>
                        <TextContent>
                            <ContentText
                                fontSize={fontSize}
                            >{contentText}</ContentText>
                        </TextContent>
                    </TextWrapper>
                    <ImageWrapper>
                        <img
                            width={'100%'}
                            height={'100%'}
                            src={img} alt={"Banner"}/>
                    </ImageWrapper>
                </ContentWrapper>
            </BannerWrapper>
        </>
    );
};

export default HomeContent;