import React from 'react';
import {kdaColor} from "../../../../../function";
import SummaryContent from "../index";
import styled from "styled-components";


const WinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const RatioText = styled.span`
  font-size: 22px;
  font-weight: bold;
`;


const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
`;

const KDAText = styled.span`
  font-size: 12px;
  color: #313131;
  text-align: center;
  min-width: 4ch;
`;

const DeathText = styled.span`
  font-size: 12px;
  text-align: center;
  color: #DE5353;
  min-width: 4ch;
`;

const DivideText = styled.span`
  font-size: 8px;
  color: #313131;
  opacity: 0.4;
`

const SummaryContentKDA = ({title = "KDA", kill, death, assist, kda = 0}) => {

    return (
        <SummaryContent
            title={title}>
            <WinWrapper>
                <RatioText>
                    {kdaColor(kda.toFixed(2))}
                </RatioText>
                <TextWrapper>
                    <KDAText>{kill}</KDAText>
                    <DivideText>/</DivideText>
                    <DeathText>{death}</DeathText>
                    <DivideText>/</DivideText>
                    <KDAText>{assist}</KDAText>
                </TextWrapper>
            </WinWrapper>
        </SummaryContent>
    );
};

export default SummaryContentKDA;