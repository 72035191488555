import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const WinAndLostDiv = styled.div`
  display: flex;
  flex: 1 0 100%;
  justify-content: space-between;
`;

const RateText = styled.span`
  text-align: center;
  width: 2ch;
  font-size: 12px;
  color: #313131;
`;

const TransText = styled.span`
  font-size: 12px;
  color: #313131;
  opacity: 0.6;
`;


const WinLoseText = ({win, lost}) => {
    const {t} = useTranslation();
    return (
        <>
            <WinAndLostDiv>
                <RateText>{win}</RateText>
                <TransText>{t('summoner.contractionWin')}</TransText>
                <RateText>{lost}</RateText>
                <TransText>{t('summoner.contractionLose')}</TransText>
            </WinAndLostDiv>
        </>
    );
};

export default WinLoseText;