import React, {useCallback} from 'react';
import styled from "styled-components";
import DownArrow from "../../../../../../../common/TagWithArrow/DownArrow";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${props => {
    let items = 'flex-end';
    if (props.reverse) items = 'flex-start';
    return items;
  }};
  justify-content: space-between;
`;

const DmageText = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: 500;
  color: ${props => props.color};
`;

const IndicatorText = styled.div`
  font-size: 12px;
  transform: scale(0.952);
  margin-bottom: 4px;
  opacity: 0.6;
  font-weight: 500;
  color: ${props => props.color};
`;

const Graph = styled.div`
  display: flex;
  justify-content: ${props => {
    let items = 'flex-end';
    if (props.reverse) items = 'flex-start';
    return items;
  }};
  width: 100%;
  height: 4px;
  background-color: ${props => {
    let bgColor = '#ffffff';
    if (props.bgColor) bgColor = props.bgColor;
    return bgColor;
  }};
`;
const Filled = styled.div`
  height: 4px;
  width: ${props => props.percent}%;
  background-color: ${props => props.color};
  position: relative;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const PercentText = styled.div`
  color: #313131;
  font-size: 12px;
  opacity: 0.6;
  transform: scale(0.775);
  margin-left: 1px;
  line-height: 15px;
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: -18px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Absolutediv = styled.div`
  height: 5px;
  position: absolute;
  right: -3.45px;
  top: -8px;
`;

const AbsoluteBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
`;

const ZeroSpan = styled.span`
  top: -16px;
  position: absolute;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
`;

const GradeText = styled.span`
  font-weight: normal;
  font-size: 11px;
  opacity: 0.6;
`;

const DamageGraph = ({color, barColor, total, dmg, bgColor, addPercent, reverse, indicator, grade}) => {
    const {t} = useTranslation();
    const calcFilled = useCallback(() => {
        return Math.trunc(dmg / total * 100);
    }, [total, dmg]);


    const gradeText = useCallback(() => {
        switch (Number(grade)) {
            case 1 : {
                return `${t('summoner.firstGrade')}`
            }
            case 2: {
                return `${t('summoner.secondGrade')}`
            }
            case 3: {
                return `${t('summoner.thirdGrade')}`
            }
            default: {
                return `${t('summoner.grade')}`
            }
        }
    }, [grade]);


    return (
        <Wrapper reverse={reverse}>
            <TextWrapper>
                {indicator ? null :
                    <>
                        <DmageText color={color}>{dmg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</DmageText>
                        {addPercent &&
                            <PercentText>
                                %
                            </PercentText>
                        }
                    </>
                }
            </TextWrapper>
            <Graph bgColor={bgColor} reverse={reverse}>
                <Filled percent={calcFilled()} color={barColor}>
                    {grade ?
                        <Absolutediv>
                            <AbsoluteBox>
                                <ZeroSpan>{grade}<GradeText>{gradeText()}</GradeText></ZeroSpan>
                                <DownArrow
                                    color={'#566A7A'}
                                    size={3.5}
                                />
                            </AbsoluteBox>

                        </Absolutediv> : null}
                </Filled>
            </Graph>
            {indicator ?
                <IndicatorWrapper>
                    <IndicatorText color={color}>{0}</IndicatorText>
                    <IndicatorText color={color}>{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</IndicatorText>
                </IndicatorWrapper> : null}
        </Wrapper>
    );
};

export default DamageGraph;
