import React, {useCallback} from 'react';
import styled from "styled-components";
import ChampLane from "../../../../summoner/Champ/ChampLane";
import regionList from "../../../../../store/static_json/regionList";
import DownArrow from "../../../../common/TagWithArrow/DownArrow";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  padding: 9px 5px 9px 20px;
  background-color: #F9F9FA;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LangAndLaneWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LaneWrapper = styled.div`
  height: 28px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid #C3D1DC;
  padding: 3px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 18px;

  select {
    border: 1px solid #C3D1DC;
    border-radius: 4px;
    padding: 0 8px;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 80px;
    height: 100%;
  }
`;


const ArrowWrapper = styled.div`
  position: absolute;
  right: 8px;
`;

const VersionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const VersionSpan = styled.span`
  font-size: 12px;
  transform: scale(.891);
`;


const RankingTableFilter = ({
                                rankingRegion,
                                setCurrentLane,
                                currentLane,
                                onClickRegion,
                                botRate,
                                midRate,
                                jugRate,
                                supRate,
                                topRate,
                                onClickLane,
                            }) => {
    const {t} = useTranslation();
    const version = useSelector((state) => state.gameVersion.version);
    const onChangePosition = useCallback((lane) => {
        if (typeof lane === 'string') {
            setCurrentLane(lane);
        }
    }, [setCurrentLane]);

    const laneRate = useCallback((value) => {
        const currentValue = typeof value === 'number' ? value : 0;
        return (currentValue * 100).toFixed(0)
    }, [])

    const laneValue = useCallback((lane) => {
        switch (lane) {
            case 'Top': {
                return laneRate(topRate);
            }
            case 'Jungle': {
                return laneRate(jugRate);
            }
            case 'Middle': {
                return laneRate(midRate);
            }
            case 'Bot': {
                return laneRate(botRate);
            }
            case 'Supporter': {
                return laneRate(supRate);
            }
        }
    }, [botRate, midRate, jugRate, supRate, topRate]);


    return (
        <Wrapper>
            <LangAndLaneWrapper>
                <SelectWrapper>
                    <select onChange={onClickRegion}>
                        {regionList.map((data) => {
                            if (!data.disabled) {
                                return <option value={data.title}
                                               selected={rankingRegion.serverName === data.serverName}
                                               key={data.title}>{data.title}</option>
                            } else {
                                return null;
                            }

                        })}
                    </select>
                    <ArrowWrapper>
                        <DownArrow color={"#313131"}/>
                    </ArrowWrapper>
                </SelectWrapper>
                <LaneWrapper>
                    <ChampLane
                        mapValue={laneValue}
                        onChangePosition={onChangePosition}
                        selectedLane={currentLane}
                    />
                </LaneWrapper>
            </LangAndLaneWrapper>
            <VersionWrapper>
                <VersionSpan>
                    {t('ranking.versionInfo')} {version.slice(0,5)} Patch
                </VersionSpan>
            </VersionWrapper>
        </Wrapper>
    );
};

export default RankingTableFilter;
