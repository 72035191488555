import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  
  &:hover > .tooltipWrapper > .tooltip,
  &:active > .tooltipWrapper > .tooltip {
    display: ${props => {
    let dispaly = 'flex';
    if (props.disabled) dispaly = 'none';
    return dispaly;
}};
  }`;

const SimpleToolTipWrapper = styled.div`
  display: none;
  position: relative;
  z-index: 200;
  font-size: 11px;
  color: #fff;
  line-height: 1.4;
`;

const SimpleToolTipText = styled.div`
  text-align: center;
  background-color: rgba(10, 20, 30, 0.98);
  padding: 8px 10px;
  display: flex;
  flex-shrink: 0;
  transform: translateX(-49%);
  white-space: pre-wrap;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  color: ${props => props.color};
  font-weight: ${props => props.weight};

  &::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    display: block;
    width: 6px;
    height: 4px;
    margin-left: -3px;
    background: url('/images/ico_dropbox_arrow_down_w.svg') no-repeat center;
  }
`;

const AbosluteWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;

  z-index: 200;
  margin-bottom: 8px;
  animation: tooltip-show 0.5s;


  @keyframes tooltip-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

`;

const TooltipWrapper = ({children, tooltipText, disabled,textColor = 'white',fontweight}) => {
    return (
        <>
            <Wrapper
                disabled={disabled}
            >
                {children}
                <AbosluteWrapper className={'tooltipWrapper'}>
                    <SimpleToolTipWrapper className={'tooltip'}>
                        <SimpleToolTipText color={textColor} weight={fontweight}>{tooltipText}</SimpleToolTipText>
                    </SimpleToolTipWrapper>
                </AbosluteWrapper>
            </Wrapper>
        </>
    );
};

export default TooltipWrapper;
