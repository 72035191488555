import React from 'react';
import styled from "styled-components";

const Text = styled.span`
  color: ${props => {
    let color = '#69788D';
    if (props.children >= 75) color = '#F19000';
    else if (props.children >= 50) color = '#259A7E';
    else if (props.children >= 25) color = '#DE5353';
    if(props.staticColor && typeof props.staticColor === 'string') color = props.staticColor;
    return color;
  }};
  font-weight: ${props => {
    let weight = 'normal';
    if (props.fontWeight) weight = props.fontWeight;
    return weight;
  }};
  font-size: ${props => {
    let size = '14px';
    if (props.fontSize) size = props.fontSize;
    return size;
  }};
`;

const WinRateText = ({children, fontWeight, fontSize,staticColor}) => {
    return (
        <Text fontWeight={fontWeight} fontSize={fontSize} staticColor={staticColor}>{children}</Text>
    );
};

export default WinRateText;