import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {ImageLane} from "../../../../../common";
import {allOffObject, sortChampData} from "../../../../../../function";
import {useTranslation} from "react-i18next";
import ChampionImageWithLane from "../../../../../common/ChampionImageWithLane";
import {useSelector} from "react-redux";
import WinRateText from "../../../../../common/WinrateText";
import RankingTableHeader
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/RankingTableHeader";
import useInput from "../../../../../../common/hooks/useInput";
import ChampSynergyTableItem from "./ChampSynergyTableItem";


const Wrapper = styled.div`
  flex: 1;
  border: 1px solid #839DB2;
  height: fit-content;

`;

const Table = styled.table`
`;

const PositionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;

  .imgLane {
    width: 16px;
    aspect-ratio: 1;
  }
`;

const PositionText = styled.div`
  margin-left: 4px;
  font-weight: 500;
  font-size: 12px;
`;

const TableTR = styled.tr`
  border-top: 1px solid #839DB2;
  border-bottom: 1px solid #839DB2;
  background: #DDEBE2;

  height: 28px;
`;


const TwoByTwo = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;



  .imgChamp {
    width: 52px;
    height: 52px;
  }

  .non_image {
    width: 52px;
    height: 52px;


  }
`;

const StaticBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
`;

const StaticInfoText = styled.span`
  font-size: 12px;
  margin-right: 6px;
  text-align: center;
`;

const ChampNameSpan = styled.span`
  font-size: 16px;
  margin-top: 6px;
`;

const BoldText = styled.span`
  font-size: 14px;
  font-weight: bold;

`;

const PercentText = styled.span`
  font-size: 14px;
  margin-left: 1px;

`;

const initSortList = {
    games: {
        value: "games",
        sortType: false,
    },
    win_rate: {
        value: "win_rate",
        sortType: true,
    },
};

const initSort = [{value: "games", sortType: false}];


const ChampSynergyTableContent = ({position, selectedPosition, list, positionText, games, championId, winRate}) => {
    const {t} = useTranslation();
    const champions = useSelector((state) => state.imageStore.champions);
    const currentChampion = useMemo(() => Array.isArray(champions) && champions.find((data) => Number(data.championId) === Number(championId)), [championId, champions]);
    const currentLang = useSelector((state) => state.translation.lang);
    const [currentSort, setCurrentSort] = useInput([...initSort]);
    const [sortList, setSortList] = useState({...initSortList});

    useEffect(() => {
        if (Array.isArray(list)) {
            setSortList({...initSortList});
            setCurrentSort([...initSort]);
        }
    }, [list])


    const onClickSort = useCallback((data) => {
        if (typeof data === 'object') {
            const copyData = {...data};
            const copySortData = {...sortList};
            if (copyData) {
                if (copySortData[copyData.value]) {
                    const allOff = allOffObject(copySortData);
                    copyData.sortType = !copyData.sortType;
                    allOff[copyData.value].sortType = copyData.sortType;
                    setCurrentSort([{...copyData}]);
                    setSortList(copySortData);
                }
            }
        }
    }, [sortList]);

    return (
        <Wrapper className={'wrapper'}>
            <PositionWrapper>
                <ImageLane
                    lane={position}
                />
                <PositionText>
                    {positionText}
                </PositionText>
            </PositionWrapper>
            <Table>
                <colgroup>
                    <col width={"2%"}/>
                    <col width={"2%"}/>
                </colgroup>
                <thead>
                <TableTR>
                    <RankingTableHeader
                        onClickSort={onClickSort}
                        sortArrowLeft={true}
                        sort={sortList.games}
                        textMargin={'0 0 0 28px'}
                        title={t('tabs.games')}
                        currentSort={currentSort}

                    />
                    <RankingTableHeader
                        onClickSort={onClickSort}
                        sortArrowLeft={true}
                        sort={sortList.win_rate}
                        title={t('championDetailBuild.wr')}
                        currentSort={currentSort}
                    />
                </TableTR>
                </thead>
                <tbody>
                {position.toLowerCase() !== selectedPosition.toLowerCase() &&
                    sortChampData(list, currentSort).map((data) => {
                        return (
                            <ChampSynergyTableItem
                                champion_id={data.champion_id}
                                win_rate={data.win_rate}
                                games={data.games}
                            />
                        )
                    })}
                </tbody>
            </Table>
            {position.toLowerCase() === selectedPosition.toLowerCase() &&
                <TwoByTwo>
                    <ChampionImageWithLane
                        champion_id={championId}
                        position={position}
                    />
                    {currentChampion &&
                        <ChampNameSpan>{currentLang === 'KOR' ? currentChampion.champion_name_kr : currentChampion.champion_name_en}</ChampNameSpan>}
                    <StaticBox>
                        <StaticInfoText>{t('tabs.games')}</StaticInfoText>
                        <BoldText>{games}</BoldText>
                    </StaticBox>
                    <StaticBox>
                        <StaticInfoText>{t('championDetailBuild.wr')}</StaticInfoText>
                        <WinRateText>{winRate.toFixed(1)}</WinRateText>
                        <PercentText>%</PercentText>
                    </StaticBox>
                </TwoByTwo>}
        </Wrapper>
    );
};

export default ChampSynergyTableContent;