import React, {useCallback} from 'react';
import InfluInfo from "../../../summoner/Profile/InfluInfo";
import styled from "styled-components";
import {getTierScore, tierBadge} from "../../../../function";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  padding: 4px 14px 0;
  border-radius: 3px;
  margin-bottom: 4px;
  height: 48px;
  background-color: ${props => {
    let color = 'transparent';
    if (props.selected) color = '#E7ECFB';
    return color;
  }};
  border: 1px solid ${props => {
    let color = 'transparent';
    if (props.selected) color = '#809AEF';
    return color;
  }};

  &:hover {
    background-color: #E7ECFB;
  }
  
`;

const Bar = styled.div`
  width: 32px;
  height: 3px;
  background-color: ${props => props.barColor}
`;

const ContentWrapper = styled.div`
  padding-left: 14px;
`;

const StreamerInfoWrapper = styled.div`
  margin-bottom: 4px;
`;

const TierWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


const TierBadge = styled.span`
  color: #FFFFFF;

  .tier_badge {
    border-radius: 5px;
    display: flex;
    font-size: 11px !important;
    width: 24px;
    height: 14px;
    justify-content: center;
    align-items: center;
  }
`;

const SummonerName = styled.span`
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  margin-left: 3px;
`;


const ChartMultiLegend = ({selected, index, onClick, tier, summonerName, barColor, influData}) => {
    const onClickWrapper = useCallback(() => {
        if (typeof onClick === 'function') {
            if (selected) {
                onClick(99);
            } else {
                onClick(index);
            }
        }
    }, [selected, index, onClick]);

    return (
        <Wrapper onClick={onClickWrapper} selected={selected}>
            <Bar index={index} barColor={barColor}/>
            <ContentWrapper>
                {influData?.status &&
                    <StreamerInfoWrapper>
                        <InfluInfo
                            showLinks={false}
                            influData={influData}
                            textColor={'#313131'}
                            maxWidthText={100}
                        />
                    </StreamerInfoWrapper>}
                <TierWrapper>
                    <TierBadge>{tierBadge(getTierScore(tier).txt)}</TierBadge>
                    <SummonerName>{summonerName}</SummonerName>
                </TierWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

export default ChartMultiLegend;
