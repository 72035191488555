import {lazy, useCallback, useEffect, useState} from 'react';
import regionList from "../store/static_json/regionList";

export const lazyIdle = (factory) => {
    return lazy(
        () =>
            new Promise((resolve) => {
                setTimeout(() => resolve(factory()), 300)
            })
    )
}

export const allOffObject = (copyData) => {
    if (typeof copyData === 'object') {
        const copy = {...copyData};
        Object.keys(copy).forEach((key) => {
            copy[key].sortType = true;
        })
        return copy;
    }
    return copyData;
}

export const prevTierAndDivision = (tier, division) => {
    if (typeof tier === 'string') {
        if (tier.toLowerCase() === 'grandmaster' || tier.toLowerCase() === 'master' || tier.toLowerCase() === 'challenger') {
            return tier.toLowerCase();
        } else {
            return `${tier.toLowerCase()} ${division}`;
        }
    }

};

export const getRegion = (region) => {
    if(typeof region === 'string') {
        const currentRegion = regionList.find((data) => data.title === region);
        if(currentRegion) {
            return currentRegion.serverName;
        }
    }
    return 'KR';
}

const _testHasDot = (text) => {
    if (typeof text === 'string') {
        const regexp = /\.+/;
        const testResult = regexp.test(text);
        return testResult ? text.split(".") : text;
    }
    return "";
}

export const onStorePuuId = (name,region,data) => {
    if(data) {
        localStorage.setItem("tempPuuId", JSON.stringify(data));
    }
    const url = `/summoner/${region}/${name}`;
    window.open(url, "_blank", "noreferrer");
};

export const getNestedObject = (text = '', obj) => {

    if (typeof text === 'string' && typeof obj === 'object' && Object.values(obj).length > 0) {
        const testedValue = _testHasDot(text);
        if (Array.isArray(testedValue)) {
            const result = testedValue.reduce((sum, cur) => sum[cur], obj);
            return result;
        } else {
            return obj[text];
        }
    }
    return "";
};

export const cho_convertor = (value) => {
    value = value.replace("ㄳ", "ㄱㅅ");
    value = value.replace("ㄵ", "ㄴㅈ");
    value = value.replace("ㄶ", "ㄴㅎ");
    value = value.replace("ㄺ", "ㄹㄱ");
    value = value.replace("ㄻ", "ㄹㅁ");
    value = value.replace("ㄼ", "ㄹㅂ");
    value = value.replace("ㄽ", "ㄹㅅ");
    value = value.replace("ㄾ", "ㄹㅌ");
    value = value.replace("ㄿ", "ㄹㅍ");
    value = value.replace("ㅀ", "ㄹㅎ");
    value = value.replace("ㅄ", "ㅂㅅ");
    return value;
};

export const useTitle = (initTitle) => {
    const [title, setTitle] = useState(initTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector('title');
        htmlTitle.innerText = title;
        if (title !== 'Loading...') {
            const setMeta = document.querySelector('meta[name=title]');
            const ogMeta = document.querySelector('meta[property="og:title"]')
            const twiter = document.querySelector('meta[property="twitter:title"]')
            setMeta.setAttribute('content', title);
            ogMeta.setAttribute('content', title);
            twiter.setAttribute('content', title);
        }
    };

    useEffect(updateTitle, [title]);

    return setTitle;
};

export const setSummonerName = (userName) => {
    let summonerName = userName.replace(/\s/gi, '');
    if (summonerName.length === 2) {
        const result = userName.split('').join('%20');

        summonerName = result;
    }

    return summonerName
}

export const getShortTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        const diffDate = new Date(nowDate - gameDate);
        const minutes = Math.floor(diffDate / 60000);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        let result = 0;


        if (minutes > 60) {
            if (hours > 24) {
                if (days > 365) {
                    result = lang === 'KOR' ? '오래 전' : 'long time ago';
                } else {
                    if (Math.abs(days) === 1) {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : 'd ago');
                    } else {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : 'd ago');
                    }

                }
            } else {
                if (Math.abs(hours) === 1) {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : 'h ago');
                } else {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : 'h ago');
                }
            }
        } else {
            if (Math.abs(minutes) === 1) {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : 'm ago');
            } else {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : 'm ago');
            }

        }

        return result;
    }

};


export const getDividedGameTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        const diffDate = new Date(nowDate - gameDate);
        const minutes = Math.floor(diffDate / 60000);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        let result = 0;
        let text = '';


        if (minutes > 60) {
            if (hours > 24) {
                if (days > 365) {
                    text = lang === 'KOR' ? '오래 전' : 'long time ago';
                } else {
                    if (Math.abs(days) === 1) {
                        result = Math.abs(days);
                        text = (lang === 'KOR' ? '일 전' : ' day ago');
                    } else {
                        result = Math.abs(days)
                        text = (lang === 'KOR' ? '일 전' : ' days ago');
                    }

                }
            } else {
                    result = Math.abs(hours);
                    text = (lang === 'KOR' ? '방금 전' : ' hour ago');
            }
        }  else {
            result = Math.abs(minutes);
            text = (lang === 'KOR' ? '방금 전' : ' hour ago');
        }

        return [result, text];
    }

};

export const getGameTimeHoursWithMin = (unixTime, lang) => {

}
export const getLPTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        nowDate.setHours(24,0,0,0);
        const diffDate = nowDate - gameDate;
        const minutes = Math.trunc(diffDate / 60000);
        const hours = Math.trunc(minutes / 60);
        const days = Math.trunc(hours / 24);

        let result = 0;


        if (hours > 24) {
            if (days > 365) {
                result = lang === 'KOR' ? '오래 전' : 'long time ago';
            } else {
                if (Math.abs(days) === 1) {
                    result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' d+');
                } else {
                    result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' d+');
                }

            }
        } else {
            result = lang === 'KOR' ? '오늘' : "today"
        }


        return result;
    }
};

// 게임시간 가져오기
export const getGameTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        const diffDate = nowDate - gameDate;
        const minutes = Math.trunc(diffDate / 60000);
        const hours = Math.trunc(minutes / 60);
        const days = Math.trunc(hours / 24);

        let result = 0;


        if (minutes > 60) {
            if (hours > 24) {
                if (days > 365) {
                    result = lang === 'KOR' ? '오래 전' : 'long time ago';
                } else {
                    if (Math.abs(days) === 1) {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' day ago');
                    } else {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' days ago');
                    }

                }
            } else {
                if (Math.abs(hours) === 1) {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : ' hour ago');
                } else {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : ' hours ago');
                }
            }
        } else {
            if (Math.abs(minutes) === 1) {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : ' min ago');
            } else {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : ' minutes ago');
            }

        }

        return result;
    }

};

export const patchHistoryTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        const diffDate = nowDate - gameDate;
        const minutes = Math.trunc(diffDate / 60000);
        const hours = Math.trunc(minutes / 60);
        const days = Math.trunc(hours / 24);

        let result = 0;


        if (minutes > 60) {
            if (hours > 24) {
                    if (Math.abs(days) === 1) {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' day ago');
                    } else {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' days ago');
                    }

            } else {
                if (Math.abs(hours) === 1) {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : ' hour ago');
                } else {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : ' hours ago');
                }
            }
        } else {
            if (Math.abs(minutes) === 1) {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : ' min ago');
            } else {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : ' minutes ago');
            }

        }

        return result;
    }

};


// 게임시간 시/분/초 를 초단위로
export const calcDuration = (duration) => {
    const hms = duration.split(':');
    const hours = hms[0] === '00' ? 0 : Number(hms[0]) * 3600;
    const minutes = hms[1] === '00' ? 0 : Number(hms[1]) * 60;
    const seconds = hms[2] === '00' ? 0 : Number(hms[2]);

    return hours + minutes + seconds;
};

// 게임시간 시/분/초 구하기
export const getTimeHMS = (time, num, lang) => {
    let tmp = time.split(':');
    let hour = parseInt(tmp[0]);
    let minute = parseInt(tmp[1]) > 9 ? parseInt(tmp[1]) : `0${parseInt(tmp[1])}`;
    let second = parseInt(tmp[2]) > 9 ? parseInt(tmp[2]) : `0${parseInt(tmp[2])}`;

    if (num === 'num') {
        return hour !== 0
            ? `${hour}:${minute}:${second}`
            : `${minute}:${second}`;
    } else {

        if (lang === 'KOR') {
            return hour !== 0 ? `${hour}시간 ${minute}분 ${second}초` : `${minute}분 ${second}초`;
        } else {
            return hour !== 0 ? `${hour}:${minute}:${second}` : `${minute}:${second}`;
        }
    }
};

// 시간 포멧
export const getFormatTime = (time) => {
    // time은 xx.xx 형식
    let returnVal = '';
    let min = Math.floor(time);
    let sec = time - Math.floor(time);
    if (sec === 0) {
        sec = '00';
    } else {
        if (Math.round(sec * 60) === 60) {
            min += 1;
            sec = '00';
        } else {
            sec = (Math.round(sec * 60) < 10 ? '0' : '') + Math.round(sec * 60);
        }
    }
    returnVal = min + ':' + sec;
    return returnVal;
};

// 인게임 시간 구하기
export const getIngameTime = (duration) => {
    // let hour = parseInt(duration / 3600);

    let min = parseInt((duration % 3600) / 60);
    let sec = duration % 60;

    return `${min}:${sec < 10 ? '0' + sec : sec}`;
};

// 팀별 우세도 계산
export const getWinrateCalc = (winrate, team) => {
    let result = 0;
    if (winrate > 0) {
        // 블루가 우세
        team === 'blue' ? (result = winrate + 50) : (result = winrate - 50);
    } else {
        // 레드가 우세
        team === 'blue' ? (result = 100 + (winrate - 50)) : (result = winrate - 50);
    }
    result = Math.abs(result);
    return result;
};

// 음수 양수 판단
export const getNeGa = (arg) => {
    let result = '';
    if (arg === 0) {
        result = '';
    } else if (arg > 0) {
        result = 'positive';
    } else {
        result = 'negative';
    }
    return result;
};

// LP Score 계산
export const getLPScore = (tier, division, lp, grandvalue, challengervalue,multiPly = 100) => {
    let returnValue = lp;
    let divisionCount = 4;

    if (tier === 'IRON') {
        divisionCount *= 1;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'BRONZE') {
        divisionCount *= 2;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'SILVER') {
        divisionCount *= 3;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'GOLD') {
        divisionCount *= 4;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'PLATINUM') {
        divisionCount *= 5;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'DIAMOND') {
        divisionCount *= 6;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'MASTER') {
        returnValue += 2400;
    } else if (tier === 'GRANDMASTER') {
        returnValue += grandvalue * (multiPly / 100);
    } else if (tier === 'CHALLENGER') {
        returnValue += challengervalue * (multiPly / 100);
    }

    return returnValue;
};

// 승률 구하기
export const getWinrate = (win, lose) => {
    let result = Math.round((win / (win + lose)) * 100);
    return result;
};

// KDA 구하기
export const getKda = (k, d, a) => {
    let result = 0;
    if (d === 0) {
        result = Math.round((k + a) * 1.2 * 100) / 100;
    } else {
        result = Math.round(((k + a) / d) * 100) / 100;
    }
    return result;
};

// AI Score 구하기
export const getAIScore = (minmax_z) => {
    let scoreResult = 0;
    scoreResult = (minmax_z * 100).toFixed(0);

    return scoreResult;
};


const setMostData = (array, flag) => {
    const copyArray = [...array];
    const result = [];

    for (let i in copyArray) {
        const findIndex = result.findIndex((data, index) => data[flag] === copyArray[i][flag]);
        if (findIndex > -1) {
            result[findIndex] = {
                ...result[findIndex],
                gameCount: result[findIndex].gameCount + 1,
                ai_score: result[findIndex].ai_score + copyArray[i].ai_score,
                wins: copyArray[i].win ? result[findIndex].wins + 1 : 0,
            }
        } else {
            result.push({
                gameCount: 1,
                [flag]: copyArray[i][flag],
                ai_score: copyArray[i].ai_score,
                wins: copyArray[i].win ? 1 : 0,
            });
        }

    }

    result.sort((a, b) => {
        if (a.gameCount < b.gameCount) {
            return 1;
        } else if (a.gameCount > b.gameCount) {
            return -1;
        } else if (a.gameCount === b.gameCount) {
            const aScore = a.ai_score / a.gameCount;
            const bScore = b.ai_score / b.gameCount;
            if (aScore < bScore) {
                return 1;
            } else if (aScore > bScore) {
                return -1;
            } else if (aScore === bScore) {
                if (a.wins < b.wins) {
                    return 1;
                } else if (a.wins < b.wins) {
                    return -1;
                } else if (a.wins === b.wins) {
                    return 0;
                }
            }
        }
    });


    return result;
}

export const capitalizeLetter = (value) => {
    if (typeof value === 'string') {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}

export const threedigits = (value) => {
    if (value !== undefined && (typeof value === 'string' || typeof value === 'number')) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
}

export const urlChecker = (url) => {
    const copyUrl = url;
    const lastword = copyUrl.charAt(copyUrl.length - 1);
    let finalUrl = url;
    if (lastword === '/') {
        finalUrl = finalUrl.substr(0, finalUrl.length - 1);
    }
    return finalUrl;
}
export const sortNestedObject = (data, filter, extra = () => {
}) => {
    try {
        const copyData = [...data];
        const ascending = [-1, 1, 0];
        const decending = [1, -1, 0];


        copyData.sort((a, b) => {
            const firstFilter = extra && extra(a, b);
            if (!firstFilter) {
                for (const condition in filter) {
                    const compare = filter[condition];
                    const sortMath = compare.sortType ? [...ascending] : [...decending];
                    const firstValue = compare.value.split(".").reduce((sum, cur) => sum[cur], a);
                    const secondValue = compare.value.split(".").reduce((sum, cur) => sum[cur], b);
                    if (firstValue < secondValue) {
                        return sortMath[0];
                    } else if (firstValue > secondValue) {
                        return sortMath[1];
                    }
                }
                return 0;
            } else {
                return firstFilter
            }
        })


        return copyData.map((data, index) => {
            return {
                ...data,
                cid: index,
            }
        });
    } catch (e) {
        console.log("sortingError", e);
        return data;
    }
}

export const getTierDivisionCombined = (tier, division) => {
    if (tier && division) {
        let resultTxt = '';
        switch (tier) {
            case 'CHALLENGER':
                resultTxt = 'C';
                break;
            case 'GRANDMASTER':

                resultTxt = 'GM';
                break;
            case 'MASTER':

                resultTxt = 'M';
                break;
            case 'DIAMOND':
                resultTxt = 'D';
                break;
            case 'PLATINUM':
                resultTxt = 'P';
                break;
            case 'GOLD':
                resultTxt = 'G';
                break;
            case 'SILVER':
                resultTxt = 'S';
                break;
            case 'BRONZE':
                resultTxt = 'B';
                break;
            case 'IRON':
                resultTxt = 'I';
                break;
            default:
                break;
        }
        if (tier !== 'CHALLENGER' && tier !== 'GRANDMASTER' && tier !== 'MASTER') {
            return resultTxt + division;
        } else {
            return resultTxt;
        }
    }
    return "";
}

export const getLineRank = (array) => {
    const sortedData = setMostData(array, 'lane');
    const result = [];
    result.push(sortedData[0].lane);
    if (sortedData.length > 1) {
        result.push(sortedData[1].lane);
    }
    return result;
}


export const getHighRankChampion = (array) => {
    const sortedData = setMostData(array, 'championId');
    const result = [];
    result.push(sortedData[0].championId);
    if (sortedData.length > 1) {
        result.push(sortedData[1].championId);
    }


    return result;

}

// 배열의 중복데이터 중 최다 value와 중복 횟수 구하기 (내림차순으로 return)
export const getSortedArr = (array) => {

    // 1. 출연 빈도 구하기
    let counts = array.reduce((pv, cv) => {
        pv[cv] = (pv[cv] || 0) + 1;
        return pv;
    }, {});

    // 2. 요소와 개수를 표현하는 배열 생성 => [ [요소: 개수], [요소: 개수], ...]
    let result = [];
    let realResult = [];
    for (let key in counts) {
        result.push([key, counts[key]]);
    }


    // 3. 출현 빈도별 정리하기
    result.sort((first, second) => {
        // 정렬 순서 바꾸려면 return first[1] - second[1];
        return second[1] - first[1];
    });


    // 4. 정렬된 2차원 배열을 1차원으로 변경
    result.forEach((result, idx) => {
        result.pop();
        realResult.push(result[0].toString());
    });

    // 배열이 리턴
    return realResult;
};


export const getAiPrediction = (tier, aiscore, data) => {

    if (tier && aiscore !== undefined) {
        let userTier = tier.tier + tier.division;
        const tiers = {
            IRONIV: {
                value: 47,
                ranks: 'I4',
            },
            IRONIII: {
                value: 47,
                ranks: 'I3',
            },
            IRONII: {
                value: 47,
                ranks: 'I2',
            },
            IRONI: {
                value: 47,
                ranks: 'I1',
            },
            BRONZEIV: {
                value: 47,
                ranks: 'B4',
            },
            BRONZEIII: {
                value: 47,
                ranks: 'B3'
            },
            BRONZEII: {
                value: 47,
                ranks: 'B2',
            },
            BRONZEI: {
                value: 47,
                ranks: 'B1'
            },
            SILVERIV: {
                value: 48,
                ranks: 'S4',
            },
            SILVERIII: {
                value: 48,
                ranks: 'S3',
            },
            SILVERII: {
                value: 48,
                ranks: 'S2',
            },
            SILVERI: {
                value: 48,
                ranks: 'S1',
            },
            GOLDIV: {
                value: 48,
                ranks: 'G4'
            },
            GOLDIII: {
                value: 48,
                ranks: 'G3'
            },
            GOLDII: {
                value: 48,
                ranks: 'G2',
            },
            GOLDI: {
                value: 48,
                ranks: 'G1',
            },
            PLATINUMIV: {
                value: 48,
                ranks: 'P4',
            },
            PLATINUMIII: {
                value: 48,
                ranks: 'P3',
            },
            PLATINUMII: {
                value: 48,
                ranks: 'P2',
            },
            PLATINUMI: {
                value: 48,
                ranks: 'P1',
            },
            DIAMONDIV: {
                value: 50,
                ranks: 'D4'
            },
            DIAMONDIII: {
                value: 50,
                ranks: 'D3'
            },
            DIAMONDII: {
                value: 51,
                ranks: 'D2'
            },
            DIAMONDI: {
                value: 52,
                overValue: 0,
                ranks: 'D1',
            },
            MASTERI: {
                value: 53,
                overValue: data?.GRANDMASTER || 100,
                ranks: 'M'
            },
            GRANDMASTERI: {
                value: 53,
                overValue: data?.CHALLENGER || 1000,
                ranks: 'GM',
            },
            CHALLENGERI: {
                value: 53,
                overValue: 10000,
                ranks: 'C'
            }
        }

        const ranks = ['I4', 'I3', 'I2', 'I1', 'B4', 'B3', 'B2', 'B1', 'S4', 'S3', 'S2', 'S1', 'G4', 'G3', 'G2', 'G1', 'P4', 'P3', 'P2', 'P1', 'D4', 'D3', 'D2', 'D1', 'M', 'M', 'M', 'GM', 'GM', 'GM', 'C'];
        const aiArray = [-3, -2, -2, -2, -2, -1, -1, -1, -1, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3];

        if (!tiers[userTier]) {
            userTier = 'BRONZEI';
        }

        const calculatedValue = aiscore - tiers[userTier].value;


        let caledAiValue;

        const userTierValue = ranks.findIndex((data) => data === tiers[userTier].ranks);
        if (calculatedValue < -9) {
            caledAiValue = aiArray[0];
        } else if (calculatedValue > 12) {
            caledAiValue = aiArray[aiArray.length - 1];
        } else {
            caledAiValue = aiArray[(calculatedValue + 9)]
        }

        if (userTier === 'MASTERI' || userTier === 'GRANDMASTERI' || userTier === "CHALLENGERI") {
            if (data) {
                const lpcal = tier.leaguePoints + (caledAiValue * 100);
                const currentTier = tiers[userTier];
                const prevTier = Object.entries(tiers).find((data) => data[1].ranks === ranks[userTierValue - 1])[1];

                if (lpcal > currentTier.overValue) {
                    caledAiValue = 3;
                } else if (prevTier.overValue > lpcal) {
                    caledAiValue = -1;
                } else {
                    caledAiValue = 0;
                }

            }
        }


        // let resultNum = 0;
        let resultTxt;
        const predictionValue = userTierValue + caledAiValue;


        if (predictionValue > ranks.length - 1) {
            resultTxt = ranks[ranks.length - 1];
        } else if (predictionValue < 0) {
            resultTxt = ranks[0];
        } else {
            resultTxt = ranks[predictionValue];
        }


        return {txt: resultTxt};
    }
}

// 티어 구하기, 티어 예측 구하기
export const getTierScore = (tier, aiscore) => {
    let resultNum = 0;
    let resultTxt = '';
    let predictNum = 0;


    if (48 > aiscore) {
        predictNum += Math.floor((aiscore - 48) / 5) * 100;
    } else if (52 < aiscore) {
        predictNum += Math.ceil((aiscore - 52) / 5) * 100;
    } else {
        predictNum = 0;
    }

    if (typeof tier === 'number') {
        resultNum = Math.trunc(Math.round(tier / 100) * 100);
    } else {
        let str = tier.split(' ');
        switch (str[0].toUpperCase()) {
            case 'CHALLENGER':
                resultNum = 2600;
                resultTxt = 'C';
                break;
            case 'GRANDMASTER':
                resultNum = 2500;
                resultTxt = 'GM';
                break;
            case 'MASTER':
                resultNum = 2400;
                resultTxt = 'M';
                break;
            case 'DIAMOND':
                resultNum = 2000;
                resultTxt = 'D';
                break;
            case 'PLATINUM':
                resultNum = 1600;
                resultTxt = 'P';
                break;
            case 'GOLD':
                resultNum = 1200;
                resultTxt = 'G';
                break;
            case 'SILVER':
                resultNum = 800;
                resultTxt = 'S';
                break;
            case 'BRONZE':
                resultNum = 400;
                resultTxt = 'B';
                break;
            case 'IRON':
                resultNum = 0;
                resultTxt = 'I';
                break;
            default:
                resultNum = 0;
                break;
        }


        const upperText = str[0].toUpperCase();

        if (upperText !== 'CHALLENGER' && upperText !== 'GRANDMASTER' && upperText !== 'MASTER') {
            switch (str[1]) {
                case "4":
                    resultNum += 0;
                    resultTxt += '4';
                    break;
                case '3':
                    resultNum += 100;
                    resultTxt += '3';
                    break;
                case '2':
                    resultNum += 200;
                    resultTxt += '2';
                    break;
                case '1':
                    resultNum += 300;
                    resultTxt += '1';
                    break;
                default:
                    break;
            }
        }
    }



    if (aiscore !== undefined || typeof tier === 'number') {
        resultNum += predictNum;
        if (0 > resultNum) {
            resultTxt = 'I4';
        } else if (0 <= resultNum && resultNum < 100) {
            resultTxt = 'I4';
        } else if (100 <= resultNum && resultNum < 200) {
            resultTxt = 'I3';
        } else if (200 <= resultNum && resultNum < 300) {
            resultTxt = 'I2';
        } else if (300 <= resultNum && resultNum < 400) {
            resultTxt = 'I1';
        } else if (400 <= resultNum && resultNum < 500) {
            resultTxt = 'B4';
        } else if (500 <= resultNum && resultNum < 600) {
            resultTxt = 'B3';
        } else if (600 <= resultNum && resultNum < 700) {
            resultTxt = 'B2';
        } else if (700 <= resultNum && resultNum < 800) {
            resultTxt = 'B1';
        } else if (800 <= resultNum && resultNum < 900) {
            resultTxt = 'S4';
        } else if (900 <= resultNum && resultNum < 1000) {
            resultTxt = 'S3';
        } else if (1000 <= resultNum && resultNum < 1100) {
            resultTxt = 'S2';
        } else if (1100 <= resultNum && resultNum < 1200) {
            resultTxt = 'S1';
        } else if (1200 <= resultNum && resultNum < 1300) {
            resultTxt = 'G4';
        } else if (1300 <= resultNum && resultNum < 1400) {
            resultTxt = 'G3';
        } else if (1400 <= resultNum && resultNum < 1500) {
            resultTxt = 'G2';
        } else if (1500 <= resultNum && resultNum < 1600) {
            resultTxt = 'G1';
        } else if (1600 <= resultNum && resultNum < 1700) {
            resultTxt = 'P4';
        } else if (1700 <= resultNum && resultNum < 1800) {
            resultTxt = 'P3';
        } else if (1800 <= resultNum && resultNum < 1900) {
            resultTxt = 'P2';
        } else if (1900 <= resultNum && resultNum < 2000) {
            resultTxt = 'P1';
        } else if (2000 <= resultNum && resultNum < 2100) {
            resultTxt = 'D4';
        } else if (2100 <= resultNum && resultNum < 2200) {
            resultTxt = 'D3';
        } else if (2200 <= resultNum && resultNum < 2300) {
            resultTxt = 'D2';
        } else if (2300 <= resultNum && resultNum < 2400) {
            resultTxt = 'D1';
        } else if (2400 <= resultNum && resultNum < 2500) {
            resultTxt = 'M';
        } else if (2500 <= resultNum && resultNum < 2600) {
            resultTxt = 'GM';
        } else {
            resultTxt = 'C';
        }
    }


    return {num: resultNum, txt: resultTxt};
};

export const _sorting_objects = (array, filter, flag) => {
    let temp_object = {};
    for (let data in array) {
        temp_object[data] = sortChampData(array[data], filter, flag);
    }
    return temp_object;
};

export const sortChampData = (data, filter, extra = () => {
},) => {
    try {
        const copyData = [...data];
        const ascending = [-1, 1, 0];
        const decending = [1, -1, 0];


        copyData.sort((a, b) => {
            const firstFilter = extra && extra(a, b);
            if (!firstFilter) {
                for (const condition in filter) {
                    const compare = filter[condition];
                    const sortMath = compare?.reverseSort ? compare.sortType ? [...decending] : [...ascending] : compare.sortType ? [...ascending] : [...decending];
                    if (a[compare.value] < b[compare.value]) {
                        return sortMath[0];
                    } else if (a[compare.value] > b[compare.value]) {
                        return sortMath[1];
                    }
                }
                return 0;
            } else {
                return firstFilter
            }

        })


        return copyData.map((data, index) => {
            return {
                ...data,
                cid: index,
            }
        });
    } catch (e) {
        console.log("sortingError", e);
        return data;
    }

}

// 객체 안의 객체 정렬
export const getSortedObject = (data, attr, flag) => {
    let arr = [];
    for (const prop in data) {
        if (data.hasOwnProperty(prop)) {
            let obj = {};
            obj[prop] = data[prop];
            obj.tempSortName = data[prop][attr];
            arr.push(obj);
        }
    }

    if (flag === 'ascending') {
        arr.sort(function (a, b) {
            let at = a.tempSortName,
                bt = b.tempSortName;
            return at > bt ? 1 : at < bt ? -1 : 0;
        });
    } else {
        arr.sort(function (a, b) {
            let at = a.tempSortName,
                bt = b.tempSortName;
            return at < bt ? 1 : at > bt ? -1 : 0;
        });
    }

    let result = [];
    for (let i = 0, l = arr.length; i < l; i++) {
        let obj = arr[i];
        delete obj.tempSortName;
        let id = 0;
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                id = prop;
            }
        }
        let item = obj[id];
        item.cid = Number(id);
        result.push(item);
    }

    // 배열이 리턴
    return result;
};

// 배열 객체를 정렬
export const getSortedArray = (array) => {
    let result = [];
    for (let i = 0; i < array.length; i++) {
        for (const key in array[i]) {
            result.push(array[i][key]);
        }
    }

    return result;
};

// 데미지를 퍼센트로 바꾸기
export const getDamagePercent = (arg, array, txt) => {
    let maxDamage = getSortedObject(getSortedArray(array), txt);
    let result = ((arg / maxDamage[0][txt]) * 100).toFixed(1);

    return result;
};

// 챔피언 중에 스킬 마스터 렙이 다른 챔피언이 있어서 스킬 마스터 count를 구해야함!
export const getMaxCount = (type, championId) => {
    /***
     * championId
     - 77 : 우디르 (6 6 6 6)
     - 126 : 제이스 (6 6 6 1)
     - 350 : 유미 (6 5 5 3)
     - 523 : 아펠리오스 (6 6 6 3)
     ***/
    let count = 5;
    if (type === 'Q') {
        if (
            championId === '77' ||
            championId === '126' ||
            championId === '350' ||
            championId === '523'
        ) {
            count = 6;
        }
    } else if (type === 'W') {
        if (championId === '77' || championId === '126' || championId === '523') {
            count = 6;
        }
    } else if (type === 'E') {
        if (championId === '77' || championId === '126' || championId === '523') {
            count = 6;
        }
    }

    return count;
};

// 점수 색상 바꾸기 (4 color)
export const scoreColor = (num = '-') => {
    if(Number(num) === 100) {
        return <span className="score_color verygood">100</span>;
    } else if (num >= 75) {
        return <span className="score_color verygood">{num}</span>;
    } else if (num >= 50) {
        return <span className="score_color good">{num}</span>;
    } else if (num >= 25) {
        return <span className="score_color bad">{num}</span>;
    } else {
        return <span className="score_color verybad">{num}</span>;
    }
};

// kda 색상 바꾸기 (3 color)
export const kdaColor = (num) => {
    if (num >= 4) {
        return <span className="kda_color high">{num}</span>;
    } else if (num >= 3) {
        return <span className="kda_color highMiddle">{num}</span>;
    } else if (num >= 2) {
        return <span className="kda_color middle">{num}</span>;
    } else {
        return <span className="kda_color low">{num}</span>;
    }
};

// 티어 뱃지 변경
export const tierBadge = (tier) => {
    if (tier) {

        if (tier === 'C') {
            return <span className="tier_badge challenger">{tier}</span>;
        } else if (tier === 'GM') {
            return <span className="tier_badge grandmaster"><span className={'grandmastertext'}>{tier}</span></span>;
        } else if (tier === 'M') {
            return <span className="tier_badge master">{tier}</span>;
        } else {
            switch (tier[0]) {
                case 'D':
                    return <span className="tier_badge diamond">{tier}</span>;
                case 'P':
                    return <span className="tier_badge platinum">{tier}</span>;
                case 'G':
                    return <span className="tier_badge gold">{tier}</span>;
                case 'S':
                    return <span className="tier_badge silver">{tier}</span>;
                case 'B':
                    return <span className="tier_badge bronze">{tier}</span>;
                case 'I':
                    return <span className="tier_badge iron">{tier}</span>;
                default:
                    return <span className="tier_badge unranked">U</span>;
            }
        }
    }
    return <span className="tier_badge unranked">U</span>
};

// 티어 텍스트 변경
export const tierText = (tier, division) => {
    let div = 0;
    if (division === 4) {
        div = 4;
    } else if (division === 3) {
        div = 3;
    } else if (division === 2) {
        div = 2;
    } else if (division === 1) {
        div = 1;
    }
    switch (tier) {
        case 'CHALLENGER':
            return <span className="tier_color challenger">Challenger</span>;
        case 'GRANDMASTER':
            return <span className="tier_color grandmaster">Grandmaster</span>;
        case 'MASTER':
            return <span className="tier_color master">Master</span>;
        case 'DIAMOND':
            return <span className="tier_color diamond">Diamond {`${div}`}</span>;
        case 'PLATINUM':
            return <span className="tier_color platinum">Platinum {`${div}`}</span>;
        case 'GOLD':
            return <span className="tier_color gold">Gold {`${div}`}</span>;
        case 'SILVER':
            return <span className="tier_color silver">Silver {`${div}`}</span>;
        case 'BRONZE':
            return <span className="tier_color bronze">Bronze {`${div}`}</span>;
        case 'IRON':
            return <span className="tier_color iron">Iron {`${div}`}</span>;
        default:
            return <span className="tier_color unrank">Unranked</span>;
    }
};

// 평균 티어 텍스트 변경2
export const tierAvgText = (tier) => {
    let tmp = tier.split(' ');
    let div = 0;
    if (tmp[1] === 'IV') {
        div = 4;
    } else if (tmp[1] === 'III') {
        div = 3;
    } else if (tmp[1] === 'II') {
        div = 2;
    } else if (tmp[1] === 'I') {
        div = 1;
    } else {
        div = '';
    }
    return (
        <>
            <span className="tier_avg t">{tmp[0]}</span>
            <span className="tier_avg d">{div ?? ''}</span>
        </>
    );
};

// 분당 cs구하기
export const csPerMinute = (cs, time) => {
    let tmp = time.split(':');
    let minute = parseInt(tmp[1]);

    return (cs / minute).toFixed(1);
};

// 라인이름 줄이기
export const cutLaneName = (lane) => {
    let result = '';
    switch (lane) {
        case 'Top':
            result = 'Top';
            break;
        case 'Middle':
            result = 'Mid';
            break;
        case 'Jungle':
            result = 'Jug';
            break;
        case 'Bot':
            result = 'Bot';
            break;
        case 'Supporter':
            result = 'Sup';
            break;
        default:
            result = 0;
            break;
    }
    return result;
};

export const isAram = (data) => {
    switch (data) {
        case 450 :
            return true;
        case 720 :
            return true;
        default:
            return false;
    }
};

export const noPositionCheck = (queue_id) => {
    switch (queue_id) {
        case 450 :
            return false;
        case 900 :
            return false;
        case 1900 :
            return false;
        case 720 :
            return false;
        default:
            return true;
    }
}
