import React from 'react';
import styled from "styled-components";
import SimpleTooltip from "../../../common/SimpleTooltip";
import {useTranslation} from "react-i18next";
import {scoreColor} from "../../../../function";


const Wrapper = styled.div`
  padding-top: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
`;

const HeaderText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 61.2%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WinLoseSpan = styled.span`
  font-size: 12px;
`;

const WinLoseAi = styled.span`
  text-align: center;
  min-width: 3ch;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.color};
`;

const WinLoseWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


const AiText = styled.span`
  margin-left: 5px;
  font-size: 47px;
  font-weight: bold;
`;

const WinLoseText = styled.span`
  margin-bottom: 5px;
  font-size: 12px;
  color: #313131;
`;


const InfoAIScore = ({win, aiWin, lose, aiLose, aiScore}) => {
    const {t} = useTranslation();
    return (<>
        <Wrapper>
            <HeaderWrapper>
                <HeaderText>AI-Score</HeaderText>
                <ImageWrapper>
                    <SimpleTooltip
                        imgSrc={'/images/list_btn_tip_s_w.svg'}
                        tooltipText={t('summoner.aiScorePredictionExplain')}
                    />
                </ImageWrapper>
            </HeaderWrapper>
            <ContentWrapper>
                <TextWrapper>
                    <WinLoseWrapper>
                        <WinLoseSpan>{win}</WinLoseSpan>
                        <WinLoseText>{t('summoner.contractionWin')}</WinLoseText>
                    </WinLoseWrapper>
                    <WinLoseAi>{isNaN(aiWin) ? scoreColor(0) : scoreColor(aiWin)}</WinLoseAi>
                </TextWrapper>
                <TextWrapper>
                    <WinLoseWrapper>
                        <WinLoseSpan>{lose}</WinLoseSpan>
                        <WinLoseText>{t('summoner.contractionLose')}</WinLoseText>
                    </WinLoseWrapper>
                    <WinLoseAi>{isNaN(aiLose) ? scoreColor(0) : scoreColor(aiLose)}</WinLoseAi>
                </TextWrapper>
                <AiText>{scoreColor(aiScore)}</AiText>
            </ContentWrapper>
        </Wrapper>
    </>);
};

export default InfoAIScore;