import React, {useCallback, useMemo, useState} from 'react';
import styled from "styled-components";
import SearchListLI from "./ListLI";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  height: 52px;
  display: flex;
  padding-inline: 10px;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ListUL = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
`;


const SearchListByLane = ({onClick}) => {
    const [selected, setSelected] = useState(0);
    const {t} = useTranslation();
    const list = useMemo(() => [t('championAnalyze.all'), t('championAnalyze.top'), t('championAnalyze.jungle'), t('championAnalyze.mid'), t('championAnalyze.bot'), t('championAnalyze.support'),t('championAnalyze.rotation')], [t]);
    const laneName = useMemo(() => ['All', 'Top', 'Jungle', 'Middle', 'Bot', 'Supporter','Rotation'], [])
    const onClickLi = useCallback((value, lane) => {
        if (value !== undefined && lane !== undefined) {
            setSelected(value);
            onClick(value, lane)
        }
    }, []);

    return (
        <Wrapper>
            <ListUL>
                {list.map((data, index) => {
                    return (
                        <SearchListLI
                            lane={laneName[index]}
                            key={data + "laneData"}
                            data={data}
                            index={index}
                            onClick={onClickLi}
                            selected={selected === index}
                        />
                    )
                })}
            </ListUL>
        </Wrapper>
    );
};

export default React.memo(SearchListByLane);