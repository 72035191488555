// 액션 생성
const SEARCHLIST = 'searchList/SEARCHLIST';

// 액션 함수 내보내기
export const searchList = (searchs, flag) => ({type: SEARCHLIST, searchs, flag});

// 초기 상태 값
const initialState = {
    searchs: [],
    flag: 'add',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCHLIST:
            const arr = state.searchs.slice(-12);
            let filtered;
            if (action.flag === 'add') {
                filtered = arr.filter((data, index) => data?.summoner_basic_info_dict?.summoner_id !== action?.searchs?.summoner_basic_info_dict?.summoner_id);

                filtered.push(action.searchs);
            } else {
                filtered = arr.filter((e) => e?.summoner_basic_info_dict?.summoner_id !== action?.searchs?.summoner_basic_info_dict?.summoner_id)
            }
            return {
                searchs: [...filtered],
            };
        default:
            if (state.searchs.length > 12) {
                return {
                    ...state,
                    searchs: state.searchs.slice(-12),
                }
            } else {
                return state;
            }
    }
};

export default reducer;
