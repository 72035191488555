import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import RankingHeader from "./RankingHeader";
import RankingTable from "./RankingTable";
import RankingTableNavigator from "./RankingTable/RankingTableContent/RankingTableNavigator";
import {useHistory, useLocation, useParams} from "react-router-dom";
import useQuery from "../../../common/hooks/useQuery";
import regionList from "../../../store/static_json/regionList";
import {useDispatch, useSelector} from "react-redux";
import {GET_RANKING_REQUEST} from "../../../store/reducer/ranking";
import {capitalizeLetter} from "../../../function";
import {Loading} from "../../common";
import InternalServerError from "../../common/InternalError";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 21px;
  margin-bottom: 10vh;
`;

const AbsoluteBackground = styled.div`
  position: absolute;
  width: 100%;
  min-width: 1300px;
  height: 480px;
  top: 0;
  background: transparent linear-gradient(90deg, #8FA5C2 0%, #6277A1 100%) 0 0;
`;

const ContentWrapper = styled.div`
  width: 1152px;
  border-radius: 6px;
  border: 1px solid #A5BED2;
  background-color: #fff;
  padding: 26px 12px;
  position: relative;
  z-index: 1;
`;


const RankingContent = () => {
    const query = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();
    const {position, platform} = useParams();
    const {
        ranking,
        getRankingLoading,
        getRankingDone,
        getRankingError,
    } = useSelector((state) => state.ranking);
    const version = useSelector((state) => state.gameVersion.version);
    const currentPage = useMemo(() => Number(query.get('page')) > 0 ? Number(query.get('page')) : 1, [query.get('page')]);
    const rankingRegion = useMemo(() => regionList.find((data) => data.title === platform), [platform]);

    const onClickToFist = useCallback(() => {
        query.set('page', "1");
        const url = `/ranking/${platform}/${position.toLowerCase()}?${query.toString()}`
        history.push(url);
    }, [currentPage, position, platform]);

    const onClickToEnd = useCallback(() => {
        query.set('page', ranking?.total_page?.toString());
        const url = `/ranking/${platform}/${position.toLowerCase()}?${query.toString()}`
        history.push(url);
    }, [currentPage, position, platform,ranking]);

    const onClickPrev = useCallback(() => {
        if (currentPage > 1) {
            query.set('page', (Number(currentPage) - 1).toString());
            const url = `/ranking/${platform}/${position.toLowerCase()}?${query.toString()}`
            history.push(url);
        }
    }, [currentPage, position, platform]);

    const onClickNext = useCallback(() => {
        if (currentPage < ranking?.total_page) {
            query.set('page', (Number(currentPage) + 1).toString());
            const url = `/ranking/${platform}/${position.toLowerCase()}?${query.toString()}`
            history.push(url);
        }
    }, [currentPage, position, ranking, platform]);

    useEffect(() => {
        dispatch({
            type: GET_RANKING_REQUEST,
            data: {
                platform: rankingRegion.title,
                page: currentPage,
                lane: capitalizeLetter(position),
            }
        })
    }, [position, currentPage, rankingRegion.title]);



    return (
        <Wrapper>
            <AbsoluteBackground/>
            <ContentWrapper>
                <RankingHeader
                    challengerLp={ranking?.challenger_lp}
                    grandMasterLp={ranking?.grandmaster_lp}
                    rankingRegion={rankingRegion}
                />
                <RankingTable
                    version={version}
                    getRankingLoading={getRankingLoading}
                    getRankingError={getRankingError}
                    ranking={ranking}
                    currentLane={position}
                    rankingRegion={rankingRegion}
                />
                {!getRankingLoading &&
                    <RankingTableNavigator
                        onClickToEnd={onClickToEnd}
                        onClickToFist={onClickToFist}
                        onClickPrev={onClickPrev}
                        onClickNext={onClickNext}
                        currentPage={currentPage}
                        lastPage={ranking?.total_page}
                    />
                }
            </ContentWrapper>
        </Wrapper>
    );
};

export default RankingContent;
