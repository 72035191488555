import React, {useCallback} from 'react';
import styled from "styled-components";
import FilterArrow from "../../../../../common/FilterArrow";

const Wrapper = styled.th`
  padding-block: 8px;
  //background-color: #F1F3F8;
`;

const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const HeaderText = styled.span`
  display: flex;
  font-size: 12px;
  white-space: nowrap;
  ${props => {
    if (props.clickable) {
      return `&:hover {
          cursor: pointer;
        }`
    }
  }}

`;

const ArrowImage = styled.img`
`;


const RankingTableHeader = ({
                                percent, title, sort, absolute, onClickSort = () => {}, currentSort
                            }) => {
    const onClickHeader = useCallback(() => {
        if (sort) {
            onClickSort(sort)
        }
    }, []);


    return (
        <Wrapper onClick={onClickHeader} percent={percent}>
            <HeaderTextWrapper>
                <HeaderText clickable={sort ? true : false}>
                    {title}
                </HeaderText>
                {sort !== undefined &&
                    <FilterArrow
                        selected={currentSort[0].value === sort.value}
                        arrow={sort.sortType}
                    />
                }
            </HeaderTextWrapper>
        </Wrapper>);
};

export default React.memo(RankingTableHeader);