import React, {useCallback} from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const DmageText = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: 500;
  color: ${props => props.color};
`;

const Graph = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 4px;
  background-color: ${props => {
    let bgColor = '#ffffff';
    if (props.bgColor) bgColor = props.bgColor;
    return bgColor;
}};
`;
const Filled = styled.div`
  height: 4px;
  width: ${props => props.percent}%;
  background-color: ${props => {
    let color = '#69788D';
    if (props.percent >= 75) color = '#F19000';
    else if (props.percent >= 50) color = '#259A7E';
    else if (props.percent >= 25) color = '#DE5353';
    if(props.staticColor && typeof props.staticColor === 'string') color = props.staticColor;
    return color;
  }};
  
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const PercentText = styled.div`
  color: #313131;
  font-size: 12px;
  opacity: 0.6;
  transform: scale(0.775);
  margin-left: 1px;
  line-height: 15px;
`;

const WinRateGraph = ({color, barColor, total, dmg, bgColor, addPercent}) => {

    const calcFilled = useCallback(() => {
        return Math.trunc(dmg / total * 100);
    }, [total, dmg]);


    return (
        <Wrapper>
            <TextWrapper>
                <DmageText color={color}>{dmg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</DmageText>
                {addPercent &&
                    <PercentText>
                        %
                    </PercentText>
                }
            </TextWrapper>
            <Graph bgColor={bgColor}>
                <Filled percent={calcFilled()} color={barColor}/>
            </Graph>
        </Wrapper>
    );
};

export default WinRateGraph;