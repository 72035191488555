import React, {useCallback} from 'react';
import styled from "styled-components";

const ListLI = styled.li`
  display: flex;
  padding-block: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${props => {
      let color = '#F1F3F9';
      if(props.selected) color = '#fff';
      return color;
  }};
  flex: 1;
  border-right: 1px solid ${props => {
    let color = '#A5BED2';
    if (props.last) color = 'transparent';
    return color;
  }};
`;

const ListText = styled.span`
  margin-left: 4px;
  text-align: center;
  font-size: 12px;
  color: ${props => {
    let color = '#828282';
    if (props.selected) color = '#313131';
    return color;
  }};
  font-weight: ${props => {
    let color = '';
    if (props.selected) color = 'bold';
    return color;
  }};;
  line-height: 22px;
`;

const LaneImage = styled.img`
  opacity: ${props => {
    let opacity = '0.5';
    if (props.selected) opacity = '1';
    return opacity
  }};
`;

const RankListLI = ({data, index, selected, onClick, last, lane = "Top"}) => {
    const onClickList = useCallback(() => {
        if (onClick !== undefined && index !== undefined) {
            onClick(index,lane);
        }
    }, []);


    return (
        <ListLI
            last={last}
            onClick={onClickList}
            selected={selected}
        >
            <LaneImage
                selected={selected}
                width={16} height={16} src={`/images/icon_s_position_${lane.toLowerCase()}.svg`}/>
            <ListText
                selected={selected}>{data}</ListText>
        </ListLI>
    );
};


export default React.memo(RankListLI);