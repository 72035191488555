import React from 'react';
import dompurify from "dompurify";
import styled from "styled-components";

const SimpleToolTipWrapper = styled.div`
  display: none;
  position: relative;
  z-index: 200;
  font-size: 11px;
  color: #fff;
  line-height: 1.4;
`;

const SimpleToolTipText = styled.span`
  text-align: center;
  background-color: rgba(10, 20, 30, 0.98);
  padding: 8px 10px;
  display: flex;
  flex-shrink: 0;
  transform: translateX(-50%);
  white-space: pre-wrap;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

  &::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    display: block;
    width: 6px;
    height: 4px;
    margin-left: -3px;
    background: url('/images/ico_dropbox_arrow_down_w.svg') no-repeat center;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  &:hover > .tooltipWrapper > .tooltip,
  &:active > .tooltipWrapper > .tooltip {
    display: flex;
  }
`;

const AbosluteWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;

  z-index: 200;
  margin-bottom: 8px;
  animation: tooltip-show 0.5s;


  @keyframes tooltip-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

`;


const SimpleTooltip = ({imgSrc, tooltipText}) => {
    return (
        <TooltipWrapper>
            <img
                src={imgSrc}
                alt={'simpleTooltipAlt'}
            />
            <AbosluteWrapper className={'tooltipWrapper'}>
                <SimpleToolTipWrapper className={'tooltip'}>
                    <SimpleToolTipText>{tooltipText}</SimpleToolTipText>
                </SimpleToolTipWrapper>
            </AbosluteWrapper>
        </TooltipWrapper>
    );
};

export default SimpleTooltip;