import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {MatchItemContainer} from '../../styled/SummonerStyle';
import styled from "styled-components";
import {
    csPerMinute,
    getGameTime,
    getKda,
    getTimeHMS,
    isAram,
    kdaColor, noPositionCheck,
    onStorePuuId,
    sortChampData
} from "../../function";
import {ImageChamp, ImageItem, ImageLane, ImageRune, ImageSpell} from "../common";
import MatchChampImg from "./MathItem/ChampImg";
import {openModal} from "../../store/modules/modal";
import ScoreWrapper from "./DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import {ChartScore} from "../charts";
import {Link} from "react-router-dom";
import MatchDetail from "./MatchDetail";
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import DamageGraph from "./DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import MultiKill from "../common/MultiKill";


const ChartScoreDiv = styled.div`
  width: 170px;
`;

const ChampImageWrapper = styled.div`
  position: relative;
  display: flex;
`;

const ChampImageAbsoluteLevel = styled.div`
  position: absolute;
  display: flex;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;

const RuneWrapper = styled.div`
  cursor: pointer;
`;

const AwayText = styled.span`
  opacity: 1;
  color: #c24a4a;
`;

const MarginTopDiv = styled.div`
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: ${props => {
    let content = 'space-between';
    if (props.isUrf) content = 'center';
    return content;
  }};
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: ${props => props.paddingTop}px;
  padding-bottom: ${props => props.paddingBottom}px;
  margin-left: ${props => props.marginLeft}px;
`;
const RowBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  padding-top: ${props => props.paddingTop}px;
  padding-bottom: ${props => props.paddingBottom}px;
  margin-left: 8px;
  margin-right: 5px;
`;


const LaneBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

// noinspection CssUnknownTarget
const CrownMvp = styled.span`
  background-image: url('images/ico_best_player01.svg');
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 2px;
`;

// noinspection CssUnknownTarget
const CrownAce = styled.span`
  background-image: url('images/ico_best_player04.svg');
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 2px;
`;


const DamageGraphHeader = styled.div`
  width: 140px;
  margin-left: 9px;
  margin-bottom: 6px;
`;

const DamageBackground = styled.div`
  width: 140px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  background: rgba(255, 255, 255, 0.57);
  border-radius: 3px;
  padding: 0 8px 18px;
  margin-left: 9px;
`;

const DamageGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px 0;
  height: 100%;
  width: 170px;
`;


const MatchItem = ({match, openId, handleMoreInfo, region, currentLang, summoner, openButton = true}) => {
    const {t} = useTranslation();
    const [myMatch, setMyMatch] = useState({});
    const dispatch = useDispatch();
    const [openIndex, setOpenIndex] = useState(0);
    const [hide, setHide] = useState(false);
    const onChangeHide = useCallback(() => {
        setHide(prev => !prev);
    }, []);

    const allDmg = useMemo(() => {
        if (Array.isArray(match?.participants_list) && match.participants_list.length > 0) {
            return match.participants_list.reduce((sum, data, index) => {
                if (sum.totaldmg < data.total_damage_dealt) {
                    sum.totaldmg = data.total_damage_dealt;
                }

                if (sum.totalTaken < data.total_damage_taken) {
                    sum.totalTaken = data.total_damage_taken;
                }

                return sum;
            }, {totaldmg: 0, totalTaken: 0});
        }

    }, [match]);
    const RatingRender = useCallback(({match, rating}) => {
        if (!match.is_runaway) {
            if (rating === 1) {
                return (
                    <>
                        <i>{`1`}</i>{t('summoner.firstGrade')}
                    </>
                )
            }
            if (rating === 2) {
                return (
                    <>
                        <i>{`2`}</i>{t('summoner.secondGrade')}
                    </>
                )
            }
            if (rating === 3) {
                return (
                    <>
                        <i>{`3`}</i>{t('summoner.thirdGrade')}
                    </>
                )
            }
            return (
                <>
                    <i>{rating}</i>{t('summoner.grade')}
                </>
            )
        }

        return (
            <AwayText>{t("tags.escape")}</AwayText>
        )

    }, [])


    useEffect(() => {
        const list = match?.participants_list
        if (Array.isArray(list)) {
            const item = list.find((data) => data.puu_id === summoner?.summoner_basic_info_dict?.puu_id);
            if (typeof list === 'object') {
                setMyMatch(item)
            }
        }
    }, [match, summoner]);

    useEffect(() => {
        setHide(false);
    },[openId]);


    const onClickGraph = useCallback((e) => {
        e.stopPropagation();
        setOpenIndex(1);
        handleMoreInfo(match.match_basic_dict.match_id)
    }, [match, handleMoreInfo]);

    const onClickOpen = useCallback((e) => {
        setOpenIndex(0);
        handleMoreInfo(match.match_basic_dict.match_id)
    }, [match, handleMoreInfo])


    const setHms = useCallback((time) => {
        const hour = time > 3600 ? time / 3600 : 0;
        const min = time / 60;
        const sec = time % 60;
        return `${hour}:${min}:${sec}`;
    }, []);


    const aiSorted = useMemo(() => {
        return sortChampData(match?.participants_list, [{value: "ai_score", sortType: false}]);
    }, [match, myMatch]);
    const damageSorted = useMemo(() => {
        return sortChampData(match?.participants_list, [{value: "total_damage_dealt", sortType: false}]);
    }, [match, myMatch])

    const getRank = useMemo(() => {
        if (Array.isArray(match?.participants_list)) {
            const index = aiSorted.findIndex((data) => data.puu_id === myMatch.puu_id);
            return index + 1;
        }
        return 0;
    }, [match, myMatch, aiSorted]);

    const getDamageRank = useMemo(() => {
        if (Array.isArray(match?.participants_list) && isAram(match?.match_basic_dict?.queue_id)) {
            const index = damageSorted.findIndex((data) => data.puu_id === myMatch.puu_id);
            return index + 1;
        }
        return 0;
    }, [match, myMatch, aiSorted]);

    const getMvp = useMemo(() => {
        if (Array.isArray(match?.participants_list)) {
            const filteredTeam = match?.participants_list.filter((data) => data.side === myMatch.side);
            const sorted = sortChampData(filteredTeam, [{value: "ai_score", sortType: false}]);
            const index = sorted.findIndex((data) => data.puu_id === myMatch.puu_id);
            return index === 0;
        }
    }, [match, myMatch]);


    const barColor = useCallback(() => {
        switch (getDamageRank) {
            case 1:
                return '#F3A634';
            case 2:
                return '#F3A634';
            case 3:
                return '#259A7E';
            case 4:
                return '#259A7E';
            case 5:
                return '#259A7E';
            case 6:
                return '#DE5353';
            case 7:
                return '#DE5353';
            case 8:
                return '#DE5353';
            default:
                return '#69788D';
        }
    }, [getDamageRank]);


    const getName = useCallback((player) =>  {
        const name = player?.summoner_name;
        if (typeof name === 'string' && name.length > 0) {
            if(hide) {
                return new Array(name.length).fill('').map(() => "*");
            } else {
                return name;
            }
        }
        return "";
    },[hide]);

    const getMatchName = useCallback((queueId) => {
        if (queueId) {
            switch (queueId) {
                case 0 :
                    return t('summoner.custom');
                case 420 :
                    return t('summoner.rankedSolo');
                case 430 :
                    return t('summoner.blind');
                case 440 :
                    return t('summoner.rankedFlex');
                case 450 :
                    return t('summoner.aram');
                case 900 :
                    return 'U.R.F';
                case 1400:
                    return t('summoner.ultbook');
                case 1900 :
                    return 'U.R.F';
                case 700 :
                    return t('summoner.clash');
                case 720 :
                    return t('summoner.clashARAM')
            }
        }
        return "";
    }, [t]);


    return (
        <MatchItemContainer>
            {Object.keys(myMatch).length !== 0 && (
                <div
                    className={`rowContainer ${match.remake ? 'remake' : myMatch.is_win ? 'win' : 'lose'} ${openId ? 'open' : 'close'}`}
                >
                    <div className="row" onClick={onClickOpen}>
                        <div className="col flex_column0"></div>
                        <div className="col flex_column1">
                            <MarginTopDiv paddingTop={20} paddingBottom={20}>
                                <h5>
                                    {getMatchName(match.match_basic_dict.queue_id)}
                                </h5>
                                <p>
                                    {getGameTime(
                                        Number(match.match_basic_dict.creation_timestamp + match.match_basic_dict.game_duration),
                                        currentLang
                                    )}
                                </p>
                                <dt>
                              <span>
                                {match.remake
                                    ? t('summoner.remake')
                                    : myMatch.is_win
                                        ? t('summoner.win')
                                        : t('summoner.lose')}
                              </span>
                                    {getTimeHMS(setHms(match.match_basic_dict.game_duration), 'no', currentLang)}
                                </dt>
                            </MarginTopDiv>
                        </div>
                        <div className="col flex_column2">
                            <dl>
                                <dt className={`${myMatch.is_runaway ? 'away' : 'home'}`}>
                                    <ChampionImageWithLane
                                        disableImage={!noPositionCheck(match.match_basic_dict.queue_id)}
                                        champion_id={myMatch.champion_id}
                                        position={myMatch.position}
                                    />
                                </dt>
                                <dd>
                                    <ImageSpell spellId={myMatch.spell_id_dict.spell_1} smite={myMatch.smite}/>
                                    <ImageSpell spellId={myMatch.spell_id_dict.spell_2} smite={myMatch.smite}/>
                                </dd>
                                <dd>
                                    <RuneWrapper onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(openModal(true, myMatch))
                                    }}>
                                        <ImageRune runeId={myMatch.rune_detail_dict.perk_0}/>
                                        <ImageRune runeId={myMatch.rune_detail_dict.perk_sub_style}/>
                                    </RuneWrapper>
                                </dd>
                            </dl>
                        </div>
                        <div className="col flex_column3">
                            <RowBox paddingTop={20} paddingBottom={15}>
                                <MarginTopDiv>
                                    <h5>AI-Score</h5>
                                    <MarginTopDiv onClick={(e) => {
                                        if (!isAram(match.match_basic_dict.queue_id)) {
                                            e.stopPropagation();
                                            dispatch(openModal(true, myMatch, match))
                                        }
                                    }}>
                                        <ScoreWrapper
                                            noneClickable={isAram(match.match_basic_dict.queue_id)}
                                            paddingHorizontal={8}
                                            score={myMatch.is_runaway ? 0 : Math.trunc(myMatch.ai_score)}
                                        />
                                    </MarginTopDiv>

                                    <p>
                                        {myMatch?.is_win ? (getMvp && <CrownMvp/>) : (getMvp && <CrownAce/>)}
                                        {match.remake ? (
                                            <>
                                                <i>-</i>
                                            </>
                                        ) : (
                                            <RatingRender
                                                rating={getRank}
                                                match={myMatch}
                                            />

                                        )}
                                    </p>
                                </MarginTopDiv>
                            </RowBox>
                            {isAram(match.match_basic_dict.queue_id) ?
                                <DamageGraphWrapper>
                                    <DamageGraphHeader>{t('gameAnalytics.damage')}</DamageGraphHeader>
                                    <DamageBackground>
                                        <DamageGraph
                                            grade={getDamageRank}
                                            indicator={true}
                                            reverse={true}
                                            dmg={myMatch.total_damage_dealt}
                                            total={allDmg?.totaldmg}
                                            color={'#313131'}
                                            barColor={barColor()}
                                            bgColor={'#BFD1DF'}
                                        />
                                    </DamageBackground>
                                </DamageGraphWrapper>
                                :
                                <ChartScoreDiv>
                                    <ChartScore
                                        time={match.match_basic_dict.game_duration}
                                        marginTop={20}
                                        marginBottom={18}
                                        gridColor={myMatch.is_win ? "rgba(179, 210, 233, 1)" : "rgba(235, 206, 204, 1)"}
                                        data={match.time_analysis}
                                        myMatch={myMatch}
                                        width={170}
                                        height={100}
                                        label={true}
                                    />
                                </ChartScoreDiv>
                            }
                        </div>

                        <div className="col flex_column4">
                            {/*<p className="type1">*/}
                            {/*    Level<span>{myMatch.champLevel}</span>*/}
                            {/*</p>*/}
                            <MarginTopDiv paddingTop={18} paddingBottom={7}>
                                <p className="type2">
                                    <span>{myMatch.final_stat_dict.kills}</span>/<span
                                    className="death">{myMatch.final_stat_dict.deaths}</span>/
                                    <span>{myMatch.final_stat_dict.assists}</span>
                                </p>
                                <p className="type3">
                                    {myMatch.final_stat_dict.deaths === 0 ? (
                                        <span className="kda_color perfect" style={{marginTop: 3}}>Perfect</span>
                                    ) : (
                                        <>
                                            <span style={{marginTop: 3}}>
                                            {kdaColor(getKda(myMatch.final_stat_dict.kills, myMatch.final_stat_dict.deaths, myMatch.final_stat_dict.assists).toFixed(2))} KDA</span>

                                        </>
                                    )}
                                </p>
                                <p className="type4">
                                    <span>{myMatch.final_stat_dict.cs}</span>CS
                                    (<i>{csPerMinute(myMatch.final_stat_dict.cs, setHms(match.match_basic_dict.game_duration))}</i>/
                                    {t('summoner.minute')})
                                </p>
                                <MultiKill
                                    triple={myMatch?.triple_kills}
                                    penta={myMatch?.penta_kills}
                                    quadra={myMatch?.quadra_kills}
                                />
                            </MarginTopDiv>

                        </div>
                        <div className="col flex_column5">
                            <MarginTopDiv paddingTop={25} paddingBottom={20}>
                                <div className="cbox">
                                    <ImageItem itemId={myMatch.final_item_dict.item_0}/>
                                    <ImageItem itemId={myMatch.final_item_dict.item_1}/>
                                    <ImageItem itemId={myMatch.final_item_dict.item_2}/>
                                    <ImageItem itemId={myMatch.final_item_dict.item_6}/>
                                    <ImageItem itemId={myMatch.final_item_dict.item_3}/>
                                    <ImageItem itemId={myMatch.final_item_dict.item_4}/>
                                    <ImageItem itemId={myMatch.final_item_dict.item_5}/>
                                    <img
                                        loading={"lazy"}
                                        src="/images/btn_item_build_search_w.svg"
                                        className="build_detail"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch(openModal(true, myMatch))
                                        }}
                                        alt={t('buttons.detail')}
                                    />
                                </div>
                            </MarginTopDiv>
                        </div>
                        <div className="col flex_column6">
                            <div className="team">
                                {match.participants_list.map((player, index) => {
                                    const name = getName(player);
                                    return (
                                        index < 5 && (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                }}
                                                key={index}
                                            >
                                                <ChampionImageWithLane champion_id={player.champion_id}
                                                                       borderRadius={hide ? '4px' : player.puu_id === myMatch.puu_id ? '50%' : '4px'}/>
                                                <p className={hide ? "" : player.puu_id === myMatch.puu_id ? "my" : ""}>
                                                    <span
                                                        onClick={() => onStorePuuId(player.summoner_name, region, player.puu_id)}
                                                        className="common_search"
                                                    >
                                                        {name}
                                                    </span>
                                                </p>
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                            <div className="team">
                                {match.participants_list.map((player, index) => {
                                    const name = getName(player);
                                    return (
                                        index >= 5 && (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                }}
                                                key={index}
                                            >
                                                <ChampionImageWithLane champion_id={player.champion_id}
                                                                       borderRadius={hide ? '4px' : player.puu_id === myMatch.puu_id ? '50%' : '4px'}/>
                                                <p className={hide ? "" : player.puu_id === myMatch.puu_id ? "my" : ""}>
                                                    <span
                                                        onClick={() => onStorePuuId(player.summoner_name, region, player.puu_id)}
                                                        className="common_search"
                                                    >

                                                        {name}
                                                    </span>
                                                </p>
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col flex_column7">
                            {openButton &&
                                <button onClick={onClickOpen}>
                                    <img src="/images/icon_arrow_b.svg" alt={t('buttons.detail')}/>
                                </button>
                            }
                        </div>
                    </div>
                    {openId && <MatchDetail
                        isUrf={(match.match_basic_dict.queue_id === 900 || isAram(match.match_basic_dict.queue_id))}
                        region={region}
                        summoner={summoner}
                        match={match} myMatch={myMatch}
                        openIndex={openIndex}
                        hide={hide}
                        onChangeHide={onChangeHide}
                    />}
                </div>
            )}
        </MatchItemContainer>
    );
};


const isEquals = (prev, next) => {
    return prev.match?.gameId === next.match?.gameId && prev.openId === next.openId
}
export default React.memo(MatchItem, isEquals);
