import React, {useEffect} from 'react';
import styled from "styled-components";
import {Header, Notice} from "../common";
import RankingContent from "./RankingContent";
import {useTitle} from "../../function";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
`;

const Ranking = () => {
    const {t} = useTranslation();
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const titleUpdate = useTitle('Loading...');
    setTimeout(() => titleUpdate(`${t("championsRanking.ranking")} - ${t('header.title')}`), 500);

    return (
        <Wrapper>
            <Header/>
            <Notice fixed={false}/>
            <RankingContent/>
        </Wrapper>
    );
};

export default Ranking;
