import React from 'react';
import {scoreColor} from "../../../../function";
import styled from "styled-components";

const Wrapper = styled.div`
  
  justify-content: flex-end;
  padding-bottom: 5px;
  display: flex;
  flex-direction: ${props => {
    let direction = 'row';
    if (props.reverse) direction = 'row-reverse';
    return direction;
}};
`;

const GameCountText = styled.span`
  font-size: 12px;

`;
const TranslationText = styled.span`
  color: #313131;
  opacity: 0.6;
  font-size: 12px;
  padding-left: 3px;
`;

const PercentText = styled.span`
  font-size: 12px;
  font-weight: bold;
`;
const PercentExtra = styled.span`
    font-weight: bold;
  font-size: 12px;
  color: #313131;
  opacity: 0.6;
`;

const MarginDiv = styled.div`
    margin-right: 5px;
`;


const SummonerGameCount = ({scorePercent, seasonGames, gamesTranslation, reverse}) => {

    return (
        <>
            <Wrapper
                reverse={reverse}
            >
                <div>
                    <PercentText>{scoreColor(scorePercent)}</PercentText>
                    <PercentExtra>%</PercentExtra>
                </div>
                <MarginDiv/>
                <div>
                    <GameCountText>{seasonGames}</GameCountText>
                    <TranslationText>{gamesTranslation}</TranslationText>
                </div>
            </Wrapper>
        </>
    );
};

export default SummonerGameCount;