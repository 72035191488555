import React, {useMemo} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  width: 81px;
  height: 17px;
`;

const MultiKillItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 17px;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  border-radius: 4px;
  background-color: ${props => {
    let color = 'transparent';
    if (props.triple) color = '#EBF6FF';
    if (props.quadra) color = '#FFEEEE';
    if (props.penta) color = '#FCF4EC';
    return color;
  }};

  border: 1px solid ${props => {
    let border = 'transparent';
    if (props.triple) border = '#559AFF'
    if (props.quadra) border = '#FF6186';
    if (props.penta) border = '#FF894B';
    return border;
  }};

  color: ${props => {
    let color = 'transparent';
    if (props.triple) color = '#559AFF'
    if (props.quadra) color = '#FF6186';
    if (props.penta) color = '#FF894B';
    return color;
  }};
`;

const MultiKill = ({triple, quadra, penta}) => {
const {t} = useTranslation();
    const currentKill = useMemo(() => {
        if(penta) {
            return t('summoner.multiKill.penta');
        } else if(quadra) {
            return t('summoner.multiKill.quadra');
        } else if(triple) {
            return t('summoner.multiKill.triple');
        }
        return '';

    },[triple,quadra,penta]);

    return (
        <Wrapper>
            { currentKill && <MultiKillItem triple={triple} quadra={quadra} penta={penta}>{currentKill}</MultiKillItem>}
        </Wrapper>
    );

};

export default MultiKill;
