import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import ChampCarouselItem from "./ChampCarouselItem";
import _ from "lodash";
import {sortChampData} from "../../../../../../function";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: clip;
  max-width: 920px;

  * {
    pointer-events: ${props => {
      let evetns = 'all';
      if (props.isDragging) evetns = 'none';

      return evetns;
    }};
`;

const ArrowRight = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 4px;
`;


const ChampCarouselContent = ({list, handleChamp, selectedChamp, position,visibleCount,onChangeIndicate,indicate}) => {
    const dragRef = useRef(null);;
    const staticContentValue = 54 * visibleCount;



    const onClickSelected = useCallback((e, value) => {
        handleChamp(value);
    }, []);

    const scrollToArrowLeftArrow = useCallback(() => {
        const value = staticContentValue * (indicate - 1);
        dragRef.current.scrollTo({left: value, behavior: 'smooth'});

        onChangeIndicate(indicate-1);
    }, [indicate])

    const scrollToRightArrow = useCallback((e) => {
        const value = staticContentValue * (indicate + 1);
        dragRef.current.scrollTo({left: staticContentValue * (indicate + 1), behavior: 'smooth'});
        onChangeIndicate(indicate + 1);
    }, [indicate]);

    useEffect(() => {
        dragRef.current.scrollTo({left: staticContentValue * (indicate), behavior: 'smooth'});
    },[indicate])


    useEffect(() => {
        dragRef.current.scrollTo({left: 0});
    }, [list]);


    if (!Array.isArray(list) || list.length === 0) return null;

    return (
        <Wrapper>
            <ArrowRight onClick={scrollToArrowLeftArrow}>
                    <img
                        src={'./images/ico_list_arrow_prev.svg'}
                        alt={""}
                    />
            </ArrowRight>
            <CarouselWrapper
                ref={dragRef}
            >
                {sortChampData(list,[{value: 'games', sortType: false,},{value: 'champion_id', sortType: true,}]).map((data) => {
                    return (
                        <ChampCarouselItem
                            position={position}
                            onClickSelected={onClickSelected}
                            key={data.champion_id}
                            champion_id={data.champion_id}
                            win_rate={data.win_rate}
                            games={data.games}
                            selected={selectedChamp === data.champion_id}/>
                    )
                })}
            </CarouselWrapper>
            <ArrowRight onClick={scrollToRightArrow}>
                    <img
                        src={'./images/ico_list_arrow_next.svg'}
                        alt={""}
                    />
            </ArrowRight>
        </Wrapper>
    );
};

export default ChampCarouselContent;

