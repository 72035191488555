import React, {useCallback} from 'react';
import HistoryContentPatchInfoContent from "./HistoryContentPatchInfoContent";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .infoContent:not(:last-of-type) {
    margin-bottom: 6px;
  }
`;

const HistoryContentPatchInfo = ({list, champion, version, aram, type}) => {


    return (
        <Wrapper>
            {Array.isArray(list) ? list.map((data, index) => {
                return (
                    <HistoryContentPatchInfoContent
                        background={type === 'ARAM' ? '#EFEBF9' : '#EEF0F5'}
                        champion={champion}
                        version={version}
                        spellKey={type === 'ARAM' ? 'ARAM' : data.skill_key}
                        spell={champion}
                        key={index}
                        title={data.title}
                        skillDetailList={data.skill_detail_history}
                    />
                )
            }) : null}
            {Array.isArray(aram) ? aram.map((data, index) => {
                return (
                    <HistoryContentPatchInfoContent
                        background={'#EFEBF9'}
                        champion={champion}
                        version={version}
                        spellKey={'ARAM'}
                        spell={champion}
                        key={index}
                        title={data.title}
                        skillDetailList={data.skill_detail_history}
                    />
                )
            }) : null}
        </Wrapper>
    );
};

export default HistoryContentPatchInfo;
