import React, {useCallback} from 'react';
import {ImageChamp, ImageLane} from "../ImageSrc";
import styled from "styled-components";
import MatchChampImg from "../../summoner/MathItem/ChampImg";

const ChampImageWrapper = styled.div`
  position: relative;
  display: flex;
  .imgDiv {
    z-index: 21;
    border-radius: ${props => {
      let radius = 0;
      if(props.champId) radius = '4px';
      if(props.borderRadius) radius = props.borderRadius;
      return radius;
    }};  
  }
  .imgLane {
    border-bottom-right-radius: 4px;
  }
`;

const ChampImageAbsoluteLevel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  aspect-ratio: 1;
  justify-content: center;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ChampionImageWithLane = ({champion_id, position,absoluteWidth,borderRadius,disableImage}) => {

    const checkPosition = useCallback(() => {
        if(position) {
            return position?.toUpperCase() !== 'ALL'
        } else {
            return false;
        }

    }, [position])

    return (
        <ChampImageWrapper champId={champion_id} borderRadius={borderRadius}>
            <MatchChampImg champId={champion_id}/>
            {checkPosition() && <ChampImageAbsoluteLevel absoluteWidth={absoluteWidth}>
                {!disableImage && <ImageLane lane={position} color={'white'}/>}
            </ChampImageAbsoluteLevel>}
        </ChampImageWrapper>
    );
};

export default ChampionImageWithLane;
