import React from 'react';
import styled from "styled-components";
import UpArrow from "../../../../../common/TagWithArrow/UpArrow";
import DownArrow from "../../../../../common/TagWithArrow/DownArrow";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.span`
  font-size: 12px;
  color: #676B7D;
  margin-left: 6px;
`;

const Slash = styled.span`
  font-size: 12px;
  transform: scale(0.775);
  margin-left: 8px;
  margin-right: 6px;
  opacity: 0.4;
`;

const LPText = styled.span`
  font-size: 12px;
  color: #313131;
`;

const ArrowWrapper = styled.div`
  margin-left: 8px;
`;

const RankingHeaderItem = ({img, lp,title, diff}) => {
    return (
        <Wrapper>
            <img
                src={img}
                width={24}
                height={24}
                alt={""}
            />
            <Title>
                {title}
            </Title>
            <Slash>/</Slash>
            <LPText>{lp} LP</LPText>
            <ArrowWrapper>
                {diff > 0 ? <UpArrow/> : <DownArrow/>}
            </ArrowWrapper>
        </Wrapper>
    );
};

export default RankingHeaderItem;
