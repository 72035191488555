import React, {useCallback} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #C3D1DC;
  border-radius: 6px;
  height: 32px;
  max-width: 240px;
  flex: 1;
  align-items: center;
`;

const InputBar = styled.input`
  height: 26px;
  background-color: transparent;
  color: #313131;
  border: 0;
  margin-right: 30px;
  flex: 1;
  padding-left: 5px;
  font-size: 12px;

  ::placeholder {
    opacity: 0.5;
  }
`;

const SearchButton = styled.button`
  right: 10px;
  top: 0;
  bottom: 2px;
  position: absolute;
`;

const ButtonImg = styled.img`
`;


const ChampionsInput = ({inputValue, setInputValue}) => {

    const {t} = useTranslation();
    const onChangeInputValue = useCallback((value) => {
        if (typeof value.target.value === 'string') {
            setInputValue(value.target.value);
        }
    }, []);


    return (
        <Wrapper>
            <InputBar
                value={inputValue}
                onChange={onChangeInputValue}
                placeholder={t('championAnalyze.searchPlaceholder')}
            />
            <SearchButton>
                <ButtonImg
                    src={'/images/ico_search_w.svg'}
                    width={18}
                    height={18}
                />
            </SearchButton>
        </Wrapper>
    );
};


export default React.memo(ChampionsInput);
