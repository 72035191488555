// 액션 생성
import {GET_IMAGE_DATA_FAILURE, GET_IMAGE_DATA_REQUEST, GET_IMAGE_DATA_SUCCESS} from "../reducer/imageStore";
import runeShards from "../static_json/runeShards";

const IMAGE_STORE = 'imageStore/IMAGE_STORE';

// 액션 함수 내보내기
export const imageStore = (champions, runes, spells, items, tooltip,aiData,season,streamerTag) => ({
  type: IMAGE_STORE,
  champions,
  runes,
  spells,
  items,
  tooltip,
  aiData,
  season,
  streamerTag,
});

// 초기 상태 값
const initialState = {
  champions: [],
  spells: {},
  runes: [],
  runeShards: runeShards,
  items: {},
  aiData: {
    tier_boundary_solo :[],
  },
  season:[],
  streamerTag:[],
  currentSeason: 0,
  tooltip: false,

  getImageDataLoading : false,
  getImageDataDone :false,
  getImageDataError : null,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IMAGE_DATA_REQUEST: {
      return {
        getImageDataLoading : false,
        getImageDataDone :false,
        getImageDataError : null,
      }
    }
    case GET_IMAGE_DATA_SUCCESS: {
      return {
        getImageDataLoading : false,
        getImageDataDone :true,
      }
    }
    case GET_IMAGE_DATA_FAILURE: {
      return {
        getImageDataLoading : false,
        getImageDataDone :false,
        getImageDataError : action.error,
      }
    }

    case IMAGE_STORE:
      let currentSeason = 0;
      if(action.season) {
        currentSeason = Number(Math.max.apply(null, action.season));

      }
      return {
        champions: action.champions,
        spells: action.spells,
        runes: action.runes,
        items: action.items,
        tooltip: action.tooltip,
        aiData: action.aiData,
        season: action.season,
        currentSeason: currentSeason,
        streamerTag: action.streamerTag,
      };
    default:
      return state;
  }
};
export default reducer;
