import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ChampionsRankingTableContent from "./ChampionsRankingTableContent";
import {useSelector} from "react-redux";
import ChampionRankingTableHeader from "./ChampionRankingTableHeader";
import {capitalizeLetter, sortChampData} from "../../../../../function";
import {Loading} from "../../../../common";
import InternalServerError from "../../../../common/InternalError";
import {FixedSizeList} from 'react-window';


const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TBody = styled.tbody`

  tr:nth-child(even) {
    background-color: #F2F4F9;
  }
`;


const ChampionsRankingTable = ({ranks = [], lane, sliceMultiple, onChangeMultiple}) => {
    const {t} = useTranslation();
    const version = useSelector((state) => state.gameVersion.version);
    const championData = useSelector((state) => state?.imageStore.champions);

    const componentRef = useRef();
    const sliceCount = useMemo(() => (Math.trunc(window.screen.height / 64) + 1 > 24 ? Math.trunc(window.screen.height / 64) + 1 : 25), [window]);

    const {
        getChampionsRankLoading,
        getChampionsRankError
    } = useSelector((state) => state.champions);
    const [sortData, setSortData] = useState(() => (
        {
            rank: {
                value: 'rank',
                sortType: false,
                reverseSort: true,
            },
            tier: {
                value: 'tier',
                sortType: true,
                reverseSort: true,
            },
            ai_score: {
                value: 'ai_score',
                sortType: true,
            },
            win_rate: {
                value: 'win_rate',
                sortType: true,
            },
            pick_rate: {
                value: 'pick_rate',
                sortType: true,
            },
            ban_rate: {
                value: 'ban_rate',
                sortType: true,
            },
        }
    ));

    const [sort, setSort] = useState([{
        value: 'rank',
        sortType: false,
        reverseSort: true,
    }]);


    const allOffObject = useCallback((copyData) => {
        if (typeof copyData === 'object') {
            const copy = {...copyData};
            Object.keys(copy).forEach((key) => {
                copy[key].sortType = true;
            })
            return copy;
        }
        return copyData;
    }, [])

    const onClickSort = useCallback((data) => {

        if (typeof data === 'object') {
            const copyData = {...data};
            const copySortData = {...sortData};
            if (copyData) {
                if(copySortData[copyData.value]) {
                    const allOff = allOffObject(copySortData);
                    copyData.sortType = !copyData.sortType;
                    allOff[copyData.value].sortType = copyData.sortType;
                    setSort([{...copyData}]);
                    setSortData(copySortData);
                }
            }
        }
    }, [sortData]);


    const getChampion = useCallback((id) => {
        if (Array.isArray(championData)) {
            return championData.find((data) => Number(data.championId) === Number(id));
        }
    }, [championData]);

    const rankMemo = useMemo(() => {
        const currentData = ranks.map((mapData) => ({
            ...mapData?.performance_dict[capitalizeLetter(lane)],
            champion_id: mapData.champion_id,
        }));
        return sortChampData(currentData, sort);
    }, [sort, ranks, lane]);


    const handlePagenation = useCallback(() => {
        if (Array.isArray(ranks)) {
            const scrollTop = document.documentElement.scrollTop;
            if ((scrollTop !== 0) && sliceMultiple === 1) {
                onChangeMultiple(99);
            }
        }
    }, [sliceMultiple, sliceCount, ranks]);

    useEffect(() => {
        window.addEventListener("scroll", handlePagenation);
        return () => {
            window.removeEventListener("scroll", handlePagenation);
        };
    }, []);

    if (getChampionsRankError) return (
        <InternalServerError/>
    );
    if (championData.length === 0 || getChampionsRankLoading) return (
        <Loading/>
    );

    if (ranks.length === 0) return (
        <InternalServerError errorText={"There are no data"}/>
    );

    return (
        <Wrapper ref={componentRef}>
            <colgroup>
                <col width={'12%'}/>
                <col/>
                <col width={'7%'}/>
                <col width={'20%'}/>
                <col width={'15%'}/>
                <col width={'15%'}/>
                <col width={'15%'}/>
                <col width={'5%'}/>
            </colgroup>
            <thead>
            <ChampionRankingTableHeader
                currentSort={sort}
                onClickSort={onClickSort}
                sortData={sortData}
            />
            </thead>
            <TBody>
                {Array.isArray(ranks) && rankMemo.slice(0, sliceCount * sliceMultiple).map((data, index) => {
                    return (
                        <ChampionsRankingTableContent
                            lane={lane}
                            tier={data.tier}
                            rankDelta={data.rank_delta}
                            champion={getChampion(data.champion_id)}
                            pickRate={data?.pick_rate}
                            winRate={data?.win_rate}
                            banRate={data?.ban_rate}
                            aiScore={data?.ai_score}
                            key={data.rank + lane + data.champion_id}
                            counter={data?.counter || []}
                            rank={data.rank}
                            version={version}
                        />
                    )
                })}
            </TBody>
        </Wrapper>
    );
};

export default ChampionsRankingTable;
