import React from 'react';
import styled from "styled-components";
import {getAiPrediction, scoreColor, tierBadge} from "../../../../function";
import {useSelector} from "react-redux";

const MostContentWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContentImage = styled.div`

  .imgChamp {
    width: 40px;
    aspect-ratio: 1;
  }

  .imgLane {
    width: 40px;
    aspect-ratio: 1;
  }
`;

const BadgeAndWinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.4rem;
  min-width: 3rem;
`;

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const AiScoreText = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const MarginDiv = styled.div`
  display: inline-flex;
  margin-right: 8px;
`;

const WinAndLostDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
`;

const RateText = styled.span`
  text-align: center;
  width: 3ch;
  font-size: 12px;
  color: #313131;
`;

const TransText = styled.span`
  font-size: 12px;
  color: #313131;
  opacity: 0.6;
`;


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MostContent = ({ImageComponent, queueNum, summoner, aiScore, win, lost, t, region}) => {
    const aiData = useSelector((state) => state.imageStore.aiData)[region];


    return (
        <>
            <MostContentWrapper>
                <ContentImage>
                    {ImageComponent && <ImageComponent/>}
                </ContentImage>
                <ContentWrapper>
                    <BadgeAndWinWrapper>
                        <ScoreWrapper>
                            {Object.keys(summoner?.season_tier_info_dict).length > 0 &&
                                (
                                    <MarginDiv>
                                        {((queueNum === 0 || queueNum === 420) && summoner.season_tier_info_dict.ranked_solo_5x5 !== undefined) && tierBadge(getAiPrediction(summoner.season_tier_info_dict.ranked_solo_5x5, aiScore, aiData).txt)}
                                        {queueNum === 440 && summoner.season_tier_info_dict.ranked_solo_5x5 !== undefined && tierBadge(getAiPrediction(summoner.season_tier_info_dict.ranked_solo_5x5, aiScore, aiData).txt)}
                                    </MarginDiv>
                                )
                            }
                            <AiScoreText>{scoreColor(aiScore)}</AiScoreText>
                        </ScoreWrapper>
                        <WinAndLostDiv>
                            <RateText>{win}</RateText>
                            <TransText>{t('summoner.contractionWin')}</TransText>
                            <RateText>{lost} </RateText>
                            <TransText>{t('summoner.contractionLose')}</TransText>
                        </WinAndLostDiv>
                    </BadgeAndWinWrapper>

                </ContentWrapper>
            </MostContentWrapper>
        </>
    )
        ;
};

export default MostContent;