import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import RankingTableHeader from "./RankingTableHeader";
import CRTableRanking
    from "../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTableRanking";
import RankingSummoner from "./RankingSummoner";
import RankingTier from "./RankingTier";
import WinRateBar from "../../../../common/WinrateBar";
import RankingWinRate from "./RankingWinRate";
import InfluInfo from "../../../../summoner/Profile/InfluInfo";
import RankingMost from "./RankingMost";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const ContentTR = styled.tr`
  overflow: hidden;
  border-bottom: 1px solid #A5BED2;
  &:hover {
    background-color: #E0E6F5;
  }
`;

const Content = styled.td`
  height: 48px;
  vertical-align: middle;
`;

const Thead = styled.thead`
  border-top: 1px solid #A5BED2;
  border-bottom: 1px solid #A5BED2;
`;


const LevelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LevelText = styled.span`
  text-align: center;
`;

const InfluWrapper = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  margin-left: 12px;
`;

const TBody = styled.tbody`
  tr:nth-child(even) {
    background-color: #F5F7FB;
    &:hover {
      background-color: #E0E6F5;
    }
  }
`;

const RankingTableContent = ({rankingList,region,version}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const sortedRankingList = useMemo(() => {
        if(Array.isArray(rankingList)) {
            const sort = rankingList.sort((a,b) => a.rank - b.rank);
            return sort;
        } else {
            return [];
        }
    },[rankingList]);

    const onClickInfluWrapper = useCallback((summonerName) => {
        window.open(`/summoner/${region}/${summonerName}`,'_blank');
    },[region])


    return (
        <Wrapper>
            <Table>
                <colgroup>
                    <col width={'100px'}/>
                    <col width={'250px'}/>
                    <col width={'100px'}/>
                    <col width={'200px'}/>
                    <col width={'90px'}/>
                    <col width={'210px'}/>
                    <col width={'55px'}/>
                    <col width={'140px'}/>
                </colgroup>
                <Thead>
                    <tr>
                    <RankingTableHeader>{t('ranking.rank')}</RankingTableHeader>
                    <RankingTableHeader>{t('ranking.summoner')}</RankingTableHeader>
                    <RankingTableHeader>{t('ranking.tier')}</RankingTableHeader>
                    <RankingTableHeader>{t('ranking.winRate')}</RankingTableHeader>
                    <RankingTableHeader>{t('ranking.level')}</RankingTableHeader>
                    <RankingTableHeader>{t('ranking.proStreamer')}</RankingTableHeader>
                    <RankingTableHeader>{t('ranking.mostLane')}</RankingTableHeader>
                    <RankingTableHeader last={true}>{t('ranking.mostChamp')}</RankingTableHeader>
                    </tr>
                </Thead>
                <TBody>
                    {sortedRankingList.map((data) => {
                        return (
                            <ContentTR key={data?.summoner_name}>
                                <Content>
                                    <CRTableRanking
                                        newValue={9999}
                                        rankColor={'#313131'}
                                        dictMargin={'30px'}
                                        rank={data.rank}
                                        value={data.rank_delta}
                                    />
                                </Content>
                                <Content>
                                    <RankingSummoner
                                        streaks={data?.winning}
                                        restDays={data.rest_cnt}
                                        version={version}
                                        region={region}
                                        summonerName={data.summoner_name}
                                        puuId={data.puu_id}
                                        iconId={Number(data?.icon_id)}
                                    />
                                </Content>
                                <Content>
                                    <RankingTier
                                        lp={data.lp}
                                        tier={data.tier}
                                        division={1}
                                    />
                                </Content>
                                <Content>
                                    <RankingWinRate
                                        lose={data.loss_cnt}
                                        win={data.win_cnt}
                                    />
                                </Content>
                                <Content>
                                    <LevelWrapper>
                                        <LevelText>{data.level}</LevelText>
                                    </LevelWrapper>
                                </Content>
                                <Content>
                                    <InfluWrapper onClick={() => onClickInfluWrapper(data.summoner_name)}>
                                        <InfluInfo
                                            influData={{
                                                status: data.pro_streamer,
                                                name: `${data?.team_as ? data.team_as : ""} ${data.name}`
                                            }}
                                            textColor={'#313131'}
                                        />
                                    </InfluWrapper>
                                </Content>
                                <Content>
                                    <LevelWrapper>
                                        <img
                                            alt={""}
                                            width={16} height={16}
                                            src={`/images/icon_s_position_${data?.most_role?.toLowerCase()}.svg`}/>
                                    </LevelWrapper>
                                </Content>
                                <Content>
                                    <RankingMost
                                        list={(data.most_champion).slice(0, 3)}
                                    />
                                </Content>
                            </ContentTR>
                        )
                    })}

                </TBody>
            </Table>
        </Wrapper>
    );
};

export default RankingTableContent;



