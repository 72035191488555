import React, {useRef, useState} from 'react';
import HomeContent from "./HomeContent";
import Slider from 'react-slick';
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerIndicator from "./BannerIndicator";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";


const SlickWrapper = styled.div`
    max-width: 800px;
  .slick-slide {
    padding: 0 15px;
  }

  .slick-list {
    margin: 0 15px;
  }
`;

const HomeBanner = () => {
    const {t} = useTranslation();
    const lang = useSelector((state) => state.translation.lang);
    const slickref = useRef();
    const [indicator, setIndicator] = useState(0);
    const Banners = [{
        headerText: t("banner.banner01Header"),
        contentText: t("banner.banner01Content"),
        barColor: '#56BC99',
        img:
            lang === 'KOR' ?
                '/images/img_banner_kr_01.png' : '/images/img_banner_en_01.png',
        fontSize: lang === 'KOR' ? 14 : 13,
    }, {
        headerText: t("banner.banner02Header"),
        contentText: t("banner.banner02Content"),
        barColor: '#779FDB',
        img: lang === 'KOR' ? '/images/img_banner_kr_02.jpg' : '/images/img_banner_en_02.jpg',
        fontSize: lang === 'KOR' ? 14 : 13,
    }, {
        headerText: t("banner.banner03Header"),
        contentText: t("banner.banner03Content"),
        barColor: '#7F7FBB',
        img: '/images/img_banner_kr_03.jpg',
        fontSize: lang === 'KOR' ? 14 : 13,
    }]
    let settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    return (
        <>
            <SlickWrapper>
                <Slider
                    ref={slickref}
                    autoplaySpeed={3000}
                    beforeChange={(_, index) => setIndicator(index)}
                    {...settings}
                >
                    {Banners.map((data, index) => {
                        return (
                            <HomeContent
                                key={index + 'banner'}
                                fontSize={data.fontSize}
                                img={data.img}
                                headerText={data.headerText}
                                contentText={data.contentText}
                                barColor={data.barColor}
                            />
                        )
                    })}
                </Slider>
            </SlickWrapper>
            <BannerIndicator
                slickRef={slickref}
                length={Banners.length}
                currentIndicate={indicator}
            />

        </>
    );
};

export default HomeBanner;