import React from 'react';
import styled from "styled-components";
import SimpleTooltip from "../../../common/SimpleTooltip";
import TooltipWrapper from "../../../common/TooltipWrapper";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  padding: 6px;
`;

const HeaderSpan = styled.span`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  margin-inline: 2px;
`;

const ChildContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 6px 0;
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const OverviewHeader = (props) => {
    const {title, explain,explainCondition} = props;

    return (
        <Wrapper>
            <HeaderDiv>
                <HeaderSpan>
                    {title}
                </HeaderSpan>
                {explainCondition && explain &&
                        <SimpleTooltip
                            tooltipText={explain}
                            imgSrc={"/images/ico_warning.svg"}
                        />}
            </HeaderDiv>
            <ChildContent>
                {props.children}
            </ChildContent>
        </Wrapper>
    );
};

export default OverviewHeader;
