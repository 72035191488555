import React, {useCallback} from 'react';
import styled from "styled-components";


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.isDisabled ? "auto" : "pointer"};
  border: 1px solid ${props => {
    let color = '#6B78B5';
    if (props.selected) color = 'transparent';
    return color;
  }};
  background-color: ${props => {
    let color = '#fff';
    if (props.selected) {
      if (props.region === 'NA') color = '#70558E';
      if (props.region === 'EUN') color = '#3B4D82';
      if (props.region === 'EUW') color = '#4979A9';
      if (props.region === 'KR') color = '#0DBAAE';
      if (props.region === 'JP') color = '#DF7299';
      if (props.region === 'BR') color = '#69BE74';
      if (props.region === 'LAN') color = '#DECF49';
      if (props.region === 'LAS') color = '#DB9342';
      if (props.region === 'OCE') color = '#55B4D2';
      if (props.region === 'TR') color = '#C95D58';
      if (props.region === 'RU') color = '#B84984';
    }

    return color;
  }};
  border-radius: ${props => props.borderRadius || "6px"};
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
  opacity: ${props => props.isDisabled ? "0.5" : "1"};
`;

const TitleText = styled.span`
  font-size: ${props => {
    let size = '12px';
    if(props.fontSize) size = props.fontSize;
    return size;
  }};
  font-weight: ${props => {
    let weight = 'normal';
    if (props.selected) weight = 'bold';
    if (props.customWeight) weight = 'normal';
    return weight;
  }};
  color: ${props => {
    let color = '#6B78B5';
    if (props.selected) color = '#fff';
    return color;
  }};
`;

const RegionTag = ({title, isDisabled, onClick, selected, width, height, borderRadius, customWeight,fontSize}) => {
    const onClickWrapper = useCallback(() => {
        if (!isDisabled && typeof title === 'string' && onClick) {
            onClick(title);
        }
    }, [isDisabled, onClick]);
    return (
        <Wrapper region={title} className={"regionTag"} isDisabled={isDisabled} selected={selected}
                 onClick={onClickWrapper} width={width} height={height} borderRadius={borderRadius}>
            <TitleText selected={selected} customWeight={customWeight} fontSize={fontSize}>{title}</TitleText>
        </Wrapper>
    );
};

export default RegionTag;