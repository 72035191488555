import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import PositionChart from "./PositionChart";
import OverviewHeader from "../OverviewHeader";
import AiScoreBox from "./AiScoreBox";
import RecentPlayedChampions from "./RecentPlayedChampions";
import TierPrediction from "./TierPrediction";
import {useTranslation} from "react-i18next";
import OverviewKDA from "./OverviewKDA";
import OverviewWinRate from "./OverviewWinrate";
import {Loading} from "../../../common";
import InternalServerError from "../../../common/InternalError";
import ErrorBox from "../../../common/Error";
import NoGameBox from "../../../common/NoGameBox";


const FlexBox = styled.div`
  display: flex;
  flex: ${props => props.flex || 1};
`;


const Wrapper = styled.div`
  height: 123px;
  display: flex;
  flex-direction: row;

  ${FlexBox}:not(:last-of-type) {
    border-right: 1px solid #C3D1DC;
  }
`;

const LoadingBox = styled.div`
  height: 123px;
`;


const queueArray = [{
    queue: 'ALL',
    value: 'ranked_solo_5x5',
}, {
    queue: 'RANKED_FLEX_SR',
    value: 'ranked_flex_sr',
}, {
    queue: "RANKED_SOLO_5x5",
    value: 'ranked_solo_5x5'
}]


const OverviewContent = ({queue}) => {
    const {t} = useTranslation();
    const matches = useSelector((state) => state.summoner.match);
    const {
        match,
        summoner,
        getMatchByQueueLoading,
        getMatchByQueueDone,
        getMatchByQueueError
    } = useSelector((state) => state.summoner);
    const queueMemo = useMemo(() => queueArray.find((data) => data.queue === queue), [queue]);


    const positionArray = useMemo(() => {
        if (Array.isArray(matches) && matches.length > 0 && summoner?.summoner_basic_info_dict) {
            const result = matches.map((data) => {
                const findMymatch = data.participants_list.find((data) => summoner.summoner_basic_info_dict?.puu_id === data.puu_id)
                return {
                    champion_id: findMymatch.champion_id,
                    position: findMymatch.position,
                    ai_score: findMymatch.ai_score,
                    is_win: findMymatch.is_win,
                    kills: findMymatch.final_stat_dict.kills,
                    deaths: findMymatch.final_stat_dict.deaths,
                    assists: findMymatch.final_stat_dict.assists,
                    queue_id: data.match_basic_dict.queue_id,
                };
            })
            return result.slice(0, 100);
        }
        return [];
    }, [summoner, matches]);


    const aiScoreArray = useCallback(() => {
        let filterRank = [];
        if (queue === 'ALL') {
            filterRank = positionArray.filter((data) => data.queue_id === 420);
        } else {
            filterRank = positionArray.filter((data) => data.queue_id === 420 || data.queue_id === 440);
        }
        return filterRank.map((data) => data.ai_score);
    }, [positionArray, queue]);

    const KdaArray = useCallback(() => {
        const result = positionArray.reduce((sum, cur) => {
            sum.kills += cur.kills;
            sum.deaths += cur.deaths
            sum.assists += cur.assists
            return sum;

        }, {
            kills: 0,
            deaths: 0,
            assists: 0,
        });

        return {
            kills: result.kills / positionArray.length,
            deaths: result.deaths / positionArray.length,
            assists: result.assists / positionArray.length,
        }

    }, [positionArray]);

    const winArray = useCallback(() => {
        return positionArray.map((data) => data.is_win);
    }, [positionArray])


    if (getMatchByQueueError) return <NoGameBox>{"Error"}</NoGameBox>
    if (getMatchByQueueLoading) return <LoadingBox><Loading/></LoadingBox>
    if (typeof summoner?.season_tier_info_dict?.ranked_solo_5x5?.tier !== 'string') return null;
    if (match.length === 0) return <NoGameBox>{t('notice.noGame')}</NoGameBox>

    return (
        <Wrapper>
            <FlexBox>
                <FlexBox>
                    <OverviewHeader title={t('championsRanking.winRate')}>
                        <OverviewWinRate
                            data={winArray()}
                        />
                    </OverviewHeader>
                </FlexBox>
                <FlexBox>
                    <OverviewHeader title={"KDA"}>
                        <OverviewKDA
                            data={KdaArray()}
                        />
                    </OverviewHeader>
                </FlexBox>
                <FlexBox>
                    <OverviewHeader title={t('summoner.aiTierPreciction')}
                                    explain={t('summoner.aiTierPredictionExplain')}
                                    explainCondition={aiScoreArray().length < 8}>
                        <TierPrediction
                            tier={queueMemo?.value && summoner.season_tier_info_dict[queueMemo.value].tier}
                            division={queueMemo?.value && summoner.season_tier_info_dict[queueMemo.value].division}
                            ai_score={aiScoreArray()}
                        />
                    </OverviewHeader>
                </FlexBox>
                <FlexBox>
                    <OverviewHeader title={"AI-Score"}>
                        <AiScoreBox
                            positionArray={positionArray}
                        />
                    </OverviewHeader>
                </FlexBox>
            </FlexBox>
            <FlexBox>
                <FlexBox flex={0.32}>
                    <OverviewHeader title={t("summoner.overview.playedChamp")}>
                        <RecentPlayedChampions
                            data={positionArray}
                        />
                    </OverviewHeader>
                </FlexBox>
                <FlexBox flex={0.68}>
                    <OverviewHeader title={t("summoner.overview.position")}>
                        <PositionChart
                            positionArray={positionArray}
                        />
                    </OverviewHeader>
                </FlexBox>
            </FlexBox>
        </Wrapper>
    );
};

export default OverviewContent;
