import React from 'react';
import styled from "styled-components";


const Tag = styled.span`
  margin: 0 1px;
  text-align: center;
  display: flex;
  font-size: ${props => {
    let fontSize = '11px';
    if (props.fontSize) fontSize = props.fontSize;
    return fontSize;
  }};
  color: ${props => {
    let color = '#fff';
    if (props.textColor) color = props.textColor;
    return color;
  }};
  white-space: nowrap;
  background-color: ${props => {
    let color = 'transparent';
    if (props.bgColor) color = props.bgColor;
    if (props.bgColor === 'red') color = '#e37e74';
    if (props.bgColor === 'green') color = '#15b95d';
    return color;
  }};

  border: ${props => {
    let border;
    if (props.borderColor) border = `1px solid ${props.borderColor}`;
    if (props.bgColor === 'red') border = '1px solid #a85c54';
    if (props.bgColor === 'green') border = '1px solid #2a9454';
    return border;
  }};
  padding: 2px 3px;
  border-radius: 3px;
`;

const SummonerTag = ({children, fontSize,background,textColor,borderColor}) => {
    return (
        <Tag fontSize={fontSize}
             bgColor={background}
             textColor={textColor}
             borderColor={borderColor}
             disabled={true}
        >
            {children}
        </Tag>
    );
};

export default SummonerTag;