import React, {useEffect} from 'react';
import styled from "styled-components";
import {Header, Notice} from "../index";
import ChampionsContent from "./ChampionsContent";
import {useTitle} from "../../function";
import {useTranslation} from "react-i18next";
import NotFound from "../NotFound";


const Wrapper = styled.div`
`;




const Champions = () => {
    const {t} = useTranslation();
    const titleUpdate = useTitle('Loading...');
    setTimeout(() => titleUpdate(t('header.titleChampions')), 500);


    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <Wrapper>
            <Header/>
            <Notice fixed={false}/>
            {/*<NotFound/>*/}
                <ChampionsContent/>
        </Wrapper>
    );
};

export default Champions;
