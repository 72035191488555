import React, {useEffect} from 'react';
import styled from "styled-components";
import {Header, Loading, Notice} from "../common";
import ChampionsDetailContent from "./ChampionsDetailContent";
import {GET_CHAMPIONS_PATCH_HISTORY_REQUEST} from "../../store/reducer/champions";
import {useDispatch, useSelector} from "react-redux";

const Wrapper = styled.div`
`;


const ChampionsDetail = () => {


    useEffect(() => {
        window.scroll(0, 0);
    }, []);


    return (
        <Wrapper>
            <Header/>
            <Notice fixed={false}/>
            <ChampionsDetailContent/>
        </Wrapper>
    );
};

export default ChampionsDetail;
