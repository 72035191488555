import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import ChampionsWhiteWrapper from "../ChampionsWhiteWrapper";
import ChampionsRankLaneSelect from "./ChampionsRankLaneSelect";
import {useDispatch, useSelector} from "react-redux";
import ChampionsRankingTable from "./ChampionsRankingTable";
import {useTranslation} from "react-i18next";
import ChampionsHeader from "../ChampionsHeader";
import ChampionFilter from "../ChampionFilter";
import {GET_CHAMPIONS_RANK_REQUEST} from "../../../../store/reducer/champions";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import useInput from "../../../../common/hooks/useInput";
import useQuery from "../../../../common/hooks/useQuery";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  margin-left: 5px;
`;

const LaneWrapper = styled.div`
  margin-inline: 10px;
`;

const ChampionsRank = () => {
    const {t} = useTranslation();
    const championRanks = useSelector((state) => state?.champions?.ranks);
    const [selectedLane, setSelectedLane] = useState('top');
    const dispatch = useDispatch();
    const history = useHistory();
    const {url} = useRouteMatch();
    const query = useQuery();
    const versions = useSelector((state) => state.champions.championVersions);
    const [sliceMultiple, onChangeSliteMultiple] = useState(1);
    const [currentVersion, onChangeCurrentVersion] = useInput(encodeURIComponent(query.get('version')) !== 'null' && encodeURIComponent(query.get('version')) ? encodeURIComponent(query.get('version')) : versions[0]);
    const [currentTier, onChangeCurrentTier] = useInput(encodeURIComponent(query.get('tier')) !== 'null' && encodeURIComponent(query.get('tier')) ? decodeURIComponent(query.get('tier')).trim() + "+" : "Platinum+");


    useEffect(() => {
        if (versions) {
            onChangeCurrentVersion(versions[0]);
        }
    }, [versions])

    const getSelectedRank = useMemo(() => {
        try {
            return championRanks[selectedLane.toLowerCase()]
        } catch (e) {
            return [];
        }
    }, [selectedLane, championRanks])

    const onClickLane = useCallback((index, lane) => {
        setSelectedLane(lane);
        onChangeSliteMultiple(1);
    }, []);

    const onSelectVersion = useCallback((version) => {
        // dispatch({
        //     type: GET_CHAMPIONS_RANK_REQUEST,
        //     data: {
        //         version: version,
        //         tier:currentTier,
        //     }
        // });
        history.replace(`/champions?version=${version}&tier=${currentTier}`);
        onChangeCurrentVersion(version);
    }, [currentTier]);

    const onSelectTier = useCallback((tier) => {
        // dispatch({
        //     type: GET_CHAMPIONS_RANK_REQUEST,
        //     data: {
        //         version: currentVersion,
        //         tier: tier,
        //     }
        // });


        history.replace(`/champions?version=${currentVersion}&tier=${tier}`);
        onChangeCurrentTier(tier)
    }, [currentVersion]);


    return (
        <Wrapper>
            <ChampionsHeader
                title={t('championAnalyze.championRanking')}
            >
                <ChampionFilter
                    currentVersion={currentVersion}
                    selectVersion={onSelectVersion}
                    currentTier={currentTier}
                    selectTier={onSelectTier}
                />
            </ChampionsHeader>
            <ChampionsWhiteWrapper>
                <LaneWrapper>
                    <ChampionsRankLaneSelect
                        onClick={onClickLane}
                    />
                </LaneWrapper>
                <ChampionsRankingTable
                    onChangeMultiple={onChangeSliteMultiple}
                    sliceMultiple={sliceMultiple}
                    lane={selectedLane}
                    ranks={getSelectedRank}
                />
            </ChampionsWhiteWrapper>
        </Wrapper>
    );
};

export default ChampionsRank;
