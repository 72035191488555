import React, {useCallback, useMemo, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import CNameLI from "./NameLI";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

`;

const NameUL = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const AlphabetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: ${props => props.width || 0};
`;

const FilterName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 4px;
  flex-shrink: 0;
  height: 18px;
`;

const FilterText = styled.span`
  font-size: 10px;
`;

const ChampionsCname = ({onClick}) => {
    const [selected, setSelected] = useState(0);
    const {i18n, t} = useTranslation();
    const allName = useMemo(() => ['전체', 'ALL'], [])
    const korName = useMemo(() => ['ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'], []);
    const endName = useMemo(() => ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'], []);
    const renderName = useMemo(() => ['ko-KR', 'ko'].includes(i18n.language) ? korName : endName, [i18n.language]);

    const onClickLi = useCallback((value, data) => {
        if (value !== undefined && data !== undefined) {
            setSelected(value);

            if (data === allName[0] || data === allName[1]) {
                onClick(false);
            } else {
                onClick(data);
            }

        }
    }, []);

    const filterTitle = useMemo(() => (
        <FilterName>
            <FilterText>
                {t('championAnalyze.choFilter')} :
            </FilterText>
        </FilterName>
    ), [i18n.language]);

    return (
        <Wrapper>
            <NameUL>
                <AlphabetWrapper width={'auto'}>
                {filterTitle}
                <CNameLI
                    onClick={onClickLi}
                    selected={selected === 0}
                    index={0}
                    data={['ko-KR', 'ko'].includes(i18n.language) ? allName[0] : allName[1]}
                />
                </AlphabetWrapper>
                <AlphabetWrapper width={['ko-KR', 'ko'].includes(i18n.language) ? '350px' : '320px'}>
                    {Array.isArray(renderName) && renderName.map((data, index) => {
                        return (
                            <CNameLI
                                key={data + "choData"}
                                onClick={onClickLi}
                                selected={selected === index + 1}
                                index={index + 1}
                                data={data}
                            />
                        )
                    })}
                </AlphabetWrapper>
            </NameUL>
        </Wrapper>
    );
};


export default React.memo(ChampionsCname);