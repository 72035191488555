import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import dompurify from 'dompurify';
import {Header} from '../../components';
import {cutLaneName, useTitle} from '../../function';
import MultiSearch from './MultiSearch';
import MultiList from './MultiList';
import {MultiContainer} from '../../styled/MultiStyle';
import styled from "styled-components";
import {Loading, Notice} from "../common";
import {GET_MULTI_LIST_REQUEST, GET_SHORT_LINK_REQUEST} from "../../store/reducer/summoner";
import _ from "lodash";
import MultiEmpty from "./Empty";
import ServerStatusErrorbox from "../common/Error/StatusError";
import {changeRegion} from "../../store/modules/region";
import regionList from "../../store/static_json/regionList";


const ContentWrapper = styled.div`
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    margin: 0;
  }
`;

const AdWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const AdSection = styled.div`
`;

const ImgWrapper = styled.div`
  display: flex;
`;

const PickImg = styled.img`
  width: fit-content;
  max-height: 289px;
`;


const AdBox = styled.div`
  width: 300px;
  height: 600px;
  margin-left: ${props => props.leftMargin + "rem"};
  margin-right: ${props => props.rightMargin + "rem"};
  @media only screen and (max-width: 1786px) {
    display: none;
  }
`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 1162px;
  padding: 0 16px 16px;
  background-color: #fff;
  margin-bottom: 10vh;
  border-radius: 6px;
  z-index: 1;
`;


const Multi = () => {
        const dispatch = useDispatch();
        const {t} = useTranslation();
        const sanitizer = dompurify.sanitize;
        const history = useHistory();
        const location = useLocation();
        const [inpText, setInpText] = useState('');
        const currentLang = useSelector((state) => state.translation.lang);
        const currentSeason = useSelector((state) => state.imageStore.currentSeason);
        const [init, setInit] = useState(true);
        const {
            multiList,
            multiLPChart,
            getMultiListLoading,
            getMultiListUpdateLoading,
            getMultiListUpdateDone,
            getMultiListDone,
            shortLink,
            getShortLinkDone,
            getShortLinkLoading,
            setShortLink,
            setShortLinkDone
        } = useSelector((state) => state.summoner);
        const [copy, setCopy] = useState({
            copyUrl: window.location.href,
            copied: false,
        });
        const [avg, setAvg] = useState(0);
        const region = useSelector((state) => state.region.result);
        const params = useParams();


        const laneText = useCallback((lane, currentLang) => {
            if (currentLang !== 'KOR') {
                return cutLaneName(lane).toUpperCase();
            }

            switch (lane) {
                case 'Top':
                    return '탑';
                case 'Jungle':
                    return '정글';
                case 'Middle':
                    return '미드';
                case 'Bot':
                    return '바텀';
                case 'Supporter':
                    return '서폿';
                default:
                    break;
            }

        }, [])

        const titleUpdate = useTitle('Loading...');

        setTimeout(() => titleUpdate(t('header.titleMulti')), 500);


        useEffect(() => {
            if (getShortLinkDone && !getShortLinkLoading && currentSeason) {
                let dispatchText = '';
                if (shortLink.summoners) {
                    window.history.replaceState(null, null, `/multi/${shortLink.region}/${shortLink.summoners}`);
                    dispatchText = shortLink.summoners;
                    setInpText(shortLink.summoners);
                } else {
                    const urlText = params.name;
                    dispatchText = params.name;
                    setInpText(urlText);
                }

                const paramRegion = regionList.find((data) => (data.title === params.region && !data.disabled));
                dispatch(changeRegion(paramRegion ? paramRegion.title : shortLink.region));

                if (paramRegion) {
                    dispatch({
                        type: GET_MULTI_LIST_REQUEST,
                        data: {
                            summoners: dispatchText,
                            region: params.region ? params.region : shortLink.region,
                            currentSeason: currentSeason
                        }
                    })
                }

            }
        }, [getShortLinkDone, getShortLinkLoading, params, currentSeason]);

        useEffect(() => {
            if (getMultiListUpdateDone && !getMultiListUpdateLoading) {
                if (multiList.length > 0) {
                    const reduceAiScore = multiList.reduce((sum, cur) => {
                        sum += cur.summoner_info_dict.ai_score_avg;
                        return sum
                    }, 0);
                    setAvg(Math.trunc(reduceAiScore / multiList.length));
                }
            }
        }, [getMultiListUpdateLoading, getMultiListUpdateDone, multiList]);


        const getCount = (array, flag) => {
            try {
                const copyArray = [...array];
                const result = [];

                for (let i in copyArray) {
                    const findIndex = result.findIndex((data, index) => data[flag] === copyArray[i][flag]);
                    if (findIndex > -1) {
                        result[findIndex] = {
                            ...result[findIndex],
                            gameCount: result[findIndex].gameCount + 1,
                            ai_score: result[findIndex].ai_score + copyArray[i].ai_score,
                            wins: copyArray[i].win ? result[findIndex].wins + 1 : 0,
                        }
                    } else {
                        result.push({
                            gameCount: 1,
                            [flag]: copyArray[i][flag],
                            ai_score: copyArray[i].ai_score,
                            wins: copyArray[i].win ? 1 : 0,
                        });
                    }
                }

                result.sort((a, b) => {
                    if (a.gameCount < b.gameCount) {
                        return 1;
                    } else if (a.gameCount > b.gameCount) {
                        return -1;
                    } else if (a.gameCount === b.gameCount) {

                        const aScore = a.ai_score / a.gameCount;
                        const bScore = b.ai_score / b.gameCount;

                        if (a.wins < b.wins) {
                            return 1;
                        } else if (a.wins < b.wins) {
                            return -1;
                        } else if (a.wins === b.wins) {
                            if (aScore < bScore) {
                                return 1;
                            } else if (aScore > bScore) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }
                    }
                });
                return result;
            } catch (e) {
                return array
            }
        }

        const carryFunction = (list) => {

            if (list?.losses !== undefined && list?.wins !== undefined) {
                if (list.losses + list.wins > 4) {
                    if (list.ai_score_avg > 56) {
                        return true;
                    }
                }
            }

            return false;
        }


// ai score 평균


// 공유하기
        const handleCopy = () => {
            const array = _.uniqBy(multiList, (e) => e.summoner.summoner_basic_info_dict.summoner_id);
            const sortedMultilist = array.sort((a, b) => b.summoner_info_dict.ai_score_avg - a.summoner_info_dict.ai_score_avg);


            const resultArray = [];
            if (sortedMultilist.length > 0) {
                resultArray.push(getCount(sortedMultilist[0].summoner_info_dict.last_match_list, 'position')[0]);
            }

            if (sortedMultilist.length > 1) {
                resultArray.push(getCount(sortedMultilist[1].summoner_info_dict.last_match_list, 'position')[0]);
            }


            const jum = currentLang === 'KOR' ? '점' : "";


            let copyText = `DEEPLOL.GG ${t('multi.copyHeader')}\n\n`
            copyText += `- ${t('multi.copyAI')} AI-Score : ${avg}${jum}\n\n`


            if (carryFunction(sortedMultilist[0]?.summoner_info_dict)) {
                copyText += `- ${t('tags.carry')} 1 : ${sortedMultilist[0].summoner.summoner_basic_info_dict.summoner_name}(${laneText(resultArray[0].position, currentLang)},${sortedMultilist[0].summoner_info_dict.ai_score_avg}${jum})\n\n`
                if (carryFunction(sortedMultilist[1]?.summoner_info_dict)) {
                    copyText += `- ${t('tags.carry')} 2 : ${sortedMultilist[1].summoner.summoner_basic_info_dict.summoner_name}(${laneText(resultArray[1].position, currentLang)},${sortedMultilist[1].summoner_info_dict.ai_score_avg}${jum})\n\n`
                }
            }

            copyText += `- ${window.location.origin + '/multi/'}${region}/${setShortLink.urlkey}`
            navigator.clipboard.writeText(copyText);

            setCopy({copied: true});
            closeCopied();
        };

// 공유하기 툴팁
        const closeCopied = () => {
            setTimeout(() => {
                setCopy({copied: false});
            }, 2000);
        };


// 소환사명 받아서 하나씩 배열로 변경
        const getSummonersName = (text) => {
            if (typeof text === 'string') {
                // (joined the room.)| (님이|로비에|참가하셨습니다.)|(,\s)|,|\n|\r
                let arr_str = text.replace(/(joined the room.)|(joined the lobby.)|(joined the lobby)|(님이 로비에 참가했습니다.)|(님이 로비에 참가하셨습니다.)|(がロビーに参加しました)|(,\s)|,|\n|\r/gi, '/');
                arr_str = arr_str.replace(/(\s\/)+|(\s\/)/gi, '/');
                arr_str = arr_str.replace(/(\/\s)|(\/)+/gi, '/');
                arr_str = arr_str.replace(/\/$/gi, '');
                arr_str = arr_str.split('/');


                // 중복, 빈값 제거
                const set = new Set(arr_str);
                const uniqueArr = [...set];
                const modyArr = uniqueArr.filter((item) => item !== '').slice(0, 8);


                window.history.replaceState(null, null, `/multi/${region}/${modyArr}`);
                setInit(false);
                dispatch({
                    type: GET_MULTI_LIST_REQUEST,
                    data: {
                        summoners: modyArr.toString(),
                        region: region,
                        currentSeason: currentSeason
                    }
                })
                // history.replace({
                //     pathname: `/multi/${modyArr}`,
                //     state: {name: modyArr},
                // });
            }
        };


        useEffect(() => {
            setAvg(0);
            if (typeof params.name === 'string') {
                let text = params.name;
                if (text.length > 0) {
                    dispatch({
                        type: GET_SHORT_LINK_REQUEST,
                        data: {
                            short: text
                        },
                    });
                    setInit(false);
                }
            }
        }, [params]);

        const onClickIngameButton = () => {
            if (multiList.length > 0) {
                let summonerName = multiList[0]?.summoner?.summoner_basic_info_dict?.summoner_name;
                if (summonerName) {
                    const origin = window.location.origin;
                    window.open(`${origin}/summoner/${region}/${summonerName}?tabs=3`, '_blank',);
                }

            }
        }

        useEffect(() => {
            window.scroll(0, 0);
        }, []);

        return (
            <>
                <Header/>
                <Notice/>
                <ServerStatusErrorbox/>

                <MultiContainer>
                    <AdBox float={'left'} rightMargin={2} leftMargin={2}>

                    </AdBox>
                    <CenterDiv>
                        <div className="multi_top">
                            <ContentWrapper className="center">
                                <MultiSearch getSummonersName={getSummonersName} inpText={inpText}/>
                                <div className="info">
                                    <div className="box">
                                        <h4>{t('multi.teamAvg')} AI-Score</h4>
                                        <div className="bg">
                                            <p>{isNaN(avg) ? '0' : Math.trunc(avg)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button className="ingame" onClick={onClickIngameButton}>
                                        {t('buttons.ingame')}
                                    </button>
                                    <div onClick={handleCopy} text={copy.copyUrl}>
                                        <button className="share">{t('buttons.share')}</button>
                                    </div>
                                    {copy.copied && <span className="share_tooltip">{t('multi.clipboard')}</span>}
                                </div>
                            </ContentWrapper>
                        </div>

                        <div className="multi_bottom">


                            <ContentWrapper className="contentCenter">
                                {getMultiListLoading ? <Loading/> :
                                    (init || !getMultiListDone) ? (
                                        <div className="how_to_use">
                                            <h3>{t('multi.howtouse')}</h3>
                                            <div className="box">
                                                <p dangerouslySetInnerHTML={{__html: sanitizer(t('multi.text'))}}></p>
                                                <div className="imgs">
                                                    <ImgWrapper>
                                                        <PickImg src="/images/img_picks.png" alt="picks" className="picks"/>
                                                    </ImgWrapper>

                                                    <img src="/images/icon_s_expand_arrow.svg" alt="arrow"/>

                                                    <ImgWrapper>
                                                        <PickImg src="/images/img_browser.png" alt="browser" width={'100%'}
                                                                 className="browser"/>
                                                    </ImgWrapper>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        multiList.length > 0 ?
                                            <MultiList
                                                isLoading={getMultiListUpdateLoading}
                                                searchNames={setShortLink}
                                                multiLPChart={multiLPChart}
                                                multiList={multiList}/>
                                            :
                                            <MultiEmpty/>
                                    )
                                }
                            </ContentWrapper>

                        </div>
                        <AdWrapper>
                            <AdSection>

                            </AdSection>
                        </AdWrapper>
                    </CenterDiv>
                    <AdBox float={'right'} leftMargin={2} rightMargin={2}>

                    </AdBox>
                </MultiContainer>

            </>
        );
    }
;

export default Multi;
