import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  border-style:solid ;
  border-color: #A5BED2;
  border-top-width: 1px;
  border-bottom-width: 1px;
  background-color: #F1F3F9;
  display: flex;
  flex-direction: row;
  padding: 4px 0;
`;

const ChampionsBar = ({children}) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default ChampionsBar;