import styled from 'styled-components';

export const HomeContainer = styled.div`
  position: relative;
  padding: 0 0 3rem 0;
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10rem 0 0;
    .react-tabs {
      position: relative;
      width: 620px;
      margin: 20px auto 0;
      background-color: #fff;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);

      &__tab-list {
        display: flex;
      }
      &__tab {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 37px;
        font-size: 14px;
        font-weight: 700;
        color: rgba(49, 49, 49, 0.5);
        background-color: #d7dae5;
        border: 1px solid #9ca1b2;
        cursor: pointer;

        &--selected {
          background-color: #fff;
          color: #3362e5;
          border-bottom: 0;

          &::before {
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            display: block;
            width: 100%;
            height: 3px;
            background: linear-gradient(90deg, #10d9b9 0%, #2765ce 100%);
          }
          &:first-of-type {
            border-right: 0;
          }
          &:last-of-type {
            border-left: 0;
          }
        }
      }
      &__tab-panel {
        display: none;
        height: 110px;
        overflow-y: auto;
        border: 1px solid #9ca1b2;
        border-top: 0;
        ::-webkit-scrollbar {
          width: 5px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #b8cddc;
        }
        ::-webkit-scrollbar-track {
          background-color: #dbe8ff;
        }

        &--selected {
          display: block;
        }
      }
    }
  }
`;
