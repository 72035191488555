import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {ChartAi} from '../../components';
import Summary from './Summary';
import {OverviewContainer} from '../../styled/SummonerStyle';
import SearchChampions from "./Overview/SearchChampion";
import styled from "styled-components";
import {sortChampData} from "../../function";

const ChartContainer = styled.div`
  position: relative;
`;

const AbsoluteBox = styled.div`
  position: absolute;
  top: 150px;
  left: 5px;
`;

const getData = (matchInfo, summoner) => {
    if (Array.isArray(matchInfo)) {

        const sliceMatch = matchInfo.slice(0, 10);

        const reduced = sliceMatch.reduce((sum, cur, index) => {
            const curSummoner = cur.participants_list.find((data) => data.puu_id === summoner.summoner_basic_info_dict.puu_id);
            if (typeof curSummoner === 'object') {
                const sortParticipants = sortChampData(cur.participants_list, {value: "ai_score", sortType: false});
                const rating = sortParticipants.findIndex((data) => data.puu_id === summoner.summoner_basic_info_dict.puu_id) + 1;
                sum.xAxisArr.push(new Date((cur.match_basic_dict.creation_timestamp) * 1000 || 0));
                sum.scoreArr.push(curSummoner.ai_score);
                sum.gameArr.push({...curSummoner, rating: rating});
                sum.winloseArr.push(curSummoner.is_win);
                sum.ratingArr.push(rating);
            }
            return sum;
        }, {xAxisArr: [], scoreArr: [], gameArr: [], winloseArr: [], ratingArr: []});
        return {
            xAxisArr: reduced.xAxisArr.reverse(),
            scoreArr: reduced.scoreArr.reverse(),
            gameArr: reduced.gameArr.reverse(),
            winloseArr: reduced.winloseArr.reverse(),
            ratingArr: reduced.ratingArr.reverse()
        }
    }
};


const Overview = ({matchInfo, handleQueueFilter, handleChampFilter, queueNum, region}) => {
    const {t} = useTranslation();
    const summoner = useSelector((state) => state.summoner.summoner);
     const [data, setData] = useState(getData(matchInfo, summoner));
    const [path, setPath] = useState([]);
    const [isOpend, setIsOpend] = useState(false);


    useEffect(() => {
        if (data.xAxisArr) {
            let reduced = data.xAxisArr.reduce((sum, data, index) => {
                if (typeof data === 'object') {
                    const newDate = data.setHours(0, 0, 0, 0);
                    const findIndex = sum.findIndex((current, index) => {
                        const zeroHour = current.data.setHours(0, 0, 0, 0);
                        return zeroHour === newDate;
                    })

                    if (findIndex === -1) {
                        sum.push({
                            data: data,
                            count: (sum[sum.length - 1]?.count ? sum[sum.length - 1]?.count : 0) + 1,
                        })
                    } else {
                        sum[findIndex] = {
                            ...sum[findIndex],
                            count: sum[findIndex].count + 1,
                        }
                    }
                }
                return sum;
            }, []);

            if (data.xAxisArr.length < 10) {
                const array = [];
                for (let i = data.xAxisArr.length; i < 10; i++) {
                    array.push({count: i});
                }
                reduced = [...reduced, ...array];
            }


            if (path.length === 0) {
                setPath(reduced);
            }

        }
    }, [data]);


    // 자동완성 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (isOpend && (!e.current || !e.current.contains(e.target))) setIsOpend(false);
        },
        [isOpend]
    );

    useEffect(() => {
        setData(getData(matchInfo, summoner))
    }, [matchInfo, summoner])


    useEffect(() => {

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside, matchInfo]);


    const renderPath = useCallback(() => {
        if (path.length > 0) {
            return (
                <>
                    <path fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="none"
                          data-z-index="1" className="highcharts-grid-line"
                          d={`M 91.5 0 L 91.5 ${path.some((data) => data.count === 1) ? 52 : 37}`} opacity="1"/>
                    <path fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="none"
                          data-z-index="1" className="highcharts-grid-line"
                          d={`M 138.5 0 L 138.5 ${path.some((data) => data.count === 2) ? 52 : 37}`} opacity="1"/>
                    <path fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="none"
                          data-z-index="1" className="highcharts-grid-line"
                          d={`M 186.5 0 L 186.5 ${path.some((data) => data.count === 3) ? 52 : 37}`} opacity="1"/>
                    <path fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="none"
                          data-z-index="1" className="highcharts-grid-line"
                          d={`M 233.5 0 L 233.5 ${path.some((data) => data.count === 4) ? 52 : 37}`} opacity="1"/>
                    <path fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="none"
                          data-z-index="1" className="highcharts-grid-line"
                          d={`M 281.5 0 L 281.5 ${path.some((data) => data.count === 5) ? 52 : 37}`} opacity="1"/>
                    <path fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="none"
                          data-z-index="1" className="highcharts-grid-line"
                          d={`M 329.5 0 L 329.5 ${path.some((data) => data.count === 6) ? 52 : 37}`} opacity="1"/>
                    <path fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="none"
                          data-z-index="1" className="highcharts-grid-line"
                          d={`M 376.5 0 L 376.5 ${path.some((data) => data.count === 7) ? 52 : 37}`} opacity="1"/>
                    <path fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="none"
                          data-z-index="1" className="highcharts-grid-line"
                          d={`M 424.5 0 L 424.5 ${path.some((data) => data.count === 8) ? 52 : 37}`} opacity="1"/>
                    <path fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="none"
                          data-z-index="1" className="highcharts-grid-line"
                          d={`M 471.5 0 L 471.5 ${path.some((data) => data.count === 9) ? 52 : 37}`} opacity="1"/>
                </>
            )
        } else {
            return <></>
        }
    }, [path])


    return (
        <OverviewContainer>
            <div className="o__menu">
                <nav>
                    <button
                        type="button"
                        className={`${queueNum === 'ALL' ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter('ALL')}
                    >
                        {t('tabs.total')}
                    </button>
                    <button
                        type="button"
                        className={`${queueNum === "RANKED_SOLO_5x5" ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter("RANKED_SOLO_5x5")}
                    >
                        {t('tabs.rankedSolo')}
                    </button>
                    <button
                        type="button"
                        className={`${queueNum === "RANKED_FLEX_SR" ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter("RANKED_FLEX_SR")}
                    >
                        {t('tabs.rankedFlex')}
                    </button>
                    <button
                        type="button"
                        className={`${queueNum === "NORMAL" ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter("NORMAL")}
                    >
                        {t('tabs.normal')}
                    </button>
                    <button
                        type="button"
                        className={`${queueNum === "URF" ? 'selected' : 'btn'}`}
                        onClick={() => handleQueueFilter("URF")}
                    >
                        {t('tabs.event')}
                    </button>
                </nav>
                <div className="champ">
                    <SearchChampions
                        handleChampFilter={handleChampFilter}
                        t={t}
                    />
                </div>
            </div>
            <div className="o__info">
                {matchInfo.length !== 0 ? (
                    <>
                        <Summary gameArr={data.gameArr} queueNum={queueNum} summoner={summoner} matchInfo={matchInfo}
                                 region={region}/>
                        <ChartContainer>
                            <ChartAi data={data}/>
                            <AbsoluteBox>
                                {/*37 / 54*/}
                                <svg width={472} height={70}>
                                    <g>
                                        {renderPath()}
                                    </g>
                                </svg>
                            </AbsoluteBox>
                        </ChartContainer>
                    </>
                ) : (
                    <div className="no_data">{t('notice.noGame')}</div>
                )}
            </div>
        </OverviewContainer>
    );
};

export default React.memo(Overview);
