import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import RankingHeaderLP from "./RankingHeaderLP";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  padding-left: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;
const Title = styled.span`
  font-size: 26px;
  font-weight: 500;
`;
const ExtraText = styled.p`
  color: #313131;
  margin-top: 2px;
  opacity: 0.6;
`;


const UpdatedTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const UpdatedText = styled.span`
  color: #212121;
  font-size: 12px;
`;

const RankingHeader = ({rankingRegion,challengerLp,grandMasterLp}) => {
    const {t} = useTranslation();

    return (
        <Wrapper>
            <TitleWrapper>
                <Title>{t("ranking.soloRank")}</Title>
                <RankingHeaderLP
                    challengerLp={challengerLp}
                    grandMasterLp={grandMasterLp}
                    rankingRegion={rankingRegion}
                />
            </TitleWrapper>
            <ExtraText>{t('ranking.rankingInfo')}</ExtraText>
            <UpdatedTextWrapper>
                <UpdatedText>{t("ranking.updateInfo")}</UpdatedText>
            </UpdatedTextWrapper>
        </Wrapper>
    );
};

export default RankingHeader;
