import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import ChampCarouselContent from "./ChampCarouselContent";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  border-top: 1px solid #A5BED2;
  border-bottom: 1px solid #A5BED2;
  padding: 6px 20px;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const CarouselInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 6px;
  width: 123px;
`;

const ChampSelectDiv = styled.div`
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const InfoText = styled.span`
  font-size: 12px;
  color: #313131;
  opacity: 0.6;
  height: 15px;
`;

const GameCountText = styled(InfoText)`
  margin-top: 7px;

`;

const WinRateText = styled(InfoText)`
  margin-top: 5px;
`;


const ChampSelectSpan = styled.span`
    white-space: nowrap;
`;

const IndicateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 6px;
  min-height: 6px;
`;

const CarouselContentWrapper = styled.div`

`;

const IndicateCircle = styled.div`
  cursor: pointer;
  border-radius: 50%;
  width: 6px;
  aspect-ratio: 1;
  background: #6A5AAC;
  margin-right: 10px;
  opacity: ${props => {
    let opacity = '0.3';
    if (props.selected) opacity = '1';
    return opacity;
  }};
`;

const ChampCarousel = ({list, handleChamp, selectedChamp, position}) => {
    const {t} = useTranslation();
    const visibleCount = 18;
    const [indicate, setIndicate] = useState(0);


    const onChangeIndicate = useCallback((value) => {
        if (value !== -1 && value <= Math.trunc(list.length / visibleCount)) {
            setIndicate(value);
        }
    }, []);




    useEffect(() => {
        onChangeIndicate(0);
    }, [list])


    return (
        <Wrapper>
            <CarouselWrapper>
                <CarouselInfoWrapper>
                    <ChampSelectDiv>
                        <ChampSelectSpan>{t('tabs.selectChampion')}</ChampSelectSpan>
                    </ChampSelectDiv>
                    <GameCountText>{t('tabs.games')}</GameCountText>
                    <WinRateText>{t('championDetailBuild.wr')}(%)</WinRateText>
                </CarouselInfoWrapper>
                <CarouselContentWrapper>
                    {(Array.isArray(list) && list.length !== 0) &&
                        <ChampCarouselContent
                            indicate={indicate}
                            onChangeIndicate={onChangeIndicate}
                            visibleCount={visibleCount}
                            position={position}
                            list={list}
                            selectedChamp={selectedChamp}
                            handleChamp={handleChamp}
                        />}
                </CarouselContentWrapper>
            </CarouselWrapper>
            <IndicateWrapper>
                {(Array.isArray(list) && list.length !== 0) &&
                new Array(Math.ceil(list.length / visibleCount)).fill("").map((data, index) => {
                    return (
                        <IndicateCircle
                            onClick={() => onChangeIndicate(index)}
                            selected={index === Math.trunc(indicate)}
                            key={index}
                        />
                    )
                })}
            </IndicateWrapper>
        </Wrapper>
    );
};

export default ChampCarousel;