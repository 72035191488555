import styled from 'styled-components';

export const SummonerContainer = styled.div`
  position: relative;
  min-height: 200vh;
  overflow: hidden;
  padding-bottom: 100px;

  .not_found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 120px;

    h5 {
      margin: 30px 0 20px;
      font-size: 42px;
      color: #808db2;

      span {
        font-weight: 700;
        color: #313131;
      }
    }

    p {
      font-size: 20px;
    }
  }

  .no_game {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;

    p {
      font-size: 36px;
    }
  }

  .main_tab {
    width: 1130px;
    margin: 0 auto;
  }

  .main_tab_list {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 100;
    padding: 7px 8px;
    background-color: #F9F9FA;
    border: 1px solid #C3D1DC;
    border-radius: 6px;

    .react-tabs__tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 108px;
      height: 32px;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      opacity: 1;

      &:hover {
        color: #675FB3;
      }

      &--selected {
        background: transparent linear-gradient(74deg, #1eb8a0 0%, #3c68b2 100%);
        font-weight: 400;
        color: #fff;
        opacity: 1;

        &:hover {
          color: #fff;
        }
      }

      & + .react-tabs__tab {
        margin-left: 20px;
      }
    }

    .live {
      display: inline-block;

      img {
        margin-top: 6px;
        margin-right: -6px;
      }
    }
  }

  .main_tab_panel {
    position: relative;
    z-index: 100;
  }
`;

export const ProfileContainer = styled.div`
  width: 1130px;
  margin: 0 auto;
  padding: 10px 0 9px;

  .p__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;

    .img {
      display: block;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 100%;
      opacity: 0.95;
    }

    &::after {
      content: '';
      position: absolute;
      top: 430px;
      left: 0;
      display: block;
      width: 100%;
      height: calc(100% - 430px);
      background: #fff;
      backdrop-filter: blur(20px);
    }
  }

  .p__info {
    position: relative;
    z-index: 100;
    display: flex;
    flex: 1;
    padding: 8px;
    background-color: #F9F9FA;
    border: 1px solid #C3D1DC;
    border-radius: 6px;

    .icon {
      position: relative;

      img {
        border-radius: 6px;
      }

      p {
        position: absolute;
        bottom: -13px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        height: 26px;
        padding: 0 6px;
        background: transparent linear-gradient(180deg, #2f4d3c 0%, #233048 100%);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
        border: 1px solid #65e5b2;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #f2f2f2;
      }
    }

    .text {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 13px;

      .pro {
        display: block;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
      }

      .name {
        display: flex;
        margin-top: 5px;

        p {
          font-size: 26px;
          color: #313131;
        }
      }

      .update {
        display: flex;
        align-items: flex-end;
        margin-top: 8px;

        button {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          width: 120px;
          height: 32px;
          background-color: #3FB3B4;
          border: 1px solid #4159A8;
          font-size: 12px;
          color: #fff;


          &.complete {
            background: transparent;

            &::before {
              display: none;
            }
          }

          .fill {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: transparent linear-gradient(90deg, rgba(63, 179, 180, 0.8) 0%, rgba(65, 89, 168, 0.8) 100%);
          }

          span {
            position: relative;
          }
        }

        p {
          margin-left: 10px;
          margin-bottom: 1px;
          font-size: 11px;
          font-weight: 400;

          span {
            display: inline-block;
            margin-left: 4px;
            color: #313131;
          }
        }
      }

      .favorite {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 24px;
          background: #fff;
          border-radius: 3px;
          font-size: 12px;
          border: 1px solid #7e9ae6;
          transition: all 0.2s linear;

          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }

          &.off {
            opacity: 0.4;

            &:hover {
              opacity: 1;
            }

            &::before {
              background: url('/images/ico_star_off.svg') no-repeat center;
            }
          }

          &.on {
            opacity: 1;

            &::before {
              background: url('/images/ico_star_on.svg') no-repeat center;
            }
          }
        }
      }
    }
  }

  .p__tier {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .item {
      display: inline-flex;
      align-content: center;
      justify-content: center;
      height: 18px;
      line-height: 18px;
      padding: 0 7px;
      background: rgba(13, 19, 36, 0.7);
      border-radius: 4px;
      font-size: 12px;
      color: #e5e5e5;

      i {
        display: inline-block;
        margin-right: 4px;
      }

      span {
        font-weight: 500;
        text-transform: capitalize;
      }
    }

    .item + .item {
      margin-left: 4px;
    }
  }
`;

export const InfoContainer = styled.div`
  padding: 9px 0 13px;

  .p__box {
    display: flex;
    justify-content: space-between;
    height: 254px;

    .tier {
      display: flex;
      width: 570px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #A5BED2;
      border-radius: 6px;

      .react-tabs__tab-list {
        width: 235px;
        margin-right: 14px;
        border-radius: 6px;
      }

      .react-tabs__tab {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 110px;
        background: #ffffff;

        & + .react-tabs__tab {
          margin-top: 10px;
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          opacity: 1;
          position: absolute;
          top: 0%;
          left: 0;
          outline: 1px solid #A5BED2;
          box-sizing: border-box;
          border-radius: 6px;
          transition: all 0.2s linear;
        }

        &--selected {
          &::before {
            outline: 3px solid #5d9ceb;
            opacity: 1;
          }
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 25px;
          padding: 0 15px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          h5 {
            font-size: 12px;
            font-weight: 500;
          }

          dl {
            display: flex;
            align-items: center;

            dt {
              font-size: 11px;
              color: #313131;

              span {
                display: inline-block;
                margin-left: 3px;
                color: #259a7e;
                font-weight: 700;
              }
            }

            dd {
              margin-left: 5px;
              font-size: 11px;
              color: #313131;
            }
          }
        }

        .info {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 0 12px;

          .t_group {
            flex: 1;
            margin: 0 8px 0 12px;
            font-size: 12px;
            color: rgba(49, 49, 49, 0.6);

            .t1 {
              font-size: 16px;
              font-weight: 700;
              color: #313131;
            }

            .t2 {
              margin-top: 4px;
              color: #313131;

              .lp + .game {
                margin-left: 10px;
              }

              .game {
                color: rgba(49, 49, 49, 0.6);
              }

              .game i {
                color: #313131;
              }
            }

            .t3 {
              margin-top: 2px;

              .game i {
                color: #313131;
              }

              .game + .game {
                margin-left: 10px;
              }
            }

            span {
              display: inline-block;
            }
          }
        }
      }

      .react-tabs__tab-panel {
        display: none;
        flex: 1;

        &--selected {
          display: block;
        }

        .title {
          margin: 5px 0 12px 22px;
          font-size: 14px;
          font-weight: 700;

          span {
            font-size: 12px;
            font-weight: 400;
            color: rgba(49, 49, 49, 0.6);
          }
        }

        .tooltip {
          p {
            font-family: 'Noto Sans KR';
            font-size: 11px;
            font-weight: 400;

            span {
              font-weight: 700;
            }
          }
        }
      }
    }

    .champ {
      display: flex;
      flex-direction: row-reverse;
      width: 550px;
      background-color: #fff;
      border: 1px solid #A5BED2;
      border-radius: 6px;

      &.loading {
        align-items: center;
        justify-content: center;
      }

      .react-tabs__tab-list {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        overflow: hidden;
        border-left: 1px solid #aaadc2;
      }

      .react-tabs__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 58px;
        height: 58px;
        background-color: #BDD5D5;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        line-height: 1.4;
        cursor: pointer;
        transition: all 0.2s linear;

        & + .react-tabs__tab {
          border-top: 1px solid #A5BED2;
        }

        &--selected {
          font-weight: 700;
          background-color: #3FB3B4;
        }
      }

      .react-tabs__tab-panel {
        border-bottom-left-radius: 6px;
        display: none;
        flex: 1;

        &--selected {
          display: block;
        }

        table {
          width: 100%;
          text-align: center;
          border-bottom-left-radius: 6px;

          th {
            height: 42px;
            vertical-align: middle;
            font-size: 12px;
            font-weight: 500;

            p {
              display: inline-flex;
              align-items: center;
              justify-content: space-around;
              height: 17px;
              padding: 0 10px;
              background-color: #bfcadb;
              border-radius: 4px;

              span {
                font-size: 11px;
                font-weight: 400;
              }
            }
          }


          td {
            height: 42px;
            vertical-align: middle;
            font-size: 14px;
            font-weight: 400;
            border-bottom: 1px solid #A5BED2;

            span {
              font-weight: 700;
            }

            i {
              display: inline-block;
              margin: 0 2px;
              font-size: 12px;
            }

            p {
              margin-top: 4px;
              font-size: 12px;

              .death {
              }
            }

            &.no_data {
              border-bottom: 0;
              border-bottom-left-radius: 6px;
              height: 208px;
            }
          }


          tbody tr:nth-of-type(odd) {
            background-color: #FFFFFF;
          }

          tbody tr:nth-of-type(even) {
            background-color: #F1F3F9;
          }

          tbody tr:first-of-type td {
            border-top: 1px solid #7f8298;
          }

          tbody tr:nth-child(5) {
            td {
              border-bottom: 0;
            }

            td:first-child {
              border-bottom-left-radius: 6px;
              border-bottom: 0;
            }

            border-bottom-left-radius: 6px;
          }
        }

        .imgChamp {
          width: 38px;
        }
      }
    }
  }

  .imgEmblem {
    width: 62px;
  }

  .chart_lp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 18px;
  }
`;

export const OverviewContainer = styled.div`
  background: #f4f4f5;
  border: 1px solid #c3d1dc;
  border-radius: 10px;
  backdrop-filter: blur(6px);

  .o__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 47px;
    padding: 0 20px;
    border-bottom: 1px solid #c3d1dc;

    nav {
      display: flex;

      button {
        position: relative;
        padding: 8px 10px;
        margin-right: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #828282;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 3px;
          overflow: hidden;
          background: transparent linear-gradient(93deg, #1eb8a0 0%, #3c68b2 100%);
        }

        &:hover {
          color: #3679AE;
          font-weight: bold;
        }

        &.selected {
          color: #212121;
          font-weight: bold;

          &::after {
            width: 100%;
            transition: all 0.2s linear;
          }
        }
      }
    }
  }

  .o__info {
    display: flex;

    .chart_box {
      flex: 1;
      padding: 0 5px;

      h5 {
        margin: 13px 12px 0;
        font-weight: 700;
      }
    }

    .chart_dot {
      position: absolute;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 12px;
      margin-left: -8px;
      background-color: #fff;
      border-radius: 2px;
      opacity: 0.9;
      font-family: 'Noto Sans KR';
      font-size: 11px;
      font-weight: 400;

      &.good {
        bottom: -22px;
        color: #259a7e;

        .crown {
          top: -36px;
        }
      }

      &.bad {
        top: -22px;
        color: #c24a4a;

        .crown {
          bottom: -36px;
        }
      }

      .crown {
        position: absolute;
        left: 50%;
        margin-left: -11px;
      }
    }


    .chart_ai_label {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-top: 1px solid #fff;
      font-size: 11px;

      .date {
        display: flex;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-color: white;
        border-top: 1px solid #fff;
        background-color: #b1b3c9;
      }

      .rating {
        display: flex;
        flex: 1 1 100%;
        width: 100%;
        border-style: solid;
        border-color: white;
        border-top: 1px solid #fff;
        justify-content: center;
        align-items: center;
        background-color: #d3d4e1;
      }

      .win {
        flex: 1 1 100%;
        border-style: solid;
        border-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #b4d1e9;
      }

      .lose {
        border-style: solid;
        border-color: white;
        flex: 1 1 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e4baba;
      }
    }

    .highcharts-axis-labels {
      width: 100%;
      max-width: 477.8px;
      height: 50px;
      top: auto !important;
      left: auto !important;
      right: 9px;
      bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #fff;
    }

    .highcharts-axis-labels > span {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      transform: rotate(0) !important;
      transform-origin: 0 !important;
      display: block !important;
      width: 100% !important;
      height: 50px;
    }

    .highcharts-axis-labels > span:last-of-type {
      border-right: 0;
    }
  }

  .no_data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 207px;
    text-align: center;
  }

  .champ {
    position: relative;
    width: 200px;

    &__search {
      position: relative;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #212121;

      input {
        width: 100%;
        height: 28px;
        padding: 0 25px 0 10px;
        background: none;
        border: 0;

        &::placeholder {
          color: rgba(33, 33, 33, 0.5);
        }
      }

      button {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background: url('/images/ico_search_w.svg') no-repeat center;
        background-size: cover;
        font-size: 0;
        text-indent: -9999px;
      }
    }

    &__autocomplete {
      position: absolute;
      top: 100%;
      left: 0;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-height: 140px;
      margin-top: 10px;
      padding: 10px 0;
      background: #fff;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 15%);
      border: 1px solid #313131;
      z-index: 99;

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #b8cddc;
      }

      ::-webkit-scrollbar-track {
        background-color: #dbe8ff;
      }

      &-item {
        display: flex;
        align-items: center;
        height: 24px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }
`;

export const SummaryContainer = styled.div`
  position: relative;
  width: 570px;

  .row {
    display: flex;

    & + .row {
      border-top: 1px solid #c3d1dc;
    }

    .col {
      padding: 13px 0;
      text-align: center;
      border-right: 1px solid #c3d1dc;

      &.c1 {
        width: 140px;
      }

      &.c2 {
        width: 140px;
      }

      &.c3 {
        width: 105px;
      }

      &.c4 {
        width: 185px;
      }

      &.c5 {
        padding: 22px 0;
        align-items: center;
        justify-content: center;
        width: 280px;
      }

      &.c6 {
        padding: 22px 0;
        align-items: center;
        justify-content: center;
        width: 290px;
      }
    }

    h5 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;

      .info {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 7px;
        font-size: 0;
        text-indent: -9999px;
        background: url('/images/list_btn_tip_s_w.svg') no-repeat center;
        cursor: pointer;
      }
    }

    .mi {
      margin: 10px 0;
      font-size: 22px;
      font-weight: 700;

      .small {
        font-size: 12px;
        font-weight: 400;
        color: rgba(49, 49, 49, 0.6);
      }
    }

    .sb {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin: 0 2px;
        font-size: 12px;

        &.death {
          color: #de5353;
        }
      }

      .small {
        font-size: 11px;
        font-weight: 400;
        color: rgba(49, 49, 49, 0.6);
      }
    }

    .predict {
      display: flex;
      justify-content: center;
      margin-top: 12px;


      strong {
        display: block;
        margin-top: 5px;
        font-size: 12px;
      }

      .arrow {
        display: inline-block;
        width: 10px;
        height: 8px;
        margin: 8px 5px;
        background: url('/images/ico_cell_right_arrow_w.svg') no-repeat center;
      }
    }

    .aiscore {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 94px;
      }

      p {
        display: flex;
        flex-direction: column;
        margin: 0 4px;
        font-size: 12px;

        span {
          display: block;
          margin-top: 5px;
          font-size: 22px;
          font-weight: 700;
        }
      }

      .rating {
        width: 100%;
        margin: 5px 0 0 0;
      }

      strong {
        display: block;
        margin-left: 3px;

        span {
          font-size: 47px;
          font-weight: 700;
        }
      }
    }

    .most {
      margin: 0 15px;

      dt {
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          margin-left: 15px;
          font-weight: 700;
        }
      }

      dd {
        margin-top: 8px;
        font-size: 12px;
        color: rgba(49, 49, 49, 0.6);

        p {
          display: flex;
          align-items: center;
          line-height: 1.4;
        }

        span {
          display: inline-block;
          margin-right: 2px;
          color: #313131;
        }
      }
    }
  }

  .imgChamp {
    width: 40px;
  }

  .imgLane {
    width: 40px;
  }
`;

export const MatchListContainer = styled.div`
  padding-bottom: 10px;

  .imgChamp {
    width: 60px;
  }

  .imgSpell {
    width: 24px;
  }

  .imgRune {
    width: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.8);

    & + .imgRune {
      padding: 2px;
    }
  }

  .imgLane {
    width: 20px;
  }

  .imgItem {
    width: 24px;
  }

  .build_detail {
    cursor: pointer;
  }

  .more_game {
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 6px;
    background: #f2f2f2;
    font-size: 16px;
    color: #555e5e;
    text-align: center;
    border: 1px solid #acacac;
    border-radius: 2px;
    box-shadow: 0 1px #dcdfdf;

    span {
      position: relative;
      z-index: 10;
    }

    .fill {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      background: rgba(245, 97, 97, 0.4);
    }

    &:disabled {
      cursor: auto;
      background-color: #b2b7bf;
    }
  }
`;

export const MatchItemContainer = styled.div`
  & + & {
    margin-top: 6px;
  }


  .rowContainer {
    border: 1px solid rgba(0, 0, 0, 0.14);


    &.win {
      background-color: #E7F0FC;

      &:hover {
        background-color: #D2E6F5;
      }

      .flex_column0 {
        background-color: #5d9ceb;
      }

      .flex_column1 dt span {
        color: #376edc;
      }

      &.open {
        //border: 3px solid #5D9CEB;
        background-color: #D2E6F5;
        outline: 3px solid #5D9CEB;
        outline-offset: -3px;

        .react-tabs {
          border-top: 3px solid #D2E6F5;
        }
      }

    }

    &.lose {
      background-color: #FBECEA;

      &:hover {
        background-color: #FFDEDB;
      }

      .flex_column0 {
        background-color: #e37e74;
      }

      .flex_column1 dt span {
        color: #c24a4a;
      }

      &.open {
        background-color: #FFDEDB;
        outline: 3px solid #E37E74;
        outline-offset: -3px;

      }

    }

    &.remake {
      background-color: #b2b7bf;

      .flex_column0 {
        background-color: #272727;
      }

      .flex_column1 dt span {
        color: #272727;
      }

      .flex_column7 button {
        display: none;
      }
    }

    &.open {
      .flex_column7 img {
        transform: rotate(180deg);
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
    height: 100px;
    cursor: pointer;
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.14);
    text-align: center;
    font-size: 12px;
  }

  .flex_column0 {
    width: 12px;
  }

  .flex_column1 {
    justify-content: space-between;
    width: 128px;

    h5 {
      font-size: 16px;
    }

    p {
      margin-top: 10px;
      color: rgba(49, 49, 49, 0.65);
    }

    dt {
      margin-top: 10px;

      span {
        display: inline-block;
        margin-right: 5px;
        font-weight: 700;
      }
    }

    dd {
      display: flex;
      align-items: center;
      margin-top: 5px;
      color: rgba(49, 49, 49, 0.65);

      > div {
        margin-left: 4px;
      }

      .tier_avg {
        display: inline-block;
        font-weight: 700;
        color: #313131;

        &.t {
          text-transform: lowercase;

          &::first-letter {
            text-transform: capitalize;
          }
        }

        &.d {
          margin-left: 2px;
        }
      }
    }
  }

  .flex_column2 {
    justify-content: center;
    width: 145px;

    dl {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    dt {
      position: relative;

      &.away {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4) url(/images/icon_exit_cross.svg) no-repeat center center;
          z-index: 5;
        }
      }

      p {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        margin: 6px 0 0;
        padding: 0 12px;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 4px;

        span {
          display: inline-block;
          margin-left: 2px;
          font-size: 11px;
        }
      }
    }

    dd {
      width: 24px;
      margin-left: 6px;

      div + div {
        margin-top: 6px;

        .imgRune {
          padding: 2px;
        }
      }
    }
  }

  .flex_column3 {
    width: 285px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .cbox {
      margin-right: 5px;
    }


    h5 {
      font-size: 12px;
      margin-bottom: 5px;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(49, 49, 49, 0.65);
      min-height: 16px;
      margin-top: 5px;

      i {
        font-weight: 700;
        color: #313131;
      }

      .crown {
        margin-right: 6px;
      }
    }
  }

  .flex_column4 {

    justify-content: center;
    width: 120px;

    p {
      display: flex;
      align-items: center;

      &.type1 {
        color: rgba(49, 49, 49, 0.6);

        span {
          display: inline-block;
          margin-left: 6px;
          font-weight: 700;
        }
      }

      &.type2 {
        font-size: 10px;
        color: rgba(49, 49, 49, 0.4);

        span {
          display: inline-block;
          margin: 0 4px;
          font-size: 15px;
          font-weight: 700;
          color: #313131;

          &.death {
            color: #c24a4a;
          }
        }
      }

      &.type3 {
        color: rgba(49, 49, 49, 0.6);

        span {
          display: inline-block;

          &.perfect {
            margin-right: 0;
          }
        }
      }

      &.type4 {
        margin: 6px 0 5px;
        color: rgba(49, 49, 49, 0.6);

        span {
          display: inline-block;
          margin-right: 5px;
          color: #313131;
        }

        i {
          color: #313131;
        }
      }

      &.type5 {
        color: rgba(49, 49, 49, 0.6);

        span {
          display: inline-block;
          margin-right: 2px;
          color: #313131;
        }
      }
    }
  }

  .flex_column5 {
    justify-content: space-between;
    width: 125px;

    .cbox {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img:nth-child(n+1) {
        margin-left: 1px;
        margin-bottom: 2px;
      }

      img {
        //margin: 2px 1px;
        &:last-of-type {
          border: 0;
        }
      }
    }

    .vision {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 3px;

        img {
          margin-right: 2px;
        }
      }
    }
  }

  .flex_column6 {
    width: 280px;
    flex-direction: row;

    .team {
      width: 110px;
      margin: 0 12px;

      & > div {
        display: flex;
        align-items: center;
        width: auto;

        & + div {
          margin-top: 3px;
        }
      }

      p {
        flex: 1;
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
        text-align: left;
        color: rgba(49, 49, 49, 0.8);
        line-height: 16px;
        opacity: 1;

        &:hover {
          color: #000000;
          text-decoration: underline;
        }
      }

      .my {
        span {
          color: #313131;
          font-weight: bold;
          opacity: 1;
        }

        .imgChamp {
          border-radius: 50%;
        }
      }
    }

    .imgChamp {
      width: 22px;
    }
  }

  .flex_column7 {
    width: 33px;
    border-right: 0;

    button {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        bottom: 14px;
        left: 50%;
        margin-left: -7px;
        width: 14px;
        opacity: 0.4;
      }
    }
  }
`;

export const MatchDetailContainer = styled.div`



  .analysis_ai {
    display: flex;
    padding: 14px;

    .total {
      width: 187px;
      background-color: #fff;
      border: 1px solid #a5bed2;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 6px 5px 11px;
        font-size: 12px;
      }

      .list {
        display: none;
        font-size: 12px;

        &.selected {
          display: block;
        }

        &__item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 46px;
          border-top: 1px solid #a5bed2;

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }

          &.win {
            background-color: #cde7f5;
          }

          &.lose {
            background-color: #eedddf;
          }
        }

        .rating {
          width: 19px;
        }

        .champ {
          position: relative;
          width: 28px;
          margin-left: 4px;

          &.my {
            .box {
              &::after {
                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                display: block;
                width: 100%;
                height: 100%;
                border: 3px solid #ffdd00;
                border-radius: 50%;
                z-index: 5;
              }
            }
          }

          .box {
            position: relative;
            z-index: 5;
            display: block;
          }
        }

        .score {
          width: 32px;
          margin-left: 2px;
          font-size: 18px;
          font-weight: 700;
        }

        .chart {
          width: 90px;
          margin-left: 5px;
          flex-direction: column;

          .num {
            margin-bottom: 8px;
          }

          .bar {
            position: relative;
            width: 80px;
            height: 8px;
            margin: 0 auto;
            background: #fff;
            border-radius: 3px;

            &.blue .line {
              background-color: #539dff;
            }

            &.red .line {
              background-color: #e96ba2;
            }
          }

          .line {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            animation: progressbar 0.3s linear;
            animation-iteration-count: 1;
            border-radius: 3px;
          }
        }
      }

      .imgChamp {
        width: 28px;
        border-radius: 50%;
      }

      .crown {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -11px;
        background-size: 85%;
        z-index: 10;
      }
    }

    .result {
      flex: 1;
      margin-left: 13px;
      background-color: #fff;
      border: 1px solid #a5bed2;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 30px;

        .team {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(49, 49, 49, 0.6);

          strong {
            display: inline-block;
            margin: 0 5px;
            font-size: 22px;
            font-weight: 700;

            &.win {
              color: #5682db;
            }

            &.lose {
              color: #de4469;
            }
          }
        }

        .center {
          flex: 1;
        }

        .main {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;

          > div {
            display: flex;
            align-items: center;
          }

          .vs {
            margin: 5px 6px 0;
            font-weight: 700;
          }

          .red {
            width: 200px;
            justify-content: flex-end;

            .golds {
              margin-left: 15px;
              flex-direction: row-reverse;

              img {
                margin-right: 8px;
              }
            }
          }

          .blue {
            width: 200px;
            justify-content: flex-end;

            .golds {
              margin-right: 15px;
              flex-direction: row;

              img {
                margin-left: 8px;
              }
            }
          }

          .win {
            color: #5682db;
          }

          .lose {
            color: #de4469;
          }

          .golds {
            display: flex;
            align-items: flex-end;
          }

          .kills {
            font-size: 24px;
            font-weight: 500;
          }
        }

        .sub {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 8px;
          font-size: 12px;

          .time {
            margin: 0 4px;
            font-weight: 700;
            color: rgba(49, 49, 49, 0.75);
          }

          .objs {
            min-width: 200px;
            display: flex;
            align-items: center;

            &.red {
              flex-direction: row;
            }

            &.blue {
              flex-direction: row-reverse;
            }

            img {
              margin: 0 2px;
            }
          }
        }
      }

      .content {
        .t_head {
          display: flex;
          align-items: center;
          height: 34px;
          border-top: 1px solid #a5bed2;
          background-color: #fff;
          font-size: 12px;

          .team {
            flex: 1;
            display: flex;
            align-items: center;

            span {
              display: block;
              text-align: center;
            }
          }

          .buttons {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 192px;

            button {
              width: 54px;
              height: 24px;
              background-color: #fff;
              border: 1px solid #5c628d;
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
              border-radius: 3px;
              transition: all 0.2s linear;

              &.selected {
                background-color: #5c628d;
                color: #fff;
              }
            }
          }
        }

        .t_body {
          position: relative;
          display: flex;
          font-size: 12px;

          > ul {
            flex: 1;

            &.win {
              background-color: #cde7f5;
            }

            &.lose {
              background-color: #efdee0;
            }
          }

          .team {
            display: flex;
            align-items: center;
            border-top: 1px solid #a5bed2;

            > div {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 79px;
            }
          }
        }

        .team_column1 {
          width: 105px;

          .cbox {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            img {
              width: 20px;
              margin: 1px;

              &:last-of-type {
                border: 0;
              }
            }
          }

          .vision {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;

            span {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 2px;

              img {
                margin-right: 1px;
              }
            }
          }
        }

        .team_column2 {
          width: 80px;

          p {
            &.type2 {
              ////background-color: red;
              color: rgba(49, 49, 49, 0.6);

              span {
                display: inline-block;
                margin: 0 4px;
                color: #313131;
              }
            }

            &.type3 {
              margin-top: 4px;
              color: rgba(49, 49, 49, 0.6);

              span {
                display: inline-block;
                margin-right: 4px;
                color: #313131;
              }
            }

            &.type4 {
              margin-top: 4px;
              color: rgba(49, 49, 49, 0.6);

              span {
                display: inline-block;
                margin-right: 4px;
                color: #313131;
              }
            }
          }
        }

        .team_column3 {
          width: 105px;

          a {
            display: block;
            width: 100%;
          }

          dl {
            display: flex;
            align-items: center;
            justify-content: center;

            & + p {
              width: 100%;
              margin-top: 2px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: break-word;
              line-height: 16px;

              &.away {
                color: #c24a4a;
              }

              &.my {
                font-weight: bold;
              }
            }
          }

          dt {
            position: relative;
            margin: 0 1px;

            &.away {
              &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4) url(/images/icon_exit_cross.svg) no-repeat center center;
                z-index: 5;
              }
            }

            &.my {
              &::before {
                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                display: block;
                width: 100%;
                height: 100%;
                border: 3px solid #ffdd00;
                z-index: 5;
              }
            }

            span {
              position: absolute;
              bottom: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 15px;
              height: 15px;
              background-color: rgba(0, 0, 0, 0.7);
              border: 1px solid rgba(255, 236, 236, 0.7);
              font-size: 10px;
              font-weight: 400;
              color: #fff;
            }
          }

          dd {
            width: 20px;
            margin: 0 1px;

            div + div {
              margin-top: 2px;

              .imgRune {
                padding: 2px;
              }
            }
          }
        }

        .team_column4 {
          width: 60px;

          h4 {
            font-size: 25px;
            font-weight: 700;
            cursor: pointer;

            &:hover {
              animation: hoverScale 150ms linear;
              transform: scale(1.2);
            }

            @keyframes hoverScale {
              0% {
                transform: scale(1);
              }
              100% {
                transform: scale(1.2);
              }
            }
          }

          p {
            margin: 5px 0 4px;

            &.away {
              color: #c24a4a;
            }
          }

          .tier_badge {
            width: 24px;
            font-size: 11px;
            font-weight: 400;
            height: 14px;
            border-radius: 5px;
          }
        }

        .compare {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 192px;

          .box {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 68px;
            margin: 12px 0;
            padding: 0 14px;
            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 3px;

            &.damage_box {
              padding: 0;
            }

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 5;
              display: block;
              width: 0;
              height: 79px;
              margin-top: -40px;
              border-left: 1px dotted rgba(0, 0, 0, 0.5);
            }
          }

          .lane {
            position: relative;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 21px;
            height: 21px;
            margin: 0 auto;
            background: #fff;
            border-radius: 2px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

            img {
              margin-top: 1px;
            }
          }

          .influence {
            position: relative;
            z-index: 10;
            width: 100%;
            height: 20px;
            margin-top: 10px;

            .bar {
              position: absolute;
              top: 0;
              display: block;
              width: 100%;
              height: 8px;

              &.win {
                background: #4481d6;
              }

              &.lose {
                background: #df6a52;
              }
            }
          }

          .damage {
            position: relative;
            z-index: 10;
            width: 100%;
            height: 20px;
            margin-top: 8px;

            .bar {
              position: absolute;
              top: 0;
              display: block;
              width: 50%;
              height: 8px;

              &.win i {
                background: #4481d6;
              }

              &.lose i {
                background: #df6a52;
              }

              i {
                position: absolute;
                top: 0;
                display: block;
                height: 100%;
              }
            }

            .txt {
              &.red {
                right: 7px;
              }

              &.blue {
                left: 7px;
              }
            }
          }

          .txt {
            display: block;
            position: absolute;
            top: 12px;
          }

          .red {
            right: 0;

            i {
              right: 0;
            }
          }

          .blue {
            left: 0;

            i {
              left: 0;
            }
          }
        }
      }

      .red {
        flex-direction: row-reverse;

        .team_column3 {
          dl {
            flex-direction: row;

            & + p {
              padding-left: 10px;
              text-align: left;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .blue {
        flex-direction: row;

        .team_column3 {
          dl {
            flex-direction: row-reverse;

            & + p {
              padding-right: 10px;
              text-align: right;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .imgMonster {
        display: none;
        width: 13px;

        &.dragon {
          display: block;
        }
      }

      .imgChamp {
        width: 42px;
      }

      .imgSpell {
        width: 20px;
      }

      .imgRune {
        width: 20px;
      }
    }

    .toggle {
      position: relative;
      width: 90px;

      &__btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 3px 8px 6px 8px;
        font-size: 12px;
        color: #313131;
        cursor: pointer;
        border-bottom: 1px solid #313131;

        &::after {
          content: '';
          overflow: hidden;
          display: inline-block;
          border-color: #313131 transparent transparent transparent;
          border-style: solid;
          border-width: 3px;
          width: 0;
          height: 0;
          transition: all 0.3s linear;
          margin-top: 4px;
          margin-left: 9px;
        }
      }

      &__list {
        position: absolute;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        max-width: 536px;
        max-height: 140px;
        margin-top: 5px;
        padding: 5px 0;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        border: 1px solid #313131;
        z-index: 10;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__list-item {
        display: flex;
        align-items: center;
        height: 25px;
        padding: 0 10px;
        font-size: 12px;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }

  .analysis_time {
    display: flex;
    flex-direction: column;
    padding: 14px 14px 24px;

    .tab {
      background-color: #eaecf1;
      border: 1px solid #a5bed2;
      font-size: 12px;

      .t_head {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 9px;
        background-color: #fff;
        border-bottom: 1px solid #a5bed2;

        span {
          position: relative;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            display: inline-block;
            margin-left: 5px;
            font-size: 12px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            display: inline-block;
            width: 1px;
            height: 8px;
            margin-top: -4px;
            background-color: #a5bed2;
          }

          &:first-of-type {
            &::before {
              display: none;
            }
          }
        }
      }

      .t_body {
        padding: 7px 9px;

        .team {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          > li {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 210px;
            height: 46px;
            margin: 2px 0;
            border: 1px solid #7590a4;
            cursor: pointer;

            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            }

            &.win {
              background-color: #cde7f5;

              &.react-tabs__tab--selected {
                &::before {
                  border-color: #266adc;
                }
              }
            }

            &.lose {
              background-color: #efdee0;

              &.react-tabs__tab--selected {
                &::before {
                  border-color: #c12d5f;
                }
              }
            }

            &.react-tabs__tab--selected {
              &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: -1px;
                display: block;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                box-sizing: border-box;
                border: 3px solid transparent;
                background: transparent;
              }
            }
          }
        }

        .rating {
          display: flex;
          flex-direction: column;
          width: 25px;

          .crown {
            margin-bottom: 2px;
          }
        }

        .champ {
          margin-left: 6px;
        }

        .score {
          margin-left: 6px;
          font-size: 20px;
          font-weight: 700;
        }

        .chart {

        }
      }

      .imgChamp {
        width: 32px;
        border-radius: 50%;
      }
    }

    .react-tabs__tab-panel {
      position: relative;
      margin-top: 17px;
      padding: 12px 2px 70px;
      background-color: #fff;
      border: 1px solid #a5bed2;

      > div {
        flex: 1;
      }
    }

    .chart_dot {
      position: absolute;
      top: -25px;
      left: 50%;
      width: 42px;
      padding: 3px 0;
      margin-left: -21px;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      color: #313131;
      line-height: 1;

      &.good {
        color: #259a7e;
      }

      &.bad {
        color: #de5353;
      }

      &.one_line {
        top: -10px;
      }

      .score {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 700;
      }

      .diff {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
        font-size: 11px;
        font-weight: 400;
        color: #313131;

        i {
          &.positive {
            &::before {
              background-image: url('/images/ico_graph_arrow_up_green.svg');
            }
          }

          &.negative {
            &::before {
              background-image: url('/images/ico_graph_arrow_down_red.svg');
            }
          }

          &::before {
            content: '';
            display: inline-block;
            width: 7px;
            height: 5px;
            margin-right: 1px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }

    .highcharts-plot-band-label {
      display: block;
    }

    .marker_score {
      position: absolute;
      top: -6px;
      right: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-size: 13px;
      font-weight: 500;
      color: #fff;

      &.man {
        top: -16px;
      }
    }

    .legend {
      position: absolute;
      top: 45px;
      left: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 24px;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
      font-size: 12px;
      font-weight: 700;

      i {
        display: inline-block;
        width: 28px;
        height: 0;
        margin-left: 8px;
        border: 1px dashed #a878d8;
      }
    }

    .timeline {
      position: absolute;
      bottom: 26px;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 48px;
      padding: 0 30px 0 43px;
      background-color: #fff;

      .team {
        flex: 1;

        &.win {
          background: transparent linear-gradient(90deg, rgba(183, 212, 255, 0.5) 0%, rgba(183, 212, 255, 0.5) 100%);
        }

        &.lose {
          background: transparent linear-gradient(270deg, rgba(255, 187, 195, 0.5) 0%, rgba(255, 187, 195, 0.5) 100%);
        }
      }

      .cade {
        position: relative;
        width: 100%;
        height: 100%;
        border-left: 2px solid #8388a5;
        border-right: 2px solid #8388a5;
      }

      .deca {
        position: relative;
        width: calc(100% - 20px);
        height: 100%;
        margin: 0 10px;
      }

      .monster {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-left: -9px;

        img {
          width: 100%;
        }
      }
    }
  }

  .loading {
    height: 525px;

    &_spin {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 525px;
    }
  }

  @keyframes progressbar {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
`;

export const ChartModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  &.openModal {
    .dimm,
    .modal {
      animation: modal-show 0.5s;
    }
  }

  &:focus {
    display: none;
  }

  .dimm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2px);
    z-index: 10;
  }

  .buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
    z-index: 20;

    button {
      position: absolute;
      width: 62px;
      height: 62px;
      margin-top: -26px;
      background-position: center;
      background-repeat: no-repeat;

      &.prev {
        top: 0;
        left: -72px;
        background-image: url('/images/icon_s_collapse_arrow.svg');
      }

      &.next {
        top: -5px;
        right: -72px;
        background-image: url('/images/icon_s_collapse_arrow.svg');
        transform: rotate(180deg);
      }
    }
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
    background: #fff;
    border: 3px solid #000;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    z-index: 20;

    .description {
      position: absolute;
      bottom: -25px;
      left: 10px;

      p {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
      }
    }

    .title {
      position: relative;

      h3 {
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        font-weight: 700;

        span {
          display: inline-block;
          margin-left: 5px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(49, 49, 49, 0.6);
          letter-spacing: 1px;
        }
      }

      .winrate {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;

        .team {
          display: flex;
          align-items: flex-end;

          &.win {
            h4 {
              color: #5890de;
            }
          }

          &.lose {
            h4 {
              color: #d15465;
            }
          }

          h4 {
            margin: 0 6px;
            font-size: 28px;
            font-weight: 700;

            span {
              display: inline-block;
              margin-left: 3px;
              font-size: 16px;
              font-weight: 400;
              color: rgba(49, 49, 49, 0.6);
            }
          }

          .updown {
            margin-bottom: 5px;
          }
        }

        .woose_bar {
          position: relative;
          display: flex;
          align-items: center;
          width: 240px;
          margin: 15px 10px 0;

          .center {
            position: relative;
            width: 2px;
            height: 15px;
            background: #1c202a;
          }

          .bar {
            height: 8px;

            &.win {
              background-color: #5890de;
            }

            &.lose {
              background-color: #d15465;
            }
          }

          .graduation {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            display: flex;
            justify-content: space-between;

            i {
              width: 1px;
              height: 100%;
              background-color: rgba(51, 58, 78, 0.3);
            }
          }
        }
      }
    }

    .result {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 0;
      border-top: 1px solid #a5bed2;
      border-bottom: 1px solid #a5bed2;

      .team {
        position: relative;
        flex: 1;
        margin: 0 15px;

        &.blue {
          ul {
            justify-content: flex-end;
          }

          h4 {
            justify-content: flex-start;
          }
        }

        &.red {
          ul {
            justify-content: flex-start;
          }

          h4 {
            justify-content: flex-end;
          }
        }

        &.win {
          h4 {
            background: #5890de;
          }
        }

        &.lose {
          h4 {
            background: #d15465;
          }
        }

        h4 {
          position: absolute;
          top: 20px;
          left: 0;
          display: flex;
          align-items: center;
          width: 100%;
          height: 18px;
          padding: 0 10px;
          font-size: 12px;
          font-weight: 400;
          color: #f6f6f6;
          z-index: 5;
        }

        ul {
          position: relative;
          display: flex;
          z-index: 10;
        }

        ul li {
          margin: 0 12px;
        }

        .score {
          display: block;
          margin: 3px 0 2px;
          font-size: 14px;
          font-weight: 700;
          text-align: center;
        }
      }
    }

    .view {
      display: flex;
      padding: 10px 14px;
      background-color: #f4f4f5;

      .event_box {
        flex: 1;
        height: 276px;
        overflow-y: auto;
        overflow-x: hidden;

        ::-webkit-scrollbar {
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #b8cddc;
        }

        ::-webkit-scrollbar-track {
          background-color: #dbe8ff;
        }
      }

      .event_item {
        display: flex;
        align-items: center;

        > div {
          display: flex;
        }

        &.win {
          .event_column1 {
            .num {
              border-color: #5890de;
            }
          }

          .event_column2 {
            background-color: rgba(88, 144, 222, 0.6);
          }

          .event_column3 {
            .vs {
              background-image: url('/images/ico_cross_graph_b.svg');
            }
          }

          .event_column4 {
            background: rgba(41, 56, 91, 0.6);
          }
        }

        &.lose {
          .event_column1 {
            .num {
              border-color: #d15465;
            }
          }

          .event_column2 {
            background-color: rgba(209, 84, 101, 0.6);
          }

          .event_column3 {
            .vs {
              background-image: url('/images/ico_cross_graph_r.svg');
            }
          }

          .event_column4 {
            background: rgba(163, 163, 164, 0.75);
          }
        }

        .event_column1 {
          width: 30px;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .num {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background: #26262d 0% 0% no-repeat padding-box;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 700;
            color: #fff;
          }

          .time {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            font-size: 12px;
          }
        }

        .event_column2 {
          position: relative;
          align-items: flex-end;
          justify-content: flex-end;
          width: 267px;
          height: 60px;
          margin-left: 13px;
          padding: 3px 13px;

          .list {
            position: relative;
            z-index: 10;
            display: flex;
            flex-direction: row-reverse;

            > li {
              margin: 0 8px;
            }

            .imgChamp,
            .imgMonster {
              width: 36px;
            }
          }

          .killer {
            position: relative;
            z-index: 10;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 5;
            display: block;
            width: 100%;
            height: 16px;
            background-color: #fff;
          }
        }

        .event_column3 {
          margin: 0 22px;

          .vs {
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }

        .event_column4 {
          position: relative;
          align-items: flex-end;
          justify-content: center;
          width: 50px;
          height: 60px;
          padding: 3px;

          .target {
            position: relative;
            z-index: 10;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 5;
            display: block;
            width: 100%;
            height: 16px;
            background-color: #fff;
          }
        }

        .updown {
          margin-top: 6px;
        }
      }

      .event_item + .event_item {
        margin-top: 4px;
      }

      .map_box {
        position: relative;
        width: 276px;
        height: 276px;
        margin-left: 30px;
        background: url('/images/bg_map_tracking@2x.png') no-repeat center;
        background-size: cover;

        .mini {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: default;
          color: #fff;
        }

        .num {
          width: 24px;
          height: 24px;
          margin-top: -12px;
          margin-left: -12px;
          background: #26262d 0% 0% no-repeat padding-box;
          border-width: 2px;
          border-style: solid;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 700;

          &:hover {
            z-index: 100;
          }

          &.win {
            border-color: #5890de;
          }

          &.lose {
            border-color: #d15465;
          }
        }

        .destruction {
          width: 16px;
          height: 16px;
          margin-top: -8px;
          margin-left: -8px;

          &:hover {
            z-index: 100;
          }
        }

        .icon {
          width: 16px;
          height: 16px;
          margin-top: -8px;
          margin-left: -8px;
        }
      }
    }

    .chart {
      position: relative;
      padding: 0 2px 70px;

      .timeline {
        padding: 0 27px 0 39px;
      }

      .plot_lines {
        display: block;
        width: 8px;
        height: 8px;
        transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
        background: #00d5b0;
        border: 1px solid #ffff00;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.57);
      }
    }

    .champ {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        background-color: rgba(0, 0, 0, 0.7);
        border: 1px solid rgba(255, 236, 236, 0.7);
        font-size: 10px;
        font-weight: 400;
        color: #fff;
      }

      &.is_mine {
        &::before {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          display: block;
          width: 100%;
          height: 100%;
          border: 3px solid #ffdd00;
          z-index: 5;
        }
      }

      .imgChamp,
      .imgMonster {
        width: 36px;
      }
    }

    .updown {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;

      &.positive {
        i {
          display: inline-block;
          background-image: url('/images/ico_graph_arrow_up_green.svg');
        }
      }

      &.negative {
        i {
          display: inline-block;
          background-image: url('/images/ico_graph_arrow_down_red.svg');
        }
      }

      i {
        display: none;
        width: 7px;
        height: 5px;
        margin-left: 2px;
        margin-right: 2px;
        margin-top: 2px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  @keyframes modal-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const FloatContainer = styled.div`
  z-index: 99;
  width: 1150px;

  &.not_fixed {
    position: absolute;
    top: 10px;
  }

  &.fixed {
    position: fixed;
    top: 70px;
  }

  .float {
    position: absolute;
    width: 300px;

    &.left {
      top: 0;
      left: -340px;
    }

    &.right {
      top: 0;
      right: -320px;
    }

    & > div {
      border: 1px solid #c3d1dc;
      background-color: #fff;

      & + div {
        margin-top: 10px;
      }
    }
  }

  .advertising {
    height: auto;
  }

  .history {
    display: flex;

    h4 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      font-size: 14px;
      font-weight: 700;
      background-color: #fff;
      border-bottom: 1px solid #c3d1dc;
    }

    .box {
      flex: 1;
      background-color: #f5f5f5;

      ul {
        display: block;
        overflow-y: auto;
        padding: 6px 4px;
        width: 100%;
        height: 200px;

        ::-webkit-scrollbar {
          width: 1px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #b8cddc;
        }

        ::-webkit-scrollbar-track {
          background-color: #dbe8ff;
        }

        li {
          width: 100%;
          margin: 0;

          & + li {
            margin-top: 4px;
          }
        }
      }

      .no_list {
        height: 200px;
        padding: 0 5px;
        font-size: 12px;
      }
    }

    .box + .box {
      border-left: 1px solid #c3d1dc;
    }
  }

  .ai_score {
    h3 {
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 700;
      background-color: #fff;

      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 7px;
        background-color: #fff;
        border: 2px solid #44bd9f;
        border-radius: 50%;
        box-sizing: border-box;
      }
    }

    .box {
      padding: 19px;
      background-color: #f5f5f5;

      p {
        margin-bottom: 10px;
        padding: 6px 8px 8px;
        background-color: #ddebe2;
        border-radius: 8px;
        font-size: 12px;
        color: #3e414a;
        line-height: 1.4;
      }
    }
  }
`;

export const ChampContainer = styled.div`
  margin: 10px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #fff;
  border: 1px solid #A5BED2;


  .filter {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 20px;

    select {
      width: 100%;
      height: 29px;
      padding: 0 8px;
      border: 0;
      border-bottom: 1px solid #212121;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 8l4 4 4-4z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: center;
      cursor: pointer;
    }

    .rank_type {
      width: 113px;
    }

    .position_type {
      display: flex;
      align-items: center;
      margin-left: 28px;

      .btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        padding: 0 10px;
        background-color: #fff;
        border: 1px solid #719893;
        border-radius: 3px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        font-size: 12px;
        font-weight: 500;
        color: #719893;
        cursor: pointer;
        transition: all 0.2s linear;

        &.selected {
          background: transparent linear-gradient(180deg, #58cbaa 0%, #37aaaa 100%);
          border: 1px solid #196068;
          color: #fff;
          text-shadow: 0px 1px 0px rgba(25, 96, 104, 1);

          p {
            &::before {
              display: none;
            }
          }

          .selector {
            background-color: #296e65;
            transition: all 0.2s linear;

            .selected {
              background-color: #000;
            }
          }
        }

        p {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 10;
          }
        }

        &:last-of-type {
          margin-left: 18px;

          &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: -10px;
            width: 1px;
            height: 26px;
            background-color: rgba(112, 112, 112, 0.5);
          }
        }
      }

      .selector {
        display: flex;
        align-items: center;
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        background-color: #e0e0e0;
        border-radius: 2px;
        padding: 2px 0;

        span {
          position: relative;
          display: inline-block;
          margin: 0 1px 0 2px;
          padding: 1px 4px;
          border-radius: 2px;
          transition: all 0.2s linear;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: -2px;
            display: inline-block;
            width: 1px;
            height: 10px;
            margin-top: -5px;
            background-color: rgba(255, 255, 255, 0.5);
          }

          &:first-of-type {
            padding: 1px 8px;

            &::after {
              display: none;
            }
          }
        }
      }
    }

    .champ_search {
      position: relative;
      width: 200px;
      margin-left: 10px;

      input {
        width: 100%;
        height: 29px;
        padding: 0 8px;
        border: 0;
        border-bottom: 1px solid #212121;
        background-image: url('/images/ico_search_w.svg');
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: center;
        background-size: 18px 18px;
      }
    }

    .season_type {
      width: 160px;
      margin-left: 12px;
    }

    .reset {
      margin-left: 10px;

      button {
        width: 32px;
        height: 32px;
        background: #5c628d url('/images/ico_reset.svg') no-repeat center;
        border: 1px solid #474c72;
        border-radius: 3px;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
        font-size: 0;
        text-indent: -9999px;
      }
    }
  }

  .table {
    width: 100%;
    text-align: center;

    thead {
      background: #ddebe2;
      border-top: 1px solid #a5bed2;
      border-bottom: 1px solid #a5bed2;

      th {
        position: relative;
        height: 44px;
        vertical-align: middle;

        &:last-of-type,
        &:first-of-type {
          .t-cell {
            border-left: 0;
          }
        }

        &.sort {
          cursor: pointer;
        }
      }

      .t-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #a5bed2;
      }

      .name {
        display: inline-block;
        font-size: 12px;
        font-weight: 700;

        i {
          font-weight: 400;
          color: rgba(49, 49, 49, 0.8);
        }
      }

      .arrow {
        display: flex;
        flex-direction: column;
        width: 6px;
        height: 12px;
        margin-left: 7px;

        &::before {
          content: '';
          display: inline-block;
          flex: 1;
          background: url('/images/ico_graph_arrow_up.svg') no-repeat center top;
        }

        &::after {
          content: '';
          display: inline-block;
          flex: 1;
          background: url('/images/ico_graph_arrow_down.svg') no-repeat center bottom;
        }
      }

      .sort {
        &.descending {
          .arrow {
            &::before {
              opacity: 0.4;
            }
          }
        }

        &.ascending {
          .arrow {
            &::after {
              opacity: 0.4;
            }
          }
        }
      }

      .bottom {
        th {
          padding: 0 2px;
        }

        .t-cell {
          padding: 3px 5px;
          border-left: 0;
          background-color: rgba(255, 255, 255, 0.8);
        }

        .name {
          font-size: 11px;
          font-weight: 400;
        }

        .arrow {
          margin-left: 2px;
        }
      }
    }

    tbody {
      tr {
        &.open, &.close {
          cursor: pointer;
          border-bottom: 1px solid #a5bed2;

          &:hover {
            background-color: #E0E6F5;
          }
        }

        &.open {
          outline: 3px solid #7A6CB1;
          outline-offset: -3px;

          .arrow img {
            transform: rotate(180deg);
          }
        }

        &.detail {

          background: #f5f7fb;
          //border-left: 3px solid #989eb8;
          //border-right: 3px solid #989eb8;
          //border-bottom: 3px solid #989eb8;
          //outline: 3px solid #989eb8;
          //outline-offset: -3px;
        }
      }

      td {
        height: 52px;
        font-size: 14px;
        vertical-align: middle;
        //border-bottom: 1px solid #a5bed2;
      }

      .noraml {
        font-weight: 400;
      }

      .bold {
        font-weight: 700;
      }

      .champ {
        display: flex;
        align-items: center;
        padding: 0 5px;

        p {
          margin-left: 6px;
          font-weight: 500;
        }
      }

      .winlose {
        display: flex;
        align-items: center;
        padding: 0 10px;

        .graph {
          position: relative;
          width: 105px;
          height: 20px;
          border-radius: 4px;
          overflow: hidden;

          > span {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            overflow: hidden;

            i {
              display: inline-block;
            }
          }
        }

        .win {
          left: 0;
          justify-content: flex-start;
          background: #699ce3;
          border-radius: 4px 0px 0px 4px;

          i {
            padding-left: 5px;
          }
        }

        .lose {
          right: 0;
          justify-content: flex-end;
          background: #f57593;
          border-radius: 0px 4px 4px 0;

          i {
            padding-right: 5px;
          }
        }

        .winrate {
          margin-left: 17px;
        }
      }

      .kda {
        p {
          margin-top: 4px;
          font-size: 12px;
        }
      }

      .arrow {
        position: relative;
        width: 100%;
        height: 100%;
        border-left: 1px solid #a5bed2;

        img {
          position: absolute;
          bottom: 14px;
          left: 50%;
          margin-left: -7px;
          width: 14px;
          opacity: 0.4;
        }
      }

      .vs {
        font-size: 12px;
        font-weight: 500;
        color: #5c628d;
      }

      .vs_champ {
        margin-top: -3px;
        padding: 11px 35px 14px;
        outline: 3px solid #7A6CB1;
        outline-offset: -3px;

        .list_wrap {
          overflow: hidden;
          width: 100%;
          max-height: 400px;
          margin-top: 3px;

          &.open {
            max-height: inherit;
          }
        }

        .list_more {
          width: 100%;
          height: 40px;
          background: #e3e4ee;
          border: 1px solid #818ac4;
          box-shadow: inset 0px 0px 0px rgba(255, 255, 255, 1), 0px 2px 4px rgba(0, 0, 0, 0.2);
          font-size: 14px;
          font-weight: 500;
        }

        .list {
          display: table;
          width: 100%;
        }

        .list > li {
          display: table-row;
          height: 40px;
          background: #fff;
          border: 1px solid #a5bed2;

          &:nth-of-type(even) {
            background: #F5F7FB;
          }

          .td {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }

      .no_data {
        height: 492px;
        border-bottom: 0;
      }
    }

    .imgChamp {
      width: 34px;
    }

    .imgLane {
      width: 20px;
    }
  }

  .loading {
    height: 550px;

    &_spin {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 550px;
    }
  }
`;

export const IngameContainer = styled.div`
  min-height: 560px;
  margin: 10px 0;

  &.on {
    background-color: transparent;
    border-radius: 0;
  }

  &.off {
    background-color: #fff;
    border-radius: 10px;
  }

  .not_playing,
  .not_supported {
    height: 360px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    button {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
    }

    p {
      line-height: 30px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 700;
    }

  }

  .overview {
    height: 160px;
    padding: 12px 15px;
    background-color: #F8FAFF;
    border: 1px solid #C3D1DC;
    .title {
      display: flex;
      align-items: center;

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 61px;
        height: 27px;
        background: #ea5a5a 0% 0% no-repeat padding-box;
        border-radius: 5px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        letter-spacing: 1px;
      }

      p {
        margin: 0 17px 0 13px;
        font-weight: 700;
      }

      dl {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
      }

      dd {
        position: relative;
        margin-left: 8px;
        padding-left: 9px;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          width: 1px;
          height: 10px;
          margin-top: -5px;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .average {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 80px;
      margin: 13px 0 16px;
      padding: 6px 8px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      .team {
        flex: 1;
        display: flex;
        align-items: center;
        //&.blue {
        //  flex-direction: row;
        //  padding-right: 100px;
        //  .name {
        //    flex:1;
        //    display: flex;
        //    margin-left: 3rem;
        //    color: #5682db;
        //  }
        //  .cont {
        //    text-align: left;
        //  }
        //  .winrate {
        //    display: flex;
        //    flex-wrap: nowrap;
        //    align-items: flex-end;
        //    strong {
        //      color: #5682db;
        //    }
        //    .winrate_percent {
        //      margin-left: 3px;
        //     margin-bottom: 6px;
        //    }
        //  }
        //}
        &.red {
          flex-direction: row-reverse;
          //padding-left: 100px;
          .name {
            flex-direction: row-reverse;
            display: flex;
            flex-grow: 1;
            color: #de4469;
          }

          .cont {
            text-align: right;
          }

          .winrate {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-end;

            strong {
              color: #de4469;
            }

            .winrate_percent {
              margin-left: 3px;
              margin-bottom: 6px;
            }
          }
        }

        .name {
          font-size: 28px;
          font-weight: 700;
        }

        .cont {
          min-width: 150px;

          .avg {
            padding: 2px 10px;
            background-color: rgba(0, 70, 162, 0.08);
            border-radius: 7px;

            p {
              font-size: 12px;
              line-height: 20px;

              strong {
                font-weight: 500;
              }
            }
          }

          .ban {
            display: flex;
            flex-direction: row;
            margin: 7px 7px 1px;
          }

          .imgChamp {
            width: 22px;
            margin: -3px;
          }

          .tier_badge {
            width: 24px;
            height: 14px;
            font-size: 11px;
            font-weight: 400;
            border-radius: 5px;
            line-height: 12px;
          }
        }

        .winrate {
          font-size: 24px;
          font-weight: 500;
          color: rgba(49, 49, 49, 0.6);

          strong {
            font-size: 52px;
            font-weight: 700;
          }
        }
      }

      .predict {
        position: absolute;
        top: -40px;
        left: 50%;
        width: 132px;
        height: 132px;
        background-color: #fafbfc;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 50%;
        transform: translateX(-50%);

        > div {
          //position: relative;
          //z-index: 5;
        }

        > span {
          //position: absolute;
          //top: 50%;
          //left: 50%;
          //z-index: 10;
          //display: flex;
          //text-align: center;
          //align-items: center;
          //justify-content: center;
          //width: 80px;
          //height: 80px;
          //background-color: #fafbfc;
          //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
          //border-radius: 50%;
          //transform: translate(-50%, -50%);
          //font-size: 19px;
          //font-weight: 700;
          //line-height: 1.2;
        }
      }
    }
  }

  .content {
    margin: 10px 0;
    background-color: #fff;
    border: 1px solid #a5bed2;

    .t_head {
      .row {
        display: flex;
        align-items: center;
        width: calc(100% - 36px);
        height: 35px;
        text-align: center;
      }

      .team {
        flex: 1;
        display: flex;
        align-items: center;

        &.blue {
          flex-direction: row;
        }

        &.red {
          flex-direction: row-reverse;
        }
      }

      .center {
        min-width: 132px;
      }

      .tit {
        font-size: 12px;
        font-weight: 700;

        span {
          display: block;
          margin-top: 2px;
          font-size: 11px;
          font-weight: 400;
          color: rgba(49, 49, 49, 0.8);
        }
      }

      .item1 {
        width: 220px;
      }

      .item2 {
        width: 185px;
        margin: 0 5px;
      }

      .item3 {
        position: relative;
        width: 65px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          display: block;
          width: 1px;
          height: 8px;
          margin-top: -4px;
          background-color: #a5bed2;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }
    }

    .t_body {
      min-height: 400px;

      .button {
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: 100%;
        border-left: 1px solid rgba(0, 0, 0, 0.07);

        button {
          position: relative;
          width: 100%;
          height: 100%;
        }

        img {
          position: absolute;
          bottom: 14px;
          left: 50%;
          margin-left: -7px;
          width: 14px;
          opacity: 0.4;
        }
      }

      .row {
        position: relative;
        z-index: 10;
        display: flex;
        width: calc(100% - 36px);
        height: 130px;
        padding: 10px;
        text-align: center;
      }

      .team {
        position: relative;
        flex: 1;
        display: flex;
        height: 100%;

        &.blue {
          flex-direction: row;

          .champ_data {
            flex-direction: row-reverse;

            .txt {
              text-align: right;

              p {
                flex-direction: row;

                > em:last-of-type {
                  margin-left: 10px;
                }
              }
            }
          }

          .rune_data {
            flex-direction: row-reverse;

            .runes {
              flex-direction: row-reverse;
              margin-left: 9.5px;

              .twrap {
                margin-left: 3px;

                &:last-of-type {
                  margin-left: 0;
                }
              }

              &:last-of-type {
                margin-left: 0;
              }
            }
          }

          .profile_data {
            margin-left: 10px;

            a {
              &:hover {
                text-decoration: underline;
              }

            }

            text-align: left;

            .tags {
              left: 0;
              flex-direction: row;
              justify-content: flex-start;

              span + span {
                margin-left: 5px;
              }
            }

            .texts dl {
              justify-content: flex-start;
            }

            .promos {
              margin-right: 55px;
            }
          }

          .summoner_info {
            flex-direction: row;

          }
        }

        &.red {
          flex-direction: row-reverse;

          .champ_data {
            flex-direction: row;
            //.txt {
            //  text-align: left;
            //  p {
            //    flex-direction: row-reverse;
            //    > em:last-of-type {
            //      margin-right: 10px;
            //    }
            //  }
            //}
          }

          .rune_data {
            flex-direction: row;

            .runes {
              flex-direction: row;
              margin-right: 9.5px;

              .twrap {
                margin-right: 3px;

                &:last-of-type {
                  margin-right: 0;
                }
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }

          .profile_data {
            margin-right: 10px;
            text-align: right;

            a {
              &:hover {
                text-decoration: underline;
              }
            }

            .tags {
              right: 0;
              flex-direction: row-reverse;
              justify-content: flex-end;

              span + span {
                margin-right: 5px;
              }
            }

            .texts dl {
              justify-content: flex-end;
            }

            .promos {
              margin-left: 55px;
            }
          }

          .summoner_info {
            flex-direction: row-reverse;
          }
        }
      }

      .center {
        width: 240px;
      }

      .ingame_list {
        position: relative;
      }

      .ingame_item {
        position: relative;
        border-top: 1px solid #a5bed2;

        &:nth-of-type(even) {
          background-color: #f1f3f9;
        }

        &.complete {
          &::after {
            display: none;
          }
        }

        &.incomplete {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
          }
        }

        &.open {
          background-color: #d2e6f5;

          &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 10;
            display: block;
            width: calc(100% + 2px);
            height: 100%;
            border: 3px solid rgb(93, 156, 235);
            box-sizing: border-box;
            z-index: 5;
          }

          .button {
            z-index: 10;

            img {
              transform: rotate(180deg);
            }
          }
        }

        .loading {
          position: absolute;
          z-index: 30;

          .dank-ass-loader {
            margin-top: 0;
          }
        }
      }

      .ingame_detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 36px);
        text-align: center;

        h4 {
          width: 100%;
          padding: 9px 0;
          background-color: #fff;
          font-size: 12px;
          font-weight: 700;

          span {
            font-weight: 400;
            color: rgba(49, 49, 49, 0.6);
          }
        }
      }
    }

    .summoner_info {
      display: flex;
      width: 210px;

      .icon_data {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 60px;
        padding-bottom: 27px;

        p {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 5px;
          background-color: #dde1ec;
          border-radius: 2px;

          span {
            display: inline-block;
            margin-top: 1px;
            margin-left: 3px;
            font-size: 11px;
            font-weight: 500;
            text-transform: uppercase;
          }

          &.not_most {
            span {
              color: #c24a4a;
            }

            i {
              display: inline-block;
              width: 14px;
              height: 14px;
              margin-left: 4px;
              font-size: 0;
              text-indent: -9999px;
              background: url('/images/icon_error.svg') no-repeat center;
            }
          }
        }

        .duo {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          display: inline-block;
          text-align: center;
          height: 18px;
          padding: 0 4px;
          font-size: 11px;
          border-radius: 3px;
          line-height: 16px;
          background: #f6eb86;
          border: 1px solid #e0ae47;
        }
      }

      .profile_data {
        padding-top: 22px;
        position: relative;
        flex: 1;

        .tags {
          display: flex;
          align-content: center;

          & > span {
            flex: 0 0 auto;
            display: inline-block;
            text-align: center;
            height: 18px;
            padding: 0 4px;
            font-size: 11px;
            color: #fff;
            border-radius: 3px;
            line-height: 16px;

            &.win {
              background-color: #15b95d;
              border: 1px solid #2a9454;
            }

            &.lose {
              background-color: #e37e74;
              border: 1px solid #a85c54;
            }
          }
        }

        .promos {
          margin-top: 2px;

          span {
            width: 13px;
            height: 13px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
            font-size: 0;
            text-indent: -9999px;
          }
        }

        .texts {
          line-height: 20px;

          &.tag_on {
            padding-top: 22px;
          }

          &.tag_off {
            padding-top: 22px;
          }

          a {
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: break-word;
            font-size: 14px;
            font-weight: 500;
          }

          dl {
            display: flex;
            align-items: center;
            font-size: 10px;
            color: rgba(49, 49, 49, 0.4);
          }

          .t1 {
            dt {
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                display: inline-block;
                margin-right: 4px;
                font-size: 14px;
                font-weight: 500;
              }
            }

            dd {
              margin-left: 3px;
              font-size: 12px;
              color: rgba(49, 49, 49, 1);
            }
          }

          .t2 {
            dt {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              color: rgba(49, 49, 49, 0.6);
              margin-right: 5px;

              span {
                display: inline-block;
                margin-right: 2px;
                font-size: 14px;
                font-weight: 700;
                color: #259a7e;
              }
            }

            dd {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 4px;
              font-size: 12px;
              color: rgba(49, 49, 49, 0.6);

              span {
                display: inline-block;
                margin-right: 2px;
                color: rgba(49, 49, 49, 1);
              }
            }
          }
        }

        .pro {
          display: block;
          font-size: 12px;
          color: #007edf;
          line-height: 14px;
        }
      }

      .loading {
        position: relative !important;
      }
    }

    .game_info {
      width: 196px;
      margin: 0;
      padding-top: 22px;

      .champ_data {
        display: flex;
        flex: 1;
        //.txt {
        //  flex: 1;
        //  p {
        //    display: flex;
        //    align-items: center;
        //    justify-content: flex-end;
        //    font-size: 12px;
        //    color: rgba(49, 49, 49, 0.6);
        //    line-height: 18px;
        //    &.name {
        //      font-weight: 500;
        //    }
        //    strong {
        //      color: #313131;
        //    }
        //    .score_color {
        //      font-weight: 700;
        //    }
        //    .kda_color {
        //      font-weight: 400;
        //    }
        //    > em {
        //      display: block;
        //    }
        //  }
        //}
        .spell {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0 6px;
        }
      }

      .rune_data {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .runes {
          position: relative;
          display: flex;
          align-items: center;

          img {
            position: relative;
            z-index: 10;
            background-color: rgb(255, 255, 255);
            border-radius: 50%;
            width: 14px;
            margin: 0 1.5px;

            &:first-of-type {
              margin-left: 0;
            }

            &:last-of-type {
              margin-right: 0;
            }

            &.imgRune {
              margin: 0;
            }
          }

          .twrap {
            z-index: 10;
            background-color: #fff;
            border-radius: 50%;

            img {
              width: 18px;
            }
          }

          &::before {
            content: '';
            position: absolute;
            z-index: 5;
            top: 50%;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: #7ea1be;
          }
        }
      }
    }

    .score_info {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 18px;

      .score {
        display: flex;
        flex-direction: column;
        width: 50px;

        .score_color {
          font-size: 28px;
          font-weight: 700;
        }

        .rating {
          display: block;
          margin-top: 5px;
          font-size: 12px;
        }
      }

      .graph {
        flex: 1;
        padding: 0 10px;

        .number {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 10px;

            span {
              font-size: 12px;
            }
          }
        }

        .bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          height: 8px;
          margin-top: 3px;

          p {
            height: 100%;

            &.blue {
              background-color: #4481d6;
            }

            &.red {
              background-color: #df6a52;
            }
          }
        }
      }
    }

    .counter_info {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      margin-top: 9px;
      background-color: rgba(0, 70, 162, 0.08);
      border-radius: 7px;

      .counter {
        display: flex;
        align-items: center;

        &.blue {
          flex-direction: row-reverse;
        }

        &.red {
          flex-direction: row;
        }

        p {
          margin: 0 7px;
          font-size: 11px;
          color: rgba(49, 49, 49, 0.6);

          span {
            font-weight: 500;
          }
        }

        img {
          width: 24px;
          border-radius: 50%;
        }
      }

      .vs {
        width: 64px;
        margin: 0 9px;

        dt {
          font-size: 12px;
          color: #5c628d;
        }

        dd {
          font-size: 11px;

          span {
            font-size: 10px;
          }
        }
      }
    }

    .detail_info {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 12px;
      background-color: #f1f3f9;
      border-top: 1px solid #a5bed2;

      .match_list {
        flex: 1;
        display: flex;
      }

      .match_item {
        &:last-of-type {
          .box {
            border-right: 1px solid #a5bed2;
          }
        }
      }

      .box {
        width: 46px;
        height: 100px;
        padding: 4px;
        text-align: center;
        border: 1px solid #a5bed2;
        border-right: 0;

        &.win {
          background-color: #d7e9fe;
        }

        &.lose {
          background-color: #fee7e7;
        }

        .champ {
          .imgChamp {
            width: 42px;
          }

          .imgLane {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: #313131;
          }
        }

        .score {
          margin: 4px 0;
          font-size: 18px;
          font-weight: 700;
        }

        .kda {
          margin-bottom: 3px;

          .perfect {
            font-size: 11px;
          }
        }

        .rating {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 12px;
            height: 9px;
            margin-right: 3px;
            margin-top: 1px;
          }

          .away {
            color: #c24a4a;
          }
        }
      }

      .time {
        margin-top: 5px;
        font-size: 10px;
        color: rgba(49, 49, 49, 0.6);
        letter-spacing: -0.01em;
        text-align: center;
      }

      .match_num {
        width: 50px;
        margin: 0 13px;

        h5 {
          font-size: 26px;
          font-weight: 700;
        }

        p {
          font-size: 11px;
        }

        .winlose {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 12px;
          margin-bottom: 4px;

          dl {
            flex: 1;
          }

          dd {
            margin-top: 4px;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }

      .team {
        align-items: center;
        padding: 10px;

        &.blue {
          padding-right: 0;
        }

        &.red {
          padding-left: 0;
        }

        &:last-of-type {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 1px;
            height: 100%;
            background-color: #a5bed2;
          }
        }
      }
    }

    .imgChamp {
      width: 60px;
    }

    .imgSpell {
      width: 24px;
    }

    .imgRune {
      width: 18px;
    }

    .imgEmblem {
      width: 55px;
    }
  }
`;
