import React, {useMemo} from 'react';
import styled from "styled-components";
import {ImageChamp} from "../../../../../common";
import {Link} from "react-router-dom";
import {onStorePuuId} from "../../../../../../function";
import BigTooltip from "../../../../../Tooltip/BigTooltipBox";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const ImageWrapper = styled.div`
  width: 28px;
  border-radius: 50%;
  aspect-ratio: 1;
  border: 1px solid black;
  background-color: black;

  .imgChamp {

  }
`;

const ProfileIcon = styled.img`
  z-index: 999;
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;

const SummonerName = styled.span`
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 22ch;
  margin-left: 16px;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ErrorBox = styled.div`
  margin-left: 6px;
`;

const StreakBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-right: 12px;
`;

const StreakSpan = styled.span`
  font-size: 14px;
  color: ${props => {
    let color = '';
    if (props.streak === 'winning') color = '#FF6060';
    if (props.streak === 'losing') color = '#538FE8';
    return color;
  }};
`;

const RankingSummoner = ({iconId, region, version, summonerName, puuId, restDays, streaks}) => {
    const {t} = useTranslation();
    const streaksMemo = useMemo(() => {
        if (streaks > 4) {
            return 'winning';
        } else if (streaks < -4) {
            return 'losing'
        }
        return false;
    }, [streaks]);

    return (
        <Wrapper>
            <ImageWrapper>
                <ProfileIcon src={`${process.env.REACT_APP_IMG}${version}/img/profileicon/${iconId}.png`} alt={''}/>
            </ImageWrapper>
            <SummonerName onClick={() => onStorePuuId(summonerName, region, puuId)}>{summonerName}</SummonerName>
            {streaksMemo && (
                <StreakBox>
                    <BigTooltip tooltipText={streaksMemo === 'winning' ? t('ranking.winningStreakTooltip') : t('ranking.losingStreakTooltip')}>
                        <img src={`/images/icon_ranking_${streaksMemo}.svg`} width={16} height={16} alt={''}/>
                    </BigTooltip>
                    <StreakSpan streak={streaksMemo}>{Math.abs(streaks)}</StreakSpan>
                </StreakBox>
            )}
            {(Number(restDays) > 13 || restDays === -1) &&
                <ErrorBox>
                    <BigTooltip tooltipText={t('ranking.unPlayTooltip')}>
                        <img src={'/images/ico_error.svg'} width={16} height={16} alt={''}/>
                    </BigTooltip>
                </ErrorBox>
            }
        </Wrapper>
    );
};

export default RankingSummoner;
