import React, {useCallback} from 'react';
import styled from "styled-components";

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ContentWrapper = styled.div`
  position: relative;
  margin-right: 0.2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  cursor: pointer;

  &::after {
    content: '';
    width: ${props => {
      if (props.selected) {
        return '100%';
      } else {
        return 0;
      }
    }};
    display: block;
    height: 3px;
    background: transparent linear-gradient(93deg, #1eb8a0 0%, #3c68b2 100%);
    transition: ${props => {
      if (props.selected) {
        return 'width 200ms linear;';
      }
      return '';
    }};
    overflow: hidden;
  }
;`;

const ContentText = styled.span`
  padding: 0 1rem 0.4rem;
  color: ${props => {
    let color = '#828282'
    if (props.selected) color = '#212121';
    return color;
  }};
  font-size: 14px;
  font-weight: ${props => {
    if (props.selected) {
      return 'bold';
    }
  }};

  &:hover {
    color: ${props => {
      let color = '#3679AE';
      if (props.selected) color = '#212121';
      return color;
    }};
    font-weight: bold;
    //pesude element
  }

  &::before {
    content: "${props => props.attr}";
    display: block;
    visibility: hidden;
    overflow: hidden;
    font-weight: bold;
    height: 0;
  }
`;
const NewTag = styled.span`
  position: absolute;
  top: -12px;
  right: -8px;
  background: transparent linear-gradient(112deg, #27E2C5 0%, #4B7CCE 100%) 0 0 no-repeat padding-box;
  padding: 1px 4px;
  border-radius: 3px;
  font-size: 9px;
  color: #fff;
  font-weight: bold;
  font-family: Arial;
`;

const Cateogry = ({list = [], selected = 0, onClickCategory}) => {

    const onClick = (index) => {
        if (index !== selected) {
            onClickCategory(index)
        }
    }

    const renderCategory = useCallback(() => {

        return list.map((data, index) => {
            return (
                <ContentWrapper
                    key={index + data.key}
                    selected={selected === index} onClick={() => onClick(index)}>
                    <ContentText attr={data.title} selected={selected === index}>{data.title}</ContentText>
                    {data.isNew && <NewTag>NEW</NewTag>}
                </ContentWrapper>
            )
        })
    }, [list])

    return (
        <CategoryWrapper>
            {renderCategory()}
        </CategoryWrapper>
    );
};

export default Cateogry;