import React, {useEffect} from 'react';
import styled from "styled-components";
import ChampionsSearch from "./ChampionsSearch";
import ChampionsRank from "./ChampionsRank";
import {useDispatch, useSelector} from "react-redux";
import {GET_CHAMPIONS_RANK_REQUEST} from "../../../store/reducer/champions";
import {GoogleAdvertise} from "../../common";
import useQuery from "../../../common/hooks/useQuery";
import {useLocation} from "react-router-dom";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
`;

const AdTopAdWrapper = styled.div`
  z-index: 1;
  width: 1130px;
  height: 128px;
  margin-top: 12px;
  margin-bottom: 21px;

  .adsbygoogle {
    min-width: 400px;
    max-width: 1130px;
    max-height: 128px;
    width: 100%;
    height: 100%;
  }
`;

const SideAdWrapper = styled.div`
  position: sticky;
  top: 70px;
  width: 300px;
  height: 600px;
  margin-top: 63px;
  margin-left: 16px;
  margin-right: 16px;
  z-index: 1;


  .adsbygoogle {
    width: 300px;
    height: 600px;
  }

`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 1162px;
  padding: 0 16px 16px;
  background-color: #fff;
  margin-bottom: 10vh;
  border-radius: 6px;
  z-index: 1;
`;

const AbsoluteBackground = styled.div`
  position: absolute;
  width: 100%;
  min-width: 1300px;
  height: 480px;
  top: 0;
  z-index: 0;
  background: transparent linear-gradient(90deg, #8FA5C2 0%, #6277A1 100%) 0 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
`;

const ContentBox = styled.div`
  border: 1px solid black;
  background-color: red;
`;


const ChampionsContent = () => {
    const dispatch = useDispatch();
    const championRanks = useSelector((state) => state?.champions?.ranks);
    const {
        getChampionsRankLoading,
        getChampionsRankDone,
        getChampionsRankError,
    } = useSelector((state) => state.champions);
    const versions = useSelector((state) => state.champions.championVersions);
    const {search} = useLocation();
    const query = useQuery();


    useEffect(() => {
        dispatch({
            type: GET_CHAMPIONS_RANK_REQUEST,
            data: {
                version: encodeURIComponent(query.get('version')) ? encodeURIComponent(query.get('version')) : versions[0],
                tier: query.get('tier') !== 'null' && typeof query.get('tier') === 'string' ? decodeURIComponent(query.get('tier')).trim() + "+" : "",
            }

        })
    }, [search]);


    return (
        <Wrapper>
                <ContentWrapper>
                    <SideAdWrapper marginRight={'16px'}>

                    </SideAdWrapper>
                    <CenterDiv>
                        <ChampionsSearch/>
                        <ChampionsRank/>
                    </CenterDiv>
                    <SideAdWrapper marginLeft={'16px'}>

                    </SideAdWrapper>
                </ContentWrapper>
        </Wrapper>
    );
};

export default ChampionsContent;
