import React, {useCallback, useEffect,} from 'react';
import styled from "styled-components";
import useInput from "../../../../../common/hooks/useInput";
import ScoreWrapper from "../../../DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const WinLoseWrapper = styled.div`
  margin-right: 6px;
`;

const WinLoseSpanWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
`;

const WinLoseSpan = styled.span`
  font-size: 12px;
`;


const scoreInit = {
    count: 0,
    value: 0,
}
const initObj = {
    win_ai_score: {
        ...scoreInit
    },
    lose_ai_score: {
        ...scoreInit
    },
    total_ai_score: {
        ...scoreInit
    }
};

const WIN_AI_SCORE = 'win_ai_score';
const LOSE_AI_SCORE = 'lose_ai_score';
const TOTAL_AI_SCORE = 'total_ai_score'

const AiScoreBox = ({positionArray}) => {
    const {t} = useTranslation();
    const [aiScores, onChangeAiScores] = useInput({...initObj});


    const setValue = useCallback(() => {
        return positionArray.reduce((sum, cur) => {
            if (cur.is_win) {
                sum.win_ai_score = {
                    count: sum.win_ai_score.count + 1,
                    value: sum.win_ai_score.value + cur.ai_score,
                }
            } else {
                sum.lose_ai_score = {
                    count: sum.lose_ai_score.count + 1,
                    value: sum.lose_ai_score.value + cur.ai_score,
                }
            }

            sum.total_ai_score = {
                count: sum.total_ai_score.count + 1,
                value: sum.total_ai_score.value + cur.ai_score,
            }
            return sum;
        }, {...initObj})
    }, [positionArray]);


    const renderValue = useCallback((objString) => {
        if (aiScores[objString].count > 0) {
            return (aiScores[objString].value / aiScores[objString].count).toFixed(0)
        } else {
            return 0;
        }
    }, [aiScores]);

    const renderCount = useCallback((objString) => {
        if (aiScores[objString].count > 0) {
            return aiScores[objString].count;
        } else {
            return 0;
        }
    }, [aiScores])


    useEffect(() => {
        if ((Array.isArray(positionArray) && positionArray.length > 0)) {
            onChangeAiScores(setValue());
        } else {
            onChangeAiScores({...initObj});
        }
    }, [positionArray]);

    return (
        <Wrapper>
            <WinLoseWrapper>
                <WinLoseSpanWrapper>
                    <WinLoseSpan>{renderCount(WIN_AI_SCORE)}{t('gameAnalytics.win')}</WinLoseSpan>
                </WinLoseSpanWrapper>
                <ScoreWrapper score={renderValue(WIN_AI_SCORE)} testid={WIN_AI_SCORE} fontSize={14}
                              paddingHorizontal={8} noneClickable={true} paddingVertical={3}/>
            </WinLoseWrapper>
            <WinLoseWrapper>
                <WinLoseSpanWrapper>
                    <WinLoseSpan>{renderCount(LOSE_AI_SCORE)}{t('gameAnalytics.lose')}</WinLoseSpan>
                </WinLoseSpanWrapper>
                <ScoreWrapper
                    score={renderValue(LOSE_AI_SCORE)} testid={LOSE_AI_SCORE} fontSize={14}
                              paddingHorizontal={8} noneClickable={true} paddingVertical={3}/>
            </WinLoseWrapper>
            <ScoreWrapper score={renderValue(TOTAL_AI_SCORE)} testid={TOTAL_AI_SCORE} fontSize={28}
                          noneClickable={true}/>
        </Wrapper>
    );
};

export default AiScoreBox;
