import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import AiScoreBox from "../../NewOverview/OverviewContent/AiScoreBox";
import ScoreWrapper from "../../DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import {getGameTime} from "../../../../function";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  margin-top: 16px;
  padding-left: 12px;
  margin-bottom: 5.5px;
  background: #fff;
`;

const DateSpan = styled.span`
  font-size: 14px;
  color: #313131;
  font-weight: bold;
  margin-right: 5px;
`;


const SummaryWrapper = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StaticText = styled.span`
  font-size: 12px;
  opacity: .65;
`;

const SlashText = styled(StaticText)`
  transform: scale(0.775);
  margin-inline: 6px;
`;

const InfoText = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin: 2px;
`;

const RestDayInfo = ({match, timestamp, duration, currentLang}) => {
    const {t} = useTranslation();
    const initObj = {
        games: 0,
        wins: 0,
        lose: 0,
        ai_score: 0,
    }

    function displayedAt(createdAt, lang) {
        const midnight = new Date();
        const currentTime = new Date() - createdAt;
        const seconds = currentTime / 1000

        const minutes = seconds / 60
        if (minutes < 60) {
            if (lang !== 'KOR' && Math.trunc(minutes) === 1) {
                return `${Math.trunc(minutes)} min ago`
            }
            return `${Math.trunc(minutes)}${lang !== 'KOR' ? " minutes ago" : "분 전"}`
        }
        const hours = minutes / 60

        if (hours < 24) {
            if (lang !== 'KOR' && Math.trunc(hours) === 1) {
                return `${Math.trunc(hours)} hour ago`
            }
            return `${Math.trunc(hours)}${lang !== 'KOR' ? " hours ago" : "시간 전"}`
        }
        const days = currentTime / 86400000

        if (lang !== 'KOR' && Math.trunc(days) === 1) {
            return `${Math.trunc(days)} day ago`
        }
        return `${Math.trunc(days)}${lang !== 'KOR' ? " days ago" : "일 전"}`
    }

    const summaryMatchMemo = useMemo(() => {
        if (Array.isArray(match) && match.length > 0) {
            return match.reduce((sum, cur) => {
                sum = {
                    games: sum.games + 1,
                    wins: cur.is_win ? sum.wins + 1 : sum.wins,
                    lose: cur.is_win ? sum.lose : sum.lose + 1,
                    ai_score: sum.ai_score + cur.ai_score,
                }
                return sum;
            }, {...initObj})
        }
    }, [match]);

    const winRateCalc = useCallback((games, win) => {
        if (games === 0) {
            return 0;
        }
        if (win === 0) {
            return 0;
        }
        return Math.trunc(win / games * 100)

    }, [])


    return (
        <Wrapper>
            <DateSpan>{getGameTime(Number(timestamp), currentLang,true)}</DateSpan>
            <SummaryWrapper>
                <InfoText>{summaryMatchMemo.games}</InfoText>
                <StaticText>{t('summoner.games')}</StaticText>
                <SlashText>/</SlashText>
                <InfoText>{summaryMatchMemo.wins}</InfoText>
                <StaticText>{t('gameAnalytics.win')}</StaticText>
                <InfoText>{summaryMatchMemo.lose}</InfoText>
                <StaticText>{t('gameAnalytics.lose')}</StaticText>
                <SlashText>/</SlashText>
                <InfoText>{winRateCalc(summaryMatchMemo.games, summaryMatchMemo.wins)}</InfoText>
                <StaticText>%</StaticText>
                <SlashText>/</SlashText>
                <ScoreWrapper
                    noneClickable={true}
                    score={summaryMatchMemo.ai_score / summaryMatchMemo.games}
                    fontSize={12}
                    paddingHorizontal={6}
                    paddingVertical={2}
                />
            </SummaryWrapper>
        </Wrapper>
    );
};

export default RestDayInfo;
