import React, {useCallback, useEffect} from 'react';
import styled from "styled-components";
import useInput from "../../../../../common/hooks/useInput";
import {sortChampData} from "../../../../../function";
import ScoreWrapper from "../../../DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import ChampionImage from "../../../../common/ChampionImage";
import {useTranslation} from "react-i18next";
import ChampionImageWithLane from "../../../../common/ChampionImageWithLane";


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .non_image{
    width: 24px;
    height: 24px;
    pointer-events: none;
    margin: 0;
  }

  .imgChamp {
    width: 26px;
    aspect-ratio: 1;
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  ${ContentWrapper}:nth-child(2) {
    margin-block: 8px;
  }
`;

const WinLoseSpan = styled.div`
  text-align: right;
  min-width: ${props => props.width || "3ch"};
  font-size: 12px;
  color: #313131;
  margin-right: 2px;
`;

const SubText = styled.span`
  font-size: 12px;
  color: #313131;
  opacity: 0.6;
`;

const WinLoseWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const reduceObj = {
    champion_id: 0,
    games: 0,
    win: 0,
    lose: 0,
    ai_score: 0,
}


const RecentPlayedChampions = ({data}) => {
    const {t} = useTranslation();
    const [recentData, onChangeRecentData] = useInput([]);


    function sumPush(sum, cur) {
        sum.push({
            ...reduceObj,
            champion_id: cur.champion_id,
            games: 1,
            win: cur.is_win ? 1 : 0,
            lose: !cur.is_win ? 1 : 0,
            ai_score: cur.ai_score,
        })
    }

    function setSum(findIndex, sum, cur) {
        if (findIndex > -1) {
            const IndexedSumData = sum[findIndex];
            sum[findIndex] = {
                ...IndexedSumData,
                games: ++IndexedSumData.games,
                win: cur.is_win ? ++IndexedSumData.win : IndexedSumData.win,
                lose: cur.is_win ? IndexedSumData.lose : ++IndexedSumData.lose,
                ai_score: IndexedSumData.ai_score + cur.ai_score,
            }
        } else {
            sumPush(sum, cur);
        }
    }

    const reduceData = useCallback(() => {
        const result = data.reduce((sum, cur) => {
            if (sum.length > 0) {
                const findIndex = sum.findIndex((sumData) => sumData.champion_id === cur.champion_id);
                setSum(findIndex, sum, cur);
            } else {
                sumPush(sum, cur)
            }

            return sum;
        }, []);
        return sortChampData(result, [{sortType: false, value: "games"}, {
            sortType: false,
            value: "ai_score"
        }]).slice(0, 3);
    }, [data]);

    useEffect(() => {
        if (Array.isArray(data) && data.length > 0) {
            onChangeRecentData(reduceData());
        } else {
            onChangeRecentData([]);
        }
    }, [data]);


    if (!Array.isArray(data) || data.length === 0) {
        return <Wrapper>There are no data</Wrapper>
    }
    return (
        <Wrapper>
            {recentData.map((data) => {
                return (
                    <ContentWrapper key={data.cid}>
                        <ChampionImageWithLane
                            champion_id={Number(data.champion_id)}
                        />
                        {/*<WinLoseWrapper>*/}
                        {/*    <WinLoseSpan width={"2ch"}>{data.games}</WinLoseSpan>*/}
                        {/*    <SubText>games</SubText>*/}
                        {/*</WinLoseWrapper>*/}
                        <WinLoseWrapper>
                            <WinLoseSpan>{Math.floor(data.win / data.games * 100)}</WinLoseSpan>
                            <SubText>%</SubText>
                            <WinLoseSpan width={"2ch"}>({data.win}</WinLoseSpan>
                            <SubText>{t('gameAnalytics.win')}</SubText>
                            <WinLoseSpan width={"2ch"}>{data.lose}</WinLoseSpan>
                            <SubText>{t('gameAnalytics.lose')}</SubText>
                            <WinLoseSpan width={'1px'}>)</WinLoseSpan>
                        </WinLoseWrapper>
                        <WinLoseWrapper>

                        </WinLoseWrapper>
                        <div>
                            <ScoreWrapper
                                noneClickable={true}
                                paddingHorizontal={8}
                                paddingVertical={4}
                                fontSize={14}
                                score={Math.floor(data.ai_score / data.games)}
                            />
                        </div>
                    </ContentWrapper>
                )
            })}
        </Wrapper>
    );
};

export default RecentPlayedChampions;