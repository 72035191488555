import React, {useMemo} from 'react';
import styled from "styled-components";

const LaneTag = styled.div`
  cursor: pointer;
  min-width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${props => {
    let background = 'transparent';
    if (props.selected) background = '#6A5AAC';
    return background;
  }};
  color: ${props => {
    let color = '#6A5AAC';
    if (props.selected) color = '#fff';
    return color;
  }};
`;


const LaneBox = styled(LaneTag)`
  opacity: ${props => {
    let opacity = '0.6';
    if (props.selected) opacity = '1';
    return opacity;
  }};
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ValueText = styled.span`
  font-size: 12px;
  margin-left: 4px;
`;
const PercentText = styled.span`
  font-size: 12px;
  transform: scale(0.83);
  margin-left: 1px;
`;

const AllLangSpan = styled.span`
  font-size: 12px;
  transform: scale(0.9);
  font-weight: bold;
`;

const ChampLane = ({selectedLane, onChangePosition, mapValue}) => {
    const laneName = useMemo(() => ['All', 'Top', 'Jungle', 'Middle', 'Bot', 'Supporter'], []);
    return <>
        {laneName.map((lane,index) => {

            if (typeof mapValue === 'function' && typeof selectedLane === 'string' && typeof onChangePosition === 'function') {
                if (lane === 'All') {
                    return (
                        <LaneTag selected={selectedLane === 'All'} onClick={() => onChangePosition("All")} key={index}>
                            <AllLangSpan>
                                ALL
                            </AllLangSpan>
                        </LaneTag>)
                } else {
                    return (
                        <LaneBox key={lane} selected={selectedLane === lane} onClick={() => onChangePosition(lane)}>
                            <img
                                alt={""}
                                width={16} height={16}
                                src={`/images/icon_s_position_${lane.toLowerCase()}${selectedLane === lane ? "_w" : ""}.svg`}/>
                            <ValueWrapper>
                                <ValueText>
                                    {mapValue(lane)}
                                </ValueText>
                                <PercentText>
                                    %
                                </PercentText>
                            </ValueWrapper>
                        </LaneBox>
                    )
                }
            } else {
                return null;
            }
        })}
    </>;
};

export default ChampLane;
