import React from 'react';
import SummonerChampion from "../SummonerChampion";
import SummonerGameCount from "../SummonerGameCount";
import SeasonScore from "../SeasonScore";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ProfileWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

const SummonerProfile = ({part, gameInfo, reverse}) => {
    const {t} = useTranslation();
    const champNames = useSelector((state) => state.imageStore.champions);
    const currentLang = useSelector((state) => state.translation.lang);



    return (
        <ProfileWrapper>
            {champNames.map(
                (champ, idx) =>
                    Number(champ.championId) === gameInfo.champion_id && (
                        <SummonerChampion
                            key={idx}
                            reverse={reverse}
                            championName={currentLang === 'KOR' ? champ.champion_name_kr : champ.champion_name_en}
                        />
                    )
            )}
            <SummonerGameCount
                reverse={reverse}
                gamesTranslation={t('summoner.games')}
                scorePercent={part?.season_champion_info_dict?.win_rate.toFixed(1)}
                seasonGames={part?.season_champion_info_dict?.games}
            />

            <SeasonScore
                reverse={reverse}
                kda={part?.season_champion_info_dict?.kda?.toFixed(2)}
                seasonAiScore={part?.summoner_info_dict?.ai_score_avg}
            />
        </ProfileWrapper>
    );
};

export default SummonerProfile;