import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {bookmarkList} from '../../store/modules/bookmarkList';
import {StorageContainer} from '../../styled/CommonStyle';
import styled from "styled-components";
import RegionTag from "./NewSearch/RegionTag";
import {getTierScore, tierBadge} from "../../function";

const NodataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;;
`;

const RegionTagWrapper = styled.div`
  .tier_badge {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 14px;
    line-height: 15px;
    font-size: 11px;
    border-radius: 5px;

    &.challenger {
      font-size: 11px;
    }

    &.grandmaster {
      width: 24px;
      font-size: 11px;

    }

    &.master {
      font-size: 11px;
    }
  }

  margin-left: 3px;
`;

const BookmarkList = ({fullWidth, onSelect}) => {
    const dispatch = useDispatch();
    const bookmarks = useSelector((state) => state.bookmarkList.bookmarks);
    const region = useSelector((state) => state.region.result);
    const viewBookmarks = useMemo(() => [...bookmarks].reverse(), [bookmarks]);
    const {t} = useTranslation();

    // 즐겨찾기 항목 제거
    const removeBookmark = (item) => {
        dispatch(bookmarkList(item, 'remove'));
    };


    return (
        <StorageContainer fullWidth={fullWidth} className={'searchListContainer'}>
            {bookmarks.length !== 0 ? (
                <ul className="bookmark_list">
                    {viewBookmarks.map((item, index) => (
                        <li key={index}>
                            <RegionTagWrapper>
                                {tierBadge(getTierScore(`${item?.season_tier_info_dict?.ranked_solo_5x5?.tier} ${item?.season_tier_info_dict?.ranked_solo_5x5?.division}`).txt)}
                            </RegionTagWrapper>
                            <p className="txt" onClick={onSelect}>
                                <Link
                                    to={`/summoner/${item.region ? item.region : region}/${item?.summoner_basic_info_dict?.summoner_name}`}
                                    className="common_search">
                                    {item?.summoner_basic_info_dict?.summoner_name}
                                </Link>
                            </p>
                            <button className="remove" onClick={() => removeBookmark(item)}>
                                {t('buttons.delete')}
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <NodataWrapper>
                    <p className="no_list">{t('persist.noFavorite')}</p>
                </NodataWrapper>
            )}
        </StorageContainer>
    );
};

export default BookmarkList;
