import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ContentWrapper = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid #C3D1DC;
  align-items: center;
`;

const ChildrenContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75%;
`;


const ContentTitleHeader = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const SummaryContent = ({children, title}) => {
    const {t} = useTranslation();
    return (
        <ContentWrapper>
            <ContentTitleHeader>{title}</ContentTitleHeader>
            <ChildrenContent>
                {children}
            </ChildrenContent>
        </ContentWrapper>
    );
};

export default SummaryContent;