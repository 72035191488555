import React, {useCallback} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {GET_MATCH_BY_QUEUE_REQUEST} from "../../../../store/reducer/summoner";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 9px;
  padding-bottom: 7.5px;
  border-bottom: 1px solid #C3D1DC;
  padding-left: 32px;
`;

const QueueWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const QueueButton = styled.button`
  position: relative;
  padding: 8px 10px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: ${props => {
    let weight = '400';
    if (props.selected) weight = 'bold';
    return weight;
  }};
  color: ${props => {
    let color = '#828282'
    if (props.selected) color = '#212121';
    return color;
  }};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    overflow: hidden;
    background: transparent linear-gradient(93deg, #1eb8a0 0%, #3c68b2 100%);
  }

  &:hover {
    color: ${props => {
      let color = '#675FB3';
      if (props.selected) color = '#212121';
      return color;
    }};
    font-weight: bold;
  }


  ${props => {
    if (props.selected) {
      return "&::after {width: 100%;transition: all 0.2s linear;}"
    }
  }}
  &.selected {
    color: #212121;
    font-weight: bold;
  }

`;

const QueueFilter = ({selected, onChangeSelected}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const filterList = [{
        title: t('tabs.total'),
        value: "ALL"
    }, {
        title: t('tabs.rankedSolo'),
        value: 'RANKED_SOLO_5x5',
    }, {
        title: t('tabs.rankedFlex'),
        value: "RANKED_FLEX_SR",
    }, {
        title: t('tabs.normal'),
        value: "NORMAL",
    }, {
        title: t('tabs.aram'),
        value: "ARAM",
    },{
        title: t('summoner.clash'),
        value: "CLASH_ALL",
        numValue: 7777,
    },
        {
            title: t('tabs.event'),
            value: "EVENT",
        }];

    const onClickQueueButton = useCallback((value) => {
        if (typeof value === 'string') {
            onChangeSelected(value);
        }
    }, []);


    return (
        <Wrapper>
            <QueueWrapper>
                {filterList.map((data, index) => {
                    return (
                        <QueueButton selected={selected === data.value} onClick={() => onClickQueueButton(data.value)}
                                     key={data.value + "filter"}>
                            {data.title}
                        </QueueButton>
                    )
                })}
            </QueueWrapper>
            <FilterWrapper>

            </FilterWrapper>
        </Wrapper>
    );
};

export default QueueFilter;
