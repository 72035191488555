import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import BookmarkList from '../common/BookmarkList';
import SearchList from '../common/SearchList';
import {FloatContainer} from '../../styled/SummonerStyle';
import {GoogleAdvertise} from '../common';
import styled from "styled-components";
import {Link} from "react-router-dom";

const SmallAdWrapper = styled.div`
`;


const BannerWrapper = styled.div`
  border: 0 !important;
  width: 300px;
  background-color: transparent;
`;

const BannerImage = styled.img`
  border: 0;
  padding: 0;
  margin: 0;
`;

const Float = () => {
    const {t} = useTranslation();
    const tabSelectorRef = useRef(null);
    const [isFloatOn, setIsFloatOn] = useState(0);
    const [isFixed, setIsFixed] = useState(false);
    const [changeBookmark, setChangeBookmark] = useState([]);
    const currentLang = useSelector((state) => state.translation.lang);

    // 북마크 변경 이벤트
    const handleChangeBookmark = useCallback(() => {
        setChangeBookmark(prev => !prev);
    },[]);

    // 스크롤 이벤트
    const throttledScroll = useMemo(
        () =>
            _.throttle(() => {
                window.scrollY >= 170 ? setIsFixed(true) : setIsFixed(false);
                if (!tabSelectorRef.current) return;
                const nextTabnavOn = window.scrollY > tabSelectorRef.current.offsetTop;
                if (nextTabnavOn !== isFloatOn) setIsFloatOn(nextTabnavOn);
            }, 0),
        [isFloatOn]
    );

    useEffect(() => {
        window.addEventListener('scroll', throttledScroll);
        return () => {
            window.removeEventListener('scroll', throttledScroll);
        };
    }, [throttledScroll]);

    return (
        <FloatContainer className={`${isFixed ? 'fixed' : 'not_fixed'}`}>
            <div className="float left">

            </div>
            <div className="float right">
                <div className="ai_score">
                    <h3>{t('persist.aiscoreTitle')}</h3>
                    <div className="box">
                        <p>{t('persist.aiscoreText')}</p>
                        {currentLang === 'KOR' ? (
                            <img src="/images/img_info_aiscore_kr.png" alt="AI Score"/>
                        ) : (
                            <img src="/images/img_info_aiscore_en.png" alt="AI Score"/>
                        )}
                    </div>
                </div>
            </div>
        </FloatContainer>
    );
};

export default Float;
