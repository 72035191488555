import React from 'react';
import styled from "styled-components";
import RankingHeaderItem from "./RankingHeaderItem";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #A5BED2;
  padding: 9px 12px;
  border-radius: 6px;
`;

const Splitter = styled.div`
  width: 1px;
  height: 18px;
  background-color: #A5BED2;
  opacity: 0.3;
  margin: 0 12px;
`;

const RankingHeaderLP = ({rankingRegion,challengerLp,grandMasterLp}) => {
    const {t} = useTranslation();
    const tier = useSelector((state) => state.imageStore.aiData);

    if(!tier?.tier_boundary_solo) return null;
    return (
        <Wrapper>
            <RankingHeaderItem
                title={t('ranking.challenger')}
                img={'/images/Emblem_CHALLENGER.png'}
                lp={challengerLp}
                diff={2}
            />
            <Splitter/>
            <RankingHeaderItem
                title={t('ranking.grandMaster')}
                img={'/images/Emblem_GRANDMASTER.png'}
                lp={grandMasterLp}
                diff={2}

            />
        </Wrapper>
    );
};

export default RankingHeaderLP;
