import React from 'react';
import styled from "styled-components";

const Text = styled.span`
  color: ${props => {
    let color = '#69788D';
    const NumberedValue = Number(props.children);
    if (NumberedValue >= 4) color = "#F19000";
    else if (NumberedValue >= 3) color = "#3571E9";
    else if (NumberedValue >= 2) color = "#259A7E";
    return color;
  }};

  
  font-weight: ${props => {
    let weight = 'normal';
    if (props.fontWeight) weight = props.fontWeight;
    return weight;
  }};
  font-size: ${props => {
    let size = '14px';
    if (props.fontSize) size = props.fontSize;
    return size;
  }};

`;

const KDAText = ({children, fontWeight, fontSize}) => {
    return (
        <Text fontWeight={fontWeight} fontSize={fontSize}>{children}</Text>
    );
};

export default KDAText;