import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import useInput from "../../../../../common/hooks/useInput";
import ScoreWrapper from "../../../DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import {useTranslation} from "react-i18next";
import {noPositionCheck} from "../../../../../function";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .hover {
    display: none;
  }

  .notHover {
    display: flex;
  }

  &:hover {
    .hover {
      display: flex;
    }

    .notHover {
      display: none;
    }
  }
`;

const Chart = styled.div`
  width: 308px;
  height: 80px;
`;

const ChartContent = styled.div`
  height: 60px;
  background-color: #EEF1F5;
  display: flex;
  flex-direction: row;
`;

const GraphWrapper = styled.div`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
`;
const BlueGraph = styled.div`
  width: 5px;
  height: ${props => props.height || 0};
  background-color: #4481D6;
  margin-right: 2px;
`;

const RedGraph = styled.div`
  width: 5px;
  height: ${props => props.height || 0};
  background-color: #DF6A52;
`;


const HoverWrapper = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-block: 3px;
  flex: 1;
`;

const LaneWrapper = styled.div`
  margin-block: 3px;
  display: flex;
  flex-direction: row;
`;

const LaneBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
`;

const HoverWinLoseWrapper = styled.div`
  display: flex;
`;

const WinRateWrapper = styled.div`
  margin-block: 1px;
`;


const HoverSpan = styled.span`
  margin: ${props => props.margin || 0};
  font-size: 12px;
  opacity: ${props => {
    let opacity = "1";
    if (props.opaicty) opacity = props.opaicty;
    return opacity;
  }};
`;

const reduceInit = {
    count: 0, win: 0, lose: 0, ai_score: 0,
}

const initReduceObject = {
    Top: {...reduceInit, position: 'Top'},
    Jungle: {...reduceInit, position: 'Jungle'},
    Middle: {...reduceInit, position: 'Middle'},
    Bot: {...reduceInit, position: 'Bot'},
    Supporter: {...reduceInit, position: 'Supporter'},
}

const PositionChart = ({positionArray}) => {
    const {t} = useTranslation();
    const [isHover, onChangeIsHover] = useInput(false);


    const reduceArray = useMemo(() => {
        if (Array.isArray(positionArray) && positionArray.length > 0) {
            return positionArray.reduce((sum, cur) => {
                if(noPositionCheck(cur.queue_id)) {
                    sum[cur.position] = {
                        count: sum[cur.position].count + 1,
                        win: cur.is_win ? sum[cur.position].win + 1 : sum[cur.position].win,
                        lose: !(cur.is_win) ? sum[cur.position].lose + 1 : sum[cur.position].lose,
                        ai_score: Number(sum[cur.position].ai_score) + Number(cur.ai_score),
                    }
                }

                return sum;
            }, {...initReduceObject})
        }
        return {...initReduceObject};
    }, [positionArray]);

    const getMaxValue = useMemo(() => {
        if (Object.values(reduceArray).length > 0) {
            return Object.values(reduceArray).reduce((sum, cur) => {

                const winMax = Math.max(sum.maxValue, cur.win);
                sum.maxValue = Math.max(winMax, cur.lose);
                return sum;

            }, {maxValue: 1})
        }
        return {maxValue: 1};
    }, [reduceArray])


    return (
        <Wrapper>
            <Chart data-testid={'hover'}>
                <ChartContent>
                    {Object.values(reduceArray).map((data, index) => {
                        return (
                            <React.Fragment key={"positionChart" + index}>
                                {data.count === 0 ?
                                    <HoverWrapper data-testid={"hoverWrapper"} className={"hover"}/> :
                                    <HoverWrapper data-testid={"hoverWrapper"} className={"hover"}>
                                        <HoverWinLoseWrapper>
                                            <HoverSpan margin={'1px'}>
                                                {data.win}
                                                <HoverSpan opaicty={'0.6'}>
                                                    {t('gameAnalytics.win')}
                                                </HoverSpan>
                                            </HoverSpan>

                                            <HoverSpan margin={'1px'}>
                                                {data.lose}
                                                <HoverSpan opaicty={'0.6'}>
                                                    {t('gameAnalytics.lose')}
                                                </HoverSpan>
                                            </HoverSpan>
                                        </HoverWinLoseWrapper>
                                        <WinRateWrapper>
                                            <HoverSpan>
                                                {data.count > 0 ? Math.trunc(data.win / data.count * 100) : 0}
                                                <HoverSpan opaicty={'0.6'}>
                                                    %
                                                </HoverSpan>
                                            </HoverSpan>
                                        </WinRateWrapper>
                                        <ScoreWrapper
                                            noneClickable={true}
                                            score={data.count > 0 ? Math.trunc((data.ai_score / data.count)) : 0}
                                            fontSize={12}
                                            paddingHorizontal={6}
                                        />

                                    </HoverWrapper>}
                                <GraphWrapper data-testid={'graph'} className={"notHover"}>
                                    <BlueGraph
                                        height={(data.count > 0 ? Math.trunc(data.win / getMaxValue.maxValue * 100) : 0) + "%"}/>
                                    <RedGraph
                                        height={(data.count > 0 ? Math.trunc(data.lose / getMaxValue.maxValue * 100) : 0) + "%"}/>
                                </GraphWrapper>
                            </React.Fragment>
                        )
                    })
                    }
                </ChartContent>
                <LaneWrapper>
                    <LaneBox>
                        <img width={16} height={16} src={`/images/icon_s_position_top.svg`} alt={""}/>
                    </LaneBox>
                    <LaneBox>
                        <img width={16} height={16} src={`/images/icon_s_position_jungle.svg`} alt={""}/>
                    </LaneBox>
                    <LaneBox>
                        <img width={16} height={16} src={`/images/icon_s_position_middle.svg`} alt={""}/>
                    </LaneBox>
                    <LaneBox>
                        <img width={16} height={16} src={`/images/icon_s_position_bot.svg`} alt={""}/>
                    </LaneBox>
                    <LaneBox>
                        <img width={16} height={16} src={`/images/icon_s_position_supporter.svg`} alt={""}/>
                    </LaneBox>
                </LaneWrapper>
            </Chart>
        </Wrapper>
    );
};

export default PositionChart;
