import React, {useCallback} from 'react';
import styled from "styled-components";
import {ImageChamp, ImageLane} from "../../../../../../common";
import ChampionImageWithLane from "../../../../../../common/ChampionImageWithLane";
import WinRateText from "../../../../../../common/WinrateText";

const Wrapper = styled.div`
  width: 54px;
  cursor: pointer;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  background: ${props => {
    let background = '#fff';
    if (props.selected) background = '#6A5AAC';
    return background;
  }};
`;

const ChampImageWrapper = styled.div`
  width: 42px;
  height: 42px;

  
  .non_image > img {
    width: 42px;
    height: 42px;
    pointer-events: none;
    margin: 0;
  }

  .imgChamp {
    width: 48px;
    height: 48px;
    pointer-events: none;
  }
`;

const GameCountText = styled.span`
  color: ${props => {
    let color = '#7473AB';
    if (props.selected) color = '#fff';
    return color;
  }};
  font-size: 12px;
  margin-top: 7px;
  height: 15px;
`;

const WinRateTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  height: 15px;
`;

const Percent = styled.span`
  margin-left: 2px;
  font-size: 12px;
  color: ${props => {
    let color = '#313131';
    if (props.selected) color = '#fff';
    return color;
  }};
`;




const ChampCarouselItem = ({selected, onClickSelected, champion_id, games, win_rate, position,index}) => {
    const onClick = useCallback((e) => {
        onClickSelected(e, champion_id);
    }, [onClickSelected]);

    const renderWinRatePercent = useCallback((value) => {
        if (value === 100) {
            return Number(value);
        } else {
            return value.toFixed(1)
        }
    }, []);


    return (
        <Wrapper selected={selected} onClick={onClick}>
            <ChampImageWrapper>
                <ChampionImageWithLane
                    champion_id={champion_id}
                    position={position}
                />
            </ChampImageWrapper>
            <GameCountText selected={selected}>{games}</GameCountText>
            <WinRateTextWrapper>
                <WinRateText fontSize={"12px"} staticColor={selected ? "#fff" : ""}>{renderWinRatePercent(win_rate)}</WinRateText>
            </WinRateTextWrapper>
        </Wrapper>
    );
};

export default ChampCarouselItem;