import React, {useCallback, useEffect, useMemo} from 'react';
import styled from "styled-components";
import ChampionSkillImage from "../../../../common/ChampionSkillImage";
import {useTranslation} from "react-i18next";
import ChampionPassiveImage from "../../../../common/ChampionPassiveImage";
import {useSelector} from "react-redux";
import axios from "axios";
import {use} from "i18next";


const Wrapper = styled.div`
  background: ${props => {
    let background = '#EEF0F5';
    if (props.background) background = props.background;
    return background;
  }};
  width: 95%;
  border-radius: 6px;
  padding: 14px;

`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SkillNameText = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin-left: 6px;
`;

const BasicText = styled.span`
  font-size: 18px;
  font-weight: 500;
`;

const PatchWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`;

const PatchText = styled.span`
  margin: 6px 0;
  font-size: 14px;
  opacity: 0.8;
  text-decoration: line-through;
`;

const AfterText = styled.span`
  font-size: 14px;
  opacity: 0.8;
  line-height: 20px;
  text-decoration: none;
`;

const ArrowSpan = styled.span`
  margin: 0 5px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const DashSpan = styled.span`
  line-height: 20px;
    margin-right: 4px;
`;

const SKILL_IMAGE_WIDTH = 36;
const SKILL_IMAGE_HEIGHT = 36;
const HistoryContentPatchInfoContent = ({title, skillDetailList, version, spell, spellKey, champion, background}) => {
    const {t, i18n} = useTranslation();
    const isKorean = useMemo(() => ["ko", "ko-KR"].includes(i18n.language), [i18n.language]);
    const gameVersion = useSelector((state) => state?.gameVersion.version);


    const RenderBySpellKey = useCallback(() => {
        switch (spellKey) {
            case 'Q'  : {
                return (
                    <>
                        <ChampionSkillImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            spell={champion?.spells[0]}
                            version={gameVersion}
                            img={champion?.spells[0].spell_img}
                        />
                        <SkillNameText>{`Q - ${isKorean ? champion?.spells[0].spell_name_kr : champion?.spells[0].spell_name_en}`}</SkillNameText>
                    </>
                )
            }
            case 'W' : {
                return (
                    <>
                        <ChampionSkillImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            spell={champion?.spells[1]}
                            version={gameVersion}
                            img={champion?.spells[1].spell_img}
                        />
                        <SkillNameText>{`W - ${isKorean ? champion?.spells[1].spell_name_kr : champion?.spells[1].spell_name_en}`}</SkillNameText>
                    </>
                )
            }
            case 'E' : {
                return (
                    <>
                        <ChampionSkillImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            spell={champion?.spells[2]}
                            version={gameVersion}
                            img={champion?.spells[2].spell_img}
                        />
                        <SkillNameText>{`E - ${isKorean ? champion?.spells[2].spell_name_kr : champion?.spells[2].spell_name_en}`}</SkillNameText>
                    </>
                )
            }
            case 'R' : {
                return (
                    <>
                        <ChampionSkillImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            spell={champion?.spells[3]}
                            version={gameVersion}
                            img={champion?.spells[3].spell_img}
                        />
                        <SkillNameText>{`R - ${isKorean ? champion?.spells[3].spell_name_kr : champion?.spells[3].spell_name_en}`}</SkillNameText>
                    </>
                )
            }
            case 'P' : {
                return (
                    <>
                        <ChampionPassiveImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            spell={champion?.passive}
                            version={gameVersion}
                            img={champion?.passive.spell_img}
                            tooltip={`<span style="font-weight: bold; color: #46B9AA;">${isKorean ? champion.passive.spell_name_kr : champion.passive.spell_name_en}</span>\n${isKorean ? champion.passive.spell_description_kr : champion.passive.spell_description_en}`}
                        />
                        <SkillNameText>{`P - ${isKorean ? champion.passive.spell_name_kr : champion.passive.spell_name_en}`}</SkillNameText>
                    </>
                )
            }
            case 'B' : {
                return <BasicText>{t('championsHistory.title.basic')}</BasicText>
            }
            case 'N' : {
                return <BasicText>{t('championsHistory.title.newChamp')}</BasicText>
            }
            case 'ARAM' : {
                return (
                    <>
                        <img
                            src={'/images/icon_history_aram.svg'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            alt={""}
                        />
                        <SkillNameText>{t('championsHistory.title.aram')}</SkillNameText>
                    </>)
            }
        }
    }, [spellKey, champion]);

    return (
        <Wrapper background={background} className={'infoContent'}>
            <ImageWrapper>
                {RenderBySpellKey()}
            </ImageWrapper>
            <PatchWrapper>
                {Array.isArray(skillDetailList) ? skillDetailList.map((data, index) => {
                    return (
                        <TextWrapper>
                           <DashSpan>-</DashSpan>
                            <AfterText>
                                <PatchText
                                    key={index}>{`${data.before ? data.text + " " + data.before : ''} `}</PatchText>
                                {data.before ? <ArrowSpan>⇒</ArrowSpan> : null}
                                <AfterText>{data.text + " " + data.after}</AfterText>
                            </AfterText>
                        </TextWrapper>
                    )
                }) : null}
            </PatchWrapper>
        </Wrapper>
    );
};

export default HistoryContentPatchInfoContent;
