import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {ImageLane} from '../../components';
import {kdaColor, scoreColor, sortChampData} from '../../function';
import TooltipWrapper from "../common/TooltipWrapper";
import ChampArrow from "./Champ/ChampArrow";
import DamageGraph from "./DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import styled from "styled-components";
import ChampionImageWithLane from "../common/ChampionImageWithLane";

const DamageWrapper = styled.div`
  margin-inline: 16px;
`;

const AllChampionText = styled.span`
  width: 100%;
  text-align: center;
`;

const ChampTable = ({
                        sort,
                        data,
                        handleSort,
                        currentOrder,
                        openId,
                        setOpenId,
                        sortOrder,
                        initialSort,
                        mostDamage,
                        leastDamage
                    }) => {
    const {t} = useTranslation();
    const champNames = useSelector((state) => state.imageStore.champions);
    const currentLang = useSelector((state) => state.translation.lang);

    const [moreId, setMoreId] = useState(null);


    // 상세보기
    const handleToggleDetail = (champion_id) => {

        if (openId === champion_id) {
            setOpenId(null);
            setMoreId(null);
        } else {
            setOpenId(champion_id);
        }
    };

    // 상세보기의 더보기
    const handleMoreDetail = (champion_id) => {
        const currentId = moreId;
        if (currentId === champion_id) {
            setMoreId(null);
        } else {
            setMoreId(champion_id);
        }
    };

    const calcWinRateScore = useCallback((value) => {
        if (Number(value) === 100) {
            return Number(value);
        } else {
            return value;
        }
    }, []);

    return (
        <>
            {data?.length > 0 ? (
                <table className="table">
                    <colgroup>
                        <col width="40px"/>
                        <col width="*"/>
                        {data[0].lane !== undefined && <col width="56px"/>}
                        <col width="70px"/>
                        <col width="130px"/>
                        <col width="95px"/>
                        <col width="130px"/>
                        <col width="130px"/>
                        <col width="56px"/>
                        <col width="70px"/>
                        <col width="73px"/>
                        <col width="56px"/>
                        <col width="36px"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th rowSpan="2">
                            <div className="t-cell">
                                <span className="name">#</span>
                            </div>
                        </th>
                        <th rowSpan="2" className={`sort ${currentOrder}`}
                            onClick={() => handleSort(sortOrder?.champions)}>
                            <div className="t-cell">
                                <ChampArrow
                                    selected={sortOrder?.champions.value === sort[0].value}
                                    title={t('tabs.champions')}
                                    arrow={sortOrder?.champions.sortType}
                                />
                                {/*<span className="name">{t('tabs.champions')}</span>*/}
                                {/*<span className="arrow"></span>*/}
                            </div>
                        </th>
                        {data[0].lane !== undefined && (
                            <th rowSpan="2">
                                <div className="t-cell">
                                    <span className="name">{t('tabs.position')}</span>
                                </div>
                            </th>
                        )}
                        <th
                            rowSpan="2"
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort(sortOrder?.games)}
                        >
                            <div className="t-cell">
                                <ChampArrow
                                    selected={sortOrder?.games.value === sort[0].value}
                                    title={t('tabs.games')}
                                    arrow={sortOrder?.games.sortType}
                                />
                            </div>
                        </th>
                        <th
                            rowSpan="2"
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort(sortOrder.win_rate)}
                        >
                            <div className="t-cell">
                                <ChampArrow
                                    selected={sortOrder?.win_rate.value === sort[0].value}
                                    extra={t('tabs.winrate')}
                                    title={t('tabs.winlose')}
                                    arrow={sortOrder?.win_rate?.sortType}
                                />
                            </div>
                        </th>
                        <th rowSpan="2" className={`sort ${currentOrder}`} onClick={() => handleSort(sortOrder.kda)}>
                            <div className="t-cell">
                                <ChampArrow
                                    selected={sortOrder?.kda.value === sort[0].value}
                                    title={'KDA'}
                                    arrow={sortOrder?.kda.sortType}
                                />
                            </div>
                        </th>
                        <th className={`sort ${currentOrder}`}
                            onClick={() => handleSort(sortOrder?.damage_dealt_per_min)}>
                            <div className={"t-cell"}>
                                <ChampArrow
                                    selected={sortOrder?.damage_dealt_per_min.value === sort[0].value}
                                    title={t('tabs.damage')}
                                    arrow={sortOrder?.damage_dealt_per_min.sortType}
                                />

                            </div>
                        </th>
                        <th className={`sort ${currentOrder}`}
                            onClick={() => handleSort(sortOrder?.damage_taken_per_min)}>
                            <div className={"t-cell"}>
                                <ChampArrow
                                    selected={sortOrder?.damage_taken_per_min.value === sort[0].value}
                                    title={t('tabs.takenDamage')}
                                    arrow={sortOrder?.damage_taken_per_min.sortType}
                                />
                            </div>
                        </th>
                        <th
                            rowSpan="2"
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort(sortOrder?.cs_per_min)}
                        >
                            <TooltipWrapper
                                tooltipText={t('tabs.csMExplain')}
                            >
                                <div className="t-cell">
                                    <ChampArrow
                                        selected={sortOrder?.cs_per_min.value === sort[0].value}
                                        title={t('tabs.csm')}
                                        arrow={sortOrder?.cs_per_min.sortType}
                                    />
                                </div>
                            </TooltipWrapper>
                        </th>
                        <th
                            rowSpan="2"
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort(sortOrder?.gold_diff_15)}
                        >
                            <TooltipWrapper
                                tooltipText={t('tabs.gd@15Explain')}
                            >
                                <div className="t-cell">
                                    <ChampArrow
                                        selected={sortOrder?.gold_diff_15.value === sort[0].value}
                                        title={'GD@15'}
                                        arrow={sortOrder?.gold_diff_15.sortType}
                                    />
                                </div>
                            </TooltipWrapper>
                        </th>
                        <th
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort(sortOrder?.damage_per_team)}>
                            <div className={"t-cell"}>
                                <TooltipWrapper
                                    tooltipText={t('tabs.dsTooltip')}
                                >
                                    <ChampArrow
                                        selected={sortOrder?.damage_per_team.value === sort[0].value}
                                        title={'DS'}
                                        arrow={sortOrder?.damage_per_team.sortType}
                                    />
                                </TooltipWrapper>
                            </div>
                        </th>
                        <th
                            className={`sort ${currentOrder}`}
                            onClick={() => handleSort(sortOrder?.gold_per_team)}>
                            <div className={"t-cell"}>
                                <TooltipWrapper
                                    tooltipText={t('tabs.gsTooltip')}
                                >
                                <ChampArrow
                                    selected={sortOrder?.gold_per_team.value === sort[0].value}
                                    title={'GS'}
                                    arrow={sortOrder?.gold_per_team.sortType}
                                />
                                </TooltipWrapper>
                            </div>
                        </th>
                        <th>
                            <div className={"t-cell"}>
                            </div>
                        </th>
                        {/*<th rowSpan="2" className={`sort ${currentOrder}`} onClick={() => handleSort('dpm')}>*/}
                        {/*    <div className="t-cell">*/}
                        {/*    </div>*/}
                        {/*</th>*/}
                        {/*    <th*/}
                        {/*        rowSpan="2"*/}
                        {/*        className={`sort ${currentOrder}`}*/}
                        {/*        onClick={() => handleSort('kill_point_per_g')}*/}
                        {/*    >*/}
                        {/*        <div className="t-cell">*/}
                        {/*            <ChampArrow*/}
                        {/*                title={t('tabs.kp')}*/}
                        {/*                arrow={sortOrder?.kill_point_per_g.sortType}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </th>*/}
                        {/*    <th rowSpan="2">*/}
                        {/*        <div className="t-cell">*/}
                        {/*            <span className="name">&nbsp;</span>*/}
                        {/*        </div>*/}
                        {/*    </th>*/}
                        {/*</tr>*/}
                        {/*<tr className="bottom">*/}
                        {/*    <th*/}
                        {/*        className={`sort ${currentOrder}`}*/}
                        {/*        onClick={() => handleSort('d_score_win_per_g')}*/}
                        {/*    >*/}
                        {/*        <div className="t-cell">*/}
                        {/*            <ChampArrow*/}
                        {/*                title={t('champions.filter.win')}*/}
                        {/*                arrow={sortOrder?.d_score_win_per_g.sortType}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </th>*/}
                        {/*    <th*/}
                        {/*        className={`sort ${currentOrder}`}*/}
                        {/*        onClick={() => handleSort('d_score_loss_per_g')}*/}
                        {/*    >*/}
                        {/*        <div className="t-cell">*/}
                        {/*            <ChampArrow*/}
                        {/*                title={t('champions.filter.lose')}*/}
                        {/*                arrow={sortOrder?.d_score_loss_per_g.sortType}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </th>*/}
                        {/*    <th className={`sort ${currentOrder}`} onClick={() => handleSort('d_score_per_g')}>*/}
                        {/*        <div className="t-cell">*/}
                        {/*            <ChampArrow*/}
                        {/*                title={t('champions.filter.avg')}*/}
                        {/*                arrow={sortOrder?.d_score_per_g.sortType}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((row, index) => {
                        return (
                            <React.Fragment key={index}>
                                <tr className={openId === row.champion_id ? 'open' : 'close'}
                                    onClick={() => handleToggleDetail(row.champion_id)}>
                                    <td>
                                        <span className="normal">{index + 1}</span>
                                    </td>
                                    <td>
                                        <div className="champ">
                                            {row?.champion_id ? <ChampionImageWithLane champion_id={row.champion_id}/> :
                                                <AllChampionText>{t('champions.allChamp')}</AllChampionText>}
                                            {champNames.map((champ, idx) => {

                                                    return (Number(champ.championId) === Number(row.champion_id) && (
                                                            <p key={idx}>
                                                                {currentLang === 'KOR'
                                                                    ? champ.champion_name_kr
                                                                    : champ.champion_name_en}
                                                            </p>)
                                                    )
                                                }
                                            )}
                                        </div>
                                    </td>
                                    {data[0].lane !== undefined && (
                                        <td>
                                            <ImageLane lane={row.lane}/>
                                        </td>
                                    )}
                                    <td>
                                        <span className="bold">{row.games}</span>
                                    </td>
                                    <td>
                                        <div className="winlose">
                                            <div className="graph">
                          <span className="win" style={{width: `${row.win_rate}%`}}>
                            <i>{row.wins}</i>
                          </span>
                                                <span className="lose" style={{width: `${100 - row.win_rate}%`}}>
                            <i>{row.losses}</i>
                          </span>
                                            </div>
                                            <div className="winrate">
                                                <span
                                                    className="bold">{scoreColor(calcWinRateScore(row.win_rate.toFixed(1)))}</span>%
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="kda">
                                            <span className="bold">{kdaColor(row.kda.toFixed(2))}</span>
                                            <p>
                                                ({row.kills.toFixed(1)} / {row.deaths.toFixed(1)} / {row.assists.toFixed(1)})
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <DamageWrapper>
                                            <DamageGraph
                                                bgColor={"#E6E6F6"}
                                                color={'#7473AB'}
                                                barColor={'#EF4D8A'}
                                                dmg={row.damage_dealt_per_min.toFixed(0)}
                                                total={mostDamage.toFixed(0)}
                                            />
                                        </DamageWrapper>
                                    </td>
                                    <td>
                                        <DamageWrapper>
                                            <DamageGraph
                                                bgColor={"#E6E6F6"}
                                                color={'#7473AB'}
                                                barColor={'#7C8698'}
                                                dmg={row.damage_taken_per_min.toFixed(0)}
                                                total={leastDamage.toFixed(0)}
                                            />
                                        </DamageWrapper>
                                    </td>
                                    <td>
                                        <span className="normal">{row.cs_per_min}</span>
                                    </td>
                                    <td>
                                        <span className="normal">{row.gold_diff_15.toFixed(0)}</span>
                                    </td>
                                    <td>
                                        <span className="normal">{row.damage_per_team.toFixed(1)}%</span>
                                    </td>
                                    <td>
                                        <span className="normal">{row.gold_per_team.toFixed(1)}%</span>
                                    </td>
                                    <td>
                                        <button className="arrow"
                                                onClick={() => handleToggleDetail(row.champion_id)}>
                                            <img src="/images/icon_arrow_b.svg" alt={t('summoner.more')}/>
                                        </button>
                                    </td>
                                </tr>
                                {openId === row.champion_id && (
                                    <tr className="detail">
                                        <td colSpan="20">
                                            <div className="vs_champ">
                                                <div
                                                    className={`list_wrap ${moreId === row.champion_id ? 'open' : 'close'}`}>
                                                    <ul className="list">
                                                        {sortChampData(row.stats_by_enemy, initialSort).map(
                                                            (item, idx) => {
                                                                return (
                                                                    <li key={idx}>
                                                                        <div className="td" style={{width: '40px'}}>
                                                                            <div className="vs">VS</div>
                                                                        </div>
                                                                        <div className="td"
                                                                             style={{width: '155px'}}>
                                                                            <div className="champ">
                                                                                <ChampionImageWithLane
                                                                                    champion_id={item.champion_id}/>
                                                                                {champNames.map(
                                                                                    (champ, idx) =>
                                                                                        Number(champ.championId) === Number(item.champion_id) && (
                                                                                            <p key={idx}>
                                                                                                {currentLang === 'KOR'
                                                                                                    ? champ.champion_name_kr
                                                                                                    : champ.champion_name_en}
                                                                                            </p>
                                                                                        )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="td" style={{width: '70px'}}>
                                                                                <span
                                                                                    className="bold">{item.games}</span>
                                                                        </div>
                                                                        <div className="td"
                                                                             style={{width: '130px'}}>
                                                                            <div className="winlose"
                                                                                 style={{paddingRight: 0}}>
                                                                                <div className="graph">
                                            <span
                                                className="win"
                                                style={{width: `${item.win_rate}%`}}
                                            >
                                              <i>{item.wins}</i>
                                            </span>
                                                                                    <span
                                                                                        className="lose"
                                                                                        style={{width: `${100 - item.win_rate}%`}}
                                                                                    >
                                              <i>{item.losses}</i>
                                            </span>
                                                                                </div>
                                                                                <div className="winrate">
                                                                                    <span
                                                                                        className="bold">{scoreColor(item.win_rate.toFixed(1))}</span>
                                                                                    %
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="td" style={{width: '95px'}}>
                                                                            <div className="kda">
                                                                                <span
                                                                                    className="bold">{kdaColor(item.kda)}</span>
                                                                                <p>
                                                                                    ({item.kills.toFixed(1)} / {item.deaths.toFixed(1)} / {item.assists.toFixed(1)})
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="td" style={{width: '130px'}}>
                                                                            <DamageWrapper>
                                                                                <DamageGraph
                                                                                    bgColor={"#E6E6F6"}
                                                                                    color={'#7473AB'}
                                                                                    barColor={'#EF4D8A'}
                                                                                    dmg={item.damage_dealt_per_min.toFixed(0)}
                                                                                    total={mostDamage.toFixed(0)}
                                                                                />
                                                                            </DamageWrapper>
                                                                        </div>
                                                                        <div className="td" style={{width: '130px'}}>
                                                                            <DamageWrapper>
                                                                                <DamageGraph
                                                                                    bgColor={"#E6E6F6"}
                                                                                    color={'#7473AB'}
                                                                                    barColor={'#7C8698'}
                                                                                    dmg={item.damage_taken_per_min.toFixed(0)}
                                                                                    total={mostDamage.toFixed(0)}
                                                                                />
                                                                            </DamageWrapper>
                                                                        </div>
                                                                        <div className="td" style={{width: '56px'}}>
                                                                            <span
                                                                                className="normal">{item.cs_per_min.toFixed(1)}</span>
                                                                        </div>
                                                                        <div className="td" style={{width: '70px'}}>
                                        <span className="normal">
                                          {item.gold_diff_15.toFixed(0)}
                                        </span>
                                                                        </div>
                                                                        <div className="td" style={{width: '73px'}}>
                                                                            <span
                                                                                className="normal">{item.damage_per_team.toFixed(1)}%</span>
                                                                        </div>
                                                                        <div className="td" style={{width: '56px'}}>
                                                                            <span
                                                                                className="normal">{item.gold_per_team.toFixed(1)}%</span>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                                {row.stats_by_enemy.length > 10 ? (
                                                    <button className="list_more"
                                                            onClick={() => handleMoreDetail(row.champion_id)}>
                                                        {moreId === row.champion_id ? t('summoner.close') : t('summoner.more')}
                                                    </button>
                                                ) : null}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                    </tbody>
                </table>
            ) : (
                <table className="table">
                    <tbody>
                    <tr>
                        <td className="no_data">{t('notice.noData')}</td>
                    </tr>
                    </tbody>
                </table>
            )}
        </>
    );
};

export default ChampTable;
