import React, {useCallback} from 'react';
import SvgArrow from "../../../../../../common/SVGArrow";
import styled from "styled-components";

const NavigatorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border:${props => {
    let border = '1px solid #A4A4B1';
    if(props.disabled) border = '1px solid #535358';
    return border;
}};
  border-radius: 6px;
  width: 24px;
  height: 24px;
  background-color: ${props => {
    let color = '#fff';
    if(props.disabled) color = '#A4A4B1';
    return color;
}};
  
  &:hover {
    cursor: ${props => {
        let cursor = 'pointer';
        if(props.disabled) cursor = 'auto';
        return cursor;
    }};
  }
`;

const LeftArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 12px;
  transform: matrix(${props => props.matrix});

`;



const RankingTableNavigatorArrow = ({disabled,matrix,Arrow,onClick}) => {
    const onClickNavigator = useCallback(() => {
        if(!disabled && typeof onClick === 'function') {
            onClick();
        }
    },[disabled,onClick])

    return (
        <NavigatorBox disabled={disabled} onClick={onClickNavigator}>
            <LeftArrowWrapper matrix={matrix}>
                <Arrow fill={disabled ? '#FFFFFF' : '#A4A4B1'} color={disabled ? '#FFFFFF' : '#A4A4B1'}/>
            </LeftArrowWrapper>
        </NavigatorBox>
    );
};

export default RankingTableNavigatorArrow;
