import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

import IngameList from './IngameList';
import {ChartWinrate, ImageChamp, Loading} from '../../components';
import {getIngameTime, getRegion, getTierScore, scoreColor, tierBadge} from '../../function';
import {IngameContainer} from '../../styled/SummonerStyle';
import styled from "styled-components";
import IngameTeamInfo from "./Ingame/IngameTeamInfo";
import BigTooltip from "../Tooltip/BigTooltipBox";
import {SSEFix} from "../../common/moduleFork/sseFix";
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import {_NOTICE, noticeChange} from "../../store/modules/notice";


const PredictionWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 80px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
`;

const PredictionText = styled.span`
  margin-top: ${props => props.marginTop};
  text-align: center;
  background-color: #fafbfc;
  font-size: ${props => props.fontSize && props.fontSize + 'px'};
  font-weight: 700;
`;

const VersusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const VersusTextWrapper = styled.div`
  margin-right: 4px;
`;

const ImageBox = styled.div`
  margin: 0 2.5px;
`;

const Ingame = ({region}) => {
    const {t} = useTranslation();
    const summoner = useSelector((state) => state.summoner.summoner);
    const language = useSelector((state) => state.translation.lang);
    const [loading, setLoading] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [supported, setSupported] = useState(false);
    const [ingameBasic, setIngameBasic] = useState({});
    const [ingamePart, setIngamePart] = useState([]);
    const [avg, setAvg] = useState({blueScore: 0, redScore: 0, blueRating: 0, redRating: 0});
    const [avgTier, setAvgTier] = useState({blueTier: '', redTier: ''});
    const [avgWinrate, setAvgWinrate] = useState({blueWinrate: 50, redWinrate: 50});
    const [ingameTime, setIngameTime] = useState(0);
    const [error, setError] = useState(false);
    const [summonerData, setSummonerData] = useState([]);
    const [updated, setUpdated] = useState(0);
    const [updateStatus, setUpdateStatus] = useState(false);
    const currentSeason = useSelector((state) => state.imageStore.currentSeason);
    const dispatch = useDispatch();
    // 평균 티어 구하기
    const getAvgTier = useCallback((blueTeam, redTeam) => {

        setAvgTier({blueTier: getTierScore(blueTeam).txt, redTier: getTierScore(redTeam).txt});
    }, []);


    // 평균 구하기
    const getAvg = useCallback((blueTeam, redTeam, blueLength, redLength) => {
        setAvg({
            blueScore: blueTeam.score / blueLength,
            redScore: redTeam.score / redLength,
            blueRating: blueTeam.rating / blueLength,
            redRating: redTeam.rating / redLength,
        });
    }, []);

    // 승률 구하기
    const getWinrate = useCallback(async (modelData) => {

        await axios.post(`${process.env.REACT_APP_NEW_API}ingame/model`, JSON.stringify(modelData), {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then(function (res) {

                const result = res.data;
                if (result?.blue_win_rate && result?.red_win_rate) {
                    setAvgWinrate({blueWinrate: result?.blue_win_rate, redWinrate: result?.red_win_rate});
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    // 데이터 - ingame part
    const getPart = useCallback(
        async (bluePlayer, redPlayer, queue) => {
            if (bluePlayer?.summoner_basic_info_dict && redPlayer?.summoner_basic_info_dict) {
                return await axios.get(`${process.env.REACT_APP_CACHE_API}ingame/part?blue_puu_id=${bluePlayer?.summoner_basic_info_dict?.puu_id}&blue_summoner_id=${bluePlayer?.summoner_id}&blue_champion_id=${bluePlayer?.champion_id}&position=${bluePlayer?.position}&red_puu_id=${redPlayer?.summoner_basic_info_dict?.puu_id}&red_summoner_id=${redPlayer?.summoner_id}&red_champion_id=${redPlayer?.champion_id}&queue_id=${queue}&season=${currentSeason}&platform_id=${getRegion(region)}`)
                    .then((res) => {

                        const result = res.data;
                        return {
                            ...result,
                            B: {
                                ...result.blue,
                                ...bluePlayer,
                                summonerId: bluePlayer.summoner_id,
                                summonerName: bluePlayer.summoner_name,
                            },
                            R: {
                                ...result.red,
                                ...redPlayer,
                                summonerId: redPlayer.summoner_id,
                                summonerName: redPlayer.summoner_name,
                            }
                        };
                    });
            }

        },
        [region,currentSeason]
    );


    const sseStream = useCallback((data, queue) => {
        const sseObj = {
            "platform_id": getRegion(region),
            "puu_id": data?.summoner_basic_info_dict?.puu_id,
            "season": 13,
            "queue_type": queue,
            "start_idx": 0,
            "count": 10
        }


        let retry = 1;

        const eventStream = new SSEFix(`${process.env.REACT_APP_NEW_API}match/matches`, {
            payload: JSON.stringify(sseObj),
        });
        eventStream.addEventListener("error", (e) => {
            const data = e?.detail?.data;
            if(data) {
                if(JSON.parse(data).status === 1) {
                    dispatch({
                        type: _NOTICE,
                        data: "alertError"
                    })
                }
                if(JSON.parse(data).status === 2 && retry) {
                    setTimeout(() => {
                        eventStream.stream();
                    }, 2000);
                    retry--;
                }
            } else {
                eventStream.close();
            }
        });

        eventStream.addEventListener("message", (e) => {
            const percent = e.data;
            if (Number(percent) === 100) {
                setUpdated((prev) => Number(prev) + 1);
            }
        });
        eventStream.stream();
    }, [region]);

    const getSummonerUpdate = useCallback(async (blueGameInfo, redGameInfo, queue) => {
        setUpdateStatus(true);
        // sseStream(blueGameInfo, transQueueText(queue));
        // sseStream(redGameInfo, transQueueText(queue));
    }, []);

    const getSummoner = useCallback(async (summoner, side) => {
        let summonerName = summoner.replace(/\s/gi, '');
        if (summonerName.length === 2) {
            const result = summonerName.split('').join('%20');
            summonerName = result;
        }
        return await axios.get(`${process.env.REACT_APP_CACHE_API}summoner/summoner?summoner_name=${encodeURI(summonerName)}&platform_id=${getRegion(region)}`)
            .then((res) => {

                const result = res.data;
                if (result !== undefined) {
                    return {
                        ...result,
                        side
                    };
                }
            });
    }, []);


    const setTierScore = useCallback((data) => {
        if (data?.tier && data?.division) {
            const result = getTierScore(`${data.tier} ${data.division}`).num;
            return !isNaN(result) ? Number(result) : 0;
        } else {
            return 0;
        }
    }, []);

    const combineTierScore = useCallback((list, queue) => {
        let blueScore = 0;
        let redScore = 0;
        let blueLength = 0;
        let redLength = 0;

        list.forEach((data, index) => {

            if (data?.season_tier_info_dict) {
                if (data.side === 'BLUE') {
                    blueScore += setTierScore(data.season_tier_info_dict[queue]);
                    blueLength++;
                } else {
                    redScore += setTierScore(data.season_tier_info_dict[queue]);
                    redLength++;
                }
            }
        });
        if (blueScore > 0 && redScore > 0) {
            return {blue: Math.trunc(blueScore / blueLength), red: Math.trunc(redScore / redLength)}
        } else {
            return {blue: 0, red: 0}
        }

    }, []);


    const getQueueText = useCallback((queue) => {
        if (queue === 420) {
            return 'ranked_solo_5x5';
        } else if (queue === 440) {
            return 'ranked_flex_sr'
        } else if (queue === 430 || queue === 900 || queue === 1400) {
            return 'ranked_solo_5x5';
        }
        return "ranked_solo_5x5";
    }, []);


    const transQueueText = useCallback((queue) => {
        if (queue === 420) {
            return 'RANKED_SOLO_5x5';
        } else if (queue === 440) {
            return 'RANKED_FLEX_SR'
        } else if (queue === 430 || queue === 900 || queue === 1400) {
            return 'INGAME_NORMAL';
        }
        return 'RANKED_SOLO_5x5';
    }, [])


    const divisionConvert = (division) => {
        if (division === 1) {
            return "I"
        } else if (division === 2) {
            return "II"
        } else if (division === 3) {
            return "III"
        } else if (division === 4) {
            return "IV"
        }
        return 'I';
    }

    const rankValue = (tier, division) => {
        const lowerCaseTier = tier.toLowerCase();
        if(tier === "") {
            return "unranked"
        }
        if (lowerCaseTier === 'master' || lowerCaseTier === 'grandmaster' || lowerCaseTier === 'challenger') {
            return tier;
        } else {
            return `${tier} ${divisionConvert(division)}`
        }
    }


    const getUseModelData = useCallback(
        (data, summoners) => {
            try {
                let result = {
                    top_feature: {},
                    jungle_feature: {},
                    middle_feature: {},
                    bot_feature: {},
                    supporter_feature: {},
                    tier_dict: {}
                }


                let laneList = ['Top', 'Jungle', 'Middle', 'Bot', 'Sup'];
                result.top_feature = {...data[0].model_feature} || [];
                result.jungle_feature = {...data[1].model_feature} || [];
                result.middle_feature = data[2].model_feature || [];
                result.bot_feature = data[3].model_feature || [];
                result.supporter_feature = data[4].model_feature || [];
                result.tier_dict = {
                    BLUE_Top: rankValue(data[0].B.season_tier_info_dict.ranked_solo_5x5.tier, data[0].B.season_tier_info_dict.ranked_solo_5x5.division),
                    RED_Top: rankValue(data[0].R.season_tier_info_dict.ranked_solo_5x5.tier, data[0].R.season_tier_info_dict.ranked_solo_5x5.division),
                    BLUE_Jungle: rankValue(data[1].B.season_tier_info_dict.ranked_solo_5x5.tier, data[1].B.season_tier_info_dict.ranked_solo_5x5.division),
                    RED_Jungle: rankValue(data[1].R.season_tier_info_dict.ranked_solo_5x5.tier, data[1].R.season_tier_info_dict.ranked_solo_5x5.division),
                    BLUE_Middle: rankValue(data[2].B.season_tier_info_dict.ranked_solo_5x5.tier, data[2].B.season_tier_info_dict.ranked_solo_5x5.division),
                    RED_Middle: rankValue(data[2].R.season_tier_info_dict.ranked_solo_5x5.tier, data[2].R.season_tier_info_dict.ranked_solo_5x5.division),
                    BLUE_Bot: rankValue(data[3].B.season_tier_info_dict.ranked_solo_5x5.tier, data[3].B.season_tier_info_dict.ranked_solo_5x5.division),
                    RED_Bot: rankValue(data[3].R.season_tier_info_dict.ranked_solo_5x5.tier, data[3].R.season_tier_info_dict.ranked_solo_5x5.division),
                    BLUE_Supporter: rankValue(data[4].B.season_tier_info_dict.ranked_solo_5x5.tier, data[4].B.season_tier_info_dict.ranked_solo_5x5.division),
                    RED_Supporter: rankValue(data[4].R.season_tier_info_dict.ranked_solo_5x5.tier, data[4].R.season_tier_info_dict.ranked_solo_5x5.division),
                };

                getWinrate(result);

            } catch (e) {
                console.log("useModel Error", e);
            }
        }, [getWinrate]);

    // getSummonerData 순서대로 호출
    const orderCall = useCallback(async (list, queue, key_time) => {

        if (Array.isArray(list) && list.length > 0) {
            let data = [];
            let summoners = [];
            await Promise.all(list.map((data) => getSummoner(data.summoner_name, data.side))).then((resolve) => {

                resolve.map((mapdata, index) => {
                    if (mapdata !== undefined) {
                        summoners.push({...mapdata, ...list[index]});
                    }
                });


                setSummonerData(summoners);
                const tierScoreResult = combineTierScore(summoners, getQueueText(queue));


                if (tierScoreResult.blue !== undefined && tierScoreResult.red !== undefined) {
                    getAvgTier(tierScoreResult.blue, tierScoreResult.red);
                }
            })


            const blueTeam = summoners.filter((data) => data.side === 'BLUE');
            const redTeam = summoners.filter((data) => data.side !== 'BLUE');


            await Promise.all(blueTeam.map((blue, index) => getPart(blue, redTeam.find((red) => red.position === blue.position), queue))).then((resolve) => {
                data = [];
                let blueAi = 0;
                let redAi = 0;
                let blueLength = 0;
                let redLength = 0;

                resolve.map((mapdata, index) => {
                    if (mapdata !== undefined) {
                        blueAi += Number(mapdata.B.summoner_info_dict.ai_score_avg);
                        redAi += Number(mapdata.R.summoner_info_dict.ai_score_avg);
                        if (!isNaN(blueAi)) {
                            blueLength += 1;
                        }
                        if (!isNaN(redAi)) {
                            redLength += 1;
                        }
                        data.push({...mapdata});
                    }
                });


                if (blueLength > 0 && redLength > 0) {
                    getAvg({score: blueAi}, {score: redAi}, blueLength, redLength);
                }
            });
            getUseModelData(data, summoners)

            if (data.length > 0) {
                await data.forEach((data) => {
                    getSummonerUpdate(data.B, data.R, queue);
                });


                // await Promise.all(blueTeam.map((blue, index) => getPart(blue, redTeam.find((red) => red.position === blue.position), queue))).then((resolve) => {
                //     data = [];
                //     let blueAi = 0;
                //     let redAi = 0;
                //     let blueLength = 0;
                //     let redLength = 0;
                //
                //     resolve.map((mapdata, index) => {
                //         if (mapdata !== undefined) {
                //             blueAi += Number(mapdata.B.summoner_info_dict.ai_score_avg);
                //             redAi += Number(mapdata.R.summoner_info_dict.ai_score_avg);
                //             if (!isNaN(blueAi)) {
                //                 blueLength += 1;
                //             }
                //             if (!isNaN(redAi)) {
                //                 redLength += 1;
                //             }
                //             data.push({...mapdata});
                //         }
                //     });
                //
                //
                //     if (blueLength > 0 && redLength > 0) {
                //         getAvg({score: blueAi}, {score: redAi}, blueLength, redLength);
                //     }
                //
                // });


                setIngamePart(data);


            }
        }
    }, []);

    useEffect(() => {
        if (ingameBasic?.participants_list && currentSeason) {
            if (updated === ingameBasic?.participants_list?.length) {

                Promise.all(ingameBasic?.participants_list.map((item, index) => getPart(item[0], item[1], ingameBasic.queue, 0))).then((resolve) => {
                    const data = [];
                    let blueAi = 0;
                    let redAi = 0;
                    let blueLength = 0;
                    let redLength = 0;

                    resolve.map((mapdata, index) => {
                        if (mapdata !== undefined) {
                            blueAi += Number(mapdata.B.ai_score_total);
                            redAi += Number(mapdata.R.ai_score_total);
                            if (!isNaN(blueAi)) {
                                blueLength += 1;
                            }
                            if (!isNaN(redAi)) {
                                redLength += 1;
                            }
                            data.push({...mapdata});
                        }
                    });


                    if (blueLength > 0 && redLength > 0) {
                        getAvg({score: blueAi}, {score: redAi}, blueLength, redLength);
                    }
                    if (data.length > 0) {
                        // setIngamePart(data);
                    }
                    setUpdateStatus(true);
                });
            }
        }
    }, [updated, ingameBasic,currentSeason])

    function customAxios(retryCount) {
        const axiosInstance = axios.create();
        const onFulfilled = (res) => res;
        const retry = (errorConfig) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(axiosInstance.request(errorConfig));
                }, 2000);
            });
        }

        const onRejected = (error) => {
            retryCount--;
            if (error.config && retryCount && error?.response?.data?.status === 2) {
                return retry(error.config);
            }

            return Promise.reject(error);
        };
        axiosInstance.interceptors.response.use(
            onFulfilled,
            onRejected,
        );
        return axiosInstance;
    }

// 데이터 - ingame basic
    const getData = useCallback(async() => {
        const apiRequest = customAxios(3);
        await apiRequest
            .get(
                `${process.env.REACT_APP_CACHE_API}ingame/basic?summoner_id=${summoner.summoner_basic_info_dict.summoner_id}&platform_id=${getRegion(region)}`
            )
            .then((res) => {
                const result = res.data;

                if (result !== 'Not playing') {
                    if (result !== 'Not supported') {
                        if (result !== 'server error') {
                            setIngameBasic(result);
                            setIngameTime(Math.trunc((Date.now() / 1000)) - result.start_timestamp);
                            orderCall(result.participants_list, result.queue_id);
                            setPlaying(result.playing);
                            setSupported(result.support);

                        } else {
                            setError(true);
                        }
                    } else {
                        setPlaying(true);
                        setSupported(false);
                    }
                }

                // setIngamePart([]);
                setLoading(true);
                setUpdated(0);
                setUpdateStatus(false)
            })
            .catch((err) => {
                const statusCode = err.response.status;
                if(err?.response?.data?.status === 1) {

                    dispatch({
                        type: _NOTICE,
                        data: 'alertError',
                    })
                }
                setError(true);
                console.error(err);
            });
    }, [region, summoner]);

    useEffect(() => {
        getData();
    }, []);

    // 게임시간 구하기
    const getTime = useCallback(() => {
        setIngameTime(ingameTime + 1);
    }, [ingameTime]);

    useEffect(() => {
        let interval = setInterval(() => {
            getTime();
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [getTime]);


    return (
        <>
            {error ? (
                <IngameContainer>
                    <div className="not_supported">
                        <div>
                            <p>
                                {t('ingame.alertError')}{' '}
                            </p>
                            <br/>
                            <button
                                onClick={() => {
                                    window.location.replace(`/summoner/${summoner.summoner_name}`);
                                }}>
                                {t('ingame.back')}
                            </button>
                        </div>
                    </div>
                </IngameContainer>
            ) : (
                <IngameContainer className={`${loading ? 'on' : 'off'}`}>
                    {loading ? (
                        playing ? (
                            supported ? (
                                <>
                                    <div className="overview">
                                        <div className="title">
                                            <h3>LIVE</h3>
                                            <p>
                                                {ingameBasic.queue_id === 420
                                                    ? t('summoner.rankedSolo')
                                                    : ingameBasic.queue_id === 440
                                                        ? t('summoner.rankedFlex')
                                                        : t('summoner.blind')}
                                            </p>
                                            <dl>
                                                <dt>{t('ingame.mapName')}</dt>
                                                <dd>{getIngameTime(ingameTime)}</dd>
                                            </dl>
                                        </div>
                                        <div className="average">
                                            <div className="team blue">
                                                <div className="cont">
                                                    <div className="avg">
                                                        <p>
                                                            {t('summoner.avg')} <strong>AI-Score</strong> :&nbsp;
                                                            {isNaN(avg.blueScore) ? 0 : scoreColor(avg.blueScore.toFixed(1))}
                                                        </p>
                                                        <p>
                                                            {t('summoner.avgTier')} :{/*{isNaN(avg.blueRating) ? 0 : avg.blueRating.toFixed(1)}*/}&nbsp;
                                                            {tierBadge(avgTier.blueTier)}
                                                        </p>
                                                    </div>
                                                    <div className="ban">
                                                        {ingameBasic.ban_id_dict.blue.map(
                                                            (item, index) => (
                                                                <ImageBox>
                                                                    <ChampionImageWithLane key={index}
                                                                                           champion_id={item}/>
                                                                </ImageBox>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <IngameTeamInfo
                                                    color={'#5682DB'}
                                                    sideName={t('summoner.side.blue')}
                                                    teamName={t('summoner.team')}
                                                    winRate={avgWinrate.blueWinrate}
                                                />
                                                {/*<SideAndPercent>*/}
                                                {/*    <TeamText>*/}
                                                {/*        {t('summoner.side.blue')} {t('summoner.team')}*/}
                                                {/*    </TeamText>*/}
                                                {/*    <div style={{ display:'flex',flexDirection: 'row',alignItems:'flex-end'}}>*/}
                                                {/*        <PercetnText>{avgWinrate.blueWinrate} </PercetnText>*/}
                                                {/*        <ExtraWrapper>*/}
                                                {/*            <PercentTextExtra>%</PercentTextExtra>*/}
                                                {/*        </ExtraWrapper>*/}
                                                {/*    </div>*/}
                                                {/*</SideAndPercent>*/}
                                            </div>
                                            {/*벽*/}
                                            <div style={{flex: 0.3}}/>
                                            <div className="team red">
                                                <div className="cont">
                                                    <div className="avg">
                                                        <p>
                                                            {t('summoner.avg')} <strong>AI-Score</strong> :&nbsp;
                                                            {isNaN(avg.redScore) ? 0 : scoreColor(avg.redScore.toFixed(1))}
                                                        </p>
                                                        <p>
                                                            {t('summoner.avgTier')} :
                                                            {/*{isNaN(avg.redRating) ? 0 : avg.redRating.toFixed(1)}*/}
                                                            &nbsp;
                                                            {tierBadge(avgTier.redTier)}
                                                        </p>
                                                    </div>
                                                    <div className="ban">
                                                        {ingameBasic.ban_id_dict.red.map(
                                                            (item, index) => (
                                                                <ImageBox>
                                                                    <ChampionImageWithLane key={index}
                                                                                           champion_id={item}
                                                                                           borderRadius={'2px'}/>
                                                                </ImageBox>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <IngameTeamInfo
                                                    color={'#DE4469'}
                                                    rowReverse
                                                    teamName={t('summoner.team')}
                                                    sideName={t('summoner.side.red')}
                                                    winRate={avgWinrate.redWinrate}
                                                />
                                                {/*<div className="name">*/}
                                                {/*    {t('summoner.side.red')} {t('summoner.team')}*/}
                                                {/*</div>*/}
                                                {/*<div className="winrate">*/}
                                                {/*    <strong> {avgWinrate.redWinrate}</strong>*/}
                                                {/*    <span className={"winrate_percent"}> % </span>*/}
                                                {/*</div>*/}
                                            </div>

                                            <div className="predict">
                                                <PredictionWrapper>
                                                    <PredictionText
                                                        fontSize={20}>{t('ingame.aiPrediction1')}</PredictionText>
                                                    <PredictionText marginTop={'9px'}
                                                                    fontSize={language === 'KOR' ? 20 : 13}>{t('ingame.aiPrediction2')}</PredictionText>
                                                </PredictionWrapper>
                                                {/*<span className={"predictText"}>{t('ingame.aiPrediction1')}{"\n"}{t('ingame.aiPrediction2')}</span>*/}
                                                <ChartWinrate
                                                    blueWinrate={avgWinrate.blueWinrate}
                                                    redWinrate={avgWinrate.redWinrate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="t_head">
                                            <div className="row">
                                                <ul className="team blue">
                                                    <li className="tit item1">{t('ingame.summoner')}</li>
                                                    <li className="tit item2">{t('ingame.champion')}</li>
                                                    <li className="tit item3">
                                                        AI-Score
                                                    </li>
                                                </ul>

                                                <VersusWrapper>
                                                    <VersusTextWrapper>
                                                        <p className="tit">{t('ingame.vs')}</p>
                                                    </VersusTextWrapper>
                                                    <BigTooltip
                                                        tooltipText={t('ingame.versusExplain')}>
                                                        <img src={'/images/list_btn_tip_s_w.svg'}/>
                                                    </BigTooltip>
                                                </VersusWrapper>
                                                <ul className="team red">
                                                    <li className="tit item1">{t('ingame.summoner')}</li>
                                                    <li className="tit item2">{t('ingame.champion')}</li>
                                                    <li className="tit item3">
                                                        AI-Score
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="t_body">
                                            {ingamePart.length !== 0 ? (
                                                <IngameList
                                                    updateStatus={updateStatus}
                                                    summonerData={summonerData}
                                                    ingameBasic={ingameBasic}
                                                    ingamePart={ingamePart}
                                                    getWinrate={getWinrate}
                                                />
                                            ) : (
                                                <Loading/>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="not_supported">
                                    <p>{t('notice.notSupport')}</p>
                                </div>
                            )
                        ) : (
                            <div className="not_playing">
                                <div>
                                    <p>
                                        {summoner.summoner_name} {t('ingame.notPlaying1')} <br/>
                                        {t('ingame.notPlaying2')}
                                    </p>
                                </div>
                            </div>
                        )
                    ) : (
                        <Loading/>
                    )}
                </IngameContainer>
            )}
        </>
    );
};

export default React.memo(Ingame);
