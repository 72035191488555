import React from 'react';
import styled from "styled-components";
import ChampImg from "../../../../../summoner/MathItem/ChampImg";
import CRTChampion
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTChampion";
import ChampionImageWithLane from "../../../../../common/ChampionImageWithLane";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


`;

const ImgWrapper = styled.div`
  margin-inline: 3px;
  .imgChamp {
    width: 36px;
    height: 36px;
  }
`;

const RankingMost = ({list = []}) => {
    return (
        <Wrapper>
            {Array.isArray(list) && list.map((data) => {
                return (
                    <ImgWrapper key={data}>
                        <ChampionImageWithLane champion_id={Number(data)} borderRadius={'4px'} />
                    </ImgWrapper>
                )
            })}
        </Wrapper>
    );
};

export default RankingMost;
