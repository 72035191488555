import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import CRTableRanking from "./CRTableRanking";
import CRTChampion from "./CRTChampion";
import CRTTier from "./CRTTier";
import ScoreWrapper from "../../../../../summoner/DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import BigTooltip from "../../../../../Tooltip/BigTooltipBox";
import {useHistory, useLocation} from "react-router-dom";
import useQuery from "../../../../../../common/hooks/useQuery";

const Wrapper = styled.tr`
  overflow: hidden;
  border-bottom: 1px solid #A5BED2;
  td:not(:last-of-type){
    cursor: pointer;
  }
`;

const Content = styled.td`
  vertical-align: middle;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const WinPickBanText = styled.span`
  text-align: center;
  font-size: 12px;
  color: #313131;
`;

const PercentText = styled.span`
  font-size: 12px;
  color: #313131;
  opacity: 0.6;
`;


const MarginDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TripleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-inline: 16px;

  ${MarginDiv}:not(:last-of-type) {
    margin-inline: 2px;
  }
`;

const EmptyDiv = styled.div`
  flex: 1;
  width: 100%;
  min-width: 110px;
  height: 100%;
`;

const ChampionsRankingTableContent = ({
                                          version,
                                          rank,
                                          winRate,
                                          pickRate,
                                          banRate,
                                          counter,
                                          tier,
                                          champion,
                                          lane,
                                          aiScore,
                                          rankDelta
                                      }) => {
    const {i18n} = useTranslation();
    const renderName = useMemo(() => ['ko-KR', 'ko'].includes(i18n.language) ? champion?.champion_name_kr : champion?.champion_name_en, [i18n.language]);
    const championData = useSelector((state) => state?.imageStore.champions);
    const counterChampions = useMemo(() => {
        if (Array.isArray(counter) && counter.length > 0) {
            return counter.map((data) => championData.find((champ) => Number(champ.championId) === Number(data)));
        }
    }, [counter]);
    const history = useHistory();
    const location = useLocation();

    const navigateToBuild = useCallback(() => {
        const champName = champion?.champion_name_en.replace(/\s|&|willump|!|/gi, "").toLowerCase();
        history.push(`/champions/${champName}/build/${lane.toLowerCase()}${location.search}`)
    }, [champion,lane,location.search]);



    return (
        <Wrapper>
            <Content onClick={navigateToBuild}>
                <CRTableRanking
                    rank={rank}
                    value={rankDelta}
                />
            </Content>
            <Content onClick={navigateToBuild}>
                <CRTChampion
                    disableOnClick={true}
                    lane={lane}
                    imageName={champion?.image_name}
                    paddingLeft={'16px'}
                    championName={renderName}
                    enChampName={champion?.champion_name_en}
                    version={version}
                />
            </Content>
            <Content onClick={navigateToBuild}>
                <CRTTier
                    tier={tier}
                />
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <div>
                        <ScoreWrapper
                            fontSize={16}
                            noneClickable={true}
                            paddingHorizontal={8}
                            score={aiScore.toFixed(0)}
                        />
                    </div>
                </TempWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <WinPickBanText>{(winRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                </TempWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <WinPickBanText>{(pickRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                </TempWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <WinPickBanText>{(banRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                </TempWrapper>
            </Content>
            <Content>
                {Array.isArray(counterChampions) && counterChampions.length > 0 ?
                    <TripleWrapper>
                        {counterChampions.map((data, index) => {
                            if (typeof data === 'object') {
                                return (
                                    <BigTooltip
                                        key={champion?.championId + "counter" + data.championId}
                                        tooltipText={`<span style="color: #46B9AA; font-weight: bold">${['ko-KR', 'ko'].includes(i18n.language) ? data?.champion_name_kr : data?.champion_name_en}</span>`}>
                                        <MarginDiv>
                                            <CRTChampion
                                                rounded={true}
                                                lane={lane}
                                                imageName={data.image_name}
                                                enChampName={data.champion_name_en}
                                                width={24}
                                                height={24}
                                                version={version}
                                            />
                                        </MarginDiv>
                                    </BigTooltip>
                                )
                            } else {
                                return <React.Fragment key={"empty" + index}/>
                            }
                        })}
                    </TripleWrapper>
                    :
                    <EmptyDiv/>
                }
            </Content>
        </Wrapper>
    );
};

export default ChampionsRankingTableContent;
