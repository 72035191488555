import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Overview from './Overview';
import MatchItem from './MatchItem';
import {MatchListContainer} from '../../styled/SummonerStyle';
import {GET_MATCH_BY_QUEUE_REQUEST, GET_MORE_MATCHES_REQUEST} from "../../store/reducer/summoner";
import Loading from "../common/Loading";
import NewOverview from "./NewOverview";
import {getGameTime} from "../../function";
import RestDayInfo from "./MatchList/RestDayInfo";


const MatchList = ({updatePer, region,queueNum,setQueueNum}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const summoner = useSelector((state) => state.summoner.summoner);
    const {
        getSummonerInfoDone, getMoreMatchesDone,
        getMatchByQueueLoading,
        getMatchByQueueDone,
        getMatchByQueueError
    } = useSelector((state) => state.summoner);
    const [openId, setOpenId] = useState(0);

    const [champId, setChampId] = useState(0);
    const matchInfo = useSelector((state) => state.summoner.match);
    const currentLang = useSelector((state) => state.translation.lang);
    const [disabled, setDisabled] = useState(false);





    useEffect(() => {
        if (getMoreMatchesDone) {
            setDisabled(false);
        }
    }, [getMoreMatchesDone])


    const onPressHandleMoreGame = (summoner, btn) => {
        setDisabled(true);
        dispatch({
            type: GET_MORE_MATCHES_REQUEST,
            data: {
                champId: champId,
                queueNum: queueNum,
                region: region,
            }
        })
    }

    // 챔피언 선택
    const handleChampFilter = (champId) => {
        const result = Number(champId);
        setChampId(result);
        dispatch({
            type: GET_MATCH_BY_QUEUE_REQUEST,
            data: {
                champId: result ? result : 0,
                queueNum: queueNum,
                region: region,
            }
        })

    };

    // 큐 선택
    // const handleQueueFilter = (data) => {
    //     setQueueNum(data);
    //     dispatch({
    //         type: GET_MATCH_BY_QUEUE_REQUEST,
    //         data: {
    //             champId: champId,
    //             queueNum: data,
    //             region: region,
    //         }
    //     })
    // };

    // 정보 더 보기
    const handleMoreInfo = (gameId) => {
        const currentId = openId;
        if (currentId === gameId) {
            setOpenId(0);
        } else {
            setOpenId(gameId);
        }
    };


    const separatingMatchByTakeARest = useMemo(() => {
        if (Array.isArray(matchInfo) && matchInfo.length > 0) {
            //4 hours == 3600 * 4
            return matchInfo.reduce((sum, cur) => {
                const restTime = 3600 * 4;
                const currentMatchTime = Number(Number(cur.match_basic_dict.creation_timestamp) + Number(cur.match_basic_dict.game_duration));
                if (sum[sum.length - 1].lastMatchStamp > currentMatchTime) {

                    const timeCalc = Number(sum[sum.length - 1].lastMatchStamp) - Number(currentMatchTime);


                    if (timeCalc - restTime > 0) {

                        sum.push({
                            timestamp: currentMatchTime,
                            lastMatchStamp: currentMatchTime,
                            match: [{...cur},],
                        })
                    } else {
                        sum[sum.length - 1].lastMatchStamp = currentMatchTime;
                        sum[sum.length - 1].match.push({...cur});
                    }

                }
                return sum;
            }, [{
                timestamp: matchInfo[0].match_basic_dict.creation_timestamp,
                lastMatchStamp: matchInfo[0].match_basic_dict.creation_timestamp + matchInfo[0].match_basic_dict.game_duration,
                match: [{...matchInfo[0]}],
            }]);

        }
        return [];
    }, [matchInfo]);

    if (!getMatchByQueueDone) return (
        <div style={{height: 100}}>
            <Loading/>
        </div>
    );



    return (
        <>
            <NewOverview
                onChangeSelectedQueue={setQueueNum}
                selectedQueue={queueNum}
                region={region}
            />
            {/*<Overview*/}
            {/*    region={region}*/}
            {/*    matchInfo={matchInfo}*/}
            {/*    handleQueueFilter={handleQueueFilter}*/}
            {/*    handleChampFilter={handleChampFilter}*/}
            {/*    queueNum={queueNum}*/}
            {/*/>*/}
            {Array.isArray(matchInfo) && matchInfo.length !== 0 ? (
                <>
                    <MatchListContainer>
                        {separatingMatchByTakeARest.map((separate, index) => {
                            const time = new Date(separate.timestamp);
                            const myMatches = separate.match.reduce((sum, data) => {
                                const thisMatch = data.participants_list.find((player) => player.puu_id === summoner.summoner_basic_info_dict?.puu_id)
                                if (thisMatch) {
                                    sum.push(thisMatch);
                                }
                                return sum;
                            }, []);
                            return (
                                <React.Fragment key={separate.timestamp}>
                                    <RestDayInfo
                                        timestamp={time}
                                        currentLang={currentLang}
                                        match={myMatches}
                                    />
                                    {separate.match.map((match, index) => {

                                        return (
                                            <MatchItem
                                                key={match.match_basic_dict.match_id + "match"}
                                                summoner={summoner}
                                                currentLang={currentLang}
                                                region={region}
                                                match={match}
                                                openId={openId === match.match_basic_dict.match_id}
                                                handleMoreInfo={handleMoreInfo}
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            )

                        })}

                        <button
                            className="more_game"
                            disabled={disabled}
                            onClick={() => onPressHandleMoreGame(summoner, 'btnClick')}
                        >
                            {updatePer !== 100 && <i className="fill" style={{width: updatePer + '%'}}></i>}
                            <span>{`${updatePer !== 100 ? updatePer + '%' : t('summoner.more')}`}</span>
                        </button>
                    </MatchListContainer>
                </>
            ) : (
                <div className="no_game">
                    <p>{t('notice.noGame')}</p>
                </div>
            )}
        </>
    );
};

export default MatchList;
