import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import dompurify from 'dompurify';

import {TooltipContainer} from '../../styled/CommonStyle';
import ChampionSkillImage from "./ChampionSkillImage";

// 챔피언 data (현재 안씀)
export const getChampData = async (champId) => {
    const res = await axios.get(`${process.env.REACT_APP_CHAMP_INFO}`);
    const result = res.data.champions.filter((item) => item.championId === `${champId}`);

    return result[0].image_name;
};


// 챔피언 이미지 컴포넌트
export const ImageChamp = ({champId}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.champions);
    const [imgObj, setImgObj] = useState({});

    useEffect(() => {
        if (dataSelector.length !== 0) {
            if (dataSelector.filter((item) => item.championId === `${champId}`).length !== 0) {
                setImgObj(dataSelector.find((item) => item.championId === `${champId}`));
            } else {
                setImgObj('');
            }
        }
        return () => {
            setImgObj('');
        };
    }, [champId, dataSelector]);

    return (
        <>
            {Object.keys(imgObj).length > 0 ? (
                    <Tooltip dataTip={imgObj} dataFor="imgChamp">
                        <img
                            src={`${process.env.REACT_APP_IMG}${version}/img/champion/${imgObj.image_name}`}
                            className="imgChamp"
                            alt={imgObj.image_name}
                        />
                    </Tooltip>
                ) :
                champId === 0 ?
                    <div className="non_image">
                        <img src="/images/icon_cell_all.svg" className="imgChamp" alt={imgObj}/>
                    </div>
                    :
                    (
                        <div className="non_image">
                            <img src="/images/icon_non_item.svg" className="imgChamp" alt={imgObj}/>
                        </div>
                    )}
        </>
    );
};

// 룬 이미지 컴포넌트
export const ImageRune = ({runeId}) => {
    const dataSelector = useSelector((state) => state.imageStore.runes);
    const [imgObj, setImgObj] = useState('');

    useEffect(() => {
        if (dataSelector.length !== 0) {
            for (const el of dataSelector) {
                if (el.id === runeId) {
                    setImgObj(el);
                } else {
                    const ids = el.slots[0].runes.filter((itm) => itm.id === runeId);
                    if (ids.length !== 0) {
                        setImgObj(ids[0]);
                    } else {
                        el.slots.forEach((slot) => {
                            slot.runes.forEach((rune) => {
                                if (rune.id === runeId) {
                                    setImgObj(rune);
                                }
                            });
                        });
                    }
                }
            }
        }
        return () => {
            setImgObj('');
        };
    }, [runeId, dataSelector]);

    return (
        <>
            {imgObj.length !== 0 ? (
                <Tooltip dataTip={imgObj} dataFor="imgRune">
                    <img
                        src={`${process.env.REACT_APP_IMG}img/${imgObj.icon}`}
                        className="imgRune"
                        alt={imgObj.name}
                    />
                </Tooltip>
            ) : (
                <div className="non_image">
                    <img src="/images/icon_non_item.svg" className="imgRune" alt={imgObj}/>
                </div>
            )}
        </>
    );
};

// 스펠 이미지 컴포넌트
export const ImageSpell = ({spellId, smite}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.spells);
    const [imgObj, setImgObj] = useState('');

    useEffect(() => {
        if (Object.keys(dataSelector).length !== 0) {
            setImgObj(Object.values(dataSelector).filter((item) => item.key === `${spellId}`)[0]);
        }
        return () => {
            setImgObj('');
        };
    }, [spellId, dataSelector]);


    if (imgObj.length === 0) return (
        <div className="non_image">
            <img src="/images/icon_non_item.svg" className="imgSpell" alt={imgObj}/>
        </div>
    )

    if (spellId === 11) return (
        <Tooltip dataTip={imgObj} dataFor="imgSpell">
            {smite === 'B' ? (
                <img
                    src="https://raw.communitydragon.org/pbe/game/assets/spells/icons2d/smite_blue.png"
                    className="imgSpell"
                    alt="smite_blue"
                />
            ) : smite === 'R' ? (
                <img
                    src="https://raw.communitydragon.org/pbe/game/assets/spells/icons2d/smite_red.png"
                    className="imgSpell"
                    alt="smite_red"
                />
            ) : smite === 'SCORCHCLAU_PUP' ? (
                <img
                    src="https://raw.communitydragon.org/latest/game/data/spells/icons2d/1101_smite.png"
                    className="imgSpell"
                    alt="smite_red"
                />
            ) : smite === 'GUSTWALKER_HATCHLING' ? (
                <img
                    src="https://raw.communitydragon.org/latest/game/data/spells/icons2d/1102_smite.png"
                    className="imgSpell"
                    alt="smite_red"
                />
            ) : smite === 'MOSSTOMPER_SEEDLING' ? (
                <img
                    src="https://raw.communitydragon.org/latest/game/data/spells/icons2d/1103_smite.png"
                    className="imgSpell"
                    alt="smite_red"
                />
            ) : (
                <img
                    src={`${process.env.REACT_APP_IMG}${version}/img/spell/${imgObj.image.full}`}
                    className="imgSpell"
                    alt={imgObj.name}
                />
            )}
        </Tooltip>
    )


    return (
        <>
            <Tooltip dataTip={imgObj} dataFor="imgSpell">
                <img
                    src={`${process.env.REACT_APP_IMG}${version}/img/spell/${imgObj.image.full}`}
                    className="imgSpell"
                    alt={imgObj.name}
                />
            </Tooltip>
        </>
    );
};

// 아이템 이미지 컴포넌트
export const ImageItem = ({itemId, width, height, upgrade = true}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.items);
    const [imgObj, setImgObj] = useState('');

    // 오른의 업그레이드 아이템
    let upgradeItem = itemId;
    if (upgrade) {
        switch (itemId) {
            case 7000:
                upgradeItem = 6693;
                break;
            case 7001:
                upgradeItem = 6692;
                break;
            case 7002:
                upgradeItem = 6691;
                break;
            case 7003:
                upgradeItem = 6664;
                break;
            case 7004:
                upgradeItem = 3068;
                break;
            case 7005:
                upgradeItem = 6662;
                break;
            case 7006:
                upgradeItem = 6671;
                break;
            case 7007:
                upgradeItem = 6672;
                break;
            case 7008:
                upgradeItem = 6673;
                break;
            case 7009:
                upgradeItem = 4633;
                break;
            case 7010:
                upgradeItem = 4636;
                break;
            case 7011:
                upgradeItem = 3152;
                break;
            case 7012:
                upgradeItem = 6653;
                break;
            case 7013:
                upgradeItem = 6655;
                break;
            case 7014:
                upgradeItem = 6656;
                break;
            case 7015:
                upgradeItem = 6630;
                break;
            case 7016:
                upgradeItem = 6631;
                break;
            case 7017:
                upgradeItem = 6632;
                break;
            case 7018:
                upgradeItem = 3078;
                break;
            case 7019:
                upgradeItem = 3190;
                break;
            case 7020:
                upgradeItem = 2065;
                break;
            case 7021:
                upgradeItem = 6617;
                break;
            case 7022:
                upgradeItem = 4005;
                break;
            default:
                upgradeItem = itemId;
                break;
        }
    }


    useEffect(() => {
        if (Object.keys(dataSelector).length !== 0) {
            let number = Object.entries(dataSelector).filter((item) => item[0] === `${upgradeItem}`)[0];
            if (number !== undefined) {
                setImgObj(upgradeItem === 0 ? 0 : number[1]);
            }
        }
        return () => {
            setImgObj('');
        };
    }, [dataSelector, upgradeItem]);

    return (
        <>
            {imgObj.length !== 0 && imgObj !== 0 ? (
                <>
                    {itemId >= 7000 && itemId < 8000 ? (
                        <Tooltip dataTip={imgObj} dataFor="imgItem" className="upgrade">
                            <img
                                src={`${process.env.REACT_APP_IMG}${version}/img/item/${imgObj.image.full}`}
                                className="imgItem"
                                alt={imgObj.name}
                                width={width}
                                height={height}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip dataTip={imgObj} dataFor="imgItem">
                            <img
                                src={`${process.env.REACT_APP_IMG}${version}/img/item/${imgObj.image.full}`}
                                className="imgItem"
                                alt={imgObj.name}
                                width={width}
                                height={height}
                            />
                        </Tooltip>
                    )}
                </>
            ) : (
                <div className="non_image">
                    <img src="/images/icon_non_item.svg" className="imgItem" alt={imgObj} width={width}
                         height={height}/>
                </div>
            )}
        </>
    );
};

// 스킬 data
export const getSkillImage = async (champName, version, lang) => {
    const resSkills = await axios.get(
        `${process.env.REACT_APP_SKILL}?version=${version}&lang=${
            lang === 'KOR' ? 'ko_KR' : 'en_US'
        }&name=${champName}`
    );
    return resSkills.data.data[champName];
};
// 스킬 이미지 컴포넌트
export const ImageSkill = ({champId, num}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.champions);
    const currentChampions = useMemo(() => {
        return dataSelector.find((data) => Number(data.championId) === Number(champId));
    }, [dataSelector, champId]);


    if (!currentChampions) return <img src="/images/icon_non_item.svg" className="imgSkill" alt={""}/>;
    return (
        <ChampionSkillImage
            reverse={true}
            margin={'0 5px 0 0'}
            width={36}
            height={36}
            spell={currentChampions?.spells[num]}
            version={version}
            img={currentChampions?.spells[num]?.spell_img}
        />
    );
};

// 라인 이미지 컴포넌트
export const ImageLane = ({lane, color}) => {
    return (
        <>
            {color === 'white' ? (
                <img
                    src={`/images/icon_s_position_${lane?.toLowerCase()}_w.svg`}
                    className="imgLane"
                    alt={lane}
                />
            ) : (
                <img
                    src={`/images/icon_s_position_${lane?.toLowerCase()}.svg`}
                    className="imgLane"
                    alt={lane}
                />
            )}
        </>
    );
};

// 오브젝트 이미지 컴포넌트
export const ImageMonster = ({obj, only, noIcon = false}) => {
    const [imgName, setImgName] = useState(typeof obj === 'string' && obj.toLowerCase());

    useEffect(() => {
        if (typeof obj === 'string') {
            setImgName(obj.toLowerCase());
        }

    }, [obj]);

    return (
        <>
            {imgName.indexOf(only) !== -1 ? (
                <img
                    src={`/images/icon_objectives_${imgName.toLowerCase()}.svg`}
                    className="imgMonster dragon"
                    alt={obj}
                />
            ) : (
                <>
                    {noIcon ? (
                        <img
                            src={`/images/icon_objectives_${imgName.toLowerCase()}.png`}
                            className="imgMonster"
                            alt={obj}
                        />
                    ) : (
                        <img
                            src={`/images/icon_objectives_${imgName.toLowerCase()}.svg`}
                            className="imgMonster"
                            alt={obj}
                        />
                    )}
                </>
            )}
        </>
    );
};

// 툴팁
const Tooltip = ({children, dataTip, dataFor}) => {
    const currentLang = useSelector((state) => state.translation.lang);
    const sanitizer = dompurify.sanitize;

    if (dataFor === 'imgRune') {
        // 룬 툴팁
        return (
            <TooltipContainer className="twrap">
                {children}
                <div className="tooltip">
                    <h5>{dataTip.name}</h5>
                    {dataTip.longDesc !== undefined && (
                        <p dangerouslySetInnerHTML={{__html: sanitizer(dataTip.longDesc)}}></p>
                    )}
                </div>
            </TooltipContainer>
        );
    } else if (dataFor === 'imgSpell') {
        // 스펠 툴팁
        return (
            <TooltipContainer className="twrap">
                {children}
                <div className="tooltip">
                    <h5>{dataTip.name}</h5>
                    <p dangerouslySetInnerHTML={{__html: sanitizer(dataTip.description)}}></p>
                </div>
            </TooltipContainer>
        );
    } else if (dataFor === 'imgItem') {
        // 아이템 툴팁
        return (
            <TooltipContainer className="twrap">
                {children}
                <div className="tooltip">
                    <h5>{dataTip.name}</h5>
                    <p dangerouslySetInnerHTML={{__html: sanitizer(dataTip.description)}}></p>
                </div>
            </TooltipContainer>
        );
    } else if (dataFor === 'imgChamp') {
        // 챔피언 툴팁
        return (
            <TooltipContainer className="twrap">
                {children}
                <div className="tooltip">
                    <h5>{currentLang === 'KOR' ? dataTip.champion_name_kr : dataTip.champion_name_en}</h5>
                </div>
            </TooltipContainer>
        );
    } else if (dataFor === 'imgSkill') {
        // 스킬 툴팁
        return (
            <TooltipContainer className="twrap">
                {children}
                <div className="tooltip">
                    <h5>{dataTip.name}</h5>
                    <p dangerouslySetInnerHTML={{__html: sanitizer(dataTip.description)}}></p>
                </div>
            </TooltipContainer>
        );
    }

    return <>{children}</>;
};
