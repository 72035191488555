import React, {useCallback} from 'react';
import styled from "styled-components";
import RankingTableFilter from "./RankingTableFilter";
import RankingTableContent from "./RankingTableContent";
import RankingTableNavigator from "./RankingTableContent/RankingTableNavigator";
import {useHistory} from "react-router-dom";
import {capitalizeLetter} from "../../../../function";
import {Loading} from "../../../common";
import InternalServerError from "../../../common/InternalError";

const Wrapper = styled.div`
  border: 1px solid #A5BED2;
  border-radius: 10px 10px 0 0;
  margin-top: 5px;
`;

const LoadingWrapper = styled.div`
  height: 200px;
  width: 100%;
`;


const RankingTable = ({rankingRegion, currentLane, ranking, getRankingLoading,version,getRankingError}) => {
    const history = useHistory();
    const onClickLane = useCallback((lane) => {
        if (typeof lane === 'string') {
            if (lane !== currentLane) {
                history.push(`/ranking/${rankingRegion.title}/${lane.toLowerCase()}`)
            }
        }

    }, [currentLane, rankingRegion.title]);

    const onClickRegion = useCallback((event) => {
        const region = event.target.value;
        if (typeof region === 'string') {
            if (region !== rankingRegion.title) {
                history.push(`/ranking/${region}/${currentLane.toLowerCase()}`)
            }
        }

    }, [currentLane, rankingRegion]);

    if(getRankingError && !getRankingLoading) {
            return (
                <Wrapper>
                    <RankingTableFilter
                        botRate={ranking?.rate_bot}
                        jugRate={ranking?.rate_jug}
                        midRate={ranking?.rate_mid}
                        supRate={ranking?.rate_sup}
                        topRate={ranking?.rate_top}
                        currentLane={capitalizeLetter(currentLane)}
                        setCurrentLane={onClickLane}
                        rankingRegion={rankingRegion}
                        onClickRegion={onClickRegion}
                    />
                    <InternalServerError
                        errorText={getRankingError?.response.data?.msg}
                    />
                </Wrapper>
            );
    }

    return (
        <Wrapper>
            <RankingTableFilter
                botRate={ranking?.rate_bot}
                jugRate={ranking?.rate_jug}
                midRate={ranking?.rate_mid}
                supRate={ranking?.rate_sup}
                topRate={ranking?.rate_top}
                currentLane={capitalizeLetter(currentLane)}
                setCurrentLane={onClickLane}
                rankingRegion={rankingRegion}
                onClickRegion={onClickRegion}
            />
            {getRankingLoading ?
                <LoadingWrapper>
                    <Loading/>
                </LoadingWrapper>
                :
                <RankingTableContent
                    version={version}
                    region={rankingRegion.title}
                    rankingList={ranking?.summoner_rank_list}
                />
            }
        </Wrapper>
    );
};

export default RankingTable;
