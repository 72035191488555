import React, {useMemo} from 'react';
import styled from "styled-components";
import {Link, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #F9F9FA;
  border: 1px solid #C3D1DC;
  padding: 7px;
  border-radius: 6px;

  span:nth-child(n+1) {
    margin-left: 5px;
  }
`;

const CategorySpan = styled(Link)`
  color: ${props => {
      let color = '#313131';
      if(props.selected) color = '#fff';
      return color;
  }};
  text-align: center;
  font-size: 18px;
  min-width: 12ch;
  padding-inline: 12px;
  padding-block: 6px;
  background: ${props => {
      let background = 'transparent';
      if(props.selected) background = 'transparent linear-gradient(74deg, #1EB8A0 0%, #3C68B2 100%) no-repeat padding-box';
      return background
  }};
  border-radius: 6px;
  opacity: ${props => {
      let opacity = '0.6';
      if(props.selected) opacity = '1';
      return opacity;
  }};
`;

const ChampionsDetailCategory = () => {
    const {t} = useTranslation();
    const {url} = useRouteMatch();
    const location = useLocation();
    const currentSelected = useMemo(() => location.pathname.split("/")[3],[location]);



    return (
        <Wrapper>
            <CategorySpan
            to={`${url}/build`}
                selected={currentSelected === 'build'}>
                {t("championDetail.build")}
            </CategorySpan>
            {/*<CategorySpan*/}
            {/*    to={`${url}/counter`}*/}
            {/*    selected={currentSelected === 'counter'}>*/}
            {/*    {t("championDetail.counter")}*/}
            {/*</CategorySpan>*/}
            <CategorySpan
                to={`${url}/history`}
                selected={currentSelected === 'history'}
                >
                {t("championDetail.history")}
            </CategorySpan>
        </Wrapper>
    );
};

export default React.memo(ChampionsDetailCategory);
