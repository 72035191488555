import React from 'react';
import {ImageLane} from "../../../common";
import {cutLaneName} from "../../../../function";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-top: 22px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const LineTag = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  background-color: #DDE1EC;
  border-radius: 3px;
`;

const LineText = styled.span`
  font-size: 11px;
  color: ${props => {
    let color = '#000';
    if (props.notMost) color = 'rgb(194, 74, 74)';
    return color;
  }}
`;

const NotMostLaneWrapper = styled.div`
  margin-left: 3px;
`;

const IngameEmblem = ({summoner, gameInfo, lane, rankQueue}) => {
    const {t} = useTranslation();
    return (
        <>
            <Wrapper>
                {(summoner.season_tier_info_dict[rankQueue]?.hasOwnProperty('tier') && summoner.season_tier_info_dict[rankQueue]?.tier) ? (
                    <img
                        src={`/images/Emblem_${summoner.season_tier_info_dict[rankQueue].tier.toUpperCase()}.png`}
                        className="imgEmblem"
                        width={55}
                        height={55}
                        alt={summoner.season_tier_info_dict[rankQueue].tier}
                    />
                ) : (
                    <img src="/images/Emblem_UNRANKED.png" className="imgEmblem" alt="UNRANKED"/>
                )}

                <LineTag>
                    <ImageLane lane={lane}/>
                    <LineText
                        notMost={!gameInfo.summoner_info_dict.is_main_position}
                    >{cutLaneName(lane).toUpperCase()}</LineText>
                    {!gameInfo.summoner_info_dict.is_main_position && (
                        <NotMostLaneWrapper>
                            <img src="/images/icon_error.svg" alt="error"/>
                            {/*// <i title={t('ingame.notMostLane')}>{t('ingame.notMostLane')}</i>*/}
                        </NotMostLaneWrapper>
                    )}
                </LineTag>
            </Wrapper>
        </>
    );
};

export default IngameEmblem;