import React from 'react';
import styled from "styled-components";


const ChampInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChampVersusInfo = styled.span`
  border-radius: 3px;
  padding: 3px 9px;
  background-color: #E3E6EF;
  font-size: 12px;
  color: #212121;
  font-weight: 400;
`;

const ExtraText = styled.span`
  margin-left: 12px;
  font-size: 12px;
  color: #212121;
`;

const ChampInfo = ({infoText, extra}) => {
    return (
        <>
            <ChampInfoWrapper>
                <ChampVersusInfo>
                    {infoText}
                </ChampVersusInfo>
                <ExtraText>
                    {extra}
                </ExtraText>
            </ChampInfoWrapper>
        </>
    );
};

export default ChampInfo;