import React, {useCallback} from 'react';
import styled from "styled-components";
import {getTierDivisionCombined, getTierScore, tierBadge} from "../../../../../function";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-block: 4px;
  padding-inline: 4px;
  margin-right: 6px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
  
    background-color: rgba(80, 108, 184, 0.1);
  }
`;
const ImgWrapper = styled.div`
  margin-right: 2px;
`;

const Tier = styled.div`
  margin-left: 7px;

  .tier_badge {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    width: 24px;
    height: 14px;
    border-radius: 5px;
  }
`;

const TextWrapper = styled.div`
  margin-left: 9px;
`;
const Text = styled.span`
  font-size: 14px;
`;

const AutoCompleteContent = ({img, tier, division, name, onClick, data,isChamp = false}) => {
    const onClickWrapper = useCallback(() => {
        if (data) {
            onClick(data);
        }
    }, [onClick, data]);


    if (!name) {
        return null;
    }

    return (
        <Wrapper onClick={onClickWrapper}>
            <ImgWrapper>
                <img
                    src={img}
                    width={20}
                    height={20}
                    alt={""}
                />
            </ImgWrapper>
            {(typeof tier === 'string' && typeof division === 'number') &&
            <Tier>
                {tierBadge(getTierDivisionCombined(tier, division))}
            </Tier>}
            <TextWrapper>
                <Text>{name}</Text>
            </TextWrapper>
        </Wrapper>
    );
};

export default AutoCompleteContent;
