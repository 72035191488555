import React, {useCallback, useEffect, useMemo} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import NotFound from './components/NotFound';
import GlobalStyles from './styled/GlobalStyles';
import i18nInit from "./i18n";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {changeVersion} from "./store/modules/gameVersion";
import axios from "axios";
import {imageStore} from "./store/modules/imageStore";
import {Home, Multi, Summoner, ToMobile} from "./pages";
import ChampionsDetail from "./components/ChampionsDetail";
import Champions from "./components/Champions";
import styled from "styled-components";
import Ranking from "./components/Ranking";



const AppWrapper = styled.div`
  min-width: 1300px;
`;




// 스토어생성
i18nInit(localStorage.getItem(process.env.REACT_APP_LANG_COOKIE) ?
    localStorage.getItem(process.env.REACT_APP_LANG_COOKIE) :
    window.navigator.language === "ko" || window.navigator.language === "ko-KR" ? 'ko' : "en-US");

const App = () => {

    const dispatch = useDispatch();
    const currentLang = useSelector((state) => state.translation.lang);
    const {t, i18n} = useTranslation();
    const choArr = useMemo(() => ["ㄱ", "ㄲ", "ㄴ", "ㄷ", "ㄸ", "ㄹ", "ㅁ", "ㅂ", "ㅃ", "ㅅ", "ㅆ", "ㅇ", "ㅈ", "ㅉ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"], []);

    const handleDescription = () => {
        const htmlTag = document.querySelector('html');
        const description = document.querySelector('meta[name=description]');
        const ogDescription = document.querySelector('meta[property="og:description"]');
        const snsDescription = document.querySelector('meta[property="twitter:description"]');
        const contentLanguage = document.querySelector('meta[http-equiv="content-language"]');
        const enDescription = 'DEEPLOL provides new strategies such as AI-score and Tier Prediction with AI technology. Check out AI-score, like Pro LoL teams.';
        const korDescription = '롤전적 검색 딥롤은 인공지능(AI) 기술로 AI Score, 티어 예측과 같은 새로운 게임 데이터를 제공합니다. 롤 프로팀도 사용하는 AI-score를 지금 확인해보세요!';
        const thisDes = currentLang === 'KOR' ? korDescription : enDescription;

        contentLanguage.setAttribute('content', currentLang === 'KOR' ? 'ko' : 'en');
        description.setAttribute('content', thisDes);
        ogDescription.setAttribute('content', thisDes);
        snsDescription.setAttribute('content', thisDes);
        htmlTag.setAttribute('lang', currentLang === 'KOR' ? 'ko' : 'en');
    }

    const changeKeywords = () => {
        const keywords = document.querySelector('meta[name=keywords]');
        keywords.setAttribute('content', t('header.keywords'));
    };

    const getGameVersion = useCallback(async () => {
        const res = await axios.get('https://ddragon.leagueoflegends.com/api/versions.json');
        dispatch(changeVersion(res.data[0]));
        return res.data[0]
        // 이미지 정보 함수호출
    }, []);

    const setChoName = useCallback((value) => {
        if (typeof value === 'string') {
            let result = "";
            for (let char of value) {
                if (char === "") {
                    continue;
                }
                if (char.charCodeAt() > 44031) {
                    const choValue = Math.trunc((char.charCodeAt() - 44032) / 588);
                    result += choArr[choValue];
                } else {
                    result += char;
                }

            }
            return result;
        }
    }, []);


    const setChoNamedChampion = useCallback((data) => {
        let choNamedChampions
        if (Array.isArray(data)) {
            choNamedChampions = data.map((data, index) => {
                const choname = data.champion_name_kr ? setChoName(data.champion_name_kr) : data.champion_name_en;
                return {
                    ...data,
                    cho_name: choname
                }
            })
        }
        return choNamedChampions;
    }, []);


    // 챔피언, 룬, 스펠, 아이템 스토어 등록 (이미지용)
    const getImageInfo = useCallback(async () => {
            try {

                const gameVersion = await getGameVersion();
                let _thisGameVersionArr = gameVersion.split('.');
                let _thisGameVersion = _thisGameVersionArr[0] + '.' + _thisGameVersionArr[1];

                // const resChampions = await axios.get(`${process.env.REACT_APP_CHAMP_INFO}`);
                const resChampions = await axios.get(`https://test-champion-info.kphws.workers.dev`);

                const resRunes = await axios.get(
                    `${process.env.REACT_APP_RUNE}?version=${_thisGameVersion}&lang=${
                        currentLang === 'KOR' ? 'ko_KR' : 'en_US'
                    }`
                );
                const resSpells = await axios.get(
                    `${process.env.REACT_APP_SPELL}?version=${_thisGameVersion}&lang=${
                        currentLang === 'KOR' ? 'ko_KR' : 'en_US'
                    }`
                );
                const resItems = await axios.get(
                    `${process.env.REACT_APP_ITEM}?version=${_thisGameVersion}&lang=${
                        currentLang === 'KOR' ? 'ko_KR' : 'en_US'
                    }`
                );

                const season = await axios.get(`${process.env.REACT_APP_CACHE_API}common/season-list`);

                const getAiData = await axios.get(`${process.env.REACT_APP_CACHE_API}common/tier-boundary`);

                const getStreamerTag = await axios.get(`${process.env.REACT_APP_CACHE_API}match/stream-pro-tag`)


               const arrayStreamerTag = Object.entries(getStreamerTag.data.stream_pro_tag).map((data) => {
                   const [key,value] = data;
                   return {
                       puu_id : key,
                       status: value,
                   }
               });



                const tooltipReady = true;

                const choNamedChampions = setChoNamedChampion(resChampions?.data?.champions)

                dispatch(
                    imageStore(
                        choNamedChampions,
                        resRunes.data,
                        resSpells.data.data,
                        resItems.data.data,
                        tooltipReady,
                        getAiData.data,
                        season.data.season_list,
                        arrayStreamerTag
                    )
                );
            } catch (e) {
                console.log(e);
            }
        },
        [currentLang]);

    useEffect(() => {
        if (!isMobile) {
            getImageInfo();
            handleDescription();
        }
    }, [getImageInfo]);


    i18next.on("languageChanged", changeKeywords);


    return (
        <>
            <GlobalStyles/>
            <AppWrapper>
                <Router>
                    <Switch>
                        {isMobile && <Route path="*" component={ToMobile}></Route>}
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route exact path="/summoner/:region/:userName/matches/:matchId">
                            <Summoner/>
                        </Route>
                        <Route exact path="/summoner/:region/:userName">
                            <Summoner/>
                        </Route>
                        <Route exact path="/summoner/:userName">
                            <Summoner/>
                        </Route>
                        <Route path="/multi/:region/:name">
                            <Multi/>
                        </Route>
                        <Route path="/multi">
                            <Multi/>
                        </Route>
                        <Route path="/champions/:champName">
                            <ChampionsDetail/>
                        </Route>
                        <Route exact path="/champions">
                            <Champions/>
                        </Route>
                        <Route exact path="/ranking/:platform/:position">
                            <Ranking/>
                        </Route>
                        <Route>
                            <NotFound/>
                        </Route>
                    </Switch>
                </Router>
            </AppWrapper>
        </>
    );

};

export default App;
