import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {range} from 'lodash';
import {getDividedGameTime, getGameTime, getLPTime} from "../../function";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ChartWrapper = styled.div`
  position: relative;

  .highcharts-tick {
    fill: rgba(255, 168, 80, 1);
    fill-opacity: 1;
    stroke-width: 0;
  }
`;

const AbsoluteBar = styled.div`
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  bottom: 23px;
  right: 9px;
  width: 266px;
  height: 1px;
  z-index: 0;
`;

const ChartLp = ({tierChart, reverse = false}) => {
    const {t} = useTranslation();
    const {originalArr, calculatedArr, dateArr, yTickPositions, grandChallengeMax} = tierChart;
    const currentLang = useSelector((state) => state.translation.lang);
    // originalArr    : 60개의 원래 티어 정보
    // calculatedArr  : 계산된 티어 포인트
    // dateArr        : 날짜 정보
    // yTickPositions : y축 값 (이제 안씀)


    const minValue = Math.floor(Math.min.apply(null, calculatedArr) / 100) * 100;
    const maxValue = (Math.floor(Math.max.apply(null, calculatedArr) / 100) + 1) * 100;
    const rangeData = range(minValue, maxValue + 100, 100);
    let newRange = rangeData.filter(
        (item, index) => {

            return index === 0 || index === rangeData?.length - 1 || item % 400 === 0 || item >= yTickPositions?.GRANDMASTER
        }
    );

    const tierPosition = [0, 400, 800, 1200, 1600, 2000, 2400, yTickPositions?.GRANDMASTER, yTickPositions?.CHALLENGER];
    const options = {
        // 하이차트 옵션
        chart: {
            marginBottom: 24,
            marginRight: 9,
            type: 'area',
            plotBackgroundColor: 'none',
            backgroundColor: 'none',
            plotBorderWidth: 0,
            spacingLeft: 0,
            spacingRight: 0,
            width: 300,
            height: 200,
            events: {
                // load: function () {
                //   var point = this.series[0].points;
                //   point.forEach((p) => {
                //     if (Math.max.apply(null, calculatedArr) === p.y) {
                //       p.update({
                //         marker: {
                //           enabled: true,
                //           fillColor: '#46BCED',
                //           radius: 5,
                //           width: 5,
                //           lineColor: '#ffffff',
                //           lineWidth: 2,
                //         },
                //         dataLabels: {
                //           enabled: false,
                //         },
                //       });
                //     }
                //   });
                // },
            },
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        xAxis: {
            margin: 0,
            labels: {
                style: {
                    textOverflow: "ellipsis",
                    fontFamily: 'Noto Sans KR',
                    fontSize: '11px',
                    fontWeight: 400,
                    color: '#313131',
                },
                useHTML: true,
                formatter: function () {
                    const calvalue = getLPTime(this.value, currentLang);
                    return calvalue;
                },
            },
            tickWidth: 1,
            tickColor: '#FFA850',
            tickInterval: 20,
            categories: dateArr,
            lineWidth: 0,
            // lineColor: 'rgba(0,0,0,0.5)',
            gridLineWidth: 1,
            gridLineColor: 'rgba(0,0,0,0.2)',
            gridLineDashStyle: 'ShortDash',
        },
        yAxis: {
            title: {
                enabled: false,
            },
            labels: {
                x: -7,
                style: {
                    fontFamily: 'Noto Sans KR',
                    fontSize: '12px',
                    fontWeight: 500,
                },
                useHTML: true,
                formatter: function () {
                    let thisValue = this.value;
                    if (thisValue === 0) return "<span style='color: #5A5959'>I4</span>";
                    else if (thisValue === 100) return "<span style='color: #5A5959; text-align: right'>I3</span>";
                    else if (thisValue === 200) return "<span style='color: #5A5959;text-align: right'>I2</span>";
                    else if (thisValue === 300) return "<span style='color: #5A5959;text-align: right'>I1</span>";
                    else if (thisValue === 400) return "<span style='color: #876B26;text-align: right'>B4</span>";
                    else if (thisValue === 500) return "<span style='color: #876B26;text-align: right'>B3</span>";
                    else if (thisValue === 600) return "<span style='color: #876B26;text-align: right'>B2</span>";
                    else if (thisValue === 700) return "<span style='color: #876B26;text-align: right'>B1</span>";
                    else if (thisValue === 800) return "<span style='color: #6A79AB;text-align: right'>S4</span>";
                    else if (thisValue === 900) return "<span style='color: #6A79AB;text-align: right'>S3</span>";
                    else if (thisValue === 1000) return "<span style='color: #6A79AB;text-align: right'>S2</span>";
                    else if (thisValue === 1100) return "<span style='color: #6A79AB;text-align: right'>S1</span>";
                    else if (thisValue === 1200) return "<span style='color: #BA8D26;text-align: right'>G4</span>";
                    else if (thisValue === 1300) return "<span style='color: #BA8D26;text-align: right'>G3</span>";
                    else if (thisValue === 1400) return "<span style='color: #BA8D26;text-align: right'>G2</span>";
                    else if (thisValue === 1500) return "<span style='color: #BA8D26;text-align: right'>G1</span>";
                    else if (thisValue === 1600) return "<span style='color: #1DA794;text-align: right'>P4</span>";
                    else if (thisValue === 1700) return "<span style='color: #1DA794;text-align: right'>P3</span>";
                    else if (thisValue === 1800) return "<span style='color: #1DA794;text-align: right'>P2</span>";
                    else if (thisValue === 1900) return "<span style='color: #1DA794;text-align: right'>P1</span>";
                    else if (thisValue === 2000) return "<span style='color: #2898C9;text-align: right'>D4</span>";
                    else if (thisValue === 2100) return "<span style='color: #2898C9;text-align: right'>D3</span>";
                    else if (thisValue === 2200) return "<span style='color: #2898C9;text-align: right'>D2</span>";
                    else if (thisValue === 2300) return "<span style='color: #2898C9;text-align: right'>D1</span>";
                    else if (thisValue === 2400) return "<span style='color: #863DA1;text-align: right'>M</span>";
                    else if (thisValue === yTickPositions?.GRANDMASTER) return "<span style='color: #C23449'>GM</span>";
                    else if (thisValue === yTickPositions?.CHALLENGER) return "<span style='color: #EE7A00'>C</span>";
                    else return '';
                },
            },
            min: minValue,
            max: maxValue,
            startOnTick: false,
            endOnTick: false,
            tickPositions: [...newRange, yTickPositions?.GRANDMASTER, yTickPositions?.CHALLENGER],
            tickColor: 'transparent',
            plotLines: tierPosition.map((data) => {
                return {
                    color: 'rgba(0,0,0,0.2)',
                    width: 1,
                    value: data,
                    dashStyle: 'ShortDash',


                }
            }),
            minorGridLineWidth: 0,
            minorGridLineColor: 'transparent',
            lineWidth: 0,
            // lineColor: 'rgba(0,0,0,0.5)',
            gridLineWidth: 0,
            // gridLineColor: 'rgba(0,0,0,0.2)',
            // gridLineDashStyle: 'ShortDash',
            plotBands: [
                {
                    color: 'rgba(90, 89, 89, 0.08)',
                    from: 0,
                    to: 400,
                },
                {
                    color: 'rgba(135, 107, 38, 0.08)',
                    from: 400,
                    to: 800,
                },
                {
                    color: 'rgba(106, 121, 171, 0.08)',
                    from: 800,
                    to: 1200,
                },
                {
                    color: 'rgba(186, 141, 38, 0.08)',
                    from: 1200,
                    to: 1600,
                },
                {
                    color: 'rgba(29, 167, 148, 0.08)',
                    from: 1600,
                    to: 2000,
                },
                {
                    color: 'rgba(40, 152, 201, 0.08)',
                    from: 2000,
                    to: 2400,
                },
                {
                    color: 'rgba(134, 61, 161, 0.08)',
                    from: 2400,
                    to: yTickPositions?.GRANDMASTER - 1,
                },
                {
                    color: 'rgba(194, 52, 73, 0.08)',
                    from: yTickPositions?.GRANDMASTER,
                    to: yTickPositions?.CHALLENGER,
                },
                {
                    color: 'rgba(238, 122, 0, 0.08)',
                    from: yTickPositions?.CHALLENGER,
                    to: yTickPositions?.CHALLENGER + 10000,
                },
            ],
        },
        series: [
            {
                type: 'line',
                showInLegend: false,
                name: 'lp graph',
                data: calculatedArr,
                lineWidth: 2,
                lineColor: '#000000',
                marker: {
                    enabled: false,
                },
                zones: [
                    {
                        value: 400,
                        color: '#5A5959',
                    },
                    {
                        value: 800,
                        color: '#876B26',
                    },
                    {
                        value: 1200,
                        color: '#6A79AB',
                    },
                    {
                        value: 1600,
                        color: '#BA8D26',
                    },
                    {
                        value: 2000,
                        color: '#1DA794',
                    },
                    {
                        value: 2400,
                        color: '#2898C9',
                    },
                    {
                        value: yTickPositions?.GRANDMASTER,
                        color: '#863DA1',
                    },
                    {
                        value: yTickPositions?.CHALLENGER,
                        color: '#C23449',
                    },
                    {
                        value: 10000,
                        color: '#EE7A00',
                    },
                ],
            },
        ],
        tooltip: {
            backgroundColor: '#ffffff',
            borderColor: '#4C506D',
            borderRadius: 4,
            className: 'tooltip',
            useHTML: true,
            formatter: function () {
                const pointIndex = originalArr[this.point.index];
                const [time, text] = getDividedGameTime(this.x, currentLang);
                const date = new Date(this.x * 1000);
                return `
          <p ><span>${text !== '방금 전' ? time : ''}</span><span style="color: #313131; opacity: 0.6; font-weight: 500">${text}</span></p>
          <p><span style="color: #313131; opacity: 0.6; font-weight: 500">${t('chart.rank')} </span><span class="tier_color ${pointIndex.tier.toLowerCase()}">${pointIndex.tier} ${pointIndex.division}</span></p>
          <p><span style="color: #313131; opacity: 0.6; font-weight: 500">LP </span> <span>${pointIndex.league_points}</span></p>
          <p><span>${date.getFullYear()}.${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}.${date.getDate() > 9 ? date.getDate() : "0" + (date.getDate())}</span></p>
              
        `;
            },
        },
    };


    Highcharts.wrap(Highcharts.Tick.prototype, 'getMarkPath', function (prev, x, y, tickLength, tickWidth, horiz, renderer) {
        if (tickWidth > 0) {
            const xPath = Math.round(x) - 2.5;
            const elevateValue = 2;
            const yPath = Math.round(y) + 0.4;

            return ["M", xPath, yPath,
                "L",
                xPath + elevateValue, yPath - elevateValue,
                xPath + elevateValue * 2, yPath,
                xPath + elevateValue, yPath + elevateValue,
                xPath, yPath
            ]
        }
    });

    return (
        <>
            {originalArr !== undefined && originalArr.length !== 0 ? (
                <ChartWrapper>
                    <AbsoluteBar/>
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={options}/>
                    </div>
                </ChartWrapper>
            ) : (
                <div className="chart_lp">No Data</div>
            )}
        </>
    );
};

export default ChartLp;
