import React from 'react';
import regionList from "../../../store/static_json/regionList";
import DownArrow from "../TagWithArrow/DownArrow";
import styled from "styled-components";

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 18px;
  height: 100%;

  select {
    border: 1px solid #C3D1DC;
    border-radius: 4px;
    padding: 0 20px 0 8px;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 100%;
  }
`;


const ArrowWrapper = styled.div`
  position: absolute;
  right: 8px;
`;
const RankingDropBox = ({onChange, list, selected, value, title}) => {
    return (
        <SelectWrapper>
            <select onChange={onChange}>
                {Array.isArray(list) && list.map((data, index) => {
                    return <option value={value(data)}
                                   selected={selected(data)}
                                   key={index}>{title(data)}</option>
                })}
            </select>
            <ArrowWrapper>
                <DownArrow color={"#313131"}/>
            </ArrowWrapper>
        </SelectWrapper>
    );
};

export default RankingDropBox;
