const runeShards =  {
    "id": 5000,
    "key": "Rune Shards",
    "icon": "null",
    "name": "룬 파편",
    "slots": [
        {
            "runes": [
                {
                    "id": 5008,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsAdaptiveForceIcon.png",
                    "name": "공격",
                    "shortDesc": "적응형 능력치 +9"
                },
                {
                    "id": 5005,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsAttackSpeedIcon.png",
                    "name": "공격",
                    "shortDesc": "공격 속도 +10%"
                },
                {
                    "id": 5007,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsCDRScalingIcon.png",
                    "name": "공격",
                    "shortDesc": "스킬 가속 +8"
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 5008,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsAdaptiveForceIcon.png",
                    "name": "공격",
                    "shortDesc": "적응형 능력치 +9"
                },
                {
                    "id": 5002,
                    "key": "Flex",
                    "icon": "perk-images/StatMods/StatModsArmorIcon.png",
                    "name": "유연",
                    "shortDesc": "방어력 +6"
                },
                {
                    "id": 5003,
                    "key": "Flex",
                    "icon": "perk-images/StatMods/StatModsMagicResIcon.MagicResist_Fix.png",
                    "name": "유연",
                    "shortDesc": "마법 저항력 +8"
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 5001,
                    "key": "Defence",
                    "icon": "perk-images/StatMods/StatModsHealthScalingIcon.png",
                    "name": "방어",
                    "shortDesc": "레벨에 따라 체력 +15 ~ 140",
                },
                {
                    "id": 5002,
                    "key": "Flex",
                    "icon": "perk-images/StatMods/StatModsArmorIcon.png",
                    "name": "유연",
                    "shortDesc": "방어력 +6"
                },
                {
                    "id": 5003,
                    "key": "Flex",
                    "icon": "perk-images/StatMods/StatModsMagicResIcon.MagicResist_Fix.png",
                    "name": "유연",
                    "shortDesc": "마법 저항력 +8"
                }
            ]
        }

    ]
}

export default runeShards;