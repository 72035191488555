import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {getAIScore, getHighRankChampion, getKda, getLineRank,} from '../../function';
import InfoAIScore from "./Info/AIScore";
import InfoAiPrediction from "./Info/AiPrediction";
import MostPickChamp from "./Info/MostPickChamp";
import MostLane from "./Info/MostLane";
import styled from "styled-components";
import SummaryContentWinrate from "./Summary/SummaryContent/Winrate";
import SummaryContentKDA from "./Summary/SummaryContent/KDA";

// Most 생성자 함수
class Most {
    constructor(games = 0, win = 0, lose = 0, kills = 0, deaths = 0, assists = 0, ai_score = 0) {
        this.games = games;
        this.win = win;
        this.lose = lose;
        this.kills = kills;
        this.deaths = deaths;
        this.assists = assists;
        this.ai_score = ai_score;

        return this;
    }
}

const getdata = (gameArr) => {
    let gameData = {
        win: 0, // 이긴게임
        lose: 0, // 진게임
        rate: 0, // 승률
        kills: 0, // 킬
        deaths: 0, // 데스
        assists: 0, // 어시스트
        kda: 0, // KDA
        lane_per_score: 0, // 라인파워
        ai_score: 0, // AI Score
        ai_score_win: 0, // 이겼을때 AI Score
        ai_score_lose: 0, // 졌을때 AI Score
        rating: 0, // 순위
        champ: [], // 모스트 챔피언
        lane: [], // 모스트 라인
        mostChamp1: new Most(),
        mostChamp2: new Most(),
        mostLane1: new Most(),
        mostLane2: new Most(),
    };

    if (gameArr !== undefined) {
        // 최근 10경기만
        let last10Arr = gameArr.length > 10 ? gameArr.slice(-10) : gameArr;
        for (const arrData of last10Arr) {
            // 게임전적 합계
            const match = arrData.final_stat_dict;
            const lane = arrData.lane_stat_dict;
            arrData.is_win ? gameData.win++ : gameData.lose++;
            arrData.is_win
                ? (gameData.ai_score_win += Number(match.ai_score.toFixed(0)))
                : (gameData.ai_score_lose += Number(match.ai_score.toFixed(0)));
            gameData.kills += match.kills;
            gameData.deaths += match.deaths;
            gameData.assists += match.assists;
            gameData.ai_score +=  Number(match.ai_score.toFixed(0));
            gameData.rating += match.rating;
            gameData.lane_per_score += Number(lane.ai_score.toFixed(0));

            // 모스트 챔피언/라인
            gameData.champ.push(arrData.champion_id);
            gameData.lane.push(arrData.position);
        }

        // 게임전적 평균
        gameData.rate = parseFloat(((gameData.win / last10Arr.length) * 100).toFixed(0));
        gameData.kills = parseFloat((gameData.kills / last10Arr.length).toFixed(1));
        gameData.deaths = parseFloat((gameData.deaths / last10Arr.length).toFixed(1));
        gameData.assists = parseFloat((gameData.assists / last10Arr.length).toFixed(1));
        gameData.kda = getKda(gameData.kills, gameData.deaths, gameData.assists);
        gameData.ai_score = parseFloat((gameData.ai_score / last10Arr.length).toFixed(0));
        gameData.ai_score_win = parseFloat((gameData.ai_score_win / gameData.win).toFixed(0));
        gameData.ai_score_lose = parseFloat((gameData.ai_score_lose / gameData.lose).toFixed(0));
        gameData.rating = parseFloat((gameData.rating / last10Arr.length).toFixed(1));
        gameData.lane_per_score = parseFloat((gameData.lane_per_score / last10Arr.length).toFixed(1));

        // 모스트 챔피언/라인 평균
        gameData.champ = getHighRankChampion(last10Arr);
        gameData.lane = getLineRank(last10Arr);


        for (const match of last10Arr) {
            // 모스트 챔피언 1
            if (match.champion_id === Number(gameData.champ[0])) {
                gameData.mostChamp1.championId = match.champion_id;
                // 게임수
                gameData.mostChamp1.games++;
                // 승패
                match.is_win ? gameData.mostChamp1.win++ : gameData.mostChamp1.lose++;
                // KDA
                gameData.mostChamp1.kills += match.kills;
                gameData.mostChamp1.deaths += match.deaths;
                gameData.mostChamp1.assists += match.assists;
                // AI Score
                gameData.mostChamp1.ai_score += Number(match.final_stat_dict.ai_score.toFixed(0));
            }
            // 모스트 챔피언 2

            if (match.champion_id === Number(gameData.champ[1])) {
                gameData.mostChamp2.championId = match.champion_id;
                // 게임수
                gameData.mostChamp2.games++;
                // 승패
                match.win ? gameData.mostChamp2.win++ : gameData.mostChamp2.lose++;
                // KDA
                gameData.mostChamp2.kills += match.kills;
                gameData.mostChamp2.deaths += match.deaths;
                gameData.mostChamp2.assists += match.assists;
                // AI Score
                gameData.mostChamp2.ai_score += Number(match.final_stat_dict.ai_score.toFixed(0));
            }
            // 모스트 라인 1
            if (match.position === gameData.lane[0]) {
                gameData.mostLane1.lane = match.position;
                // 게임수
                gameData.mostLane1.games++;
                // 승패
                match.win ? gameData.mostLane1.win++ : gameData.mostLane1.lose++;
                // KDA
                gameData.mostLane1.kills += match.kills;
                gameData.mostLane1.deaths += match.deaths;
                gameData.mostLane1.assists += match.assists;
                // AI Score
                gameData.mostLane1.ai_score += Number(match.final_stat_dict.ai_score.toFixed(0));
            }
            // 모스트 라인 2
            if (match.position === gameData.lane[1]) {
                gameData.mostLane2.lane = match.position;
                // 게임수
                gameData.mostLane2.games++;
                // 승패
                match.win ? gameData.mostLane2.win++ : gameData.mostLane2.lose++;
                // KDA
                gameData.mostLane2.kills += match.kills;
                gameData.mostLane2.deaths += match.deaths;
                gameData.mostLane2.assists += match.assists;
                // AI Score
                gameData.mostLane2.ai_score += Number(match.final_stat_dict.ai_score.toFixed(0));
            }
        }
        gameData.mostChamp1.rate = parseFloat(
            ((gameData.mostChamp1.win / gameData.mostChamp1.games) * 100).toFixed(0)
        );
        gameData.mostChamp2.rate = parseFloat(
            ((gameData.mostChamp2.win / gameData.mostChamp2.games) * 100).toFixed(0)
        );
        gameData.mostLane1.rate = parseFloat(
            ((gameData.mostLane1.win / gameData.mostLane1.games) * 100).toFixed(0)
        );
        gameData.mostLane2.rate = parseFloat(
            ((gameData.mostLane2.win / gameData.mostLane2.games) * 100).toFixed(0)
        );
        gameData.mostChamp1.ai_score = parseFloat(
            (gameData.mostChamp1.ai_score / gameData.mostChamp1.games).toFixed(0)
        );
        gameData.mostChamp2.ai_score = parseFloat(
            (gameData.mostChamp2.ai_score / gameData.mostChamp2.games).toFixed(0)
        );
        gameData.mostLane1.ai_score = parseFloat(
            (gameData.mostLane1.ai_score / gameData.mostLane1.games).toFixed(0)
        );
        gameData.mostLane2.ai_score = parseFloat(
            (gameData.mostLane2.ai_score / gameData.mostLane2.games).toFixed(0)
        );
        gameData.mostChamp1.kda = parseFloat(
            getKda(gameData.mostChamp1.kills, gameData.mostChamp1.deaths, gameData.mostChamp1.assists)
        );
        gameData.mostChamp2.kda = parseFloat(
            getKda(gameData.mostChamp2.kills, gameData.mostChamp2.deaths, gameData.mostChamp2.assists)
        );
        gameData.mostLane1.kda = parseFloat(
            getKda(gameData.mostLane1.kills, gameData.mostLane1.deaths, gameData.mostLane1.assists)
        );
        gameData.mostLane2.kda = parseFloat(
            getKda(gameData.mostLane2.kills, gameData.mostLane2.deaths, gameData.mostLane2.assists)
        );
    }

    if (gameData.mostChamp1.games === gameData.mostChamp2.games) {
        if (gameData.mostChamp1.ai_score < gameData.mostChamp2.ai_score) {
            const copyData = {...gameData.mostChamp1};
            gameData.mostChamp1 = {...gameData.mostChamp2};
            gameData.mostChamp2 = {...copyData};
        }
    }

    return gameData;
};


const Wrapper = styled.div`
  display: flex;
  width: 570px;
  border-bottom-left-radius: 6px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FlexRow = styled.div`
  display: flex;
  flex: 1 1 100%;
`;

const FlexOneWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right: 1px solid #C3D1DC;
  justify-content: center;
`;

const SecondRow = styled.div`
  display: flex;
  flex: 1 1 100%;
  border-top: 1px solid #C3D1DC;
`;


const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;


const Summary = ({gameArr, queueNum, summoner, matchInfo, region}) => {
    const {t} = useTranslation();
    const [summary, setSummary] = useState(gameArr.length > 0 ? getdata(gameArr) : {});


    useEffect(() => {
        if (gameArr.length > 0) {
            setSummary(getdata(gameArr));
        }
    }, [gameArr]);



    return (
        <Wrapper>
            {summary.ai_score && !isNaN(summary.ai_score) ? (
                <>
                    <InfoWrapper>
                        <FlexRow>
                            <FlexOneWrapper>
                                <SummaryContentWinrate
                                    lost={summary.lose}
                                    win={summary.win}
                                    title={t('summoner.winrate')}
                                    rate={summary.rate}
                                />
                                <SummaryContentKDA
                                    title={"KDA"}
                                    kda={summary.kda}
                                    kill={summary.kills}
                                    assist={summary.assists}
                                    death={summary.deaths}
                                />
                            </FlexOneWrapper>
                            <FlexOneWrapper>
                                <ColumnBox>
                                    <InfoAiPrediction
                                        region={region}
                                        matchInfo={matchInfo}
                                        queueNum={queueNum}
                                        summoner={summoner}
                                        summary={summary}
                                    />
                                </ColumnBox>
                                <ContentWrapper>
                                    <InfoAIScore
                                        win={summary.win}
                                        lose={summary.lose}
                                        aiScore={summary.ai_score}
                                        aiLose={summary.ai_score_lose}
                                        aiWin={summary.ai_score_win}
                                    />
                                </ContentWrapper>
                            </FlexOneWrapper>
                        </FlexRow>
                        <SecondRow>
                            <FlexOneWrapper>
                                <ContentWrapper>
                                    <MostPickChamp
                                        region={region}
                                        queueNum={queueNum}
                                        summoner={summoner}
                                        summary={summary}
                                    />
                                </ContentWrapper>
                            </FlexOneWrapper>
                            <FlexOneWrapper>
                                <ContentWrapper>
                                    <MostLane
                                        region={region}
                                        queueNum={queueNum}
                                        summoner={summoner}
                                        summary={summary}
                                    />
                                </ContentWrapper>
                            </FlexOneWrapper>
                        </SecondRow>
                    </InfoWrapper>
                </>
            ) : (
                <>
                    <div className="no_data">{t('notice.noGame')}</div>
                </>
            )}
        </Wrapper>
    );
};

export default React.memo(Summary);
