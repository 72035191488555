import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {NoticeContainer} from '../../styled/CommonStyle';
import {useSelector} from "react-redux";
import styled from "styled-components";

const ErrorText = styled.p`
  color: white;
`;

const VisibleP = styled.p`
    display: ${props => {
        let display = 'none';
        if(props.visible) display = 'block';
        return display;
    }};
  
  animation: fadeIn 1300ms;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;

    }
    to {
      opacity: 0;
    }
  }
`;


const Notice = ({fixed, onlyNotice = false}) => {
    const [noticeNumber, setNoticeNumber] = useState(0);
    const {t} = useTranslation();

    const contentList = [{
        title: `🏆 ${t('notice.text')}`,
        link: 'https://blog.deeplol.gg/41',
    }, {
        title: `🔔 ${t('notice.text2')}`,
        link: 'https://blog.deeplol.gg/30',
    }]

    useEffect(() => {
        const interval = setInterval(() => {
            setNoticeNumber(prev => {
                if (prev >= contentList.length - 1) {
                    return 0;
                } else {
                    return prev + 1;
                }
            });
        }, 4000);

        return () => clearInterval(interval);
    }, []);


    return (
        <NoticeContainer fixed={fixed}>
            {contentList.map((data, index) => {
                    return (
                        <VisibleP className="link" visible={index === noticeNumber} key={index}>
                            <Link to={{pathname: data.link}} target="_blank">
                                {data.title}
                            </Link>
                        </VisibleP>
                    )
            })}
        </NoticeContainer>
    )
};

export default Notice;
