import React from 'react';
import styled from "styled-components";

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 3px 5px;
`;

const Th = styled.th`
  background-color: #DDEBE2;
  height: 44px;
  vertical-align: middle;
`;

const AbsoluteSpliter = styled.div`
  position: absolute;
  right: 0;
  width: 1px;
  height: 8px;
  background-color: #A5BED2;
`;

const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  word-wrap: break-word;
  white-space: pre;
`;

const RankingTableHeader = (props) => {
    const {children, last} = props;

    if (typeof children !== 'string') return null;

    return (
        <Th>
            <Cell>
                <Title>{children}</Title>
                {!last && <AbsoluteSpliter/>}
            </Cell>
        </Th>
    );
};

export default RankingTableHeader;
