import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {bookmarkList} from '../../store/modules/bookmarkList';
import {getGameTime, getRegion, prevTierAndDivision} from '../../function';
import {ProfileContainer} from '../../styled/SummonerStyle';
import SplashImage from './SplashImage';
import styled from "styled-components";
import InfluInfo from "./Profile/InfluInfo";
import {useCountUp} from "react-countup";
import {UPDATE_SUMMONER_INFO_REQUEST} from "../../store/reducer/summoner";
import ErrorBox from "../common/Error";
import {SSEFix} from "../../common/moduleFork/sseFix";
import {_NOTICE} from "../../store/modules/notice";
import SummonerDict from "../common/SummonerDict";
import backup_champions from "../../store/static_json/champions";

const Wrapper = styled.div`
  opacity: 1;
  display: flex;
  flex: 1;
  z-index: 9999;
`;

const ExtraWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ImageWrapper = styled.button`
  display: flex;
  align-items: center;
  opacity: 1;
  z-index: 999;
`;

const ChampionBannerImage = styled.img`
  border-radius: 6px;
`;

const InfluWrapper = styled.div`
  min-height: 14px;
  display: flex;
  margin-top: 6px;
`;

const AbsoluteBox = styled.div`
  position: absolute;
  width: 300px;
  bottom: 100px;
  z-index: 999999;

  .errorStyle {
    z-index: 999999;
    padding: 14px 0;
  }
`;

const TextWrapper = styled.div`
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-block: 2px;
`;

const RedText = styled.p`
  margin-left: 6px !important;
  color: #FFA5A5;
`;

const UpdateText = styled.p`
  margin-left: 6px !important;
  color: #313131;
`;



const Profile = ({region, updatePer, userName, setUpdatePer, handleTab, queueNum}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const countUpRef = useRef(null);
    const dummyRef = useRef(null);
    const version = useSelector((state) => state.gameVersion.version);
    const currentSeason = useSelector((state) => state.imageStore.currentSeason);
    const [testValue, setTextValue] = useState(100);
    const [openError, setOpenError] = useState(false);
    const {summoner, updateStatus, summonerStatus} = useSelector((state) => state?.summoner);
    const [updateError, setUpdateError] = useState(0);


    const handleUpdate = () => {
        // const currentSocket = useSocket.connectTo();
        setUpdatePer(0);
        setTextValue(0);
        reset();

        let retry = 3;
        const updateObj = {
            "platform_id": getRegion(region),
            "puu_id": summoner?.summoner_basic_info_dict?.puu_id,
            "season": currentSeason,
            "queue_type": queueNum,
            "start_idx": 0,
            "count": 20
        }


        const eventStream = new SSEFix(`${process.env.REACT_APP_NEW_API}match/matches`, {
            payload: JSON.stringify(updateObj),
        });

        eventStream.addEventListener("error", (e) => {
            const data = e?.detail?.data;

            if (data) {
                if (JSON.parse(data).status === 1) {
                    update(100);
                    setUpdateError(1);
                    setTextValue(100);
                }


                if (JSON.parse(data).status === 2 && retry) {
                    setTimeout(() => {
                        eventStream.stream();
                    }, 1000);
                    retry--;
                } else if (!retry) {
                    update(100);
                    setTextValue(100);
                    setUpdateError(1);
                }

            } else {
                eventStream.close();
            }
        });


        eventStream.addEventListener("message", (e) => {
            // Fallback handler for undefined event types

            const percent = e.data;
            if (percent) {
                setUpdatePer(percent);
                update(percent);
            }

            if (Number(e.data) === 100) {

                DispatchToUpdate();
                eventStream.close();
            }
        });

        eventStream.stream();
    };

    const counterCallback = useCallback((value, reset) => {
        setTextValue(value);

        if (updateError) {
            return t('buttons.updated');
        }
        if (value === 100) {
            return t('buttons.updated');
        } else {
            return value + '%'
        }
    }, [updateError]);

    const DispatchToUpdate = useCallback(() => {
        dispatch({
            type: UPDATE_SUMMONER_INFO_REQUEST,
            data: {
                summonerName: userName,
                region: region,
                queue_id: queueNum,
                currentSeason: currentSeason,
            }
        });
        update(100);
        setUpdateError(0);
    }, [userName, region, queueNum, currentSeason])

    const {start, pauseResume, reset, update} = useCountUp({
        ref: countUpRef,
        start: 0,
        end: 100,
        startOnMount: false,
        delay: 0,
        formattingFn: counterCallback,
    });


    const bookmarkslength = useSelector((state) => state?.bookmarkList?.bookmarks?.length);
    const bookmarks = useSelector((state) => {
        let arr = state?.bookmarkList.bookmarks.filter((item) => item?.summoner_basic_info_dict?.summoner_id === summoner?.summoner_basic_info_dict?.summoner_id);
        return arr.length > 0
    });
    const currentLang = useSelector((state) => state?.translation?.lang);

    // 즐겨찾기 버튼
    const toggleBookmark = () => {
        if (bookmarks) {
            dispatch(bookmarkList(summoner, 'remove'));
        } else {
            if (bookmarkslength > 11) {
                opencloseError()
            } else {
                dispatch(bookmarkList({...summoner, region: region}, 'add'));
            }

        }
    };

    const onClickChampionBanner = useCallback(() => {
        handleTab(1);
    }, []);

    const opencloseError = () => {
        setOpenError(true);
        setTimeout(() => {
            setOpenError(false);
        }, 2000);
    };


    const summonerBasic = useMemo(() => summoner?.summoner_basic_info_dict, [summoner]);
    return (
        <ProfileContainer>
            {Object.keys(summonerBasic).length > 0 &&
                <>
                    {/*<div className="p__bg">*/}
                    {/*    <SplashImage summonerStat={summonerStatus}/>*/}
                    {/*</div>*/}
                    <div className="p__tier">
                        <SummonerDict
                            dict={summonerBasic.previous_season_tier_list}
                        />
                    </div>
                    <Wrapper>
                        <div className="p__info">
                            <div className="icon">
                                <img
                                    src={`${process.env.REACT_APP_IMG}${version}/img/profileicon/${summonerBasic?.profile_id}.png`}
                                    alt={summoner && summonerBasic?.profile_id}
                                    width={97}
                                    height={97}
                                />
                                <p>{summonerBasic?.level}</p>
                            </div>
                            <div className="text">
                                {/*{summoner?.pro_info.team !== '-' && (*/}
                                {/*    <span className="pro">{summoner?.pro_info.team} {summoner?.pro_info?.name}</span>*/}
                                {/*)}*/}
                                <div className="name">
                                    <p>{summonerBasic?.summoner_name}</p>
                                    <div className="favorite">
                                        {openError &&
                                            <AbsoluteBox>
                                                <ErrorBox
                                                    infoText={t('error.favorite_excess')}
                                                />
                                            </AbsoluteBox>}
                                        <button className={`${bookmarks ? 'on' : 'off'}`} onClick={toggleBookmark}>
                                            {t('buttons.favorite')}
                                        </button>
                                    </div>
                                </div>
                                <InfluWrapper>
                                    {summoner?.pro_streamer_info?.status !== '-' &&

                                        <InfluInfo
                                            influData={summonerBasic?.pro_streamer_info_dict}
                                        />
                                    }
                                </InfluWrapper>
                                <div className="update">
                                    <button disabled={testValue !== 100} onClick={handleUpdate}
                                                  updateValue={testValue}>
                                        {testValue === 100 &&
                                            <img src={'/images/ico_refresh.svg'} width={14} height={14}
                                                 style={{marginRight: 4}}/>}
                                        {testValue !== 100 && <i className="fill" style={{width: testValue + '%'}}></i>}
                                        {updateError > 0 ? <span>{t('buttons.updated')}</span> :
                                            <span ref={countUpRef}>{t('buttons.updated')}</span>
                                        }
                                        {/*<span>{`${updatePer !== 100 ? updatePer + '%' : `}</span>*/}
                                    </button>
                                    <TextWrapper>
                                        <RedText>
                                            {updateError ? t('notice.updateError') : " "}
                                        </RedText>
                                        <UpdateText>
                                            {t('summoner.lastUpdate')}:{' '}
                                            <span>{updateStatus ? getGameTime(updateStatus.updated_timestamp, currentLang) : '-'}</span>
                                        </UpdateText>
                                    </TextWrapper>
                                </div>
                            </div>
                            <ExtraWrapper>
                                <ImageWrapper
                                    onClick={onClickChampionBanner}
                                >
                                    <ChampionBannerImage
                                        width={390}
                                        height={84}
                                        src={
                                            currentLang === 'KOR' ?
                                                './images/img_banner_top01_kr.jpg'
                                                :
                                                './images/img_banner_top01_en.jpg'}
                                    />
                                </ImageWrapper>
                            </ExtraWrapper>
                        </div>
                    </Wrapper>
                </>
            }
        </ProfileContainer>
    );
};

export default React.memo(Profile);
