import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import ChampTable from './ChampTable';
import {Loading} from '../../components';
import {sortChampData} from '../../function';
import {ChampContainer} from '../../styled/SummonerStyle';
import Cateogry from "../common/Category";
import styled from "styled-components";
import ChampInfo from "./Champ/ChampInfo";
import useInput from "../../common/hooks/useInput";
import ChampFilter from "./Champ/ChampFilter";
import {LoadingContainer} from "../../styled/CommonStyle";
import ChampRenderTable from "./Champ/ChampRenderTable";
import ChampRenderBy from "./Champ/ChampRenderBy";


const CategoryWrapper = styled.div`
  padding: 12.5px 20px 7.5px;
  border-bottom-width: 1px;
  border-bottom-color: #A5BED2;
  border-style: solid;
`;

const InfoWrapper = styled.div`
  margin: 10px 10px 0 20px;
`;

const LoadingWrapper = styled.div`
  background: #fff;
  height: 300px;
  border-radius: 6px;
`;



const Champ = ({region}) => {
    const {t} = useTranslation();
    const champions = useSelector((state) => state.imageStore.champions);
    const maxvalueSeason = useSelector((state) => state.imageStore.currentSeason);
    const [categorySelected, setCategorySelected] = useState(0);
    const [seasonSelected,setSeasonSelected] = useState(maxvalueSeason ? maxvalueSeason : 13);
    const currentLang = useSelector((state) => state.translation.lang);
    const {
        summonerStatus,
        getSummonerInfoLoading,
        getChampionsBySeasonLoading,
        getSummonerInfoDone,
    } = useSelector((state) => state.summoner);


    const onClickCategory = useCallback((index) => {
        if (typeof index === 'number') {
            setCategorySelected(index);
        }
    }, []);

    const champCategory = [{
        title: t('champions.category.myChamp'),
        key: 'myChamp',
        info: t('champions.info.myChamp'),
        extra: t('champions.info.myChampExtra'),
        value: 'counter_champion_stats',
        isNew: false,
    }, {
        title: t('champions.category.enemyChamp'),
        key: 'enemyChamp',
        info: t('champions.info.enemyChamp'),
        extra: t('champions.info.enemyChampExtra'),
        value: 'counter_champion_stats_reverse',
        isNew: false,
    }, {
        title: t('champions.category.synergy'),
        key: 'synergyChamp',
        info: t('champions.info.synergy'),
        extra: t('champions.info.synergyExtra'),
        value: 'synergy_champion_stats',
        isNew: true,
    }];


    if (getSummonerInfoLoading) return (
        <ChampContainer>
            <LoadingWrapper>
                <Loading/>
            </LoadingWrapper>
        </ChampContainer>)


    return (
        <ChampContainer>
            <CategoryWrapper>
                <Cateogry
                    onClickCategory={onClickCategory}
                    selected={categorySelected}
                    list={champCategory}
                />
            </CategoryWrapper>
            <InfoWrapper>
                <ChampInfo
                    extra={champCategory[categorySelected].extra}
                    infoText={champCategory[categorySelected].info}
                />
            </InfoWrapper>
            {(getSummonerInfoLoading || getChampionsBySeasonLoading) ? (
                <Loading/>
            ) : (
                <ChampRenderBy
                    selectedSeason={seasonSelected}
                    onChangeSelectedSeason={setSeasonSelected}
                    currentCategory={categorySelected}
                    champCategory={champCategory}
                />

            )}
        </ChampContainer>
    );
};

export default Champ;
