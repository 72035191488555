import React, {useCallback} from 'react';
import styled from "styled-components";

const NameLI = styled.li`
  min-width: 23px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
`;

const NameText = styled.span`
  width: auto;
  font-size: 12px;
  padding: 0.2rem;
  margin-inline: 2.4px;
  color: ${props => {
    let color = '#000';
    if (props.selected) color = '#fff';
    return color;
}};
  line-height: 10px;

  background-color: ${props => {
    let color = 'transparent';
    if (props.selected) color = '#5F57A5';
    return color;
}};
  border: 1px solid ${props => {
    let borderColor = 'transparent';
    if (props.selected) borderColor = '#353063';
    return borderColor;
}};
  border-radius: 4px;
`;

const CNameLI = ({data, index, onClick, selected}) => {
    const onClickLi = useCallback(() => {
        if (index !== undefined && onClick !== undefined) {
            onClick(index,data);
        }
    }, [])

    return (
        <NameLI selected={selected} onClick={onClickLi}>
            <NameText selected={selected}>{data}</NameText>
        </NameLI>
    );
};

export default React.memo(CNameLI);