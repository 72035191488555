import {all, fork} from 'redux-saga/effects';
import summonerSaga from "./summoner";
import championsSaga from "./champions";
import rankingSaga from "./ranking";


export function* rootSaga() {
    // noinspection JSCheckFunctionSignatures
    yield all([
        fork(summonerSaga),
        fork(championsSaga),
        fork(rankingSaga),
    ])
}
