import React from 'react';
import BigTooltip from "../../Tooltip/BigTooltipBox";
import styled from "styled-components";

const ReletiveDiv = styled.div`
  position: relative;
  margin: ${props => props.margin || 0};
  border: 1px solid #0C0C0C;
  animation: skeleton-gradient 1.8s infinite ease-in-out;
  @keyframes skeleton-gradient {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }

    50% {
      background-color: rgba(165, 165, 165, 0.5);
    }

    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }
`;

const ChampionImage = styled.img``;

const AbsoluteSpan = styled.span`
  text-align: center;
  width: 2.5ch;
  position: absolute;
  padding: 2px;
  font-size: 12px;
  color: #fff;
  background-color: #232734;
  bottom: 0;
  right: 0;
`;

const ChampionPassiveImage = ({tooltip, version, img, absoluteText, width = 36, height = 36, margin,reverse}) => {
    return (
        <ReletiveDiv margin={margin}>
            <BigTooltip
                reverse={reverse}
                tooltipText={`${tooltip}`}>
                <ChampionImage
                    loaindg={'lazy'}
                    width={width}
                    height={height}
                    src={`${process.env.REACT_APP_IMG}${version}/img/passive/${img}`}
                />
                {absoluteText && <AbsoluteSpan>{absoluteText}</AbsoluteSpan>}
            </BigTooltip>
        </ReletiveDiv>
    );
};

export default ChampionPassiveImage;
