import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import RegionTag from "./RegionTag";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {changeRegion} from "../../../store/modules/region";
import AutoComplete from "./AutoComplete";
import regionList from "../../../store/static_json/regionList";

const Wrapper = styled.div`
  width: 620px;
`;

const AutoCompleteWrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #6B78B5;
  border-radius: 6px;
  padding: 8px 12px;
  margin-bottom: 12px;
`;

const SearchWrapper = styled.div`
  cursor: pointer;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;


const SearchInput = styled.input`
  width: 100%;
  border: 0;
  background-color: transparent;

  ::placeholder {
    opacity: 0.6;
  }
`;

const AbsoluteWrapper = styled.div`
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 110%;
`;


const NewSearch = () => {
    const {t} = useTranslation();
    const region = useSelector((state) => state.region.result);
    const [summonerName, setSummonerName] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const absoluteRef = useRef(null);
    const inputRef = useRef(null);
    const initRef = useRef(false);
    const [isFocused, setIsFocused] = useState(true);

    const onClickRegionClick = useCallback((title) => {
        dispatch(changeRegion(title));
    }, []);

    const onClickSearch = useCallback(() => {
        const regExp = /(joined the room.)|(joined the lobby.)|로비에|(がロビーに参加しました)|,|(,\s),/gi;
        if (regExp.test(summonerName) === false) {
            // 전적검색으로
            if (summonerName !== "") {
                history.push({
                    pathname: `/summoner/${region}/${summonerName}`,
                    state: {name: summonerName},
                });
            }
            // history.go(0);
        } else {
            // 멀티서치로
            let arr_str = summonerName.replace(/(joined the room.)|(joined the lobby.)|(joined the lobby)|(님이 로비에 참가했습니다.)|(님이 로비에 참가하셨습니다.)|(がロビーに参加しました)|(,\s)|,|\n|\r/gi, '/');
            arr_str = arr_str.replace(/(\s\/)+|(\s\/)/gi, '/');
            arr_str = arr_str.replace(/(\/\s)|(\/)+/gi, '/');
            arr_str = arr_str.replace(/\/$/gi, '');
            arr_str = arr_str.split('/');

            // 중복, 빈값 제거
            const set = new Set(arr_str);
            const uniqueArr = [...set];
            const modyArr = uniqueArr.filter((item) => item !== '').slice(0, 5);

            history.replace({
                pathname: `/multi/${region}/${modyArr}`,
                state: {name: modyArr},
            });

        }
    }, [summonerName]);

    const onKeyPressEnter = useCallback((e) => {
        if (e.key === 'Enter') {
            onClickSearch();
        }
    }, [onClickSearch]);

    const onChangeSummonerName = useCallback((e) => {
        setSummonerName(e.target.value);
    }, []);

    const onChangeFocusOn = useCallback(() => {
        setIsFocused(true)
    }, []);
    const onChangeFocusOut = useCallback((e) => {
        if (isFocused && absoluteRef.current && !absoluteRef.current.contains(e.target) && initRef.current === true) {
            setIsFocused(false);
        }
        initRef.current = true;
    }, [isFocused]);


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            document.addEventListener("mousedown", onChangeFocusOut,true)
            return () => {
                document.removeEventListener("mousedown", onChangeFocusOut,true)
            }
        }
    }, []);


    return (
        <Wrapper ref={absoluteRef}>
            <AutoCompleteWrapper>
                <InputWrapper>
                    <SearchInput
                        ref={inputRef}
                        onFocus={onChangeFocusOn}
                        placeholder={t('search.placeholder')}
                        onKeyPress={onKeyPressEnter}
                        onChange={onChangeSummonerName}
                    />
                    <SearchWrapper onClick={onClickSearch}>
                        <img
                            src={'/images/ico_search_w.svg'}
                            width={18}
                            height={18}
                            alt={''}
                        />
                    </SearchWrapper>
                </InputWrapper>
                <AbsoluteWrapper>
                    <AutoComplete
                        focus={isFocused}
                        region={region}
                        summonerName={summonerName}
                    />
                </AbsoluteWrapper>
            </AutoCompleteWrapper>
            <TagWrapper>
                {regionList.map((data, index) => {
                    return (
                        <RegionTag
                            key={index}
                            height={'28px'}
                            width={'48px'}
                            onClick={onClickRegionClick}
                            selected={region === data.title}
                            isDisabled={data.disabled}
                            title={data.title}
                        />
                    )
                })}
            </TagWrapper>
        </Wrapper>
    );
};

export default NewSearch;
