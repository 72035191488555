import React, {useCallback, useMemo, useState} from 'react';
import styled from "styled-components";
import RankListLI from "./RankListLI";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  height: 52px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const ListUL = styled.ul`
  display: flex;
  flex-direction: row;
  flex:1;
  justify-content: space-around;
  outline: 1px solid #A5BED2;
  outline-offset: -1px;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 5px;
`;



const ChampionsRankLaneSelect = ({onClick}) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState(0);
    const list = useMemo(() => [ t('championAnalyze.top'), t('championAnalyze.jungle'), t('championAnalyze.mid'), t('championAnalyze.bot'), t('championAnalyze.support')], [t]);
    const laneName = useMemo(() => ['Top','Jungle','Middle','Bot','Supporter'],[])


    const onClickLi = useCallback((value,lane) => {
        if (value !== undefined && lane !== undefined) {
            setSelected(value);
            onClick(value,lane)
        }
    }, []);


    return (
        <Wrapper>
            <ListUL>
                {list.map((data, index) => {
                    return (
                        <RankListLI
                            lane={laneName[index]}
                            last={list.length === (index+1)}
                            key={data+"laneData"}
                            data={data}
                            index={index}
                            onClick={onClickLi}
                            selected={selected === index}
                        />
                    )
                })}
            </ListUL>
        </Wrapper>
    );
};

export default ChampionsRankLaneSelect;