import React from 'react';
import styled from "styled-components";


const IndicatorWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;
const Indicator = styled.div`
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #C4C4C4;
`;

const IndicatorBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  transform: translateY(60%);
`;
const IndicatorBar = styled.div`
  height: 5px;
  flex: ${props => props.flex};
  background-color: #0DBAAE;
  transform: translateX(${props => props.translateX + 'px'});
  transition: transform 300ms ease-in-out;
`;

const Wrapper = styled.div`
  max-width: 240px;
  flex: 1;
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: row;
  transform: translateY(-50%);
  background-color: transparent;
  z-index: 999;
`;

const AbsoluteFlexButton = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  background-color: transparent;
`;


const BannerIndicator = ({currentIndicate, length, slickRef}) => {
    const indicatorWidth = 240;
    const lengthDivided = indicatorWidth / length;

    const flexButtonClick = (index) => {
        if (slickRef && slickRef.current) {
            slickRef.current.slickGoTo(index, false);
        }

    };

    return (
        <IndicatorWrapper>
            <Wrapper>
                <IndicatorBarWrapper
                >
                    <AbsoluteWrapper>
                        {new Array(length).fill('').map((_, index) => (
                            <AbsoluteFlexButton
                                key={index + 'indicator'}
                                onClick={() => flexButtonClick(index)}
                            >&nbsp;</AbsoluteFlexButton>
                        ))}
                    </AbsoluteWrapper>
                    <IndicatorBar
                        translateX={lengthDivided * currentIndicate}
                        flex={1 / length}
                    />
                </IndicatorBarWrapper>
                <Indicator/>
            </Wrapper>
        </IndicatorWrapper>

    );
};

export default BannerIndicator;