import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  background-color: #F9F9FA;
  border: 1px solid #C3D1DC;
  padding: 13px;
  margin: 9px 0;
`;

const TitleText = styled.span`
  color: #313131;
  font-size: 16px;
  font-weight: 500;
`;

const ChampionsHeader = ({title, children}) => {
    return (
        <Wrapper>
            <TitleText>{title}</TitleText>
            {children}
        </Wrapper>
    );
};



export default React.memo(ChampionsHeader);
