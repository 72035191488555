import React, {useCallback, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {useTranslation} from 'react-i18next';

import DetailShareTag from "./MatchDetail/DetailShareTag";
import styled from "styled-components";
import {Loading} from "../common";
import DetailHideSummonerName from "./MatchDetail/DetailHideSummonerName";

const GameAnalytics = React.lazy(() => import("./DetailTab/GameAnalytics"));
const DetailTab2 = React.lazy(() => import('./DetailTab2'));

const TabHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 16px;
`;

const HeaderWrapper = styled.div`
  display: ${props => props.visible};
  background-color: #e4e7f2;
  border-top-width: 3px;
  border-style: solid;
  border-color: ${props => props.borderColor};

  .react-tabs {
    &__tab-list {
      display: flex;
      align-items: center;
      margin: 10px 18px 0;
    }

    &__tab {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 35px;
      background-color: #d7dae5;
      border: 1px solid #9ca1b2;
      font-size: 16px;
      margin-bottom: -1px;
      border-bottom: 1px solid #9ca1b2;
      color: rgba(49, 49, 49, 0.5);
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 8px;
        background: transparent linear-gradient(0deg, #00000000 0%, #000000 100%);
        opacity: 0.1;
        transform: matrix(-1, 0, 0, -1, 0, 0);
      }

      &--selected {
        background: #FFFFFF;
        color: #313131;
        z-index: 999;
        margin-bottom: -1px;
        border-bottom: 1px solid white;

        &::before {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          display: block;
          width: 100%;
          height: 3px;
          background: linear-gradient(90deg, #10d9b9 0%, #2765ce 100%);
        }

        &::after {
          height: 1px;
          background: #fff;
        }
      }

      & + .react-tabs__tab {
        margin-left: 7px;
      }
    }

    &__tab-panel {
      display: none;
      border-top: 1px solid #9ca1b2;
      background-color: #fff;

      &--selected {
        display: block;
      }
    }
  }
`;

const LoadingWrapper = styled.div`
  height: 528px;
`;

const ConsWrapper = styled.div`
  height: 528px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
`;

const MaintainTag = styled.div`
  padding: 4px 6px;
  background: #768B9B;
  border-radius: 3px;
  font-size: 12px;
  transform: scale(0.889);
  line-height: 10px;
  color: #fff;
  margin-left: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MatchDetail = ({summoner, match, myMatch, openIndex, region, isUrf,hide,onChangeHide}) => {

    const {t} = useTranslation();





    return (
        <HeaderWrapper
            borderColor={myMatch.is_win ? '#5D9CEB' : '#E37E74'}>
            <Tabs
                defaultIndex={openIndex}
            >
                <TabHeaderWrapper>
                    <TabList>
                        <Tab>{t('tabs.aiAnalysis')}</Tab>
                        {!isUrf && <Tab disabled={true}>{t('tabs.aiGraph')}
                            <MaintainTag>{t('service.maintenance')}</MaintainTag>
                        </Tab>}
                    </TabList>
                    <ButtonWrapper>
                        <DetailHideSummonerName
                            hide={hide}
                            onChangeHide={onChangeHide}
                        />
                        <DetailShareTag
                            region={region}
                            summoner={summoner}
                            match={match}
                        />
                    </ButtonWrapper>
                </TabHeaderWrapper>
                <TabPanel>
                    <React.Suspense fallback={
                        <LoadingWrapper>
                            <Loading/>
                        </LoadingWrapper>}>
                        <GameAnalytics
                            hide={hide}
                            isUrf={isUrf}
                            region={region}
                            match={match}
                            myMatch={myMatch}
                        />
                    </React.Suspense>
                </TabPanel>
                {!isUrf && <TabPanel>
                    <React.Suspense fallback={
                        <LoadingWrapper>
                            <Loading/>
                        </LoadingWrapper>}>
                        <ConsWrapper>
                            공사중
                        </ConsWrapper>
                        {/*<DetailTab2 match={match} myMatch={myMatch}/>*/}
                    </React.Suspense>
                </TabPanel>}
            </Tabs>
            <div>
            </div>
        </HeaderWrapper>
    );
};

export default React.memo(MatchDetail);
